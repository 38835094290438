import React, { useEffect, useRef, useState } from "react";
import { MapContainer, ImageOverlay, Rectangle } from "react-leaflet";
import FloorPlanService from "services/FloorPlanService";
import { FLOORSECTION_URL } from "config/api";
import * as L from "leaflet";
import LeafletToolTip from "../LeafletMaps/custom-components/LeafletToolTip";
import pastelColors from "config/pastelColors";

const floorPlanService = new FloorPlanService();
function AlertMapView({ floorPlanId }) {
  const mapRef = useRef();
  const bounds = [
    [0, 0],
    [500, 500],
  ];
  const [alertMap, setAlertMap] = useState();
  const [mapLayer, setMapLayer] = useState(null);

  const shapePathOptions = () => {
    return {
      color: "red",
      fillColor: "red",
      fillOpacity: 0.3,
    };
  };

  useEffect(() => {
    if (!floorPlanId) return;
    try {
      floorPlanService
        .getFloorPlanByID(FLOORSECTION_URL, {
          id: floorPlanId,
        })
        .then((data) => {
          setAlertMap(data[0]);
          // if (!!mapLayer) {
          //   const polyCoords =
          //     data[0]?.floorPlanSectionPlot?.geometry?.coordinates;
          //   if (!polyCoords) return;
          //   mapLayer.fitBounds(L.polygon(polyCoords).getBounds(), {
          //     padding: [5, 5],
          //   });
          // }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      alert(err.message);
    }
  }, [floorPlanId, mapLayer]);

  const onMapLayerReady = (mapRef) => {
    setMapLayer(mapRef.target);
  };

  return (
    <MapContainer
      whenReady={(mapRef) => onMapLayerReady(mapRef)}
      className="MapContainer"
      style={{
        position: "absolute",
        height: "280px",
        width: (280 * 1.25).toString() + "px",
        overflow: "hidden",
      }}
      center={{ lat: 50, lng: 250 }}
      zoom={1}
      zoomControl={false}
    >
      <ImageOverlay
        className="object-contain my-4 relative OriginalMap"
        url={
          alertMap?.floorPlan?.imgUrl ||
          "https://parchai-ble-floorplans.s3.ap-south-1.amazonaws.com/public/floorImages/office-layout-plan.png"
        }
        bounds={bounds}
      />
      {/* {!!alertMap && (
        <Rectangle
          pathOptions={shapePathOptions()}
          bounds={
            alertMap?.floorPlanSectionPlot?.geometry?.coordinates || [0, 0]
          }
        >
          <LeafletToolTip
            label={alertMap?.floorPlanSectionPlot?.properties?.label || ""}
          />
        </Rectangle>
      )} */}
    </MapContainer>
  );
}

export default AlertMapView;
