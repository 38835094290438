import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import DateTimeService from "services/DateTimeService";
import ShiftService from "services/ShiftService";
import SWALService from "services/SWALService";

function ShiftTable({ getData, data, loading, setTableConfig }) {
  const history = useHistory();

  const editShift = (ShiftId) => history.push(`/admin/shift/edit/${ShiftId}`);

  const deleteShift = async (ShiftId) => {
    console.log(ShiftId);
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await ShiftService.delete(ShiftId).catch((err) =>
      SWALService.showError(err),
    );

    getData();

    SWALService.showSuccess("Shift has been deleted.", "Deleted!");
  };

  const today = new Date();

  const ShiftTableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Start Time",
      accessor: ({ startTime }) =>
        DateTimeService.getTimeString(
          today.toISOString().slice(0, today.toISOString().indexOf("T") + 1) +
            startTime +
            "Z",
        ),
    },
    {
      Header: "End Time",
      accessor: ({ endTime }) =>
        DateTimeService.getTimeString(
          today.toISOString().slice(0, today.toISOString().indexOf("T") + 1) +
            endTime +
            "Z",
        ),
    },
    {
      Header: "Applied Days",
      accessor: (d) => DateTimeService.getDaysFromArray(d?.daysOfWeek),
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editShift}
          deleteCallback={deleteShift}
        />
      ),
    },
  ];

  useEffect(() => {
    setTableConfig(ShiftTableConfig);
  }, []);

  return (
    <BaseTable tableConfig={ShiftTableConfig} data={data} loading={loading} />
  );
}

export default ShiftTable;
