import React, { useEffect, useState } from "react";
import BaseSelect from "./BaseSelect";

const demoRegistrationNumber = [
  {
    value: "demo1010",
    label: "demo1010",
  },
  {
    value: "demo1020",
    label: "demo1020",
  },
  {
    value: "demo1030",
    label: "demo1030",
  },
];

function VehicleRegistrationSelect({
  value,
  setValue,
  isMultiSelect,
  noLabel,
  noPopulate,
}) {
  const [vehicleReg, setVehicleReg] = useState([]);

  useEffect(() => {
    setVehicleReg(demoRegistrationNumber);
  }, [noPopulate]);

  return (
    <BaseSelect
      label={!noLabel && "Registration Number"}
      dataSource={vehicleReg}
      placeholder={"Select registration number"}
      closeMenuOnSelect={false}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default VehicleRegistrationSelect;
