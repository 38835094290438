import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import { BUILDING_URL } from "config/api";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import BuildingService from "services/BuildingService";
import SWALService from "services/SWALService";

function BuildingTable({ getData, data, loading, setTableConfig }) {
  console.log(data);
  const history = useHistory();

  const editBuilding = (buildingId) =>
    history.push(`/admin/building/edit/${buildingId}`);

  const deleteBuilding = async (buildingId) => {
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await BuildingService.deleteBuilding(BUILDING_URL, buildingId, true)
      .then((res) => {
        SWALService.showSuccess("Building has been deleted.", "Deleted!");
      })
      .catch((err) => SWALService.showError(err));

    getData();
  };

  const buildingTableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Campus",
      accessor: "campus.name",
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editBuilding}
          deleteCallback={deleteBuilding}
        />
      ),
    },
  ];

  useEffect(() => {
    setTableConfig(buildingTableConfig);
  }, []);

  return (
    <BaseTable
      tableConfig={buildingTableConfig}
      data={data}
      loading={loading}
    />
  );
}

export default BuildingTable;
