import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import SelectionRoomCard from "./SelectionRoomsCard";
import SelectionFloorCard from "./SelectionFloorCard";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import axios from "axios";
import { FLOOR_URL, FLOORSECTION_URL } from "../../../../../../config/api";

function AddRestriction() {
  const [restriction, setRestriction] = React.useState("");
  const [rooms, setRooms] = React.useState([]);
  const [floors, setFloors] = React.useState([]);
  const [beacons, setBeacons] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const roomName = "Room";
  const floorName = "Floor";
  const [gender, setGender] = React.useState("All");
  const [floor, setFloor] = React.useState("");
  const auth_key = localStorage.getItem(
    "CognitoIdentityServiceProvider.5ukisilcr03ndg9j0d1ijvoqih.e55556a7-a7a4-44a2-9010-d647460b7d32.accessToken"
  );
  const headers = {
    "Content-Type": "application/json",
    Authorization: auth_key,
  };

  React.useEffect(() => {
    axios
      .get(`${FLOOR_URL}?all=true`, headers)
      .then((response) => {
        console.log(response);
        setFloors(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  /*React.useEffect(() => {
      axios.get(`${BEACON_URL}?all=true`, headers).then((response) => {
        console.log(response);
        setFloors(response.data.data);
      }).catch((error) => { console.log(error) });
    }, []);*/

  const onChangeRestrictNm = (event) => {
    const {
      target: { name, value },
    } = event;
    setRestriction({ [name]: value });
  };

  const onChangeGender = (event) => {
    console.log(event.target.value);
    const {
      target: { name, value },
    } = event;
    setGender({ [name]: value });
  };

  const onChangeFloor = (event) => {
    console.log(event.target.value);
    const {
      target: { name, value },
    } = event;
    setFloor({ [name]: value });
    fetchRooms(value);
  };

  const fetchRooms = (floor_id) => {
    axios
      .get(`${FLOORSECTION_URL}?floorPlanId=${floor_id}`, headers)
      .then((response) => {
        console.log(response.data.data);
        setRooms(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangeRoom = (event) => {
    console.log(event.target.value);
    const {
      target: { name, value },
    } = event;
  };

  return (
    <div className="content">
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Add Restriction</CardTitle>
        </CardHeader>
        <CardBody>
          <Form className="form-horizontal">
            <Row>
              <Label md="3">Restriction Name</Label>
              <Col md="9">
                <FormGroup>
                  <Input
                    placeholder="Restriction name"
                    type="text"
                    onChange={onChangeRestrictNm}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label md="3">Gender</Label>
              <Col md="3">
                <FormGroup check className="form-check-radio">
                  <Label check>
                    <Input
                      type="radio"
                      name="gender"
                      value="All"
                      onChange={(e) => onChangeGender(e)}
                    />
                    <span className="form-check-sign" />
                    All
                  </Label>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup check className="form-check-radio">
                  <Label check>
                    <Input
                      type="radio"
                      name="gender"
                      value="Male"
                      onChange={(e) => onChangeGender(e)}
                    />
                    <span className="form-check-sign" />
                    Male
                  </Label>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup check className="form-check-radio">
                  <Label check>
                    <Input
                      type="radio"
                      name="gender"
                      value="Female"
                      onChange={(e) => onChangeGender(e)}
                    />
                    <span className="form-check-sign" />
                    Female
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Label md="3">Time Constraints</Label>
              <Col md="3">
                <FormGroup>
                  <ReactDatetime
                    dateFormat={false}
                    inputProps={{
                      className: "form-control",
                      placeholder: "From",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <ReactDatetime
                    dateFormat={false}
                    inputProps={{
                      className: "form-control",
                      placeholder: "To",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Input type="number" placeholder="Thresold (minutes)" />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label md="3">Groups Filter</Label>
              <Col md="9">
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Groups Filter"
                  name="groupsFilter"
                  closeMenuOnSelect={false}
                  isMulti
                  value={groups}
                  onChange={(value) => setGroups(value)}
                  options={[
                    {
                      value: "",
                      label: "Groups Filter",
                      isDisabled: true,
                    },
                    { value: "2", label: "Cafeteria " },
                    { value: "3", label: "Auditorium" },
                    { value: "4", label: "Laboratory" },
                  ]}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Label md="3">Beacons Filter</Label>
              <Col md="9">
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Beacons Filter"
                  name="beaconsFilter"
                  closeMenuOnSelect={false}
                  isMulti
                  value={beacons}
                  onChange={(value) => setBeacons(value)}
                  options={[
                    {
                      value: "",
                      label: "Beacons Filter",
                      isDisabled: true,
                    },
                    { value: "2", label: "AC233FA51505" },
                    { value: "3", label: "AC233FA51504" },
                    { value: "4", label: "AC233FA514F6" },
                    { value: "5", label: "AC233FA515BF" },
                    { value: "6", label: "AC233FA515BC" },
                    { value: "7", label: "AC233FA515BB" },
                  ]}
                />
              </Col>
            </Row>
            <SelectionFloorCard
              header={floorName}
              selectables={floors}
              onPressHandler={onChangeFloor}
            />
            <br />
            <SelectionRoomCard
              header={roomName}
              selectables={rooms}
              onPressHandler={onChangeRoom}
            />
            <br />
            <br />
            <Button color="info">Add Restriction</Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}

export default AddRestriction;
