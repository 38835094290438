import React from "react";
import BaseTextInput from "./BaseTextInput";

function NameInput({ value, setValue }) {
  return (
    <>
      <BaseTextInput
        label={"Name"}
        placeholder={"Enter name"}
        name={"fullname"}
        value={value}
        setValue={setValue}
      />
    </>
  );
}

export default NameInput;
