import React, { useEffect, useState } from "react";
import FloorPlanService from "services/FloorPlanService";
import BaseSelect from "./BaseSelect";

const floorPlanService = new FloorPlanService();

function FloorPlanSelect({
  value,
  setValue,
  isMultiSelect,
  noLabel,
  context = {},
  noPopulate,
}) {
  const [_floorPlans, _setFloorPlans] = useState([]);

  useEffect(() => {
    if (noPopulate) return;
    const params = { ...(context || {}) };

    floorPlanService
      .get(params)
      .then(({ data }) => _setFloorPlans(data))
      .catch((err) => console.log(err));
  }, [...Object.values(context), noPopulate]);

  return (
    <BaseSelect
      label={!noLabel && "Floorplan"}
      dataSource={_floorPlans?.map((floorPlan) => ({
        value: floorPlan?._id,
        label: floorPlan?.name,
      }))}
      placeholder={"Select Floorplan"}
      closeMenuOnSelect={false}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default FloorPlanSelect;
