import React from "react";
import BaseTimePicker from "./BaseTimePicker";

function ToTimePicker({ date, setDate, bottomHintText }) {
  return (
    <BaseTimePicker
      placeholder={"To Time"}
      label="To Time"
      value={date || null}
      changeValue={(val) => setDate(val)}
      bottomHintText={bottomHintText}
    />
  );
}

export default ToTimePicker;
