import BeaconInput from "components/UserInputComponents/Inputs/TextInputs/BeaconInput";
import BeaconModelSelect from "components/UserInputComponents/Selects/BeaconModelSelect";
import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { useSelector } from "react-redux";

function BeaconFilters({ setParams }) {
  const orgId = useSelector((state) => state.organization._id);
  const [beacons, setBeacons] = useState("");
  const [macId, setMacId] = useState("");
  const [beaconModels, setBeaconModels] = useState([]);

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <BeaconInput className="mb-4" setValue={setBeacons} value={beacons} />
        <BeaconInput
          className="mb-4"
          setValue={setMacId}
          value={macId}
          label={"Mac ID"}
          placeholder={"Enter mac id"}
        />
        <BeaconModelSelect value={beaconModels} setValue={setBeaconModels} />
        <Button
          color="info"
          onClick={() =>
            setParams({
              organizationId: orgId,
              beaconName: beacons.replace(" ", "_") || "",
              macId: macId || "",
              model: beaconModels,
            })
          }
        >
          Apply
        </Button>
      </CardBody>
    </Card>
  );
}

export default BeaconFilters;
