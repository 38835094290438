const DefaultUserRoles = [
  "DRIVER",
  "ASSET",
  "EMPLOYEE",
  "SECURITY",
  "HOUSE_KEEPING_STAFF",
  "VISITOR",
  "TRANSPORT_HELPER",
];
const DomainSpecificUserRoles = {
  SCHOOL: [
    ...DefaultUserRoles,
    "TEACHING_STAFF",
    "NON_TEACHING_STAFF",
    "STUDENT",
  ],
  CORPORATES: [...DefaultUserRoles],
  FIRM: [
    "EMPLOYEE",
    "SECURITY",
    "DRIVER",
    "TRANSPORT_HELPER",
    "ASSET",
    "VISITOR",
  ],
};

const UserRoles = [
  ...DomainSpecificUserRoles.SCHOOL,
  ...DomainSpecificUserRoles.CORPORATES,
];

const AccessRoles = ["ADMIN", "MANAGER", "OPERATOR", "USER"];

export { DomainSpecificUserRoles, UserRoles, AccessRoles };
