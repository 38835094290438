import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import GateService from "services/GateService";
import SWALService from "services/SWALService";

function GateTable({ getData, data, loading, setTableConfig }) {
  const history = useHistory();

  const editGate = (gateId) => history.push(`/admin/gate/edit/${gateId}`);

  const deleteGate = async (gateId) => {
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await GateService.delete(gateId).catch((err) => SWALService.showError(err));

    getData();

    SWALService.showSuccess("Gate has been deleted.", "Deleted!");
  };

  const GateTableConfig = [
    {
      Header: "Name",
      accessor: "label",
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editGate}
          deleteCallback={deleteGate}
        />
      ),
    },
  ];

  useEffect(() => {
    setTableConfig(GateTableConfig);
  }, []);

  return (
    <BaseTable tableConfig={GateTableConfig} data={data} loading={loading} />
  );
}

export default GateTable;
