import LocalStorage from "./storage";
import { Auth } from "aws-amplify";
import { isEmpty } from "lodash";
import moment from "moment";
class UtilsService {
  CheckUserAlreadyLoggedIn() {
    const items = { ...localStorage };
    for (let item in items) {
      if (!!item.includes(".userData")) {
        const localstorage = new LocalStorage(item, "");
        const userdata = localstorage.getLocalStorage();
        const user_data = JSON.parse(userdata);
        console.log(user_data);
        const key = user_data.UserAttributes[0].Name;
        if (key === "sub") {
          const subvalue = JSON.parse(userdata).UserAttributes[0].Value;
          if (subvalue !== undefined || subvalue !== null) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    }
  }

  getCurrentAuthenticatedUser() {
    Auth.currentAuthenticatedUser()
      .then((data) => {
        console.log(data.signInUserSession.idToken.jwtToken);
        return data.signInUserSession.idToken.jwtToken;
      })
      .catch(() => this.props.history.push("/auth"));
  }

  ConvertObjectToKeyValArray(object) {
    const keys = [];
    const values = [];
    const objectArray = Object.entries(object);
    objectArray.forEach(([key, value]) => {
      keys.push(key);
      values.push(value);
    });
    return {
      keys: keys,
      values: values,
    };
  }

  getQueryParamString(queryParamObj, invalidateCacheParam, defaultGetParams) {
    const queryObj = Object.assign(
      {},
      invalidateCacheParam,
      defaultGetParams,
      queryParamObj
    );
    const queryParamArr = this.generateArray(queryObj);
    let queryParamString = "";
    for (const queryParam of queryParamArr) {
      queryParamString += queryParam["key"] + "=" + queryParam["value"] + "&";
    }
    queryParamString = queryParamString.slice(0, -1);
    return queryParamString;
  }

  generateArray(obj) {
    return Object.keys(obj).map((key) => {
      return { key: key, value: obj[key] };
    });
  }

  CleanObject(obj) {
    for (var key in obj) {
      if (obj[key] !== null) {
        if (obj[key] !== undefined || obj[key] !== null) {
          if (typeof obj[key] === "object") {
            if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
            } else {
              this.CleanObject(obj[key]);
            }
          } else if (Array.isArray(obj[key])) {
            if (obj[key].length === 0) {
              delete obj[key];
            }
          } else {
            if (
              obj[key] === null ||
              obj[key] === undefined ||
              obj[key] === "" ||
              obj[key].length === 0
            ) {
              delete obj[key];
            }
          }
        }
      }
    }
    return obj;
  }

  cleanObj = (obj) =>
    Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

  removeObjectIfEmpty(obj) {
    for (var key in obj) {
      if (typeof obj[key] === "object") {
        if (!!isEmpty(obj[key])) {
          delete obj[key];
        } else {
          this.removeObjectIfEmpty(obj[key]);
        }
      }
    }
    return obj;
  }

  removeObjectIfNull(obj) {
    for (var key in obj) {
      if (typeof obj[key] === "object") {
        if (obj[key] === null) {
          delete obj[key];
        } else {
          this.removeObjectIfNull(obj[key]);
        }
      }
    }
    return obj;
  }

  convertUTCToISTTimeStamp(time) {
    const dt = new Date(time);
    const formattedString =
      dt.getFullYear() +
      "-" +
      (dt.getMonth() + 1) +
      "-" +
      dt.getDate() +
      " " +
      dt.getHours() +
      ":" +
      dt.getMinutes() +
      ":" +
      dt.getSeconds();
    return formattedString;
  }

  convertMomentTimestamp(timestamp) {
    return moment(timestamp).utc().format("llll");
  }

  convertToUTC(timestamp) {
    return new Date(new Date(timestamp).toUTCString()).toISOString();
  }

  getEightHoursPreviousTime(timestamp) {
    return moment
      .utc(timestamp)
      .subtract(8, "hours")
      .format("YYYY-MM-DD HH:mm:ss");
  }

  removeEmptyKeysInObject(obj) {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );
  }

  removeURLQueryStringParams(url) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split("?")[0];
    return urlparts;
  }

  groupBy = (fn, list) =>
    list.reduce(
      (prev, next) => ({
        ...prev,
        [fn(next)]: [...(prev[fn(next)] || []), next],
      }),
      {}
    );

  collectBy = (fn, list) => Object.values(this.groupBy(fn, list));
}

export default UtilsService;
