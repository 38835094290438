import types from "../types";

export const login = (payload) => {
  return {
    type: types.LOGGED_IN,
    payload: payload,
  };
};

export const logout = () => {
  return {
    type: types.LOGGED_OUT,
  };
};

const authActions = {
  login,
  logout,
};

export default authActions;
