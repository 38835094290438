import axios from "axios";
import { FLOOR_URL, FLOOR_BULK_UPLOAD_URL } from "config/api";
import ApiService from "./ApiService";
import BaseService from "./BaseService";

export default class FloorPlanService extends ApiService {
  constructor() {
    super(FLOOR_URL);
    this.baseService = new BaseService();
  }

  getAllFloorPlan(url, method, params, headers) {
    return this.baseService
      .doGET(url, method, params, headers)
      .then((response) => {
        if (!!response.data?.success) {
          return response.data.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getFloorPlanNameID(url, method, params, headers) {
    return this.baseService
      .doGET(url, method, params, headers)
      .then((response) => {
        if (!!response.data?.success) {
          let data = response.data.data;
          let floorplans_array = [];
          data.forEach((item) => {
            let floorplan_obj = { label: item.name, value: item._id };
            floorplans_array.push(floorplan_obj);
          });
          return floorplans_array;
        }
      });
  }

  getFloorPlanByID(url, params, headers) {
    return this.baseService
      .doGET(url, "GET", params, headers)
      .then((response) => {
        if (!!response.data?.success) {
          return response.data.data;
        }
      });
  }

  getFloorPlanByBuildingID(url, params, headers) {
    return this.baseService
      .doGET(url, "GET", params, headers)
      .then((response) => {
        if (!!response.data?.success) {
          return response.data.data;
        }
      });
  }

  createFloorPlan(url, data, headers) {
    return this.baseService
      .doPOST(url, "POST", data, headers)
      .then((response) => {
        if (!!response.data?.success) {
          return {
            msg: response.data.message,
            data: response.data.data,
          };
        }
      });
  }

  updateFloorPlan(url, data, params, headers) {
    return this.baseService
      .doPUT(url, "PUT", data, params, headers)
      .then((response) => {
        console.log(response);
        if (!!response.data?.success) {
          return {
            msg: response.data.message,
            data: response.data.data,
          };
        }
      });
  }

  deleteFloorPlan(url, params, headers) {
    return this.baseService
      .doDELETE(url, "DELETE", params, headers)
      .then((response) => {
        if (!!response.data?.success) {
          return {
            msg: response.data.message,
          };
        }
      });
  }
}
