import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import ActionTypeSelect from "components/UserInputComponents/Selects/ActionTypeSelect";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Form } from "reactstrap";

function ActionFilter({ setActionParams }) {
  const orgId = useSelector((state) => state.organization._id);
  const [actionName, setActionName] = useState("");
  const [actionType, setActionType] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setActionParams({
      actionName: actionName,
      actionType: actionType,
      organizationId: orgId,
    });
  };

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <BaseTextInput
            label="Action Name"
            placeholder="Enter action name"
            value={actionName}
            setValue={setActionName}
          />
          <ActionTypeSelect value={actionType} setValue={setActionType} />
          <br />
          <BaseButton>Apply</BaseButton>
        </Form>
      </CardBody>
    </Card>
  );
}

export default ActionFilter;
