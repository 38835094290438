import ChartColors from "./ChartColors";

const defaultOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    bodySpacing: 4,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
    xPadding: 10,
    yPadding: 10,
    caretPadding: 10,
  },
  responsive: 1,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
          // max: 100,
        },
        gridLines: {
          zeroLineColor: "transparent",
          drawTicks: false,
          display: false,
          drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
          max: 5,
        },

        gridLines: {
          zeroLineColor: "transparent",
          // drawTicks: false,
          display: false,
          drawBorder: false,
        },
      },
    ],
  },
  layout: {
    padding: { left: 0, right: 0, top: 15, bottom: 15 },
  },
};

const getCustomOptions = (xAxesTickConfig, yAxesTickConfig) => {
  const { scales } = defaultOptions;
  scales.xAxes.ticks = xAxesTickConfig;
  scales.yAxes.ticks = yAxesTickConfig;

  return { ...defaultOptions, ...scales };
};

const createData = (canvas, labels, data, title, chartColor) => {
  const ctx = canvas.getContext("2d");

  if (!chartColor?.opaque) chartColor = ChartColors.info;

  const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
  gradientStroke.addColorStop(0, chartColor?.opaque);
  gradientStroke.addColorStop(1, chartColor?.translucent);

  const gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
  gradientFill.addColorStop(1, chartColor?.translucent);
  gradientFill.addColorStop(0, chartColor?.transparent);

  return {
    labels,
    datasets: [
      {
        label: title,
        borderColor: gradientStroke,
        pointBorderColor: "#FFF",
        pointBackgroundColor: chartColor?.opaque,
        pointBorderWidth: 1,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 1,
        pointRadius: 4,
        fill: true,
        backgroundColor: gradientFill,
        borderWidth: 2,
        data,
      },
    ],
  };
};

const LineChartConfig = {
  getCustomOptions,
  createData,
  options: defaultOptions,
};

export default LineChartConfig;
