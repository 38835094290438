import { USER_URL } from "config/api";
import ApiService from "./ApiService";

class UserService extends ApiService {
  constructor() {
    super(USER_URL);
  }
}

export default new UserService();
