import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput.js";

function BaseNumberInput({
  label,
  placeholder,
  name,
  value,
  setValue,
  editable,
  validator,
}) {
  return (
    <BaseTextInput
      label={label}
      placeholder={placeholder}
      type={"number"}
      name={name}
      maxLength="100"
      value={value}
      setValue={(val) => setValue(val)}
      editable={editable}
      validator={validator}
    />
  );
}

export default BaseNumberInput;
