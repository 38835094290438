import React, { useEffect, useState } from "react";
import BaseSelect from "./BaseSelect";
import BeaconService from "services/BeaconService";
import { useSelector } from "react-redux";

function BeaconMacSelect({
  value,
  setValue,
  unassigned,
  isMultiSelect,
  pickKey = "macId",
}) {
  const [beacons, setBeacons] = useState([]);

  const { _id: organizationId } = useSelector((s) => s.organization);

  const fetchBeacons = async () => {
    const params = { organizationId };

    if (unassigned) params.unassigned = true;

    const { data } = await BeaconService.get(params).catch((err) =>
      console.error(err)
    );

    if (data) setBeacons(data);
  };

  useEffect(() => {
    fetchBeacons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseSelect
      label={"Beacons"}
      dataSource={beacons.map((beacon) => {
        const { macId } = beacon;
        return {
          value: beacon[pickKey],
          label: macId,
        };
      })}
      placeholder={"Select Beacon"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default BeaconMacSelect;
