import BaseTable from "components/Tables/BaseTable/BaseTable";
import BaseSwitchInput from "components/UserInputComponents/Inputs/Switch/BaseSwitchInput";
import BaseTextBoxInput from "components/UserInputComponents/Inputs/TextBoxInputs/BaseTextBoxInput";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import React, { useState } from "react";
import { Row } from "reactstrap";
import gatewayBeaconTableConfig from "./gatewayBeaconTableConfig";

function GatewayBeaconTable({ data, loading, getData, kalmanStatus }) {
  const [beaconMac, setBeaconMac] = useState();
  const [cutOff, setCutOff] = useState(-99);
  const [kalman, setKalman] = useState(false);

  const toggle = (v) => {
    setKalman(v);
    kalmanStatus(v);
  };

  return (
    <>
      <Row>
        <BaseTextInput
          value={beaconMac}
          setValue={setBeaconMac}
          label="BeaconMac to filter"
        />

        <BaseTextInput
          value={cutOff}
          setValue={setCutOff}
          label="Cut Off RSSI"
        />
        <BaseSwitchInput
          label="switch on kalman"
          value={kalman}
          setValue={toggle}
        />
      </Row>

      <BaseTable
        data={data
          ?.filter(({ mac }) => mac.match(new RegExp(beaconMac)))
          .filter((el) => el.rssi > cutOff)}
        tableConfig={gatewayBeaconTableConfig}
        loading={loading}
      />
    </>
  );
}

export default GatewayBeaconTable;
