import FilterTableHolder from "components/Holders/FilterTableHolder/FilterTableHolder";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SWALService from "services/SWALService";
import GateService from "services/GateService";
import GateFilter from "./subComponent/GateFilter";
import GateTable from "./subComponent/GateTable";

function GateListing() {
  const organizationId = useSelector((state) => state.organization._id);
  // const organizationId = "621642aa5656450009819ffc";
  const defaultParams = { organizationId };
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gateParams, setGateParams] = useState({});
  const [tableConfig, setTableConfig] = useState([]);

  useEffect(() => {
    fetchGates();
  }, [gateParams]);

  const fetchGates = async () => {
    setLoading(true);

    const params = {
      ...gateParams,
      ...defaultParams,
    };

    const { data } = await GateService.get(params).catch((err) =>
      SWALService.showError(err)
    );

    if (data) setData(data);
    setLoading(false);
  };

  const addGate = () => history.push("/admin/gate/add");

  return (
    <FilterTableHolder>
      <GateFilter setParams={setGateParams} />
      <TableHeader
        title="Gates"
        onClickAdd={addGate}
        onClickDownload={true}
        tableConfig={tableConfig}
        tableData={data}
      />
      <GateTable
        data={data}
        getData={fetchGates}
        loading={loading}
        setTableConfig={setTableConfig}
      />
    </FilterTableHolder>
  );
}

GateListing.routeName = "/admin/gate/manage";

export default GateListing;
