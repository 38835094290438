import React, { useState } from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import BrandLoader from "views/custom_components/Loader";
import NoData from "views/custom_components/NoData";
import DateTimeService from "../../../../../services/DateTimeService";
import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import TransportFilter from "./TransportFilter";
import toast from "react-hot-toast";
import AreaPresenceService from "services/AreaPresence.service";

function TransportReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [date, setDate] = useState("");

  const getAlerts = (queryParams) => {
    console.log({ queryParams });

    AreaPresenceService.get({ ...queryParams, hydrate: "asset,area" })
      .then((res) => {
        if (!res.data.length) {
          setData([]);
          return toast.error("No data to show");
        }
        setData(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const transportReportTableConfig = [
    {
      Header: "Name",
      accessor: "asset.name",
      Cell: ({ original }) => <div>{original?.asset?.name || "-"}</div>,
    },
    {
      Header: "Beacon Mac",
      accessor: "beaconMac",
      excludeFromUI: true,
    },
    {
      Header: "Unique ID",
      accessor: (d) => d?.asset?.uniqueId || "-",
    },
    {
      Header: "Gateway Mac",
      accessor: (d) => d.gatewayMac || "N/A",
      excludeFromUI: true,
    },

    {
      Header: "Vehicle",
      accessor: (d) => d?.area?.floorPlanSectionName || "N/A",
    },
    // {
    //   Header: "Place Type",
    //   accessor: "gatewayAttachType",
    // },
    {
      Header: "Entry Time",
      width: 200,
      accessor: (d) => DateTimeService.getDateTimeString(d?.createdAt),
    },
    {
      Header: "Exit Time",
      width: 200,
      accessor: (d) => DateTimeService.getDateTimeString(d?.updatedAt),
    },
  ];

  return (
    <>
      {isLoading && <BrandLoader />}
      <div className="content">
        <Row>
          <Col md={3}>
            <TransportFilter getAlerts={getAlerts} />
          </Col>
          <Col md={9}>
            <Card>
              <CardHeader>
                <TableHeader
                  fileTitle={`${data[0]?.area?.floorPlanSectionName}_${date}`}
                  title="Transport Reports"
                  onClickDownload={true}
                  tableConfig={transportReportTableConfig}
                  tableData={data}
                />
              </CardHeader>
              <CardBody>
                {!!data?.length && (
                  <>
                    <BaseTable
                      tableConfig={transportReportTableConfig}
                      data={data}
                      loading={isLoading}
                    />
                  </>
                )}
                {!data.length && (
                  <NoData
                    imgSrc="/illustrations/warning.svg"
                    message="Select filters and press on 'get reports'"
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default TransportReport;
