import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import SWALService from "services/SWALService";

import TableHeader from "components/Tables/TableHeader/TableHeader";

import ConfigureBeaconTable from "./subComponent/ConfigureBeaconTable";
import ConfigureBeaconFilter from "./subComponent/ConfigureBeaconFilter";
import ConfigureBeaconService from "services/ConfigureBeaconService";
import S3FileUpload from "views/components/S3FileUpload";
import ConfigureBeaconsModal from "views/components/ConfigureBeaconsModal";

function ConfigureBeacon() {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [beaconModalOpen, setBeaconModalOpen] = useState(false);
  const [beaconConfigs, setBeaconConfigs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({});
  const [tableConfig, setTableConfig] = useState([]);

  useEffect(() => {
    if (Object.keys(params).length) {
      getBeaconConfiguration();
    }
  }, [params]);

  const getBeaconConfiguration = async () => {
    setLoading(true);

    const {
      data: { data },
    } = await ConfigureBeaconService.getBeaconConfig({
      ...params,
    }).catch((err) => SWALService.showError(err));

    if (data) setBeaconConfigs(data);
    setLoading(false);
  };

  return (
    <div className="content">
      <S3FileUpload
        title="Upload Multiple Beacon For Configuration"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
      <ConfigureBeaconsModal
        title="Upload Multiple Beacon For Configuration"
        modalOpen={beaconModalOpen}
        setModalOpen={setBeaconModalOpen}
        getData={getBeaconConfiguration}
      />
      <Row>
        <Col md={3}>
          <ConfigureBeaconFilter setParams={setParams} />
        </Col>
        <Col md={9}>
          <Card>
            <CardHeader>
              <TableHeader
                title="Configure Beacons"
                onClickFileUpload={() => {
                  setModalOpen(true);
                }}
                onClickDownload={true}
                onClickAction={() => {
                  setBeaconModalOpen(true);
                }}
                icon="build"
                toolTipContent="Configure beacons"
                tableConfig={tableConfig}
                tableData={beaconConfigs}
              />
            </CardHeader>
            <CardBody>
              <ConfigureBeaconTable
                getData={getBeaconConfiguration}
                data={beaconConfigs}
                loading={loading}
                setTableConfig={setTableConfig}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ConfigureBeacon;
