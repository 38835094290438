import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import CreatableSelect from "react-select/creatable";
import { FormGroup, FormText, Label } from "reactstrap";

const createOption = (label) => ({
  label,
  value: label,
});

function BaseMultiTextInput({ label, value = [], setValue, validator }) {
  const [inputValue, setInputValue] = useState("");
  const [_value, _setValue] = useState([]);

  const [error, setError] = useState("");

  useEffect(() => {
    if (value?.length) value.map((label) => createOption(label));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateInput = (val) => {
    const error = validator?.(val);
    setError(error);
    return error;
  };

  const handleChange = (value) => {
    if (!value) value = [];
    _setValue(value);
    setValue(value?.map((v) => v.label));
  };

  const handleInputChange = (value) => {
    setInputValue(value);
    if (error) validateInput(value);
  };

  const handleKeyDown = (e) => {
    if (!inputValue) return;
    const keyPressed = e.key;
    if (keyPressed !== "Enter" && keyPressed !== "Tab") return;
    e.preventDefault();
    if (value.includes(inputValue))
      return toast.error(`"${inputValue}" already exists.`, {
        // id: "input_error_toast",
      });

    const error = validateInput(inputValue);

    if (error) return;

    setInputValue("");

    const newValues = [..._value, createOption(inputValue)];
    _setValue(newValues);
    setValue(newValues?.map((el) => el.label));
  };

  useEffect(() => {
    if (!value) return;

    const presetValues = value.map((v) => createOption(v));
    _setValue(presetValues);
  }, [value]);

  return (
    <FormGroup className="mb-4">
      <Label className="mb-2"> {label}</Label>
      <CreatableSelect
        components={{ DropdownIndicator: null }}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type something and press `Tab` to add"
        value={_value}
      />
      <FormText color="danger">{error}</FormText>
    </FormGroup>
  );
}

export default BaseMultiTextInput;
