import VisitorDashBoard from "./DashBoard/VisitorDashBoard";
import VisitorLogBook from "./LogBook/VisitorLogBook/VisitorLogBook";
import VisitorPolicies from "./Policies/VisitorPolicies";
import VisitorRecords from "./Records/VisitorRecords/VisitorRecords";

const VisitorRoutes = {
  collapse: true,
  name: "Visitors",
  rtlName: "صفحات",
  icon: "badge",
  module: "VISITOR_MANAGEMENT",
  state: "pagesCollapseVisitors",
  showVisible: true,
  views: [
    {
      path: "/visitors/dashboard",
      name: "Dashboard",
      rtlName: "عالتسعير",
      mini: "D",
      rtlMini: "ع",
      component: VisitorDashBoard,
      layout: "/admin",
      module: "VISITOR_MANAGEMENT",
      showVisible: true,
    },
    {
      path: "/visitors/log-book",
      name: "Logs",
      rtlName: "عالتسعير",
      mini: "L",
      rtlMini: "ع",
      component: VisitorLogBook,
      layout: "/admin",
      module: "VISITOR_MANAGEMENT",
      showVisible: true,
    },
    // {
    //   path: "/visitors/log-book/:purpose",
    //   name: "Logs",
    //   rtlName: "عالتسعير",
    //   mini: "L",
    //   rtlMini: "ع",
    //   component: VisitorLogBook,
    //   layout: "/admin",
    //   module: "VISITOR_MANAGEMENT",
    //   showVisible: false,
    // },
    {
      path: "/visitors/records",
      name: "Records",
      rtlName: "عالتسعير",
      mini: "RC",
      rtlMini: "ع",
      component: VisitorRecords,
      layout: "/admin",
      module: "VISITOR_MANAGEMENT",
      showVisible: true,
    },
    // {
    //   path: "/visitors/policies",
    //   name: "Policies",
    //   rtlName: "عالتسعير",
    //   mini: "P",
    //   rtlMini: "ع",
    //   component: VisitorPolicies,
    //   layout: "/admin",
    //   module: "VISITOR_MANAGEMENT",
    //   showVisible: true,
    // },
    {
      path: "/visitors/details",
      name: "Details",
      rtlName: "عالتسعير",
      mini: "P",
      rtlMini: "ع",
      component: VisitorLogBook,
      layout: "/admin",
      module: "VISITOR_MANAGEMENT",
      showVisible: false,
    },
    // {
    //   path: "/visitors/register",
    //   name: "Register",
    //   rtlName: "عالتسعير",
    //   mini: "RG",
    //   rtlMini: "ع",
    //   component: VisitorLogBook,
    //   layout: "/admin",
    //   module: "VISITOR_MANAGEMENT",
    //   showVisible: true,
    // },
  ],
};

export default VisitorRoutes;
