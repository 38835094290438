import IconButton from "components/UserInputComponents/IconButton/IconButton";
import React from "react";
import ReactTable from "react-table";
import BrandLoader from "views/custom_components/Loader";
import NoData from "views/custom_components/NoData";
import { Fade } from "reactstrap";

import "./BaseTable.scss";

function BaseTable({
  tableConfig,
  data = [],
  loading,
  emptyImgSrc,
  emptyMessage,
  noPagination,
  onRowClick,
  defaultSort,
  activeCond,
  filterable,
  isColorCoded,
  updateRow,
  isRowSelect,
  rowOpacityCallback,
}) {
  const _data = data.map((el, idx) => ({
    id: el._id ?? idx,
    ...el,
  }));
  const _tableConfig = tableConfig.filter((el) => !el.excludeFromUI);
  const getTrProps = (state, rowInfo, instance) => {
    const customRowProps = {};
    customRowProps.style = {};

    if (isColorCoded && rowInfo) {
      customRowProps.style = {
        ...customRowProps.style,
        background: rowInfo.original.inside ? "rgb(228,255,255)" : "white",
      };
    }
    if (rowOpacityCallback && rowInfo) {
      customRowProps.style = {
        ...customRowProps.style,
        opacity: rowInfo?.original
          ? rowOpacityCallback?.(rowInfo?.original)
          : 1,
      };
    }
    if (rowInfo?.original) {
      customRowProps.style = {
        ...customRowProps.style,
        background:
          rowInfo?.original && activeCond?.(rowInfo?.original)
            ? "rgba(0,0,0,0.05)"
            : "transparent",
        cursor: onRowClick ? "pointer" : "default",
      };

      customRowProps.onClick = () => onRowClick?.(rowInfo.original);
    }

    return customRowProps;
  };

  return (
    <Fade in={true}>
      <ReactTable
        className="-highlight my-4"
        minRows={10}
        data={_data}
        columns={_tableConfig?.map((col, idx) => ({ ...col, id: idx }))}
        loading={loading}
        getTrProps={getTrProps}
        showPaginationBottom={!noPagination}
        sortable
        showPageSizeOptions={false}
        minWidth={50}
        maxWidth={200}
        pageSize={10}
        resizable={false}
        showPageJump={false}
        filterable={filterable}
        PreviousComponent={(props) => (
          <IconButton
            {...props}
            color="info"
            className="pull-left"
            icon="arrow_back_ios"
          />
        )}
        NextComponent={(props) => (
          <IconButton
            {...props}
            color="info"
            className="pull-right"
            icon="arrow_forward_ios"
          />
        )}
        NoDataComponent={
          !filterable
            ? () => (
                <NoData
                  message={
                    emptyMessage || "Select filters and press on 'Apply'"
                  }
                  imgSrc={emptyImgSrc || "/illustrations/empty.svg"}
                  displayOverlay
                />
              )
            : undefined
        }
        loadingText={<BrandLoader noOverlay />}
      />
    </Fade>
  );
}

export default BaseTable;
