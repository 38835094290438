import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import TagSelect from "components/UserInputComponents/Selects/TagSelect";
import React, { useEffect, useState } from "react";
import { Popover } from "react-bootstrap";
import { Badge, Button, Card, CardBody, UncontrolledPopover } from "reactstrap";
import SubjectService from "services/SubjectService";
import BrandLoader from "views/custom_components/Loader";
import "./TagView.scss";

import toast from "react-hot-toast";

const subjectService = new SubjectService();

function TagView({ getData, tagId, primaryLabelKey, children }) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [subject, setSubject] = useState();

  const [tags, setTags] = useState([]);
  const [_primaryGroup, _setPrimaryGroup] = useState();
  const [primaryGroup, setPrimaryGroup] = useState();
  const [_tags, _setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const idName = `tagpopover-${tagId}`;

  useEffect(() => {
    if (tagId) fetchTags(tagId);
  }, [tagId]);

  const togglePopover = () => setPopoverOpen((prevState) => !prevState);

  const fetchTags = (tagId) => {
    try {
      subjectService
        .getById(tagId)
        .then(({ data }) => {
          setSubject(data);
          setTags(data.groups);
          _setTags(data.groups?.map((t) => t._id));
          _setPrimaryGroup(data.primaryGroup._id);
          setPrimaryGroup(data.primaryGroup);
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(true));
    } catch (error) {
      console.log("subjectData", error);
    }
  };

  // const revertToOriginalState = () => {
  //   setTags(_tags);
  // };

  const _onSave = async () => {
    togglePopover();
    const TOAST_ID = "TAGS_SAVE";

    toast.loading("Saving tag details...", { id: TOAST_ID });
    const subjectId = subject._id;
    delete subject._id;

    const payload = {
      ...subject,
      groups: _tags,
      primaryGroup: _primaryGroup,
    };

    const { success, message } = await subjectService
      .put(subjectId, payload)
      .catch((err) => toast.error("Something went wrong", { id: TOAST_ID }));
    if (!success) return toast.error("Something went wrong", { id: TOAST_ID });

    toast.success(message, { id: TOAST_ID });

    getData();
  };

  return (
    <div className="TagView">
      <div id={idName} onClick={togglePopover}>
        {children}
      </div>
      <UncontrolledPopover
        popperClassName="popover-default"
        placement="auto"
        target={idName}
        container="#portal"
        boundariesElement={`#${idName}`}
        boundary={`#${idName}`}
        content="element"
        // trigger="click"
        // hideArrow={true}
        isOpen={popoverOpen}
        toggle={togglePopover}
      >
        {loading ? (
          <div className="TagView__container">
            <Card className="mb-0 card-body ">
              <TagSelect
                value={_primaryGroup}
                label="Primary Group"
                setValue={_setPrimaryGroup}
              />
              <br />
              <TagSelect
                value={_tags}
                isMultiSelect
                setValue={_setTags}
                isCreatable
              />
              <br />
              <BaseButton onClick={_onSave}>Save</BaseButton>
            </Card>
          </div>
        ) : (
          <BrandLoader noOverlay />
        )}
      </UncontrolledPopover>
    </div>
  );
}

export default TagView;
