import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import AssetSelect from "components/UserInputComponents/Selects/AssetSelect";
import TagSelect from "components/UserInputComponents/Selects/TagSelect";
import Divider from "components/Divider/Divider";
import setQueryParams from "utils/setQueryParams";
import { useHistory } from "react-router-dom";
import FromTimePicker from "components/UserInputComponents/TimePickers/FromTimePicker";
import ToTimePicker from "components/UserInputComponents/TimePickers/ToTimePicker";

// TODO: Do the integration with Area Presence

function AssetPresenceFilter({ getAlerts }) {
  const history = useHistory();
  const orgId = useSelector((state) => state.organization?._id);

  const now = new Date();
  const todayMorning = DateTimeService.getMorningTime(now);
  const todayNight = DateTimeService.getMidnightTime(now);
  const [date, setDate] = useState(todayMorning);
  const [fromTime, setFromTime] = useState(todayMorning);
  const [toTime, setToTime] = useState(todayNight);
  const [roles, setRoles] = useState([]);
  const [assetId, setAssetId] = useState();

  const [primaryGroup, setPrimaryGroup] = useState();

  const handleFilters = () => {
    if (!(assetId || primaryGroup || roles.length))
      return toast.error(
        "Select an Asset, Primary Group, or a Role to proceed"
      );

    const payload = {
      organizationId: orgId,
      fromTime: DateTimeService.mergeDateAndTimeString(
        date.toISOString(),
        fromTime.toISOString()
      ),
      toTime: DateTimeService.mergeDateAndTimeString(
        date.toISOString(),
        toTime.toISOString()
      ),
      primaryGroup,
      assetId,
      showAll: true,
    };

    history.push({
      pathname: "/admin/asset-presence",
      search: `${setQueryParams(payload)}`,
    });

    getAlerts(payload);
  };

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <StartDatePicker label="Date" date={date} setDate={setDate} />

        <FromTimePicker date={fromTime} setDate={setFromTime} />
        <ToTimePicker date={toTime} setDate={setToTime} />

        <Divider className="my-4" />

        <TagSelect
          label="Group"
          value={primaryGroup}
          setValue={setPrimaryGroup}
        />

        <AssetSelect value={assetId} setValue={setAssetId} />

        <br />
        <Button color="info" onClick={handleFilters}>
          Get Reports
        </Button>
      </CardBody>
    </Card>
  );
}

export default AssetPresenceFilter;
