import OrganizationForm from "../../Organization/subComponent/OrganizationForm";

const organizationMenuItems = [
  {
    title: "Manage Organization",
    icon: "maps_home_work",
    target: OrganizationForm.routeName,
  },
  {
    title: "Manage Calender",
    icon: "event",
    target: "/admin/manage-calender",
  },
  {
    title: "Manage Schedules",
    icon: "calendar_month",
    target: "/admin/schedule/manage",
  },
  {
    title: "Manage Assets",
    icon: "groups",
    target: "/admin/all_subjects",
  },
  {
    title: "Manage Users",
    icon: "people",
    target: "/admin/all_users",
  },
  {
    title: "Manage Tags",
    icon: "style",
    target: "/admin/all_tags",
  },
  {
    title: "Manage Matters",
    icon: "subject",
    target: "/admin/all_matters",
  },
  {
    title: "Manage Vehicles",
    icon: "directions_bus",
    target: "/admin/vehicle/list",
  },
  {
    title: "Manage Routes",
    icon: "route",
    target: "/admin/route/list",
  },
  {
    title: "Manage Shift",
    icon: "av_timer",
    target: "/admin/shift/list",
  },
  {
    title: "Manage TransitItinerary",
    icon: "av_timer",
    target: "/admin/transitItinerary/list",
  },
];

export default organizationMenuItems;
