import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import LocalStorage from "../../../../../services/storage";
import BeaconService from "../../../../../services/BeaconService";
import { BEACON_BULK_UPLOAD_URL, BEACON_URL } from "../../../../../config/api";
import Swal from "sweetalert2";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import FormHolder from "components/Holders/FormHolder/FormHolder";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import Forms from "../../../../../config/copies/forms";
import BeaconModelSelect from "components/UserInputComponents/Selects/BeaconModelSelect";
import toast from "react-hot-toast";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import CSVparser from "views/components/CSVparser";
import CSVupload from "views/components/CSVupload";
import CSVuploadManual from "views/components/CSVuploadManual";
import SWALService from "services/SWALService";

const formatArr = [
  {
    value: "EDDYSTONE",
    label: "EDDYSTONE",
  },
  {
    value: "IBEACON",
    label: "IBEACON",
  },
  {
    value: "GATT",
    label: "GATT",
  },
];

const tableConfig = [
  {
    accessor: "beaconName",
    Header: "Becon Name",
  },
  {
    accessor: "macId",
    Header: "MAC ID",
  },
  {
    accessor: "model",
    Header: "Model",
  },
  {
    accessor: "iBeaconMajor",
    Header: "Becon Major",
  },
  {
    accessor: "iBeaconMinor",
    Header: "Becon Minor",
  },
];

function BeaconForm() {
  const { beaconId } = useParams();
  const history = useHistory();
  const buttonText = !!beaconId ? "Save Changes" : "Submit";
  const organizationId = useSelector((state) => state.organization._id);
  const [uuid, setUuid] = useState("");
  const [beaconName, setBeaconName] = useState("");
  const [model, setModel] = useState("");
  const [macId, setMacId] = useState("");
  const [majorId, setMajorId] = useState("");
  const [minorId, setMinorId] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [format, setFormat] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [beconData, setBeconData] = useState([]);
  const [manualModal, setManualModal] = useState(false);

  useEffect(() => {
    if (!beaconId) return;

    BeaconService.getById(beaconId)
      .then(({ data }) => {
        console.log("ha ha", data);
        setBeaconName(data.beaconName);
        setMacId(data.macId);
        setModel(data.model);
        setMajorId(data.iBeaconMajor);
        setMinorId(data.iBeaconMinor);
        // setUuid(data.uniqueId);
        // setPlaceId(data.placeId);
        let formatobj = {
          label: data.format,
          value: data.format,
        };
        setFormat(formatobj);
      })
      .catch((error) => {
        throw error;
      });
  }, []);

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      let beaconObj = {};
      if (macId === "") return toast.error("macId is required");
      beaconObj = {
        beaconName: beaconName,
        // uniqueId: uuid,
        macId: macId,
        model: model,
        iBeaconMajor: majorId,
        iBeaconMinor: minorId,
        // format: format.value,
        // placeId: placeId,
        organization: organizationId,
      };
      if (!beaconId)
        return BeaconService.post(beaconObj)
          .then(({ message }) => {
            Swal.fire({
              icon: "success",
              title: "Beacon Create",
              text: message,
            }).then(() => {
              history.push("/admin/beacon/manage");
            });
          })
          .catch((error) => console.log(error.message));

      BeaconService.put(beaconId, beaconObj).then(({ message }) => {
        Swal.fire({
          icon: "success",
          title: "Beacon Update",
          text: message,
        }).then(() => {
          history.push("/admin/beacon/manage");
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
  const clearEmptyRow = () => {
    const filterData = beconData.filter((object) => {
      return !!Object.keys(object).length;
    });
    setBeconData(filterData);
  };
  const handleBlukSubmit = (e) => {
    e.preventDefault();
    clearEmptyRow();
    beconData.forEach((obj) => {
      obj.organization = organizationId;
    });
    const payload = beconData;
    BeaconService.doPost(BEACON_BULK_UPLOAD_URL, payload, true)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Beacon Added",
          text: response.msg,
        }).then(() => {
          history.push("/admin/beacon/manage");
        });
      })
      .catch((error) => {
        SWALService.showError(error.message);
      });
  };

  return (
    <div className="content">
      <CSVupload
        title="Upload Multiple Beacon"
        modalOpen={modalOpen}
        data={beconData}
        setData={setBeconData}
        handleSubmit={handleBlukSubmit}
        setModalOpen={setModalOpen}
        tableConfig={tableConfig}
        setShowManual={setManualModal}
      />
      <CSVuploadManual
        setModalOpen={setManualModal}
        modalOpen={manualModal}
        gId={"242044155"}
      />
      <FormHolder
        imgSrc={Forms.BEACON.illustration}
        formHeader={Forms.BEACON.title}
        formDescription={Forms.BEACON.desc}
        CardFooterComponent=""
        formActions={
          <IconButton
            icon={"upload_file"}
            onClick={() => setModalOpen(true)}
            color={"info"}
            outlined
          />
        }
      >
        <Form onSubmit={handleSubmit}>
          <BaseTextInput
            label="Beacon Name"
            placeholder="Enter Beacon Name"
            type="text"
            value={beaconName}
            autoComplete="off"
            setValue={setBeaconName}
          />
          {/* <BaseTextInput
            label="UUID"
            placeholder="Enter UUID"
            type="text"
            value={uuid}
            autoComplete="off"
            setValue={setUuid}
          /> */}
          <BeaconModelSelect value={model} setValue={setModel} />
          {/* <BaseTextInput
            label="Model"
            placeholder="Enter model"
            type="text"
            value={model}
            autoComplete="off"
            setValue={setModel}
          /> */}
          {/* <Row className="separator">
            <Col sm="12">
              <label>Type</label>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                name="floorName"
                value={format}
                onChange={(value) => setFormat(value)}
                options={formatArr}
                placeholder="Type"
              />
            </Col>
          </Row> */}
          <BaseTextInput
            label="MAC Id"
            placeholder="Enter MAC Id"
            type="text"
            value={macId}
            autoComplete="off"
            setValue={setMacId}
          />
          <BaseTextInput
            label="Major Id"
            placeholder="Enter Major Id"
            type="text"
            value={majorId}
            autoComplete="off"
            setValue={setMajorId}
          />
          <BaseTextInput
            label="Minor Id"
            placeholder="Enter Minor Id"
            type="text"
            value={minorId}
            autoComplete="off"
            setValue={setMinorId}
          />
          {/* <BaseTextInput
            label="Place Id"
            placeholder="Enter Place Id"
            type="text"
            value={placeId}
            autoComplete="off"
            setValue={setPlaceId}
          /> */}
          <BaseButton color="info">{buttonText}</BaseButton>
        </Form>
      </FormHolder>
    </div>
  );
}

export default BeaconForm;
