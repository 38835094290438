import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import SWALService from "services/SWALService";

import TableHeader from "components/Tables/TableHeader/TableHeader";

import BeaconService from "../../../../services/BeaconService";
import BeaconFilters from "./subComponent/BeaconFilters";
import BeaconTable from "./subComponent/BeaconTable";

function Beacons() {
  const history = useHistory();
  const [beacons, setBeacons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({});
  const [tableConfig, setTableConfig] = useState([]);

  const organizationId = useSelector((state) => state.organization._id);
  const defaultParams = { organizationId };

  useEffect(() => {
    getBeacons();
  }, [params]);

  const getBeacons = async () => {
    setLoading(true);

    const { data } = await BeaconService.get({
      ...params,
      ...defaultParams,
    }).catch((err) => SWALService.showError(err));

    console.log({ data });
    if (data) setBeacons(data);
    setLoading(false);
  };

  const addBeacon = () => history.push(`/admin/beacon/add`);

  return (
    <div className="content">
      <Row>
        <Col md={3}>
          <BeaconFilters setParams={setParams} />
        </Col>
        <Col md={9}>
          <Card>
            <CardHeader>
              <TableHeader
                title="Beacons"
                onClickAdd={addBeacon}
                onClickDownload={true}
                tableConfig={tableConfig}
                tableData={beacons}
              />
            </CardHeader>
            <CardBody>
              <BeaconTable
                getData={getBeacons}
                data={beacons}
                loading={loading}
                setTableConfig={setTableConfig}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Beacons;
