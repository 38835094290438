import React from "react";
import {
  Provider,
  createStoreHook,
  createDispatchHook,
  createSelectorHook,
} from "react-redux";

import { createStore } from "redux";

import rootReducer from "./reducers";

import { composeWithDevTools } from "redux-devtools-extension";

// const storeContext = React.createContext(null);

// export const useStore = createStoreHook(storeContext);
// export const useDispatch = createDispatchHook(storeContext);
// export const useSelector = createSelectorHook(storeContext);

const initialState = {};

const centralStore = createStore(rootReducer, composeWithDevTools());

export function StoreProvider({ children }) {
  return <Provider store={centralStore}>{children}</Provider>;
}
