const devicesMenuItems = [
  {
    title: "Manage Beacon",
    icon: "settings_remote",
    target: "/admin/beacon/manage",
  },
  {
    title: "Manage Gateway",
    icon: "router",
    target: "/admin/gateway/manage",
  },
  {
    title: "Configure Beacons",
    icon: "settings_remote",
    target: "/admin/beacon/configure",
  },
];

export default devicesMenuItems;
