import { ASSET_CHANGE_URL } from "config/api";
import ApiService from "./ApiService";

class AssetChangeService extends ApiService {
  constructor() {
    super(ASSET_CHANGE_URL);
  }
}

export default new AssetChangeService();
