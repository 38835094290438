import classNames from "classnames";
import Icon from "components/Icon/Icon";
import React from "react";
import Button from "reactstrap/lib/Button";

import "./IconButton.scss";

function IconButton({
  icon,
  onClick,
  color,
  outlined,
  naked,
  className,
  ...props
}) {
  return (
    <Button
      {...props}
      className={
        `IconButton btn-icon ${className} ` +
        classNames({
          "btn-simple": outlined,
          "btn-link": naked,
        })
      }
      onClick={onClick}
      color={color}
    >
      <Icon icon={icon} clickable={true} />
    </Button>
  );
}

export default IconButton;
