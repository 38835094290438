import React from "react";
import PolicyListing from "./subComponents/PolicyListing";
import { Row } from "reactstrap";
import "./policy.css";

function ManagePolicies() {
  return <PolicyListing />;
}

export default ManagePolicies;
