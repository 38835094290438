import React from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import BaseService from "../../../../../services/BaseService";

const FileUploader = () => {
  const Preview = ({ meta }) => {
    const { name, percent, status } = meta;
    return (
      <span
        style={{
          alignSelf: "flex-start",
          margin: "10px 3%",
          fontFamily: "Helvetica",
        }}
      >
        {name}, {Math.round(percent)}%, {status}
      </span>
    );
  };

  // const getUploadParams = async ({ file, meta: { name } }) => {
  //   console.log(file)
  //   console.log(name)
  //   const baseService = new BaseService();
  //   const { uploadURL, Key } = await baseService.getPresignedUploadParams()
  //   console.log(uploadURL)
  //   console.log(Key)
  //   //return { body: file, meta: { fileUrl }, url: uploadUrl }
  // }

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  const handleChangeStatus = ({ meta }, status) => {
    console.log(status, meta);
  };

  const handleSubmit = (files, allFiles) => {
    console.log(
      "rohit",
      files.map((f) => f.meta)
    );
    allFiles.forEach((f) => f.remove());
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      PreviewComponent={Preview}
      accept=".csv, application/vnd.ms-excel, text/csv"
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      inputContent={(files, extra) =>
        extra.reject ? "CSV files only" : "Drag Files Or Browse To Upload CSV"
      }
      disabled={(files) =>
        files.some((f) =>
          ["preparing", "getting_upload_params", "uploading"].includes(
            f.meta.status
          )
        )
      }
      styles={dropzoneStyle}
    />
  );
};

const dropzoneStyle = {
  minHeight: 100,
  maxHeight: 100,
  marginBottom: 100,
  overflow: "hidden",
  dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
  inputLabel: (files, extra) => (extra.reject ? { color: "red" } : {}),
};

export default FileUploader;
