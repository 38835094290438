import Icon from "components/Icon/Icon";
import React, { useEffect, useState } from "react";
import { Col, Collapse, FormText, Input, Row } from "reactstrap";
import TooltipAction from "views/custom_components/TooltipAction";

import OrganizationFormSectionHeader from "../OrganizationFormSectionHeader";
import DaysSelect from "components/UserInputComponents/Selects/DaysSelect";

const demoAPI = {
  1: { fromTime: "13:00", toTime: "23:00" },
  2: { fromTime: "13:00", toTime: "23:00" },
  3: false,
  4: { fromTime: "13:00", toTime: "23:00" },
  5: { fromTime: "13:00", toTime: "23:00" },
  6: false,
  7: false,
};

const daysKey = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};

function WorkingTimeOrganization({ setValue, editable }) {
  const [days, setDays] = useState([]);
  const [workingTime, setWorkingTime] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  });
  const [error, setError] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setValue({
      workTimings: workingTime,
    });
  }, [workingTime, days]);

  useEffect(() => {
    // TODO: if api has data for this
    const data = demoAPI;

    const tempDays = Object.keys(demoAPI)
      .filter((dayNum) => Boolean(demoAPI[dayNum]))
      .map(Number);

    console.log("ajkshdosadib", tempDays);

    setWorkingTime(data);
    setDays(tempDays);
  }, []);

  const handleDaysChange = (value) => {
    setDays(value);
  };

  const handleChange = (event, index) => {
    const tempWorkData = {
      ...workingTime,
      [index]: {
        ...workingTime[index],
        [event.target.name]: event.target.value,
      },
    };
    setWorkingTime(tempWorkData);

    // error handling

    if (!tempWorkData[index][event.target.name].match(/(\d{2}):(\d{2})$/gi)) {
      let timeError = { ...error };
      if (event.target.name === "fromTime") {
        timeError[index] = {
          ...error[index],
          fromTimeError: "Time must be in HH:mm format",
        };
      } else {
        timeError[index] = {
          ...error[index],
          toTimeError: "Time must be in HH:mm format",
        };
      }
      setError(timeError);
    } else {
      let timeError = { ...error };
      if (event.target.name === "fromTime") {
        timeError[index] = { ...error[index], fromTimeError: "" };
      } else {
        timeError[index] = { ...error[index], toTimeError: "" };
      }
      setError(timeError);
    }
    console.log("asfasfas", error);
  };

  const handleCopyAll = () => {
    let data = [...workingTime];
    data.forEach((el, index) => {
      if (index !== 0) {
        el.fromTime = workingTime[0]["fromTime"];
        el.toTime = workingTime[0]["toTime"];
      }
    });
    setWorkingTime(data);
  };

  return (
    <div className="content">
      <OrganizationFormSectionHeader
        title="Working Time"
        value={visible}
        setValue={setVisible}
      />
      <Collapse isOpen={visible}>
        <DaysSelect isMulti value={days} setValue={handleDaysChange} />
        {days?.map((key) => {
          if (!days.includes(key)) return <></>;
          return (
            <div key={key}>
              <Row className="align-items-center mt-4">
                <Col md={2}>{daysKey[key]}</Col>

                <Col md={4}>
                  <Input
                    key={key}
                    name="fromTime"
                    value={workingTime[key].fromTime}
                    onChange={(event) => handleChange(event, key)}
                    placeholder="Enter start time in 24-hr format"
                    disabled={editable}
                    className={editable ? "form-control-plaintext" : ""}
                    readOnly={editable}
                    required
                    pattern={`(\\d{2}):(\\d{2})$`}
                    title="Time must be in HH:mm format"
                  />
                  <FormText color="danger">
                    {error[key]["fromTimeError"]}
                  </FormText>
                </Col>
                <Col md={1}>to</Col>
                <Col md={4}>
                  <Input
                    key={key}
                    name="toTime"
                    value={workingTime[key].toTime}
                    onChange={(event) => handleChange(event, key)}
                    placeholder="Enter end time in 24-hr format"
                    disabled={editable}
                    className={editable ? "form-control-plaintext" : ""}
                    readOnly={editable}
                    required
                    pattern={`(\\d{2}):(\\d{2})$`}
                    title="Time must be in HH:mm format"
                  />

                  <FormText color="danger">
                    {error[key]["toTimeError"]}
                  </FormText>
                </Col>

                <Col md={1}>
                  {key === 0 && (
                    <>
                      <span
                        onClick={handleCopyAll}
                        id="copyAll"
                        className="ml-auto"
                      >
                        <Icon clickable icon="copy_all" />
                      </span>
                      <TooltipAction target="copyAll" textContent="Copy All" />
                    </>
                  )}
                </Col>
              </Row>
            </div>
          );
        })}
      </Collapse>
    </div>
  );
}

export default WorkingTimeOrganization;
