import BuildingSelect from "components/UserInputComponents/Selects/BuildingSelect";
import FloorPlanSelect from "components/UserInputComponents/Selects/FloorPlanSelect";
import FloorSectionSelect from "components/UserInputComponents/Selects/FloorSectionSelect";
import useIsMounted from "customHooks/useIsMounted";
// import GatewaySelect from "components/UserInputComponents/Selects/GatewaySelect";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader } from "reactstrap";

function GatewayModalFilter({ setGatewayParams }) {
  const orgId = useSelector((state) => state.organization._id);
  // const [macId, setMacId] = useState("");
  const [buildingId, setBuildingId] = useState("");
  const [floorId, setFloorId] = useState("");
  const [floorPlanSectionId, setFloorPlanSectionId] = useState("");
  // const isMounted = useIsMounted()

  // useEffect(() => {}, []);

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        {/* <GatewaySelect value={macId} setValue={setMacId} /> */}
        <BuildingSelect value={buildingId} setValue={setBuildingId} />
        <FloorPlanSelect
          value={floorId}
          setValue={setFloorId}
          context={{ buildingId }}
        />
        <FloorSectionSelect
          value={floorPlanSectionId}
          setValue={setFloorPlanSectionId}
          context={{ floorPlanId: floorId }}
        />
        <Button
          color="info"
          onClick={() =>
            setGatewayParams({
              organizationId: orgId,
              // mac: macId || "",
              buildingId: buildingId || "",
              floorId: floorId || "",
              floorPlanSectionId: floorPlanSectionId || "",
            })
          }
        >
          Apply
        </Button>
      </CardBody>
    </Card>
  );
}

export default GatewayModalFilter;
