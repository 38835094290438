import React, { useState } from "react";
import { Tooltip } from "reactstrap";

function TooltipAction(props) {
  const [openToolTip, setOpenToolTip] = useState(false);
  const toggleToolTip = () => {
    setOpenToolTip(!openToolTip);
  };
  //   const tooltipStyles = {
  //     background: "#2d2f41",
  //     color: "#d3d4d8",
  //     fontWeight: 500,
  //   };
  return (
    <Tooltip
      //   style={tooltipStyles}
      target={props.target}
      isOpen={openToolTip}
      toggle={toggleToolTip}
    >
      {props.textContent}
    </Tooltip>
  );
}

export default TooltipAction;
