const { COMMUNICATION_URL } = require("config/api");
const { default: ApiService } = require("./ApiService");

class CommunicationService extends ApiService {
  constructor() {
    super(COMMUNICATION_URL);
  }
}

export default new CommunicationService();
