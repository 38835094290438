import FilterTableHolder from "components/Holders/FilterTableHolder/FilterTableHolder";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import React, { useMemo } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import VisitorRecordFilter from "./sub-components/VisitorRecordFilter/VisitorRecordFilter";
import VisitorRecordTable from "./sub-components/VisitorRecordTable/VisitorRecordTable";

function VisitorRecords() {
  const organizationId = useSelector((s) => s.organization._id);
  const [params, setParams] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableConfig, setTableConfig] = useState([]);

  return (
    <FilterTableHolder>
      <VisitorRecordFilter setParams={setParams} />
      <TableHeader
        title="Visitor Records"
        onClickDownload={true}
        tableConfig={tableConfig}
        tableData={tableData}
        fileTitle="Visitor Records"
      />
      <VisitorRecordTable
        tableData={setTableData}
        tableConfig={setTableConfig}
        params={params}
      />
    </FilterTableHolder>
  );
}

export default VisitorRecords;
