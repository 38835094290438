import React from "react";
import { Auth } from "aws-amplify";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { Link } from "react-router-dom";
import "./forgotPass.css";
import SWALService from "services/SWALService";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      code: "",
      new_password: "",
      confirm_password: "",
      noticodeFocus: false,
      phoneFocus: false,
      passFocus: false,
      conpassFocus: false,
      step: 0,
      message: "",
    };
  }

  componentDidMount() {
    document.body.classList.toggle("login-page");
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  onChangePhone = (e) => {
    this.setState({ [e.target.name]: "+91" + e.target.value });
  };

  onChangeNewPass = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeConfPass = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeNoti = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  forgetPassword = () => {
    const { username } = this.state;
    if (username === "") return;
    // Send confirmation code to user's email
    Auth.forgotPassword(username)
      .then((data) => {
        console.log(data);
        this.setState({ step: 1 });
      })
      .catch((err) => console.log(err));
  };

  resendCode = () => {
    const { username } = this.state;
    if (username !== "") {
      // Send confirmation code to user's email
      Auth.forgotPassword(username)
        .then((data) => {
          console.log(data);
          this.setState({ step: 1 });
        })
        .catch((err) => console.log(err));
    }
  };

  confirmForgetPassword = () => {
    const { code, new_password, confirm_password, username } = this.state;

    if (new_password !== confirm_password)
      return SWALService.showError("Passwords don't match");

    // Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(username, code, new_password)
      .then((data) => {
        SWALService.showSuccess("Redirecting to login");

        this.props.history.push("/auth/login");
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {this.state.step === 0 ? (
          <div className="content">
            <Container>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Form className="form">
                  <Card className="card-login card-white">
                    <CardHeader>
                      <img
                        alt="..."
                        src={require("assets/img/card-primary.png")}
                      />
                      <CardTitle tag="h6">Forget Password</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": this.state.phoneFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-mobile" /> +91
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Mobile Number"
                          type="text"
                          name="username"
                          onFocus={(e) => this.setState({ phoneFocus: true })}
                          onBlur={(e) => this.setState({ phoneFocus: false })}
                          onChange={this.onChangePhone}
                        />
                      </InputGroup>
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        className="mb-3"
                        color="primary"
                        onClick={this.forgetPassword}
                        size="lg"
                      >
                        Send
                      </Button>
                      <div className="pull-left">
                        <h6>
                          <Link className="link footer-link" to="/auth/login">
                            Back
                          </Link>
                        </h6>
                      </div>
                      <div className="pull-right">
                        <h6>
                          <a
                            className="link footer-link"
                            onClick={this.resendCode}
                          >
                            Resend
                          </a>
                        </h6>
                      </div>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Container>
          </div>
        ) : (
          <div className="content">
            <Container>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Form className="form">
                  <Card className="card-login card-white">
                    <CardHeader>
                      <img
                        alt="..."
                        src={require("assets/img/card-primary.png")}
                      />
                      <CardTitle tag="h1">Set New Password</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": this.state.noticodeFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-lock-circle" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="OTP"
                          type="text"
                          name="code"
                          onFocus={(e) =>
                            this.setState({ noticodeFocus: true })
                          }
                          onBlur={(e) =>
                            this.setState({ noticodeFocus: false })
                          }
                          onChange={this.onChangeNoti}
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": this.state.passFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-lock-circle" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="New Password"
                          type="password"
                          name="new_password"
                          onFocus={(e) => this.setState({ passFocus: true })}
                          onBlur={(e) => this.setState({ passFocus: false })}
                          onChange={this.onChangeNewPass}
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": this.state.conpassFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-lock-circle" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Confirm Password"
                          type="password"
                          name="confirm_password"
                          onFocus={(e) => this.setState({ conpassFocus: true })}
                          onBlur={(e) => this.setState({ conpassFocus: false })}
                          onChange={this.onChangeConfPass}
                        />
                      </InputGroup>
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        className="mb-3"
                        color="primary"
                        href="#"
                        onClick={this.confirmForgetPassword}
                        size="lg"
                      >
                        Submit
                      </Button>
                      <div className="pull-left"></div>
                      <div className="pull-right"></div>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Container>
          </div>
        )}
      </>
    );
  }
}

export default ForgotPassword;
