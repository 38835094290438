import FormHolder from "components/Holders/FormHolder/FormHolder";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import React, { useState } from "react";
import { Collapse, Form } from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Forms from "config/copies/forms";
import { convertToRaw, EditorState } from "draft-js";
import BaseTextBoxInput from "components/UserInputComponents/Inputs/TextBoxInputs/BaseTextBoxInput";
import draftToHtml from "draftjs-to-html";
import CommunicationService from "services/CommunicationService";
import SWALService from "services/SWALService";
import { useSelector } from "react-redux";
import BaseSelect from "components/UserInputComponents/Selects/BaseSelect";
import MessageTypeSelect from "components/UserInputComponents/Selects/MessageTypeSelect";
import SubjectSelect from "components/UserInputComponents/Selects/SubjectSelect";
import TagSelect from "components/UserInputComponents/Selects/TagSelect";
import { useHistory } from "react-router-dom";

const scopes = ["GROUP", "RECIPIENT_LIST", "GLOBAL", "MIXED"].map((el) => ({
  value: el,
  label: el,
}));
function ParentConnectForm() {
  const organizationId = useSelector((state) => state.organization._id);
  const history = useHistory();
  const [noticeTitle, setNoticeTitle] = useState("");
  const [scope, setScope] = useState("");
  const [receipientList, setRecipientList] = useState("");
  const [groups, setGroups] = useState("");
  const [messageType, setMessageType] = useState();

  let editorState = EditorState.createEmpty();
  const [description, setDescription] = useState(editorState);
  const contentRaw = draftToHtml(convertToRaw(description.getCurrentContent()));
  const user = useSelector((state) => state.user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      organization: organizationId,
      title: noticeTitle,
      desc: contentRaw,
      type: messageType,
      sender: user._id,
      receivers: {
        recipients: receipientList,
        groups: groups,
        scope: scope,
      },
    };
    const { message } = await CommunicationService.post(payload).catch(
      (err) => {
        SWALService.showError(err.message);
      }
    );
    if (message) SWALService.showSuccess(message);
    history.goBack();
  };

  return (
    <>
      <div className="content">
        <FormHolder
          imgSrc={Forms.NOTICE.illustration}
          formHeader={Forms.NOTICE.title}
          formDescription={Forms.NOTICE.desc}
        >
          <Form onSubmit={handleSubmit}>
            <BaseTextInput
              label="Notice Title"
              placeholder="Notice title"
              value={noticeTitle}
              setValue={setNoticeTitle}
            />
            <BaseTextBoxInput value={description} setValue={setDescription} />
            <MessageTypeSelect value={messageType} setValue={setMessageType} />
            <>
              <BaseSelect
                dataSource={scopes}
                value={scope}
                setValue={setScope}
                label="Scope"
                placeholder="Select people to send"
              />
              <Collapse
                isOpen={scope === "RECIPIENT_LIST" || scope === "MIXED"}
              >
                <SubjectSelect
                  isMultiSelect
                  value={receipientList}
                  setValue={setRecipientList}
                />
              </Collapse>

              <Collapse isOpen={scope === "GROUP" || scope === "MIXED"}>
                <TagSelect isMultiSelect value={groups} setValue={setGroups} />
              </Collapse>
            </>
            <BaseButton color="info">Send</BaseButton>
          </Form>
        </FormHolder>
      </div>
    </>
  );
}

export default ParentConnectForm;
