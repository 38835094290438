import React from "react";
import BaseNumberInput from "./BaseNumberInput";

function PhoneNumberInput({ label, placeholder, value, setValue, editable }) {
  return (
    <BaseNumberInput
      label={label || "Contact"}
      placeholder={placeholder || "Enter your contact number"}
      name={"contact"}
      value={value}
      setValue={setValue}
      editable={editable}
    />
  );
}

export default PhoneNumberInput;
