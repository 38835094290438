import FilterTableHolder from "components/Holders/FilterTableHolder/FilterTableHolder";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SWALService from "services/SWALService";
import RouteService from "services/RouteService";
import RouteFilter from "./subComponent/RouteFilter";
import RouteTable from "./subComponent/RouteTable";
import CsvDownloader from "utils/CsvDownloader";
import getReportData from "utils/getReportData";

function RouteListing() {
  const organizationId = useSelector((state) => state.organization._id);
  const defaultParams = { organizationId };
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [routeParams, setRouteParams] = useState({});
  const [tableConfig, setTableConfig] = useState([]);

  useEffect(() => {
    fetchRoute();
  }, [routeParams]);

  const fetchRoute = async () => {
    setLoading(true);

    const params = {
      ...routeParams,
      ...defaultParams,
    };

    const { data } = await RouteService.get(params).catch((err) =>
      SWALService.showError(err)
    );

    if (data) setData(data);
    setLoading(false);
  };

  const addRoute = () => history.push("/admin/route/add");

  return (
    <FilterTableHolder>
      <RouteFilter setRouteParams={setRouteParams} />
      <TableHeader
        title="Route"
        onClickAdd={addRoute}
        onClickDownload={true}
        tableConfig={tableConfig}
        tableData={data}
      />
      <RouteTable
        data={data}
        getData={fetchRoute}
        loading={loading}
        setTableConfig={setTableConfig}
      />
    </FilterTableHolder>
  );
}

RouteListing.routeName = "/admin/route/manage";

export default RouteListing;
