import IconButton from "components/UserInputComponents/IconButton/IconButton";
import React from "react";
import { UncontrolledTooltip } from "reactstrap";

import "./TableAction.scss";

function TableAction({ onClick, icon, color, name, ...row }) {
  const tooltipId = "action-tooltip-" + name + row?.original?._id;
  return (
    <div className="TableAction__Container">
      <IconButton
        id={tooltipId}
        icon={icon}
        color={color}
        onClick={() => onClick?.(row?.original?._id)}
        naked
      />
      {!!name && (
        <UncontrolledTooltip placement="top" target={tooltipId}>
          {name}
        </UncontrolledTooltip>
      )}
    </div>
  );
}

export default TableAction;
