import React from "react";
import { Card } from "reactstrap";

function NoData({ message = "No Data", imgSrc, displayOverlay, show }) {
  const NoDataDivStyle = {
    position: displayOverlay ? "absolute" : "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const NoDataTextStyle = {
    backgroundColor: "rgba(127, 127, 127, 0.1)",
    padding: "5px 15px",
    borderRadius: "10px",
    margin: "1rem",
    textAlign: "center",
  };

  const imgStyle = {
    width: "250px",
    minHeight: "100px",
    height: "auto",
    marginBottom: "3rem",
    marginTop: "1.5rem",
  };

  return (
    <Card style={NoDataDivStyle} className="mx-auto my-auto pt-5 pb-5">
      {!!imgSrc && <img alt="" src={imgSrc} style={imgStyle} />}
      <h5 style={NoDataTextStyle}>{message}</h5>
    </Card>
  );

  // return <></>;
}

export default NoData;
