import ApiService from "./ApiService";
import { SCHEDULES_URL } from "config/api";

class ScheduleService extends ApiService {
  constructor() {
    super(SCHEDULES_URL);
  }
}

export default new ScheduleService();
