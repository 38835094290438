import Axios from "axios";
import { FLOORSECTION_URL } from "config/api";
import React, { useEffect, useState } from "react";
import { Tooltip } from "reactstrap";
import Loader from "views/custom_components/Loader";
import NoData from "views/custom_components/NoData";
import "./LeftSideBar.scss";
import TooltipAction from "views/custom_components/TooltipAction";
import Skeleton from "react-loading-skeleton";

const FloorSectionSelect = ({
  floorSection,
  onClick,
  activeFloorSection,
  activateFloorSection,
  deletePlot,
}) => {
  const currentFloorSectionId = floorSection?.value;
  return (
    <div
      className={`editOptions ${
        activeFloorSection === currentFloorSectionId
          ? "editOptions--active"
          : ""
      } `}
      onClick={() => activateFloorSection(currentFloorSectionId)}
    >
      <div
        className={`floorSectionLabel ${
          activeFloorSection === currentFloorSectionId
            ? "floorSectionLabel--active"
            : ""
        } `}
        onClick={() => onClick(floorSection)}
      >
        <p>{floorSection?.label}</p>
      </div>
      <div className="editOptionsIcons">
        <i
          className="tim-icons icon-trash-simple deleteIcon"
          id="deleteClassName"
          onClick={() => deletePlot(currentFloorSectionId)}
        ></i>
      </div>
      <TooltipAction target="deleteClassName" textContent="Delete Class Name" />
    </div>
  );
};

function LeftSideBar({ floorPlanId, onTileClick, deletePlot }) {
  const [floorSections, setFloorSections] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeFloorSection, setActiveFloorSection] = useState(null);

  useEffect(() => {
    if (!floorPlanId) return;

    setLoading(true);

    Axios.get(FLOORSECTION_URL, {
      params: { floorPlanId },
    })
      .then(({ data: { data } }) =>
        setFloorSections([
          ...data.map((el) => ({
            value: el._id,
            label: el.floorPlanSectionName,
          })),
        ])
      )
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {loading && (
        <div className="px-4 py-5">
          <Skeleton height="2rem" />
          <br />

          {Array(3)
            .fill(0)
            .map((_) => (
              <p className="py-3">
                <Skeleton />
                <br />
              </p>
            ))}
        </div>
      )}

      {floorSections?.map((floorSection, idx) => (
        <FloorSectionSelect
          key={idx}
          floorSection={floorSection}
          onClick={onTileClick}
          activateFloorSection={setActiveFloorSection}
          activeFloorSection={activeFloorSection}
          deletePlot={deletePlot}
        />
      ))}
    </>
  );
}

export default LeftSideBar;
