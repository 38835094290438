import React, { useEffect, useState } from "react";
import BaseSelect from "./BaseSelect";
import axios from "axios";
import { useSelector } from "react-redux";

function VehicleTraxIdSelect({ value, setValue, isMultiSelect }) {
  const organizationId = useSelector((state) => state.organization._id);
  const organizationAuthKey = useSelector(
    (state) => state.organization?.attributes?.traxAPIKey
  );
  const [vehicleTraxId, setVehicleTraxId] = useState([]);

  const fetchVehicleTraxId = async () => {
    const headers = {
      Authorization: `Basic ${organizationAuthKey}`,
      Accept: "application/json",
    };
    const params = { organizationId };
    axios
      .get("https://api.traxsmart.in/api/devices", { headers, params })
      .then(({ data }) => {
        setVehicleTraxId(data);
        console.log("sfsd", data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchVehicleTraxId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseSelect
      label={"Vehicle traxId (*)"}
      dataSource={vehicleTraxId?.map((deviceTraxId) => {
        return {
          value: deviceTraxId.id,
          label: `${deviceTraxId?.name} [${deviceTraxId.uniqueId}]`,
        };
      })}
      placeholder={"Provide the tracking ID power by traxsmart"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default VehicleTraxIdSelect;
