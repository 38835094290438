import Icon from 'components/Icon/Icon';
import React from 'react'
import { CardBody } from 'reactstrap';

function EventCard(props) {
  return (
    <div className="d-flex mb-4" data-metatip="true">
      <div className="icon-container">
        <div class="icon icon-shape bg-primary-soft shadow text-center border-radius-md shadow-none">
          <Icon icon="notifications" size="lg" gradient="text-info" />
        </div>
      </div>
      <div class="ms-3">
        <div class="numbers">
          <h5 class="mb-1 text-dark text-sm">{props.name}</h5>
          <span class="text-sm">{props.fromTime}</span>
        </div>
      </div>
    </div>
  );
}

export default EventCard