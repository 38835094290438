import React, { useEffect, useState } from "react";
import "./LocationSelector.scss";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { FormGroup } from "reactstrap";
import { Label } from "reactstrap";

function LocationSelector({
  label,
  placeholder,
  setValue,
  location,
  editable,
}) {
  const [locationSet, setLocationIsSet] = useState(false);

  const [selectValue, setSelectValue] = useState({
    label: undefined,
    value: {},
  });

  const onChangeSelect = (label, addressDetails) =>
    setSelectValue({ label, value: addressDetails });

  const setLocationByLabel = (label) => {
    geocodeByAddress(label)
      .then((results) => getLatLng(results[0]))
      .then((result) => {
        const { lat, lng } = result;

        onChangeSelect(label, result);

        setValue({
          address: label,
          coordinates: { lat, lng },
        });
      })
      .catch((error) => console.error(error));
  };

  const handleProps = (data) => {
    console.log("location", data);
    setLocationByLabel(data?.label);
  };

  useEffect(() => {
    if (!location?.address || locationSet) return;
    console.log("setting location as ", location?.address);
    setLocationByLabel(location?.address);
    setLocationIsSet(true);
  }, [location]);

  return (
    <div className="LocationSelector">
      <FormGroup className="mb-4">
        <Label className="mb-2">{label}</Label>
        <GooglePlacesAutocomplete
          debounce={2000}
          minLengthAutocomplete={3}
          selectProps={{
            placeholder: "What is the location?" || placeholder,
            onChange: handleProps,
            isDisabled: editable,
            value: selectValue,
          }}
          onLoadFailed={(error) =>
            console.error("Could not inject Google script", error)
          }
          styles={{
            textInputContainer: {
              backgroundColor: "pink",
            },
            textInput: {
              height: 48,
              color: "#5d5d5d",
              fontSize: 16,
            },
            predefinedPlacesDescription: {
              color: "#1faadb",
            },
          }}
        />
      </FormGroup>
    </div>
  );
}

export default LocationSelector;
