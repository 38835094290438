import { useState } from "react";

const useFilterContext = (initValue) => {
  const [filterContext, setFilterContext] = useState(initValue ?? []);

  const setFilterContextValue = (_filterContext) => {
    const _tempContext = filterContext;
    // setFilterContext([...filterContext, _filterContext]);
    const currentContextKey = Object.keys(_filterContext)[0];
    _tempContext[currentContextKey] = Object.values(_filterContext)[0];
    setFilterContext(_tempContext);
  };

  return [filterContext, setFilterContextValue];
};

export default useFilterContext;
