import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Label, Row, UncontrolledPopover } from "reactstrap";
import SubjectService from "services/SubjectService";
import ProfileInfo from "views/pages/Profile/ProfileInfo/ProfileInfo";

import "./SubjectView.scss";

const subjectService = new SubjectService();

function SubjectView({ subjectData, subjectId, primaryLabelKey, children }) {
  const [subject, setSubject] = useState();
  const [loading, setLoading] = useState(false);

  const idName = `popover-${subjectId || subject?.id}`;

  const getSubject = () => {
    setLoading(true);
    subjectService
      .getSubjects({ id: subjectId })
      .then((subjects) => setSubject(subjects[0]))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  // const openSubjectPopper = () => {

  //   setInfoPopperIsOpen(true);
  // };

  // const closeSubjectPopper = () => {
  //   if (!hoverCard) setTimeout(() => setInfoPopperIsOpen(false), 500);
  // };

  const SubjectInfo = () => (
    <>
      <ProfileInfo subjectId={subjectId} naked />
      <Card className="mb-0 card-body ">
        <Link to={`/admin/profile/${subjectId || ""}`}>
          <Button className="mx-auto pull-right" color="info">
            View Profile
          </Button>
        </Link>
      </Card>
    </>
  );

  if (!subjectData || !subjectId) return <p>No Subject Data</p>;

  return (
    <div className="SubjectView">
      <p className="SubjectView__name clickable" id={idName}>
        <span className="clickable">{children}</span>
      </p>

      <UncontrolledPopover
        popperClassName="popover-default"
        placement="auto"
        target={idName}
        container="#portal"
        boundariesElement={`#${idName}`}
        boundary={`#${idName}`}
        content="element"
        trigger="hover"
        delay={{
          hide: 300,
        }}
        // hideArrow={true}
      >
        <div className="SubjectView__container">
          <SubjectInfo />
        </div>
      </UncontrolledPopover>
    </div>
  );
}

export default SubjectView;
