import React from "react";
import BaseDatePicker from "./BaseDatePicker";

function EndDatePicker({
  date,
  setDate,
  startDate,
  isClearable,
  bottomHintText,
  ...props
}) {
  return (
    <BaseDatePicker
      label="End Date"
      placeholder="Select end date"
      date={date}
      setDate={setDate}
      minDate={startDate}
      isClearable={isClearable}
      bottomHintText={bottomHintText}
      {...props}
    />
  );
}

export default EndDatePicker;
