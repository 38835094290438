import React, { useEffect, useState } from "react";
import CampusService from "services/CampusService";
import BaseSelect from "./BaseSelect";

function CampusSelect({ value, setValue, isMultiSelect, noLabel, noPopulate }) {
  const [campuses, setCampuses] = useState([]);

  useEffect(() => {
    if (noPopulate) return;
    CampusService.get()
      .then((res) => {
        setCampuses(res.data?.map((el) => ({ value: el._id, label: el.name })));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [noPopulate]);

  return (
    <BaseSelect
      label={!noLabel && "Campus"}
      dataSource={campuses}
      placeholder={"Select Campus"}
      closeMenuOnSelect={false}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default CampusSelect;
