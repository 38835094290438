import { BUILDING_URL } from "config/api";
import ApiService from "./ApiService";

class BuildingService extends ApiService {
  constructor() {
    super(BUILDING_URL);
  }
}

export default new BuildingService();
