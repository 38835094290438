import React from "react";
import { Col, Row } from "reactstrap";

import "./AlertBell.scss";

function AlertBell({ label, animate }) {
  return (
    <Col className="AlertBell__column">
      <Row className="align-items-center">
        <div
          className={`AlertBell__circle  ${
            animate ? "AlertBell__circle--animate" : ""
          }`}
        >
          <i className="tim-icons icon-bell-55 AlertBell__icon"></i>
        </div>
        <h2 className="AlertBell__label mb-0">{label || "Class 1A"}</h2>
        {/* 💩 */}
      </Row>
      {/* {label && (
        <Row>
          <h3 className="AlertBell__label">{label}</h3>
        </Row>
      )} */}
    </Col>
  );
}

export default AlertBell;
