import axios from "axios";
import { FLOORSECTION_URL } from "config/api";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BaseSelect from "./BaseSelect";

function GatewayMacFloorSectionSelect({
  noLabel,
  isMultiSelect,
  value,
  setValue,
  bottomHintText,
  context,
  noPopulate,
}) {
  const organizationId = useSelector((state) => state.organization._id);

  const [_floorPlanSection, _setFloorPlanSection] = useState([]);

  useEffect(() => {
    if (noPopulate) return;
    const params = { ...context, organizationId };

    axios
      .get(`${FLOORSECTION_URL}`, { params })
      .then((res) => _setFloorPlanSection(res.data.data))
      .catch((err) => console.log(err));
  }, [context]);

  return (
    <BaseSelect
      label={!noLabel && "Gateway"}
      dataSource={_floorPlanSection?.map((floorPlanSection) => ({
        value: floorPlanSection?.gatewayMac,
        label: `${floorPlanSection?.floorPlanSectionName} [${floorPlanSection?.gatewayMac}]`,
      }))}
      placeholder={"Select Gateway"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
      bottomHintText={bottomHintText}
    />
  );
}

export default GatewayMacFloorSectionSelect;
