import GatewayConnectView from "components/PopoverViews/GatewayConnectView/GatewayConnectView";
import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import { FLOORSECTION_URL } from "config/api";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import FloorPlanSectionService from "services/FloorPlanSectionService";
import SWALService from "services/SWALService";

import { Badge } from "reactstrap";
import GatewayActiveIndicator from "components/PopoverViews/GatewayActiveIndicator/GatewayActiveIndicator";

function FloorPlanSectionTable({ getData, data, loading, setTableConfig }) {
  const history = useHistory();

  const editFloorPlanSection = (floor_sec_id) =>
    history.push(`/admin/floorPlanSection/edit/${floor_sec_id}`);

  const deleteFloorSection = async (floor_sec_id) => {
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await FloorPlanSectionService.delete(floor_sec_id)
      .then(() => {
        SWALService.showSuccess(
          "The Floor Section has been deleted.",
          "Deleted!"
        );
      })
      .catch((err) => SWALService.showError(err));

    getData();
  };

  const floorSectionTableConfig = [
    {
      Header: " Name",
      accessor: "floorPlanSectionName",
    },
    {
      Header: "Campus",
      accessor: "campus.name",
    },
    {
      Header: "Building",
      accessor: "building.name",
    },
    {
      Header: "Floor Name",
      accessor: "floorPlan.name",
    },
    {
      Header: "Section Type",
      accessor: "sectionTypes",
    },
    {
      Header: "Gateway",
      accessor: "gateway.macId",
      Cell: ({ original }) => (
        <div clasName="text-nowrap">
          <GatewayConnectView
            floorSectionId={original._id}
            reloadData={getData}
            gatewayMac={original?.gateway?.macId}
          />
          <GatewayActiveIndicator gatewayMac={original?.gateway?.macId} />
        </div>
      ),
    },
    {
      Header: "Area Type",
      accessor: "areaTypes",
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editFloorPlanSection}
          deleteCallback={deleteFloorSection}
        />
      ),
    },
  ];

  useEffect(() => {
    setTableConfig(floorSectionTableConfig);
  }, []);

  return (
    <BaseTable
      tableConfig={floorSectionTableConfig}
      data={data}
      loading={loading}
    />
  );
}

export default FloorPlanSectionTable;
