import React, { Component } from 'react'
import Select from "react-select";
import {Row,Col,Form,FormGroup,Label,Input,Card,CardHeader,CardTitle,CardBody,CardFooter,
    Button,ListGroup,ListGroupItem} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import MasterDataService from '../../../../../services/MasterDataService';
import { MASTER_DATA_URL, MODULES_URL } from "../../../../../config/api";
import Swal from 'sweetalert2';
import '../masterdata.css';
import BaseTextInput from 'components/UserInputComponents/Inputs/TextInputs/BaseTextInput';
import FormHolder from 'components/Holders/FormHolder/FormHolder';
import BaseButton from 'components/UserInputComponents/BaseButton/BaseButton';
import Forms from "../../../../../config/copies/forms"

export class AddMasterData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      type: "",
      assetType: "",
      attributes: {},
      organization: "",
      allowedModulesWeb: [],
      allowedModulesMobile: [],
      selectedAllowedModulesWeb: [],
      selectedAllowedModulesMob: [],
      typesArray: [
        { value: "ROLE", label: "ROLE" },
        { value: "GROUPED_ENTITY", label: "GROUPED_ENTITY" },
        { value: "ASSET_TYPE", label: "ASSET_TYPE" },
      ],
      allowedView: false,
      buttonText: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeTitle = this.changeTitle.bind(this);
    this.changeType = this.changeType.bind(this);
    this.changeAssetType = this.changeAssetType.bind(this);
    this.masterDataService = new MasterDataService();
  }

  componentDidMount() {
    const buttonText = !!this.props.location.state.id
      ? "Save Changes"
      : "Submit";
    this.setState({ buttonText: buttonText });
    try {
      if (this.props.location.state.usecase === "add") {
        let param = { organizationId: this.props.organization[0]._id };
        this.masterDataService
          .getAllowedModules(MODULES_URL, param, true)
          .then((response) => {
            if (response.length > 0) {
              console.log(response);
              let data = response;
              let allowedModulesWeb = [],
                allowedModulesMobile = [];
              data.map((item) => {
                if (item.scope === "PARCHAI_CLOUD") {
                  allowedModulesWeb.push(item);
                } else {
                  allowedModulesMobile.push(item);
                }
              });
              this.setState({ allowedModulesWeb: allowedModulesWeb });
              this.setState({ allowedModulesMobile: allowedModulesMobile });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.props.location.state.usecase === "edit") {
        this.setState({ id: this.props.match.params.id });
        let param = { id: this.props.match.params.id };
        this.masterDataService
          .getMasterDataByID(MASTER_DATA_URL, param, true)
          .then((response) => {
            if (response.length > 0) {
              this.setState({
                title: !!response[0].title ? response[0].title : "",
              });
              let typeobj = {
                value: !!response[0].type ? response[0].type : "",
                label: !!response[0].type ? response[0].type : "",
              };
              this.setState({ type: typeobj });
              if (typeobj.value === "ROLE") {
                this.setState({ allowedView: true });
              } else {
                this.setState({ allowedView: false });
              }
              if (
                !!response[0].allowedModules &&
                response[0].allowedModules.length === 0
              ) {
                this.fetchAllowedModules();
              } else {
                this.setState({
                  allowedModulesWeb: !!response[0].allowedModules
                    ? response[0].allowedModules
                    : "",
                });
                let dataset = !!response[0].allowedModules
                  ? response[0].allowedModules
                  : [];
                dataset.map((item) => {
                  this.setState({
                    selectedAllowedModulesWeb: [
                      ...this.state.selectedAllowedModulesWeb,
                      item._id,
                    ],
                  });
                });
              }
              if (
                !!response[0].allowedModulesMob &&
                response[0].allowedModulesMob.length === 0
              ) {
                this.fetchAllowedModules();
              } else {
                this.setState({
                  allowedModulesMob: !!response[0].allowedModulesMob
                    ? response[0].allowedModulesMob
                    : "",
                });
                let dataset = response[0].hasOwnProperty("allowedModulesMob")
                  ? response[0].allowedModulesMob
                  : [];
                dataset.map((item) => {
                  this.setState({
                    selectedAllowedModulesMob: [
                      ...this.state.selectedAllowedModulesMob,
                      item._id,
                    ],
                  });
                });
              }
            }
          });
      }
    } catch (e) {
      console.error("error", e);
    }
  }

  fetchAllowedModules() {
    try {
      let param = { organizationId: this.props.organization[0]._id };
      this.masterDataService
        .getAllowedModules(MODULES_URL, param, true)
        .then((response) => {
          if (response.length > 0) {
            console.log(response);
            let data = response;
            let allowedModulesWeb = [],
              allowedModulesMobile = [];
            data.map((item) => {
              if (item.scope === "PARCHAI_CLOUD") {
                allowedModulesWeb.push(item);
              } else {
                allowedModulesMobile.push(item);
              }
            });
            this.setState({ allowedModulesWeb: allowedModulesWeb });
            allowedModulesWeb.map((item) => {
              this.setState({
                selectedAllowedModulesWeb: [
                  ...this.state.selectedAllowedModulesWeb,
                  item._id,
                ],
              });
            });
            this.setState({ allowedModulesMobile: allowedModulesMobile });
            allowedModulesMobile.map((item) => {
              this.setState({
                selectedAllowedModulesMob: [
                  ...this.state.selectedAllowedModulesMob,
                  item._id,
                ],
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.error("error", e);
    }
  }

  changeTitle = (event) => {
    this.setState({ title: event.target.value });
  };

  changeType = (event) => {
    let typeobj = {
      value: event.value,
      label: event.value,
    };
    this.setState({ type: typeobj });
    if (typeobj.value === "ROLE") {
      this.setState({ allowedView: true });
    } else {
      this.setState({ allowedView: false });
    }
  };

  changeAssetType = (event) => {
    let assettypeobj = {
      value: event.value,
      label: event.value,
    };
    this.setState({ assetType: assettypeobj });
  };

  checkedModule = (event, module_id) => {
    if (!!event.target.checked) {
      this.setState({
        selectedAllowedModulesWeb: [
          ...this.state.selectedAllowedModulesWeb,
          module_id,
        ],
      });
    } else {
      let orig_array = [...this.state.selectedAllowedModulesWeb];
      let new_array = orig_array;
      const index = new_array.indexOf(module_id);
      new_array.splice(index, 1);
      this.setState((prevState) => ({
        selectedAllowedModulesWeb: [],
      }));
      this.setState({ selectedAllowedModulesWeb: new_array });
    }
  };

  checkedModuleMobile = (event, module_id) => {
    if (!!event.target.checked) {
      this.setState({
        selectedAllowedModulesMob: [
          ...this.state.selectedAllowedModulesMob,
          module_id,
        ],
      });
    } else {
      let orig_array = [...this.state.selectedAllowedModulesMob];
      let new_array = orig_array;
      const index = new_array.indexOf(module_id);
      new_array.splice(index, 1);
      this.setState((prevState) => ({
        selectedAllowedModulesMob: [],
      }));
      this.setState({ selectedAllowedModulesMob: new_array });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    try {
      let master_obj = {
        title: this.state.title,
        type: this.state.type.value,
        attributes: this.state.attributes,
        allowedModules: this.state.selectedAllowedModulesWeb,
        allowedModulesMob: this.state.selectedAllowedModulesMob,
        organization: this.props.organization[0]._id,
      };
      console.log(master_obj);
      if (this.props.location.state.usecase === "add"){
        this.masterDataService
        .createMasterData(MASTER_DATA_URL, master_obj, true)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "MasterData Create",
            text: response.msg,
          }).then(() => {
            this.props.history.push("/admin/manage_master_data");
          });
        })
        .catch((error) => {
          console.log(error);
        });
      } else if(this.props.location.state.usecase === 'edit'){
        this.masterDataService
          .updateMasterData(MASTER_DATA_URL, master_obj, this.state.id, true)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "MasterData Update",
              text: response.msg,
            }).then(() => {
              this.props.history.push("/admin/manage_master_data");
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error);
    }

  };

  render() {
    return (
      <div className="content">
        <Row>
          <FormHolder
            imgSrc={Forms.MASTER.illustration}
            formHeader={Forms.MASTER.title}
            formDescription={Forms.MASTER.desc}
            CardFooterComponent=""
          >
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col md={12}>
                  <BaseTextInput
                    label="Title"
                    placeholder="Enter title"
                    type="text"
                    value={this.state.title}
                    setValue={(value) =>
                      this.setState({ title: value })
                    }
                  />
                  <Row className="separator">
                    <Col md={12}>
                      <Label>Type</Label>
                      <FormGroup>
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          name="type"
                          value={this.state.type}
                          onChange={this.changeType}
                          options={this.state.typesArray}
                          placeholder="Type"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {!!this.state.allowedView ? (
                <>
                  <Row className="separator">
                    <Col md={12}>
                      {this.state.allowedModulesWeb.length > 0 ? (
                        <ListGroup>
                          {this.state.allowedModulesWeb.map(
                            (allowedModulesWb, idx) => (
                              <ListGroupItem key={idx}>
                                <BaseTextInput
                                  label={allowedModulesWb?.name}
                                  type="checkbox"
                                  name={allowedModulesWb?.name}
                                  onChange={(e) =>
                                    this.checkedModule(e, allowedModulesWb?._id)
                                  }
                                />
                              </ListGroupItem>
                            )
                          )}
                        </ListGroup>
                      ) : null}
                    </Col>
                  </Row>
                  <Row className="separator">
                    <Col md={12}>
                      {this.state.allowedModulesMobile.length > 0 ? (
                        <ListGroup>
                          {this.state.allowedModulesMobile.map(
                            (allowedModulesMob, idx) => (
                              <ListGroupItem key={idx}>
                                <BaseTextInput
                                  label={allowedModulesMob?.name}
                                  type="checkbox"
                                  name={allowedModulesMob?.name}
                                  onChange={(e) =>
                                    this.checkedModule(
                                      e,
                                      allowedModulesMob?._id
                                    )
                                  }
                                />
                              </ListGroupItem>
                            )
                          )}
                        </ListGroup>
                      ) : null}
                    </Col>
                  </Row>
                </>
              ) : null}
              <BaseButton color="info">{this.state.buttonText}</BaseButton>
            </Form>
          </FormHolder>
          {/* <Col className="ml-auto mr-auto px-5" md="8">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Master Data</CardTitle>
                  </CardHeader>
                  <Form onSubmit={this.handleSubmit}>
                    <CardBody>
                      <Row>
                        <Col md={6}>
                          <BaseTextInput
                            label="Title"
                            placeholder="Enter title"
                            type="text"
                            onChange={this.changeTitle}
                          />
                        </Col>
                        <Col md={6}>
                          <Label>Type</Label>
                          <FormGroup>
                            <Select
                              className="react-select info"
                              classNamePrefix="react-select"
                              name="type"
                              onChange={this.changeType}
                              options={this.state.typesArray}
                              placeholder="Type"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {!!this.state.allowedView ? (
                        <>
                          <Row className="separator">
                            <Col md={12}>
                              {this.state.allowedModulesWeb.length > 0 ? (
                                <ListGroup>
                                  {this.state.allowedModulesWeb.map(
                                    (allowedModulesWb, idx) => (
                                      <ListGroupItem key={idx}>
                                        <BaseTextInput
                                          label={allowedModulesWb?.name}
                                          type="checkbox"
                                          name={allowedModulesWb?.name}
                                          onChange={(e) =>
                                            this.checkedModule(
                                              e,
                                              allowedModulesWb?._id
                                            )
                                          }
                                        /> */}
          {/* <FormGroup check>
                                          <div className="leftalign">
                                            <Label check>
                                              {allowedModulesWb?.name}
                                            </Label>
                                          </div>
                                          <div className="rightalign">
                                            <Input
                                              type="checkbox"
                                              name={allowedModulesWb?.name}
                                              onChange={(e) =>
                                                this.checkedModule(
                                                  e,
                                                  allowedModulesWb?._id
                                                )
                                              }
                                            />
                                          </div>
                                        </FormGroup> */}
          {/* </ListGroupItem>
                                    )
                                  )}
                                </ListGroup>
                              ) : null}
                            </Col>
                          </Row>
                          <Row className="separator">
                            <Col md={12}>
                              {this.state.allowedModulesMobile.length > 0 ? (
                                <ListGroup>
                                  {this.state.allowedModulesMobile.map(
                                    (allowedModulesMob, idx) => (
                                      <ListGroupItem key={idx}>
                                        <BaseTextInput
                                          label={allowedModulesMob?.name}
                                          type="checkbox"
                                          name={allowedModulesMob?.name}
                                          onChange={(e) =>
                                            this.checkedModule(
                                              e,
                                              allowedModulesMob?._id
                                            )
                                          }
                                        /> */}
          {/* <FormGroup check>
                                          <div className="leftalign">
                                            <Label check>
                                              {allowedModulesMob?.name}
                                            </Label>
                                          </div>
                                          <div className="rightalign">
                                            <Input
                                              type="checkbox"
                                              name={allowedModulesMob?.name}
                                              onChange={(e) =>
                                                this.checkedModuleMobile(
                                                  e,
                                                  allowedModulesMob?._id
                                                )
                                              }
                                            />
                                          </div>
                                        </FormGroup> */}
          {/* </ListGroupItem>
                                    )
                                  )}
                                </ListGroup>
                              ) : null}
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </CardBody>
                    <CardFooter className="text-center">
                      <Col md="4" className="ml-auto">
                        <Button
                          className="btn-fill btn-large btn-block"
                          color="info"
                          type="submit"
                        >
                          Add
                        </Button>
                      </Col>
                    </CardFooter>
                  </Form>
                </Card>
              </Col> */}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
      organization: state.getOrganizationReducer.organization
    }
  };
  
export default compose(withRouter, connect(mapStateToProps, null))(AddMasterData);

