import { combineReducers } from "redux";

import organization from "./organization";
import auth from "./auth";
import user from "./user";

const rootReducer = combineReducers({
  organization,
  auth,
  user,
});

export default rootReducer;
