import { ExportToCsv } from "export-to-csv";
import toast from "react-hot-toast";

const CSV_DOWNLOAD_TOAST = "CSV_DOWNLOAD_TOAST";

function CsvDownloader({ fileTitle, data }) {
  if (!data.data.length)
    return toast.error("Nothing to download", { id: CSV_DOWNLOAD_TOAST });

  const options = {
    filename: fileTitle,
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: fileTitle,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers: data.headers,
  };

  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(data.data);
}
export default CsvDownloader;
