import React, { useEffect, useState } from "react";
import GatewayService from "services/GatewayService";
import DateTimeService from "services/DateTimeService";
import "./GatewayActiveIndicator.scss";
import toast from "react-hot-toast";

function GatewayActiveIndicator({ gatewayMac }) {
  const fromTime = new Date().toISOString();

  const [lastPing, setLastPing] = useState("");
  const [active, setActive] = useState(false);

  const isActive = (lastPingTime) => {
    if (!!lastPingTime) {
      const timeDifference = DateTimeService.getDifferenceInMinutes(
        lastPingTime,
        fromTime
      );
      if (timeDifference < 5) {
        setActive(true);
      }
    }
    return;
  };
  useEffect(() => {
    if (gatewayMac) {
      try {
        GatewayService.get({ macId: gatewayMac })
          .then(({ data }) => {
            isActive(data[0]?.lastPingTime);
            setLastPing(data[0]?.lastPingTime);
          })
          .catch((err) => toast.error(err));
      } catch (err) {
        toast.error(err);
      }
    }
    return;
  }, [gatewayMac]);
  return (
    <>
      {!!lastPing && (
        <>
          {active ? (
            <div className="status status--active"></div>
          ) : (
            <div className="status status--notactive"></div>
          )}
        </>
      )}
    </>
  );
}

export default GatewayActiveIndicator;
