import BaseDatePicker from "components/UserInputComponents/DatePickers/BaseDatePicker";
import React, { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import DateTimeService from "services/DateTimeService";
import SubjectService from "services/SubjectService";
import DailyStats from "../Widgets/DailyStats/DailyStats";
import ProfileViewTimeline from "views/custom_components/ProfileViewTimeline";
import ProfilePeriodicAddentance from "../PeriodicAddentance/ProfilePeriodicAddentance";

const subjectService = new SubjectService();

function ProfileAnalytics({ subjectId, setSelectedDate }) {
  const [date, setDate] = useState(new Date());
  const [timeline, setTimeline] = useState([]);
  const fromTime = DateTimeService.getMorningTime(date).toISOString();
  const toTime = DateTimeService.getMidnightTime(date).toISOString();

  useEffect(() => {
    if (!subjectId) return;
    setSelectedDate(date);

    try {
      subjectService
        .getSubjectTimeline(subjectId, fromTime, toTime)
        .then((data) => {
          console.log("data", data);
          setTimeline(data);
        })
        .catch((err) => toast.error(err?.message));
    } catch (err) {
      toast.error(err);
    }
  }, [date]);

  return (
    <>
      <Row>
        <Col md="2" className="d-flex align-items-center">
          <h3 className="mb-3">Daily Analytics</h3>
        </Col>
        <Col md="2" className="d-flex align-items-center">
          <BaseDatePicker
            date={date}
            setDate={setDate}
            placeholder="Select Date"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="4" className="d-flex align-items-stretch">
          <DailyStats date={date} subjectId={subjectId} />
        </Col>
        <Col md="4" className="d-flex align-items-stretch">
          <ProfileViewTimeline
            date={date}
            subjectId={subjectId}
            maxHeight="32vh"
          />
        </Col>
        <Col md="4" className="d-flex align-items-stretch">
          <ProfilePeriodicAddentance date={date} assetId={subjectId} />
        </Col>
      </Row>
    </>
  );
}

export default ProfileAnalytics;
