import toast from "react-hot-toast";

const { default: Swal } = require("sweetalert2");

export default class SWALService {
  static showLoading(message) {
    Swal.fire({
      title: message || "Loading",
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  static showError(message) {
    return toast.error(message || "Something went wrong");
  }

  static showSuccess(message) {
    return Swal.fire({ title: "Success!", icon: "success", text: message });
  }

  static showConfirmDelete(customTitle) {
    const title = customTitle || "Are you sure to Delete it?";
    return Swal.fire({
      title,
      text: "You won't be able to revert this after deleting!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    });
  }
}
