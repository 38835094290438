import React from "react";
import BaseSelect from "./BaseSelect";
import periodicAttendanceSummaryTypes from "config/periodicAttendanceSummaryTypes";

function PeriodicAttendanceSummarySelect({
  value,
  setValue,
  isMultiSelect,
  noLabel,
  renderPortal,
}) {
  const attendanceSummaryTypeOptions = periodicAttendanceSummaryTypes.map(
    (el) => ({
      value: el,
      label: el,
    })
  );

  return (
    <BaseSelect
      label={!noLabel && "Attendance Type"}
      dataSource={attendanceSummaryTypeOptions}
      placeholder={"Select attendance type"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
      renderPortal={renderPortal}
    />
  );
}

export default PeriodicAttendanceSummarySelect;
