import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import SearchBar from "./SearchBar";
import NotificationPrompt from "./NotificationPrompt";
import Controls from "./Controls";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import IdleTimer from "react-idle-timer";

import { logout } from "data/actions/authActions";
import { unsetOrganization } from "data/actions/organizationAction";
import { unsetUser } from "data/actions/userActions";

import AuditLogService from "services/AuditLogService";
import SearchModal from "components/SearchModal/SearchModal";
import toast from "react-hot-toast";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
      alert: null,
      lockedScreen: false,
      isAuthenticated: false,
      loaded: false,
      usrActivity: false,
      message: "",
      valid_err: "",
      sessionRenewed: false,
    };
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.successDelete = this.successDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
    this.authenticate();
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          if (!!this.state.isAuthenticated) {
            this.setState({ sessionRenewed: true });
          }
        })
        .catch(() => {
          if (this.state.isAuthenticated)
            this.setState({ isAuthenticated: false });
        });
    });
    this.checkSessionInterval = setInterval(() => {
      // this.backgroundUserSessionCheck();
    }, 15 * 1000);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
    this.unlisten();
    clearInterval(this.checkSessionInterval);
  }

  authenticate() {
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.setState({ loaded: true, isAuthenticated: true });
        //this.backgroundUserSessionCheck();
      })
      .catch(() => this.props.history.push("/auth"));
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
      });
    } else {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent",
      });
    } else {
      this.setState({
        color: "bg-white",
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch,
    });
  };

  onLogout = () => {
    toast.promise(this.logOutUser(), {
      loading: "Signing out",
      error: "Could not successfylly Sign out",
      success: "Successfully Signed out",
    });
  };

  //log out user aws amplify
  logOutUser = async () => {
    try {
      await Auth.signOut();
      AuditLogService.postSignOut(this.props.user._id);
      this.props.unsetUser();
      this.props.unsetOrganization();
      this.props.logout();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  //backgrounded token expiration check
  backgroundUserSessionCheck = () => {
    if (!!this.state.isAuthenticated) {
      let user_session = this.props.user;
      let acc_tok_time;
      let id_tok_time;
      let current_time;
      if (user_session !== undefined) {
        if (user_session.hasOwnProperty("username")) {
          acc_tok_time = user_session.signInUserSession.accessToken.payload.exp;
          id_tok_time = user_session.signInUserSession.idToken.payload.exp;
          current_time = new Date();
          acc_tok_time = new Date(acc_tok_time * 1000);
          id_tok_time = new Date(id_tok_time * 1000);
        }
      } else {
        let user_data = JSON.parse(localStorage.getItem("userData"));
        acc_tok_time = user_data.accessToken.payload.exp;
        id_tok_time = user_data.idToken.payload.exp;
        current_time = new Date();
        acc_tok_time = new Date(acc_tok_time * 1000);
        id_tok_time = new Date(id_tok_time * 1000);
      }
      if (current_time > acc_tok_time) {
        this.warningWithConfirmAndCancelMessage();
        if (this.state.usrActivity === false) {
          setTimeout(() => {
            this.toggleLockWithSignOut();
          }, 20000);
        }
      } else {
        this.setState({
          alert: null,
        });
      }
    }
  };

  warningWithConfirmAndCancelMessage = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={this.successDelete}
          onCancel={this.cancelDelete}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
          closeOnClickOutside={false}
          btnSize=""
        >
          Are you want to continue the session
        </ReactBSAlert>
      ),
    });
  };
  autoCloseAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Auto close alert!"
          onConfirm={this.hideAlert}
          showConfirm={false}
          closeOnClickOutside={false}
        >
          It will close in 2 seconds.
        </ReactBSAlert>
      ),
    });
    setTimeout(this.hideAlert, 2000);
  };
  inputAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          input
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="Input something"
          onConfirm={(e) => this.inputConfirmAlert(e)}
          onCancel={this.hideAlert}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          btnSize=""
          closeOnClickOutside={false}
        />
      ),
    });
  };
  inputConfirmAlert = (e) => {
    this.setState({ alert: e });
    setTimeout(this.inputConfirmAlertNext, 200);
  };
  inputConfirmAlertNext = () => {
    const inputValue = this.state.alert;
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          onConfirm={this.hideAlert}
          onCancel={this.hideAlert}
          confirmBtnBsStyle="success"
          btnSize=""
          title="You entered: "
          closeOnClickOutside={false}
        >
          <b>{inputValue}</b>
        </ReactBSAlert>
      ),
    });
  };
  successDelete = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Session Restored"
          onConfirm={this.renewSession}
          onCancel={this.onLogout}
          confirmBtnBsStyle="success"
          btnSize=""
          closeOnClickOutside={false}
        >
          Your session is restored safely
        </ReactBSAlert>
      ),
    });
  };
  cancelDelete = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Session Destroyed"
          onConfirm={this.signOut}
          onCancel={this.signOut}
          confirmBtnBsStyle="danger"
          btnSize=""
          closeOnClickOutside={false}
        >
          Your session closed, and safely signed out
        </ReactBSAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  //renew user session
  renewSession = () => {
    try {
      this.hideAlert();
      if (!this.state.sessionRenewed) {
        Auth.currentSession()
          .then((data) => {
            this.setState({ sessionRenewed: true });
            this.props.lockScreenActionDispatch(false);
            this.setState({ usrActivity: true });
            this.props.refreshSessionUser(data);
            localStorage.setItem("userData", JSON.stringify(data));
            this.authenticate();
            window.location.reload(true);
          })
          .catch((err) => console.log(err));
      }
    } catch (e) {
      console.log(e);
    }
  };

  toggleLock = () => {
    try {
      if (!!localStorage.hasOwnProperty("SCREEN_LOCK")) {
        this.setState({ lockedScreen: true });
        this.props.lockScreenActionDispatch(true);
        this.props.history.push("/auth/unlock_screen");
      } else {
        this.setState({
          message: "At first you need to set password for lock the screen",
        });
        setTimeout(() => {
          this.notify("tr");
        }, 3000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  toggleLockWithSignOut = async () => {
    try {
      await Auth.signOut({ global: true });
      localStorage.removeItem(
        "CognitoIdentityServiceProvider.5ukisilcr03ndg9j0d1ijvoqih.bfe63660-537c-4517-85d2-dab6214ef5f9.accessToken:CognitoIdentityServiceProvider.5ukisilcr03ndg9j0d1ijvoqih.bfe63660-537c-4517-85d2-dab6214ef5f9.idToken:CognitoIdentityServiceProvider.5ukisilcr03ndg9j0d1ijvoqih.bfe63660-537c-4517-85d2-dab6214ef5f9.refreshToken"
      );
      this.props.removeUserSession();
      this.setState({
        alert: null,
      });
      localStorage.removeItem("persist:root");
      localStorage.clear();
      //this.props.lockScreenActionDispatch(true);
      this.props.history.push("/auth/login");
    } catch (e) {
      console.log(e);
    }
  };

  handleOnAction(event) {
    // console.log("user did something", event);
  }

  handleOnActive(event) {
    // console.log("user is active", event);
    // console.log("time remaining", this.idleTimer.getRemainingTime());
  }

  handleOnIdle(event) {
    // console.log("user is idle", event);
    // console.log("last active", this.idleTimer.getLastActiveTime());
  }

  render() {
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 20 * 1}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <Navbar
          className={classNames("navbar-absolute", {
            [this.state.color]:
              this.props.location.pathname.indexOf("full-screen-map") === -1,
          })}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize d-inline">
                <Button
                  className="minimize-sidebar btn-just-icon"
                  color="link"
                  id="tooltip209599"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                  <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="tooltip209599"
                  placement="right"
                >
                  Sidebar toggle
                </UncontrolledTooltip>
              </div>
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: this.props.sidebarOpened,
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.props.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
                {this.props.brandText}
              </NavbarBrand>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navigation"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav className="ml-auto" navbar>
                {/* <SearchBar toggleModalSearch={this.toggleModalSearch} /> */}
                <NotificationPrompt />
                <Controls
                  isAuthenticated={this.state.isAuthenticated}
                  loaded={this.state.loaded}
                  logOutUser={this.onLogout}
                />
                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>

        <SearchModal
          isOpen={this.state.modalSearch}
          close={() => this.setState({ modalSearch: false })}
        />

        {/* {this.props.drawerData.drawerOpen ? <RootDrawer /> : null}
        {this.props.analytDrawer.drawerOpen ? <RootDrawer /> : null} */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  unsetOrganization: () => dispatch(unsetOrganization()),
  unsetUser: () => dispatch(unsetUser()),
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
