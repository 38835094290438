import { NOTIFICATION_URL } from "config/api";
import ApiService from "./ApiService";

class InfraPresenceService extends ApiService {
  constructor() {
    super(NOTIFICATION_URL);
  }
}

export default new InfraPresenceService();
