import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import Loader from "views/custom_components/Loader";
import calcHeight from "../../utils/calcHeight";
import SideMenu, { MenuItem } from "../SideMenu/SideMenu";
import menuStages from "./config";

import GatewayService from "services/GatewayService";
import { useSelector } from "react-redux";

function FloorTrailingComponent({ floorPlanId }) {
  const [occupancy, setOccupancy] = useState();

  const organizationId = useSelector((s) => s.organization._id);

  const fetchOccupancy = () =>
    GatewayService.getGatewayOccupancy({
      floorId: floorPlanId,
      count: true,
      organizationId,
    })
      .then(({ data: { data } }) => setOccupancy(data.occupancy))
      .catch((err) => console.log(err));

  useEffect(() => {
    fetchOccupancy();
    const theTimeout = setInterval(() => fetchOccupancy(), 15 * 1000);
    return () => {
      clearInterval(theTimeout);
    };
  }, []);

  return (
    <div className="float-right">
      {occupancy == null ? <Loader noOverlay monoWhite /> : `${occupancy}`}
    </div>
  );
}

// function FloorPlanEditMapsComponent({ floorPlanId }) {
//   return (
//     <Link
//       style={{ color: "white" }}
//       className="float-right pl-4"
//       to={`edit-maps/${floorPlanId}`}
//     >
//       <i className="tim-icons icon-map-big" />
//     </Link>
//   );
// }

function FloorPlanMenu({
  activeMenu,
  setActiveMenu,
  floorPlans,
  setMenuHeight,
  buildingId,
  activeFloorPlanId,
}) {
  useEffect(() => {
    if (!!activeFloorPlanId) {
      console.log("floorPlan", floorPlans);
    }
  }, [activeFloorPlanId]);
  return (
    <CSSTransition
      in={activeMenu === menuStages.floorplans}
      timeout={500}
      classNames="Menu--secondary"
      unmountOnExit
      onEnter={(el) => calcHeight(el, setMenuHeight)}
    >
      <div>
        <SideMenu
          goPrevCallback={() => setActiveMenu(menuStages.buildings)}
          menuLabel={menuStages.floorplans}
          activeId={activeFloorPlanId}
        >
          {floorPlans
            ?.filter((floorPlan) => buildingId === floorPlan.buildingId)
            .map(({ handleClick, label, id }) => (
              <MenuItem
                isActive={activeFloorPlanId === id}
                handleClick={() => handleClick(id)}
                trailingComponent={<FloorTrailingComponent floorPlanId={id} />}
                // optionalTrailingComponent={
                //   <FloorPlanEditMapsComponent floorPlanId={id} />
                // }
              >
                {label.replaceAll("_", " ")}
              </MenuItem>
            ))}
        </SideMenu>
      </div>
    </CSSTransition>
  );
}

export default FloorPlanMenu;
