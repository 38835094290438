import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import AssetSelect from "components/UserInputComponents/Selects/AssetSelect";
import PeriodicAttendanceSummarySelect from "components/UserInputComponents/Selects/PeriodicAttendanceSummarySelect";
import RoleSelect from "components/UserInputComponents/Selects/RoleSelect";
import ScheduleSelect from "components/UserInputComponents/Selects/ScheduleSelect";
import TagSelect from "components/UserInputComponents/Selects/TagSelect";
import useQuery from "customHooks/useQuery";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import UtilsService from "services/UtilsService";
import setQueryParams from "utils/setQueryParams";

function PeriodicAttendanceFilter({ setFilterData }) {
  const history = useHistory();
  const { _id: organizationId } = useSelector((s) => s.organization);

  const [{ subjectId: querySubjectId }] = useQuery();

  const today = new Date();
  const startDateStamp = DateTimeService.getMorningTime(today);
  const endDateStamp = DateTimeService.getMidnightTime(today);

  const [startDate, setStartDate] = useState(startDateStamp);
  const [endDate, setEndDate] = useState(endDateStamp);
  const [subjectId, setSubjectId] = useState(querySubjectId || "");
  const [attendanceType, setAttendanceType] = useState("PERIODIC_DETAIL");
  const [roles, setRoles] = useState([]);
  const [tags, setTags] = useState("");
  const [scheduledEvent, setScheduleEvent] = useState("");

  useEffect(() => {
    if (querySubjectId) setSubjectId(subjectId);
  }, [querySubjectId]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (!attendanceType) return toast.error("Attendance Type is not selected");
    if (
      attendanceType === "PERIODIC_DETAIL" &&
      !(subjectId || (scheduledEvent && tags))
    )
      return toast.error(
        "Select an Asset or a combination of Schedule and Group for detail"
      );
    if (!subjectId && !tags && !scheduledEvent)
      return toast.error("Asset, Schedule, or Primary Group is required");
    if (!scheduledEvent) return toast.error("Schedule is required");

    const data = new UtilsService().cleanObj({
      organizationId,
      fromTime: startDate?.toISOString(),
      toTime: endDate?.toISOString(),
      assetId: subjectId || undefined,
      roles: roles?.join(",") || undefined,
      primaryGroup: tags || undefined,
      attendanceType,
      scheduledEvent,
    });

    history.push({
      pathname: "/admin/periodic-attendance-log",
      search: `${setQueryParams(data)}`,
    });

    setFilterData(data);
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <PeriodicAttendanceSummarySelect
        value={attendanceType}
        setValue={setAttendanceType}
      />
      <StartDatePicker date={startDate} setDate={setStartDate} />
      <EndDatePicker date={endDate} setDate={setEndDate} />
      <RoleSelect value={roles} setValue={setRoles} isMultiSelect />
      <ScheduleSelect
        value={scheduledEvent}
        setValue={setScheduleEvent}
        context={{
          groups: tags,
          assetId: subjectId,
        }}
      />
      <AssetSelect value={subjectId} setValue={setSubjectId} />
      <TagSelect value={tags} setValue={setTags} />
      <BaseButton>Apply</BaseButton>
    </Form>
  );
}

export default PeriodicAttendanceFilter;
