import GroupEntitySelect from "components/UserInputComponents/Selects/GroupEntitySelect";
import NameInput from "components/UserInputComponents/Inputs/TextInputs/NameInput";
import TeacherSelect from "components/UserInputComponents/Selects/TeacherSelect";
import FromTimePicker from "components/UserInputComponents/TimePickers/FromTimePicker";
import ToTimePicker from "components/UserInputComponents/TimePickers/ToTimePicker";
import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import ScheduleService from "services/ScheduleService";
import Swal from "sweetalert2";
import MatterInput from "components/UserInputComponents/Inputs/TextInputs/MatterInput";
import { useSelector } from "react-redux";
import DaysSelect from "components/UserInputComponents/Selects/DaysSelect";
import DateTimeService from "services/DateTimeService";
import FormHolder from "components/Holders/FormHolder/FormHolder";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import { useHistory, useParams } from "react-router-dom";
import Forms from "config/copies/forms";
import TagSelect from "components/UserInputComponents/Selects/TagSelect";
import toast from "react-hot-toast";
import MatterSelect from "components/UserInputComponents/Selects/MatterSelect";
import SWALService from "services/SWALService";

function ScheduleForm() {
  const orgId = useSelector((state) => state.organization._id);

  const { scheduleId } = useParams();

  const buttonText = scheduleId ? "Save Changes" : "Submit";

  const history = useHistory();

  const [name, setName] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [groups, setGroups] = useState([]);
  const [teacher, setTeacher] = useState("");
  const [associateIncharges, setAssociateIncharges] = useState([]);
  const [matter, setMatter] = useState("");
  const [days, setDays] = useState([]);

  useEffect(() => {
    if (!scheduleId) return;
    const params = { id: scheduleId };
    ScheduleService.get(params)
      .then(({ data }) => {
        // TODO: change the index after api support
        const {
          name,
          fromTime,
          toTime,
          groups,
          incharge,
          daysOfWeek,
          matter,
          associateIncharges,
        } = data[0];
        console.log(
          "rohit",
          DateTimeService.getDateTimeFromTimeString(fromTime)
        );
        setName(name);
        setFromTime(DateTimeService.getDateTimeFromTimeString(fromTime));
        setToTime(DateTimeService.getDateTimeFromTimeString(toTime));
        setGroups(groups);
        setTeacher(incharge._id);
        setDays(daysOfWeek);
        setMatter(matter._id);
        setAssociateIncharges(associateIncharges);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [scheduleId]);

  const timeShow = (date) => {
    const dt = new Date(date);
    const options = { hour: "2-digit", minute: "2-digit" };
    return dt.toLocaleTimeString("en-US", options);
  };

  const handleSchedule = (e) => {
    e.preventDefault();
    try {
      const payload = {
        organization: orgId,
        name: name,
        fromTime: DateTimeService.get24TimeStringUTC(fromTime),
        toTime: DateTimeService.get24TimeStringUTC(toTime),
        groups: groups,
        incharge: teacher,
        daysOfWeek: days,
        matter: matter,
        associateIncharges,
      };
      if (!scheduleId)
        ScheduleService.post(payload)
          .then(() => {
            history.goBack();
            Swal.fire({
              icon: "success",
              title: "Schedule Create",
              text: "Schedule created successfully",
            });
          })
          .catch((e) => {
            if (!e.data) return toast.error(e?.message);
            console.log(e.data);
            Swal.fire({
              icon: "error",
              title: e.message,
              html:
                `Similar Schedules:</br>
                </br>
                <ul>
                ` +
                e.data
                  .map(
                    (el) =>
                      `
                      <li>
                      ${el.name} (${el.fromTime} - ${
                        el.toTime
                      }) on ${DateTimeService.getDaysFromArray(el.daysOfWeek)}
                      </li>
                      `
                  )
                  .join("</br>") +
                `
                  </ul>
                  `,
            });
          });
      else
        ScheduleService.put(scheduleId, payload)
          .then(() => {
            history.goBack();
            Swal.fire({
              icon: "success",
              title: "Schedule Updated",
              text: "Changes saved successfully",
            });
          })
          .catch((e) => toast.error(e));
    } catch (error) {
      toast.error(error || "Something went wrong");
    }
  };

  return (
    <div className="content">
      <FormHolder
        imgSrc={Forms.SCHEDULE.illustration}
        formHeader={Forms.SCHEDULE.title}
        formDescription={Forms.SCHEDULE.desc}
      >
        <Form onSubmit={handleSchedule}>
          <NameInput value={name} setValue={setName} />
          <FromTimePicker date={fromTime} setDate={setFromTime} />
          <ToTimePicker date={toTime} setDate={setToTime} />
          <TagSelect
            value={groups}
            setValue={setGroups}
            label="Groups"
            isMultiSelect
          />
          <TeacherSelect
            setValue={setTeacher}
            label="Teacher Incharge"
            value={teacher}
            isMultiSelect={false}
          />
          <TeacherSelect
            setValue={setAssociateIncharges}
            label="Associate Incharges"
            value={associateIncharges}
            isMultiSelect={true}
          />
          <DaysSelect setValue={setDays} value={days} />
          {/* <MatterInput value={matter} setValue={setMatter} /> */}
          <MatterSelect value={matter} setValue={setMatter} />
          <BaseButton color="info">{buttonText}</BaseButton>
        </Form>
      </FormHolder>
    </div>
  );
}

export default ScheduleForm;
