import React, { useState } from "react";
import BaseSelect from "./BaseSelect";
import SubjectService from "services/SubjectService";
import { useEffect } from "react";
import SWALService from "services/SWALService";

const subjectService = new SubjectService();

function HelperSelect({
  value,
  setValue,
  isMultiSelect,
  noLabel,
  renderPortal,
  noPopulate,
}) {
  const [helpers, setHelpers] = useState([]);

  useEffect(() => {
    if (noPopulate) return;
    fetchDriver();
  }, [noPopulate]);

  const fetchDriver = async () => {
    try {
      subjectService
        .get({ role: "TRANSPORT_HELPER" })
        .then(({ data }) => {
          setHelpers(
            data.map((el) => {
              return {
                label: el.name,
                value: el._id,
              };
            })
          );
        })
        .catch((err) => {
          SWALService.showError(err);
        });
    } catch (err) {
      SWALService.showError(err);
    }
  };

  return (
    <BaseSelect
      label={!noLabel && "Helper"}
      dataSource={helpers}
      placeholder={"Select helper"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
      renderPortal={renderPortal}
    />
  );
}

export default HelperSelect;
