import SubjectView from "components/PopoverViews/SubjectView/SubjectView";
import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React from "react";
import Avatar from "views/custom_components/Avatar/Avatar";

function RoutePointAssetTable({
  getData,
  data,
  loading,
  setData,
  setUnMapData,
}) {
  const deleteAsset = (id) => {
    setUnMapData(
      data.filter((asset) => asset._id === id).map((asset) => asset._id)
    );
    setData(data.filter((asset) => asset._id !== id));
  };
  const assetTableConfig = [
    {
      Header: "#",
      Cell: (row) => {
        return (
          <div>
            <Avatar
              height={34}
              src={row.original.attributes?.avatar}
              alt={row.original.attributes?.name}
              gender={row.original.attributes?.gender}
            />
          </div>
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ original }) => {
        return (
          <SubjectView subjectId={original._id} subjectData={original}>
            {original.name}
          </SubjectView>
        );
      },
    },
    {
      Header: "Unique ID",
      accessor: "uniqueId",
    },
    {
      Header: "Actions",

      Cell: (row) => <TableActions {...row} deleteCallback={deleteAsset} />,
    },
  ];
  return (
    <BaseTable
      tableConfig={assetTableConfig}
      data={data}
      loading={loading}
      emptyMessage="Add assets to view"
    />
  );
}

export default RoutePointAssetTable;
