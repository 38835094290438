import React, { useState } from "react";
import BaseTimeInput from "./BaseTimeInput";
import DateTimeService from "services/DateTimeService";

function FromTimeInput({ value, setValue }) {
  const now = new Date();
  const todayMorning = DateTimeService.getMorningTime(now);
  const [startTime, setStartTime] = useState(todayMorning);

  const changeTime = (selectedTime) => {
    setStartTime(selectedTime);
    setValue(selectedTime);
  };

  return (
    <>
      <BaseTimeInput
        label={"From Time"}
        placeholder={"Select from time"}
        value={value || startTime}
        setValue={(val) => changeTime(val)}
      />
    </>
  );
}

export default FromTimeInput;
