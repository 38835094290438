import Amplify from "@aws-amplify/core";
import Storage from "@aws-amplify/storage";
import awsmobile from "./aws-exports";

window.LOG_LEVEL = "DEBUG";

export function configureAmplify() {
  Amplify.configure({
    Auth: {
      identityPoolId: awsmobile.aws_cognito_identity_pool_id,
      region: awsmobile.aws_project_region,
      userPoolId: awsmobile.aws_user_pools_id,
      userPoolWebClientId: awsmobile.aws_user_pools_web_client_id,
    },
    Storage: {
      AWSS3: {
        bucket: "parchai-ble-floorplans",
        region: awsmobile.aws_project_region,
        identityPoolId: awsmobile.aws_cognito_identity_pool_id,
      },
    },
  });
}
//Configure Storage with S3 bucket information
export function SetS3Config(bucket, level) {
  Storage.configure({
    bucket: bucket,
    level: level,
    region: awsmobile.aws_project_region,
    identityPoolId: awsmobile.aws_cognito_identity_pool_id,
  });
}
