import FilterTableHolder from "components/Holders/FilterTableHolder/FilterTableHolder";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SWALService from "services/SWALService";
import VehicleService from "services/VehicleService";
import VehicleFilter from "./subComponent/VehicleFilter";
import VehicleTable from "./subComponent/VehicleTable";
import CsvDownloader from "utils/CsvDownloader";
import getReportData from "utils/getReportData";

function VehicleListing() {
  const organizationId = useSelector((state) => state.organization._id);
  const defaultParams = { organizationId };
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vehicleParams, setVehicleParams] = useState({});
  const [tableConfig, setTableConfig] = useState([]);

  useEffect(() => {
    fetchVehicle();
  }, [vehicleParams]);

  const fetchVehicle = async () => {
    setLoading(true);

    const params = {
      ...vehicleParams,
      ...defaultParams,
    };

    const { data } = await VehicleService.get(params).catch((err) =>
      SWALService.showError(err)
    );

    if (data) setData(data);
    setLoading(false);
  };

  const addVehicle = () => history.push("/admin/vehicle/add");

  return (
    <FilterTableHolder>
      <VehicleFilter setVehicleParams={setVehicleParams} />
      <TableHeader
        title="Vehicle"
        onClickAdd={addVehicle}
        onClickDownload={true}
        tableConfig={tableConfig}
        tableData={data}
      />
      <VehicleTable
        data={data}
        getData={fetchVehicle}
        loading={loading}
        setTableConfig={setTableConfig}
      />
    </FilterTableHolder>
  );
}

VehicleListing.routeName = "/admin/vehicle/manage";

export default VehicleListing;
