class LocalStorage {
  constructor(key, value){
      this.key = key;
      this.value = value;
  }

  getLocalStorage(){
    const value = localStorage.getItem(this.key);
    this.value = value;
    return this.value;
  }

  setLocalStorage(){
    localStorage.setItem(this.key, this.value);
  }

  removeLocalStorage(){
    localStorage.removeItem(this.key);
  }
}

export default LocalStorage;
