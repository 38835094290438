import React from "react";
import PolicyInfo from "./PolicyInfo";
import PolicyTime from "./PolicyTime";
import PolicyTrigger from "./PolicyTrigger";
import PolicyService from "services/PolicyService";
import ReactWizard from "react-bootstrap-wizard";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { Col, Fade } from "reactstrap";
import PolicyActions from "./PolicyActions";
import PolicyDevices from "./PolicyDevices";
import PolicyLocation from "./PolicyLocation";
import FormHolder from "components/Holders/FormHolder/FormHolder";
import Forms from "config/copies/forms";

import DateTimeService from "services/DateTimeService";
import SWALService from "services/SWALService";
import Units from "config/Units";
import BrandLoader from "views/custom_components/Loader";

const getWizardSteps = (policy) => [
  {
    stepName: "About",
    stepIcon: "tim-icons icon-alert-circle-exc",
    component: PolicyInfo,
    stepProps: { policy },
  },
  {
    stepName: "Timings",
    stepIcon: "tim-icons icon-watch-time",
    component: PolicyTime,
    stepProps: { policy },
  },
  {
    stepName: "Devices",
    stepIcon: "tim-icons icon-mobile",
    component: PolicyDevices,
    stepProps: { policy },
  },
  {
    stepName: "Location",
    stepIcon: "tim-icons icon-square-pin",
    component: PolicyLocation,
    stepProps: { policy },
  },
  {
    stepName: "Triggers",
    stepIcon: "tim-icons icon-settings-gear-63",
    component: PolicyTrigger,
    stepProps: { policy },
  },
  {
    stepName: "Actions",
    stepIcon: "tim-icons icon-user-run",
    component: PolicyActions,
    stepProps: { policy },
  },
];

class CreatePolicy extends React.Component {
  policyId = "";

  constructor(props) {
    super(props);

    this.policyId = props.match.params.policyId;
    this.state = {
      policy: null,
      wizardSteps: null,
      loading: false,
    };
  }

  componentDidMount() {
    if (this.policyId) return this.getPolicy();
    this.setState({ wizardSteps: getWizardSteps() });
  }

  finishButtonClick = (allStates) => {
    const { About, Actions, Devices, Location, Timings, Triggers } = allStates;

    try {
      this.setState({ loading: true });

      const policyObj = {
        name: About.name,
        description: About.description,
        activeTime: {
          ...Timings,
          fromTime: DateTimeService.get24TimeStringUTC(Timings.fromTime),
          toTime: DateTimeService.get24TimeStringUTC(Timings.toTime),
          days: DateTimeService?.getDaysObject(Timings.days),
        },
        ...Devices,
        ...Location,
        actions: Actions?.actions,
        rateLimiter: {
          number: Actions.rateLimiter?.number,
          duration: Actions.rateLimiter?.duration * 1000,
        },

        triggerCondition_config_threshold: Triggers?.threshold * 1000,

        triggerCondition_config_compulsoryLocationPresence:
          Triggers?.isCompulsory,
        triggerCondition_policyType: Triggers?.policy_type.value,
        triggerCondition_triggerParam: Triggers?.policy_trigger_by.value,
        triggerCondition_config_operator: Triggers?.triggerOperator?.value,
        triggerCondition_config_proximity: "IMMEDIATE",
        accessType: About?.accessType,
        organization: this.props.organization._id,
        createdBy: this.props.user._id,
      };
      // const utils = new UtilsService();
      // const cleanPolicyObj = utils.CleanObject(policyObj);
      // const objCleanPolicyObj = utils.removeObjectIfEmpty(cleanPolicyObj);

      (async () => {
        const { message, success } = this.policyId
          ? await PolicyService.put(this.policyId, policyObj)
          : await PolicyService.post(policyObj);

        if (!success) return SWALService.showError(message);

        this.setState({ loading: false });
        SWALService.showSuccess(message).then(() =>
          this.props.history.goBack()
        );
      })();
      // console.log("safsf", policyObj);
    } catch (error) {
      console.log(error);
      SWALService.showError(error.message);
    }
  };

  getPolicy = async () => {
    this.setState({ loading: true });

    const { data } = await PolicyService.getById(this.policyId);
    this.setState({
      policy: data,
      wizardSteps: getWizardSteps(data),
      loading: false,
    });
  };

  render() {
    return (
      <div className="content">
        <FormHolder
          imgSrc={Forms.POLICY.illustration}
          formHeader={Forms.POLICY.title}
          formDescription={Forms.POLICY.desc}
        >
          <Col className="mr-auto ml-auto">
            {!!this.state.wizardSteps && (
              <>
                {this.state.loading ? (
                  <BrandLoader noOverlay />
                ) : (
                  <ReactWizard
                    steps={this.state.wizardSteps}
                    navSteps
                    // validate
                    title="Policy"
                    description="Create simple rules that will perform chosen actions when triggered"
                    headerTextCenter
                    finishButtonClasses="btn-wd btn-primary"
                    nextButtonClasses="btn-wd btn-info"
                    previousButtonClasses="btn-wd"
                    progressbar
                    color="blue"
                    finishButtonClick={this.finishButtonClick}
                  />
                )}
              </>
            )}
          </Col>
        </FormHolder>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organization: state.organization,
  user: state.user,
});

export default compose(withRouter, connect(mapStateToProps))(CreatePolicy);
