import React, { useState } from "react";
import BaseSelect from "./BaseSelect";

function BooleanSelect({
  value,
  setValue,
  label,
  placeholder,
  bottomHintText,
  isMultiSelect,
}) {
  const BooleanValues = [
    { label: "YES", value: true },
    { label: "NO", value: false },
  ];

  return (
    <BaseSelect
      label={label}
      dataSource={BooleanValues}
      placeholder={placeholder}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
      bottomHintText={bottomHintText}
    />
  );
}

export default BooleanSelect;
