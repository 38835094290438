// import React from "react";
// import {
//   Row,
//   Col,
//   PaginationItem,
//   PaginationLink,
//   Pagination,
//   Card,
//   CardBody,
//   Table,
//   Button,
// } from "reactstrap";
// import { connect } from "react-redux";
// import BaseService from "../../../../services/BaseService";
// import UtilsService from "../../../../services/UtilsService";
// import { SOS_ALERT_URL } from "../../../../config/api";
// import Swal from "sweetalert2";
// import ViolationDetailModals from "../../../components/ViolationDetailModals";
// import "./violations.css";
// import { saveViolationModalStatus } from "../../../../data_layer_saga/actions/dashboard";

// class Violations extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       classGroup: "",
//       section: "",
//       dateValue: "",
//       keyword: "",
//       modal: false,
//       toggle: false,
//       backdrop: false,
//       keyboard: false,
//       openModal: false,
//       currentPage: 0,
//       pageCount: 1,
//       pageSize: 30,
//       violationsData: [],
//       paginatedData: [],
//       paginationNumbers: null,
//     };
//   }

//   componentWillMount() {
//     this.getViolationsData();
//   }

//   getViolationsData() {
//     const { pageSize } = this.state;
//     if (this.props.location.state !== undefined) {
//       if (this.props.location.state !== null) {
//         const pageCount = Math.ceil(
//           this.props.location.state.violationsData.length % pageSize === 0
//             ? this.props.location.state.violationsData.length / pageSize
//             : this.props.location.state.violationsData.length / pageSize + 1
//         );
//         this.setState({ pageCount: pageCount });
//       } else {
//         this.fetchViolations();
//       }
//     } else {
//       this.fetchViolations();
//     }
//   }

//   fetchViolations() {
//     const { pageSize } = this.state;
//     const baseService = new BaseService();
//     const utilsService = new UtilsService();
//     let params = null;
//     if (this.props.organization !== undefined) {
//       params = { organizationId: this.props.organization[0]._id };
//     }
//     baseService
//       .doGET(SOS_ALERT_URL, "GET", params, true)
//       .then((response) => {
//         if (!!response.data.success) {
//           let violationData = response.data.data;
//           let violationsData = violationData.map((violation) => {
//             violation["generatedOn"] = utilsService.getEightHoursPreviousTime(
//               violation.createdAt
//             );
//             return violation;
//           });
//           this.setState({ violationsData: violationsData });
//           const pageCount = Math.ceil(
//             violationsData.length % pageSize === 0
//               ? violationsData.length / pageSize
//               : violationsData.length / pageSize + 1
//           );
//           this.setState({ pageCount: pageCount });
//           this.setPagination();
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }

//   setPagination() {
//     const { pageSize, pageCount, currentPage } = this.state;
//     let pageinationNumbers = [];
//     for (let i = 0; i < 8; i++) {
//       pageinationNumbers.push(
//         <PaginationItem key={i} active={currentPage === i ? true : false}>
//           <PaginationLink onClick={(e) => this.handleClick(e, i)} href="#">
//             {i + 1}
//           </PaginationLink>
//         </PaginationItem>
//       );
//       this.setState({ paginationNumbers: pageinationNumbers });
//     }
//     if (this.props.location.state !== undefined) {
//       if (this.props.location.state !== null) {
//         this.setState({
//           paginatedData: this.props.location.state.violationsData.slice(
//             currentPage * pageSize,
//             (currentPage + 1) * pageSize
//           ),
//         });
//       } else {
//         if (this.state.violationsData.length > 0) {
//           console.log(this.state.violationsData);
//           this.setState({
//             paginatedData: this.state.violationsData.slice(
//               currentPage * pageSize,
//               (currentPage + 1) * pageSize
//             ),
//           });
//         }
//       }
//     } else {
//       if (this.state.violationsData.length > 0) {
//         console.log(this.state.violationsData);
//         this.setState({
//           paginatedData: this.state.violationsData.slice(
//             currentPage * pageSize,
//             (currentPage + 1) * pageSize
//           ),
//         });
//       }
//     }
//     this.forceUpdate();
//   }

//   onChangeClassGroup = (event) => {
//     let obj = { label: event.label, value: event.value };
//     this.setState({ classGroup: obj });
//   };

//   onChangeSection = (event) => {
//     let obj = { label: event.label, value: event.value };
//     this.setState({ section: obj });
//   };

//   onChangeFromDate = (event) => {
//     this.setState({ dateValue: event.value });
//   };

//   onSearchkeyword = (event) => {
//     console.log(event.target.value);
//     this.setState({ keyword: event.target.value });
//   };

//   openViolationModalTrigger = (event, violationId) => {
//     const violationData = [...this.props.location.state.violationsData];
//     const singleViolation = violationData.filter(
//       (violation) => violation._id === violationId
//     );
//     this.handleViolationAlertCallback(singleViolation[0]._id);
//     event.preventDefault();
//   };

//   handleViolationAlertCallback = (violation_Id) => {
//     let violationId = violation_Id;
//     this.setState((prevState) => ({
//       ...prevState,
//       violation: {
//         violationId: violationId,
//         openModal: !this.state.openModal,
//         modal: !this.state.modal,
//         toggle: !this.state.toggle,
//         backdrop: !this.state.backdrop,
//         keyboard: !this.state.keyboard,
//       },
//     }));
//     setTimeout(() => {
//       this.props.saveViolationModalStatus(this.state.violation);
//     }, 2000);
//   };

//   toggleChange = () => {
//     this.setState({
//       modal: !this.state.modal,
//       toggle: !this.state.toggle,
//       backdrop: !this.state.backdrop,
//       keyboard: !this.state.keyboard,
//       openModal: !this.state.openModal,
//     });
//   };

//   handleClick = (e, index) => {
//     this.setState({ currentPage: index });
//     this.setPagination();
//     this.forceUpdate();
//   };

//   deleteViolation = (violationId) => {
//     const baseService = new BaseService();
//     Swal.fire({
//       title: "Are you sure to Delete it?",
//       text: "You won't be able to revert this after deleting!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         baseService
//           .doDELETE(SOS_ALERT_URL, "DELETE", violationId, true)
//           .then(() => {
//             Swal.fire("Deleted!", "Notice has been deleted.", "success");
//           });
//       }
//     });
//   };

//   render() {
//     return (
//       <div className="content">
//         <Row>
//           <Col md={12}>
//             <Card>
//               <CardBody>
//                 {this.state.paginatedData.length > 0 ? (
//                   <>
//                     <Table responsive>
//                       <thead className="text-primary">
//                         <tr>
//                           <th className="text-center">#</th>
//                           <th className="text-left">Student Name</th>
//                           <th className="text-left">Violation Name</th>
//                           <th className="text-left">Location</th>
//                           <th className="text-left">Timestamp</th>
//                           <th className="text-center">Action</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {this.state.paginatedData.map((violation, index) => (
//                           <tr
//                             onClick={(e) =>
//                               this.openViolationModalTrigger(e, violation._id)
//                             }
//                             style={{ cursor: "pointer" }}
//                             key={index}
//                           >
//                             <td className="text-center">
//                               <div className="photo">
//                                 <img
//                                   alt={violation.subject.name}
//                                   src={violation.subject.attributes.avatar}
//                                 />
//                               </div>
//                             </td>
//                             <td className="text-left">
//                               {violation.subject.name}
//                             </td>
//                             <td className="text-left">
//                               {violation.policy.name}
//                             </td>
//                             <td className="text-left">
//                               {violation.floorPlanSection.floorPlanSectionName}
//                             </td>
//                             <td className="text-left">
//                               {violation.generatedOn}
//                             </td>
//                             <td className="text-center">
//                               <Button
//                                 className="btn-link btn-icon"
//                                 color="success"
//                                 size="sm"
//                                 onClick={() =>
//                                   this.openModalTrigger(violation._id)
//                                 }
//                               >
//                                 <i className="tim-icons icon-book-bookmark" />
//                               </Button>
//                               <Button
//                                 className="btn-link"
//                                 color="danger"
//                                 size="sm"
//                                 onClick={() =>
//                                   this.deleteViolation(violation._id)
//                                 }
//                               >
//                                 <i className="tim-icons icon-simple-remove" />
//                               </Button>
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </Table>
//                     <Pagination aria-label="Page navigation example">
//                       <PaginationItem>
//                         <PaginationLink
//                           onClick={(e) =>
//                             this.handleClick(e, this.state.currentPage - 1)
//                           }
//                           previous
//                           href="#"
//                         />
//                       </PaginationItem>
//                       {this.state.paginationNumbers}
//                       <PaginationItem
//                         disabled={
//                           this.state.currentPage >= this.state.pageCount - 1
//                         }
//                       >
//                         <PaginationLink
//                           onClick={(e) =>
//                             this.handleClick(e, this.state.currentPage + 1)
//                           }
//                           next
//                           href="#"
//                         />
//                       </PaginationItem>
//                     </Pagination>
//                   </>
//                 ) : null}
//               </CardBody>
//             </Card>
//           </Col>
//         </Row>
//         {!!this.state.openModal && (
//           <ViolationDetailModals
//             openModal={this.state.openModal}
//             modal={this.state.modal}
//             toggle={this.state.toggle}
//             backdrop={this.state.backdrop}
//             keyboard={this.state.keyboard}
//             toggleViolationChange={this.toggleChange}
//             violationData={this.state.violationsData}
//           />
//         )}
//       </div>
//     );
//   }
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     saveViolationModalStatus: (violationModalState) =>
//       dispatch(saveViolationModalStatus(violationModalState)),
//   };
// }

// const mapStateToProps = (state) => {
//   return {
//     organization: state.getOrganizationReducer.organization,
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Violations);

import React from "react";

function Violations() {
  return <div>Violations</div>;
}

export default Violations;
