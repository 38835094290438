import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import toast from "react-hot-toast";

function PdfDownloader({ fileTitle, data }) {
  console.log("checkpdf", data, fileTitle);
  if (!data.data.length)
    return toast.error("Nothing to download", { id: "PdfDownload" });
  const unit = "pt";
  const size = "A4";
  const orientation = "portrait";

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const title = fileTitle;

  let content = {
    // startY: 50,
    head: data.headers,
    body: data.data,
  };

  const titleLines = title.split("\n").length;

  doc.text(title, marginLeft, 40);
  autoTable(doc, { margin: { top: 18 * titleLines + 40 }, ...content });
  doc.save(fileTitle);
}

export default PdfDownloader;
