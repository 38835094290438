import configureBeaconStatus from "config/enums/ConfigureBeaconStatus";
import React from "react";
import BaseSelect from "./BaseSelect";

function ConfigureBeaconStatusSelect({
  value,
  setValue,
  isMultiSelect,
  noLabel,
  context = {},
  noPopulate,
}) {
  return (
    <BaseSelect
      label={!noLabel && "Status"}
      dataSource={configureBeaconStatus?.map((status) => ({
        value: status,
        label: status,
      }))}
      placeholder={"Select Configuration Status"}
      closeMenuOnSelect={false}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default ConfigureBeaconStatusSelect;
