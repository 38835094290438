import React, { useEffect, useState } from "react";
import FilterTableHolder from "components/Holders/FilterTableHolder/FilterTableHolder";
import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import toast from "react-hot-toast";
import AttendanceService from "services/AttendanceService";
import DateTimeService from "services/DateTimeService";
import getTitleContext from "utils/getTitleContext";
import DailyAttendanceFilter from "./sub-components/DailyAttendanceFilter";
import DailyAttendanceDetailsTableConfig from "./sub-components/DailyAttendanceDetailsTableConfig";
import DailyAttendanceSummaryTableConfig from "./sub-components/DailyAttendanceSummaryTableConfig";

const cleanObj = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

function DailyAttendance() {
  const [queryParams, setQueryParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [dailyAttendance, setDailyAttendance] = useState([]);
  const [filterContext, setFilterContext] = useState([]);

  const fetchDailyAttendance = async () => {
    const params = {
      ...queryParams,
    };

    try {
      setLoading(true);

      const { data, success } = await AttendanceService.get(cleanObj(params));

      if (!success) return toast.error("Something went wrong");

      if (!data.length) {
        setDailyAttendance([]);
        return toast.error("No data to show");
      }

      setDailyAttendance(data);
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(queryParams).length <= 0) return;
    fetchDailyAttendance();
  }, [queryParams]);

  return (
    <FilterTableHolder>
      <DailyAttendanceFilter
        setFilterData={setQueryParams}
        setFilterContext={setFilterContext}
      />
      <TableHeader
        fileTitle={`Attendance_${
          queryParams?.attendanceType === "DAILY_SUMMARY" ? `Summary` : `Daily`
        }_${DateTimeService.getDateString(
          queryParams.fromTime
        )}_${DateTimeService.getDateString(
          queryParams.toTime
        )}${getTitleContext(filterContext)}`}
        title={
          queryParams?.attendanceType === "DAILY_SUMMARY"
            ? "Daily Attendance Summary"
            : "Daily Attendance Details"
        }
        onClickDownload={true}
        tableConfig={
          queryParams?.attendanceType === "DAILY_SUMMARY"
            ? DailyAttendanceSummaryTableConfig
            : DailyAttendanceDetailsTableConfig
        }
        tableData={dailyAttendance}
        countData={
          queryParams?.attendanceType !== "DAILY_SUMMARY" &&
          `${dailyAttendance.filter((el) => el.present).length}/${
            dailyAttendance.length
          }`
        }
      />
      <BaseTable
        data={dailyAttendance}
        tableConfig={
          queryParams?.attendanceType === "DAILY_SUMMARY"
            ? DailyAttendanceSummaryTableConfig
            : DailyAttendanceDetailsTableConfig
        }
        loading={loading}
      />
    </FilterTableHolder>
  );
}

export default DailyAttendance;
