import Dashboard from "views/Dashboard.js";
import ParentConnect from "views/pages/admin/parentConnect/parentConnect";
import FloorPlan from "views/pages/admin/floorPlan/floorPlan";
import FloorPlanSection from "views/pages/admin/floorPlanSection/floorPlanSection";
import SetLock from "views/pages/SetLock";
import ParentConnectForm from "views/pages/admin/parentConnect/subComponent/ParentConnectForm";
import FloorPlanForm from "views/pages/admin/floorPlan/subComponent/FloorPlanForm";
import AddRestrictionMain from "views/pages/admin/AddRestriction/AddRestrictionMain";
import AddRestriction from "views/pages/admin/AddRestriction/subcomponents/SelectionCard/AddRestriction";
import AddBeacon from "views/pages/admin/beacons/subComponent/AddBeacon";
import EditBeacon from "views/pages/admin/beacons/subComponent/EditBeacon";
import Subject from "views/pages/admin/Subject/Subject";
import Building from "views/pages/admin/Building/Building";
import BuildingForm from "views/pages/admin/Building/subComponent/BuildingForm";
import SignIn from "views/pages/auth/signIn/signIn";
import SignUp from "views/pages/auth/SignUp/SignUp";
import ForgotPassword from "views/pages/auth/forgotPass/forgotPass";
import ChangePassword from "views/pages/auth/changePass/changePass";
import Violations from "views/pages/admin/Violations/Violations";
import Alerts from "views/pages/admin/Alerts/Alerts";
import PolicyViolationReport from "views/pages/admin/Reports/PolicyViolation/PolicyViolationReport";
import SOSReport from "views/pages/admin/Reports/SOS/SOSReport";
// import EntryExitReport from "views/pages/admin/Reports/EntryExitReport/EntryExitReport";
import AreaPresenceReport from "views/pages/admin/Reports/AreaPresenceReport/AreaPresenceReport";
import InfraPresenceReport from "views/pages/admin/Reports/InfraPresenceReport/InfraPresenceReport";
import NotificationReport from "views/pages/admin/Reports/NotificationsReport";
import ManageUserRole from "views/pages/admin/ManageUserRole/ManageUserRole";
import ManageActions from "views/pages/admin/ManageActions/ManageActions";
import ManagePolicies from "views/pages/admin/ManagePolicies/ManagePolicies";
import ManageHolidays from "views/pages/admin/Holidays/ManageHolidays";
import EditHoliday from "views/pages/admin/Holidays/subComponents/EditHoliday";
import ManageMasterData from "views/pages/admin/MasterData/ManageMasterData";
import AddMasterData from "views/pages/admin/MasterData/subComponent/AddMasterData";
// import EditMasterData from "views/pages/admin/MasterData/subComponent/EditMasterData";
import EditMaps from "views/custom_components/LeafletMaps/EditMaps/EditMaps";
import ViewMaps from "views/custom_components/LeafletMaps/ViewMaps/ViewMaps";
import Schedules from "views/pages/admin/Schedules/Schedules";
import Profile from "views/pages/Profile/Profile";
import OrganizationForm from "views/pages/admin/Organization/subComponent/OrganizationForm";
import ManageUser from "views/pages/admin/User/ManageUser";
import UserForm from "views/pages/admin/User/subComponents/UserForm";
import FloorPlanSectionForm from "views/pages/admin/floorPlanSection/subComponent/FloorPlanSectionForm";
import SubjectForm from "views/pages/admin/Subject/subcomponents/SubjectForm";
import ManageCalenderEvents from "views/pages/admin/Calender/ManageCalenderEvents";
import CalenderEventForm from "views/pages/admin/Calender/subcomponents/CalenderEventForm";
import ManageTags from "views/pages/Tags/ManageTags";
import AuditTrail from "views/pages/admin/Reports/AuditTrail/AuditTrail";
import OtpVerify from "views/pages/auth/OtpVerify/OtpVerify";
import UserAdd from "views/pages/admin/User/subComponents/UserAdd";
import Beacons from "views/pages/admin/beacons/Beacons";
import Gateway from "views/pages/admin/gateway/Gateway";
import GatewayForm from "views/pages/admin/gateway/subComponent/GatewayForm";
import VisitorRoutes from "views/pages/admin/VisitorManagement/Visitor.route";
import ActionForm from "views/pages/admin/ManageActions/subComponents/ActionForm/ActionForm";
import ScheduleForm from "views/pages/admin/Schedules/sub-components/ScheduleForm";
import PolicyForm from "views/pages/admin/ManagePolicies/subComponents/PolicyForm";
import SettingsPage from "views/pages/admin/Settings/SettingsPage";
import campusRoutes from "views/pages/admin/Campus/Campus.route";
import gateRoutes from "views/pages/admin/Gate/Gate.route";
import assetDataChangeRoutes from "views/pages/admin/AssetChange/routes";
import vehicleRoutes from "views/pages/admin/Vehicles/Vehicle.route";
import shiftRoutes from "views/pages/admin/Shift/Shift.route";
import vehicleRouteRoutes from "views/pages/admin/Routes/Route.route";
import ManageGatewayBeacon from "views/pages/ManageGatewayBeacon";
import PolicyDiagnosis from "views/pages/PolicyDiagnosis";
import GatewayPings from "views/pages/GatewayPings";
import ManageMatters from "views/pages/Matter/ManageTags";
import TransportReport from "views/pages/admin/Reports/Transport/TransportReport";
import DailyAttendance from "views/pages/admin/Reports/Attendance/DailyAttendance/DailyAttendance";
import PeriodicAttendance from "views/pages/admin/Reports/Attendance/PeriodicAttendance/PeriodicAttendance";
import GatewayStateLogs from "views/pages/GatewayStateLogs";
import BuildingPresenceReport from "views/pages/admin/Reports/BuildingPresence/BuildingPresenceReport";
import TransitItineraryRoutes from "views/pages/admin/TransitItinerary/TransitItinerary.route";
import ConfigureBeacon from "views/pages/admin/ConfigureBeacons/ConfigureBeacon";
import AssetPresenceReport from "views/pages/admin/Reports/AssetPresence/AssetPresenceReport";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "space_dashboard",
    component: Dashboard,
    layout: "/admin",
    module: "DASHBOARD",
    showVisible: true,
  },
  {
    path: "/smart-location",
    name: "Smart Location",
    rtlName: "التقويم",
    icon: "location_on",
    component: ViewMaps,
    layout: "/admin",
    module: "SMART_LOCATION",
    state: "pagesCollapseSmartLoc",
    showVisible: true,
    mini: "MV",
    rtlMini: "ع",
  },
  {
    path: "/communication/view",
    name: "Communication",
    rtlName: "التقويم",
    icon: "chat_bubble",
    component: ParentConnect,
    layout: "/admin",
    module: "PARENT_CONNECT",
    showVisible: true,
  },
  {
    path: "/admin/parent-connect/send",
    rtlName: "التقويم",
    component: ParentConnectForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    collapse: true,
    name: "Reports",
    rtlName: "صفحات",
    icon: "stacked_bar_chart",
    module: "REPORTS",
    state: "pagesCollapseReport",
    showVisible: true,
    views: [
      {
        path: "/asset-presence",
        name: "Asset Presence Report",
        rtlName: "عالتسعير",
        mini: "AP",
        rtlMini: "ع",
        component: AssetPresenceReport,
        layout: "/admin",
        module: "AREA_PRESENCE_REPORT",
        showVisible: true,
      },
      {
        path: "/notification-logs",
        name: "Notification Logs Report",
        rtlName: "عالتسعير",
        mini: "AR",
        rtlMini: "ع",
        component: NotificationReport,
        layout: "/admin",
        module: "INFRA_PRESENCE_REPORT",
        showVisible: true,
      },
      {
        path: "/transports",
        name: "Transport Report",
        rtlName: "عالتسعير",
        mini: "TR",
        rtlMini: "ع",
        component: TransportReport,
        layout: "/admin",
        module: "TRANSPORT_REPORT",
        showVisible: true,
      },
      {
        path: "/policy_violation",
        name: "Policy Violation",
        rtlName: "التقويم",
        mini: "AS",
        rtlMini: "ع",
        component: PolicyViolationReport,
        layout: "/admin",
        module: "POLICY_VIOLATION",
        showVisible: false,
      },
      {
        path: "/alerts",
        name: "Alert Report",
        rtlName: "التقويم",
        mini: "AR",
        rtlMini: "ع",
        component: SOSReport,
        layout: "/admin",
        module: "Alerts",
        showVisible: true,
      },
      {
        path: "/audit_trail",
        name: "Audit Trail",
        rtlName: "التقويم",
        mini: "AT",
        rtlMini: "ع",
        component: AuditTrail,
        layout: "/admin",
        module: "AUDIT_TRAIL",
        showVisible: true,
      },
      {
        path: "/daily-attendance-log",
        name: "Daily Attendance",
        rtlName: "التقويم",
        mini: "AS",
        rtlMini: "ع",
        component: DailyAttendance,
        layout: "/admin",
        module: "Attendance",
        showVisible: true,
      },
      {
        path: "/periodic-attendance-log",
        name: "Periodic Attendance",
        rtlName: "التقويم",
        mini: "AS",
        rtlMini: "ع",
        component: PeriodicAttendance,
        layout: "/admin",
        module: "Attendance",
        showVisible: true,
      },
    ],
  },
  VisitorRoutes,
  {
    path: "/settings",
    name: "Management",
    rtlName: "التقويم",
    icon: "settings",
    component: SettingsPage,
    layout: "/admin",
    module: "SETTINGS",
    showVisible: true,
  },
  {
    path: "/admin/edit-maps/:id",
    name: "Edit Map",
    rtlName: "التقويم",
    component: EditMaps,
    layout: "/admin",
    module: "SMART_LOCATION",
    showVisible: false,
  },
  // {
  //   path: "/admin/admin/edit-maps/:id",
  //   rtlName: "التقويم",
  //   component: EditMaps,
  //   layout: "/admin",
  //   module: "SMART_LOCATION",
  //   showVisible: false,
  // },
  {
    path: "/admin/manage-calender",
    rtlName: "عالتسعير",
    component: ManageCalenderEvents,
    layout: "/admin",
    name: "Calender Events",
    showVisible: false,
  },
  {
    path: "/admin/calendar-event/add",
    rtlName: "عالتسعير",
    component: CalenderEventForm,
    layout: "/admin",
    name: "Add Calender Event",
    showVisible: false,
  },
  {
    path: "/admin/calendar-event/edit/:calenderEventId",
    rtlName: "عالتسعير",
    component: CalenderEventForm,
    layout: "/admin",
    name: "Edit Calender Event",
    showVisible: false,
  },
  {
    path: OrganizationForm.routeName,
    rtlName: "عالتسعير",
    component: OrganizationForm,
    layout: "/admin",
    name: "Organizations",
    showVisible: false,
  },
  ...campusRoutes,
  ...gateRoutes,
  ...vehicleRoutes,
  ...shiftRoutes,
  ...TransitItineraryRoutes,
  ...vehicleRouteRoutes,
  ...assetDataChangeRoutes,
  {
    path: "/admin/building/manage",
    rtlName: "عالتسعير",
    component: Building,
    layout: "/admin",
    name: "Buildings",
    showVisible: false,
  },
  {
    path: "/admin/building/add",
    name: "Add Building",
    rtlName: "عالتسعير",
    component: BuildingForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/building/edit/:buildingId",
    name: "Edit Building",
    rtlName: "عالتسعير",
    component: BuildingForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/schedule/manage",
    name: "Schedules",
    rtlName: "عالتسعير",
    component: Schedules,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/schedule/add",
    name: "Schedules",
    rtlName: "عالتسعير",
    component: ScheduleForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/schedule/edit/:scheduleId",
    name: "Schedules",
    rtlName: "عالتسعير",
    component: ScheduleForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/floor/manage",
    rtlName: "عالتسعير",
    component: FloorPlan,
    layout: "/admin",
    name: "Floors",
    showVisible: false,
  },
  {
    path: "/admin/floor/add",
    name: "Add Floor Plan",
    rtlName: "التقويم",
    component: FloorPlanForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/floor/edit/:floorPlanId",
    name: "Edit Floor Plan",
    rtlName: "التقويم",
    component: FloorPlanForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/floorPlanSection/manage",
    rtlName: "عالتسعير",
    component: FloorPlanSection,
    layout: "/admin",
    name: "Floor Areas",
    showVisible: false,
  },
  {
    path: "/admin/floorPlanSection/add",
    rtlName: "التقويم",
    component: FloorPlanSectionForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/floorPlanSection/edit/:floorPlanSectionId",
    rtlName: "التقويم",
    component: FloorPlanSectionForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/gateway-beacon/diagnosis",
    name: "Gateways and Beacons Diagnosis",
    rtlName: "التقويم",
    component: ManageGatewayBeacon,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/policy/diagnosis",
    name: "Policy Diagnosis",
    rtlName: "التقويم",
    component: PolicyDiagnosis,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/diagnosis/gateway-state",
    name: "Gateway State Diagnosis",
    rtlName: "التقويم",
    component: GatewayStateLogs,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/diagnosis/area-presence",
    name: "Area Presence Report",
    rtlName: "عالتسعير",
    mini: "AR",
    rtlMini: "ع",
    component: AreaPresenceReport,
    layout: "/admin",
    module: "AREA_PRESENCE_REPORT",
    showVisible: false,
  },
  {
    path: "/admin/diagnosis/infra-presence",
    name: "Infra Presence Report",
    rtlName: "عالتسعير",
    mini: "IPR",
    rtlMini: "ع",
    component: InfraPresenceReport,
    layout: "/admin",
    module: "INFRA_PRESENCE_REPORT",
    showVisible: false,
  },
  {
    path: "/admin/diagnosis/building-presence",
    name: "Building Presence Report",
    rtlName: "عالتسعير",
    mini: "BPR",
    rtlMini: "ع",
    component: BuildingPresenceReport,
    layout: "/admin",
    module: "BUILDING_PRESENCE_REPORT",
    showVisible: false,
  },
  {
    path: "/admin/diagnosis/pings-gateway",
    name: "Policy Diagnosis",
    rtlName: "التقويم",
    component: GatewayPings,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/gateway/manage",
    name: "Gateways",
    rtlName: "التقويم",
    component: Gateway,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/gateway/add",
    name: "Add Gateway",
    rtlName: "التقويم",
    component: GatewayForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/gateway/edit/:gatewayId",
    name: "Edit Gateway",
    rtlName: "التقويم",
    component: GatewayForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/beacon/configure",
    name: "Configure Beacons",
    rtlName: "التقويم",
    component: ConfigureBeacon,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/beacon/manage",
    name: "Beacons",
    rtlName: "التقويم",
    component: Beacons,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/beacon/add",
    name: "Add Beacon",
    rtlName: "التقويم",
    component: AddBeacon,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/beacon/edit/:beaconId",
    name: "Edit Beacon",
    rtlName: "التقويم",
    component: EditBeacon,
    layout: "/admin",
    showVisible: false,
  },
  // {
  //   path: "/admin/beacon/detect/:gatewayMac",
  //   name: "Detect Beacon",
  //   rtlName: "التقويم",
  //   component: DetectBeacon,
  //   layout: "/admin",
  //   showVisible: false,
  // },
  {
    path: "/auth/login",
    rtlName: "التقويم",
    component: SignIn,
    layout: "/auth",
    showVisible: false,
  },
  {
    path: "/auth/register",
    rtlName: "التقويم",
    component: SignUp,
    layout: "/auth",
    showVisible: false,
  },
  {
    path: "/auth/verify-otp/:userId",
    rtlName: "التقويم",
    component: OtpVerify,
    layout: "/auth",
    showVisible: false,
  },
  {
    path: "/auth/forgotPassword",
    rtlName: "التقويم",
    component: ForgotPassword,
    layout: "/auth",
    showVisible: false,
  },
  {
    path: "/admin/change_password",
    rtlName: "التقويم",
    component: ChangePassword,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/set_lock_screen_pass",
    rtlName: "التقويم",
    component: SetLock,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/profile/:id",
    rtlName: "التقويم",
    name: "Profile",
    component: Profile,
    icon: "tim-icons icon-compass-05",
    module: "PROFILE",
    state: "pagesCollapseReport",
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/restrictions",
    rtlName: "التقويم",
    component: AddRestriction,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/all_tags",
    name: "Users",
    rtlName: "التقويم",
    component: ManageTags,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/all_matters",
    name: "Matter",
    rtlName: "التقويم",
    component: ManageMatters,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/all_matters/edit/:matterID",
    name: "Matter",
    rtlName: "التقويم",
    component: ManageMatters,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/all_users",
    name: "Users",
    rtlName: "التقويم",
    component: ManageUser,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/addUser",
    name: "Add User",
    rtlName: "التقويم",
    component: UserAdd,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/editUser/:userId",
    name: "Edit User",
    rtlName: "التقويم",
    component: UserForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/all_subjects",
    name: "Assets",
    rtlName: "التقويم",
    component: Subject,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/addsubject",
    name: "Add Assets",
    rtlName: "التقويم",
    component: SubjectForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/editsubject/:id",
    name: "Edit Assets",
    rtlName: "التقويم",
    component: SubjectForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/all_restrictions",
    rtlName: "التقويم",
    component: AddRestrictionMain,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/add-restriction",
    rtlName: "التقويم",
    component: AddRestriction,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/manage_user_role",
    rtlName: "التقويم",
    component: ManageUserRole,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/action/manage",
    name: "Actions",
    rtlName: "التقويم",
    component: ManageActions,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/action/add",
    name: "Add Action",
    rtlName: "التقويم",
    component: ActionForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/action/edit/:actionId",
    name: "Edit Action",
    rtlName: "التقويم",
    component: ActionForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/policy/manage",
    name: "Policies",
    rtlName: "التقويم",
    component: ManagePolicies,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/policy/add",
    name: "Add Policy",
    rtlName: "التقويم",
    component: PolicyForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/policy/edit/:policyId",
    name: "Edit Policy",
    rtlName: "التقويم",
    component: PolicyForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/manage-holidays",
    name: "Holidays",
    rtlName: "التقويم",
    component: ManageHolidays,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/edit_holiday/:id",
    name: "Edit Holiday",
    rtlName: "التقويم",
    component: EditHoliday,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/violations",
    name: "Violations",
    rtlName: "التقويم",
    component: Violations,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/alerts",
    name: "Alerts",
    rtlName: "التقويم",
    component: Alerts,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/manage_master_data",
    name: "Master Data",
    rtlName: "التقويم",
    component: ManageMasterData,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/add_master_data",
    name: "Add Master Data",
    rtlName: "التقويم",
    component: AddMasterData,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/edit_master_data/:id",
    name: "Edit Master Data",
    rtlName: "التقويم",
    component: AddMasterData,
    layout: "/admin",
    showVisible: false,
  },
];

export default routes;
