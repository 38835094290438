import React, { useEffect, useState } from "react";
import { Button, Card, Col, Fade, Row } from "reactstrap";
import OccupancyView from "views/custom_components/OccupancyView";
import EarthMapView from "./sub-components/EarthMapView/EarthMapView";
import MapView from "./sub-components/MapView/MapView";
import menuStages from "./sub-components/MenuStages/config";
import SideBarSelect from "./sub-components/SideBarSelect/SideBarSelect";
import { useHistory, useParams } from "react-router";

import MarkerGeoPointsFetcher from "./utils/getPlotPoints";

import "./ViewMaps.scss";
import StatCard from "components/StatCard/StatCard";
import GatewayService from "services/GatewayService";
import Units from "config/Units";
import GatewayView from "views/custom_components/GatewayView";
import { useSelector } from "react-redux";
import useQuery from "customHooks/useQuery";

const campusGeoPointPromise = MarkerGeoPointsFetcher.getCampusLocations;
const buildingGeoPointPromise = MarkerGeoPointsFetcher.getBuildingLocations;

const mapCenter = [20.5937, 78.9629];

const cleanObj = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

function ViewMaps() {
  const { menu } = useParams();

  const [{ cid, fid, bid }] = useQuery();

  const organizationId = useSelector((state) => state.organization._id);

  const [activeCampus, setActiveCampus] = useState();
  const [activeBuilding, setActiveBuilding] = useState();
  const [activeFloorPlan, setActiveFloorPlan] = useState();

  const [activeMenu, setActiveMenu] = useState(menu || menuStages.campuses);
  const [activeFloorSectionId, setActiveFloorSectionId] = useState(null);

  const [activeGateways, setActiveGateways] = useState([]);
  const [offlineGateways, setOfflineGateways] = useState([]);
  const [occupancy, setOccupancy] = useState(0);

  const [viewContext, setViewContext] = useState({});

  const [isOpenGatewayModal, setIsOpenGatewayModal] = useState(false);

  const [isOpenOccupancyView, setIsOpenOccupancyView] = useState(false);

  const [gatewayStatusContext, setGatewayStatusConext] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setActiveCampus(cid);
    setActiveBuilding(bid);
    setActiveFloorPlan(fid);

    setViewContext(cleanObj({ campusId: cid, floorId: fid, buildingId: bid }));
  }, [cid, fid, bid]);

  useEffect(() => {
    history.replace({
      pathname: `/admin/smart-location/${activeMenu}`,
      search: `?cid=${activeCampus || ""}&bid=${activeBuilding || ""}&fid=${
        activeFloorPlan || ""
      }`,
    });
  }, [activeMenu, activeFloorPlan]);

  const history = useHistory();
  const onEditMap = () => {
    history.push(`/admin/edit-maps/${activeFloorPlan}`);
  };

  const closeGatewayModal = () => setIsOpenGatewayModal(false);
  const onCloseModal = () => setIsOpenOccupancyView(false);

  const openOfflineGateway = () => {
    setGatewayStatusConext(false);
    setIsOpenGatewayModal(true);
  };
  const openOnlineGateway = () => {
    setGatewayStatusConext(true);
    setIsOpenGatewayModal(true);
  };

  const onClickShape = (floorSectionId) => {
    setTimeout(() => setIsOpenOccupancyView(true), 1000);
    console.log("rohit", floorSectionId);
    setActiveFloorSectionId(floorSectionId);
  };

  const onClickMarker = ({ id, campusId }) => {
    const isBuilding = !!campusId;

    const adjacentMenuItem = document.getElementById(`menu-${id}`);

    if (adjacentMenuItem) adjacentMenuItem.click();
  };

  const [campusGeoPoints, setCampusGeoPoints] = useState();
  const [buildingGeoPoints, setBuildingGeoPoints] = useState();

  const fetchOccupancy = () => {
    setLoading(true);

    let params = {};

    if (activeCampus && activeMenu === menuStages.buildings)
      params.campusId = activeCampus;
    if (activeBuilding && activeMenu === menuStages.floorplans)
      params.buildingId = activeBuilding;
    if (activeFloorPlan && !menuStages.buildings === activeMenu)
      params.floorId = activeFloorPlan;

    if (activeMenu === menuStages.campuses) params = {};

    params = { ...params, organizationId };

    GatewayService.getGatewayOccupancy(params)
      .then(({ data: { data } }) => setOccupancy(data.occupancy))
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const updateData = () => {
    if (isOpenGatewayModal || isOpenOccupancyView) return;
    fetchOccupancy();
    fetchGatewayStatus();
  };

  useEffect(() => {
    let gatewayStatusUpdater;
    if ((isOpenGatewayModal || isOpenOccupancyView) && gatewayStatusUpdater)
      return clearTimeout(gatewayStatusUpdater);

    updateData();
    gatewayStatusUpdater = setInterval(updateData, 15 * Units.Time.second);

    return () => {
      clearTimeout(gatewayStatusUpdater);
    };
  }, [activeMenu, activeFloorPlan, isOpenGatewayModal, isOpenOccupancyView]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      campusGeoPointPromise(onClickMarker),
      buildingGeoPointPromise(onClickMarker),
    ])
      .then(([campusGeoPoints, buildingGeoPoints]) => {
        setCampusGeoPoints(campusGeoPoints);
        setBuildingGeoPoints(buildingGeoPoints);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const setData = (data) => {
    setActiveGateways(data.online);
    setOfflineGateways(data.offline);
  };

  // const setData = (data) => {
  //   setActiveGateways(data.filter(({ isOnline }) => isOnline));
  //   setOfflineGateways(data.filter(({ isOnline }) => !isOnline));
  // };
  const fetchGatewayStatus = () => {
    let params = {};

    if (activeCampus && activeMenu === menuStages.buildings)
      params.campusId = activeCampus;
    if (activeBuilding && activeMenu === menuStages.floorplans)
      params.buildingId = activeBuilding;
    if (activeFloorPlan && !menuStages.buildings === activeMenu)
      params.floorId = activeFloorPlan;

    if (activeMenu === menuStages.campuses) params = {};

    const organizationId = localStorage.getItem("Organization_ID");

    params = { ...params, organizationId, count: true };
    // params = { ...params, organizationId };

    setLoading(true);
    GatewayService.getGatewayStatus(params)
      .then(({ data: { data } }) => setData(data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const openOccupancyView = () => setIsOpenOccupancyView(true);

  return (
    <div className="content ViewMaps">
      <Row>
        <Col md="4">
          <StatCard
            icon="groups"
            statTitle="Occupancy"
            color="info"
            onClick={openOccupancyView}
            statValue={occupancy}
          />
        </Col>
        <Col md="4">
          <StatCard
            icon="router"
            statTitle="Online Gateway"
            color="success"
            statValue={activeGateways}
            onClick={openOnlineGateway}
            appearDelay={350}
          />
        </Col>
        <Col md="4">
          <StatCard
            icon="router"
            statTitle="Offline Gateway"
            color="danger"
            statValue={offlineGateways}
            onClick={openOfflineGateway}
            appearDelay={500}
          />
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <SideBarSelect
            activeFloorPlan={activeFloorPlan}
            selectFloorplan={setActiveFloorPlan}
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            onCampusSelect={setActiveCampus}
            onBuildingSelect={setActiveBuilding}
          />
          {!!activeFloorPlan && (
            <Button
              color="primary"
              onClick={(activeFloorSectionId) =>
                onEditMap(activeFloorSectionId)
              }
            >
              <i className="tim-icons icon-pencil"></i> Configure floor
            </Button>
          )}
        </Col>
        <Col md="9" style={{ height: "75vh" }}>
          <Card style={{ overflow: "hidden", height: "100%" }}>
            <Fade
              in={!activeFloorPlan}
              unmountOnExit
              className="ViewMaps__Fade"
              timeout={{ enter: 200, exit: 100 }}
            >
              <EarthMapView
                points={[]}
                activeCampus={
                  activeMenu !== menuStages.campuses ? activeCampus : null
                }
                geoPoints={
                  activeMenu === menuStages.campuses
                    ? campusGeoPoints
                    : buildingGeoPoints.filter(
                        (buildingGeoPoint) =>
                          buildingGeoPoint.campusId === activeCampus
                      )
                }
                mapCenter={mapCenter}
              />
            </Fade>

            <Fade
              in={!!activeFloorPlan}
              unmountOnExit
              className="ViewMaps__Fade"
              timeout={{ enter: 200, exit: 100 }}
            >
              <MapView
                activeFloorPlan={activeFloorPlan}
                onClickShape={onClickShape}
              />
            </Fade>
          </Card>
        </Col>
      </Row>
      {isOpenOccupancyView && (
        <OccupancyView
          context={cleanObj({
            ...viewContext,
            floorPlanSectionId: activeFloorSectionId,
          })}
          isOpen={!!isOpenOccupancyView}
          close={onCloseModal}
        />
      )}

      {isOpenGatewayModal && (
        <GatewayView
          context={viewContext}
          isOpen={isOpenGatewayModal}
          close={closeGatewayModal}
          title={`${gatewayStatusContext ? "Online" : "Offline"} Gateway List`}
          status={gatewayStatusContext}
          dataLength={gatewayStatusContext ? activeGateways : offlineGateways}
        />
      )}
    </div>
  );
}

export default ViewMaps;
