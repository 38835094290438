import { GATEWAY_URL } from "config/api";
import { FLOORSECTION_URL } from "config/api";
import joinUrlPath from "utils/joinUrlPath";
import ApiService from "./ApiService";
import BaseService from "./BaseService";

class FloorPlanSectionService extends ApiService {
  constructor() {
    super(FLOORSECTION_URL);
    this.baseService = new BaseService();
  }

  createFloorPlanSection(url, data, headers) {
    return this.baseService
      .doPOST(url, "POST", data, headers)
      .then((response) => {
        if (!!response.data?.success) {
          return {
            msg: response.data.message,
            data: response.data.data,
          };
        }
      });
  }

  attachGateway(gatewayId, floorSectionId, params, headers) {
    return this.doPost(
      joinUrlPath(this._url, "gateway/map"),
      {
        gateway: gatewayId,
        floorPlanSection: floorSectionId,
      },
      params,
      headers,
    );
  }
  detachGateway(floorSectionId, params, headers) {
    return this.doPost(
      joinUrlPath(this._url, "gateway/unmap", floorSectionId),
      undefined,
      params,
      headers,
    );
  }
}

export default new FloorPlanSectionService();
