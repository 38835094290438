import axios from "axios";
import ListCard from "components/ListCard/ListCard";
import { ATTENDANCE_URL } from "config/api";
import { ATTENDANCE_NEW_URL } from "config/api";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import DateTimeService from "services/DateTimeService";

function ProfilePeriodicAddentance({ assetId, date }) {
  const organizationId = useSelector((state) => state.organization._id);
  const fromTime = DateTimeService.getMorningTime(date)?.toISOString();
  const toTime = DateTimeService.getMidnightTime(date)?.toISOString();
  const [periodicData, setPeriodicData] = useState([]);
  const [loading, setLoading] = useState(false);

  const datePart = new Date().toISOString().split("T")[0] + "T";

  const fetchPeriodicAnalytics = async () => {
    const params = {
      organizationId,
      assetId,
      fromTime,
      toTime,
      attendanceType: "PERIODIC_DETAIL",
    };

    try {
      setLoading(true);
      const {
        data: { data },
      } = await axios.get(ATTENDANCE_URL, { params });

      if (data) {
        console.log("periodic attendance", data);
        setPeriodicData(data);
      }
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPeriodicAnalytics();
  }, [date]);

  return (
    <ListCard
      cardTitle="Periodic Attendance"
      data={periodicData?.map(({ scheduledEvent, present }) => ({
        title: scheduledEvent?.name || "Unavailable",
        subtitle: !scheduledEvent.fromTime
          ? "No Time"
          : DateTimeService.getTimeString(
              datePart + scheduledEvent?.fromTime
            ) ||
            "-" +
              " to " +
              DateTimeService.getTimeString(
                datePart + scheduledEvent?.toTime
              ) ||
            "-",
        icon: present ? "check_circle" : "cancel",
        iconColor: present ? "success" : "danger",
      }))}
      noDataMessage={
        !!periodicData.length ? "Present for the Day" : "Absent for the Day"
      }
      maxHeight="44vh"
    />
  );
}

export default ProfilePeriodicAddentance;
