import React from "react";
import { CSVLink } from "react-csv";

function CsvDownloader({ dataSource, text, disabled }) {
  return (
    <CSVLink className="text-white" data={dataSource}>
      <button
        className="btn btn-info"
        disabled={!!disabled?.length ? false : true}
      >
        {text}
      </button>
    </CSVLink>
  );
}

export default CsvDownloader;
