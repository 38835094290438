import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";
import { Auth } from "aws-amplify";
import { useHistory, useLocation, useParams } from "react-router-dom";
import UserService from "services/UserService";
import SWALService from "services/SWALService";
import toast from "react-hot-toast";

function OtpVerify() {
  const location = useLocation();
  const [user, setUser] = useState({});
  const [otp, setOtp] = useState();
  const [loading, setLoading] = useState(false);

  const { userId } = useParams();

  const { reciever } = location?.state || {};

  const history = useHistory();

  const fetchUser = async () => {
    if (!userId) return;
    setLoading(true);
    const { data } = await UserService.getById(userId);
    setUser(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchUser();
  }, [userId]);

  const confirmSignUp = async (e) => {
    e.preventDefault();

    if (!user) return SWALService.showError("No user found!");
    const username = user?.username;

    setLoading(true);

    const isSuccess = await Auth.confirmSignUp(username, otp).catch((err) =>
      SWALService.showError(err.message)
    );
    setLoading(false);

    if (!isSuccess) return toast.error("Something Went Wrong!");

    SWALService.showSuccess("OTP verified!");

    history.push("/auth/login");
  };

  // const authenticate = () => {
  //   setLoading(true);
  //   Auth.currentAuthenticatedUser()
  //     .then(() => {
  //       setLoading(false);
  //     })
  //     .catch(() => history.push("/auth"));
  // };

  const resendOtp = async () => {
    if (!user) return;
    setLoading(true);

    const username = user?.username;
    await Auth.resendSignUp(username);

    setLoading(false);
    fetchUser();
  };

  return (
    <div className="content">
      <Container>
        <Col className="ml-auto mr-auto" lg="4" md="6">
          <Form className="form">
            <Card className="card-login card-white">
              <CardHeader>
                <img alt="..." src={require("assets/img/card-primary.png")} />
                <CardTitle tag="h1">OTP verification</CardTitle>
              </CardHeader>
              <CardBody>
                {!!reciever && (
                  <h4
                    style={{ color: "black" }}
                  >{`OTP sent to ${reciever}`}</h4>
                )}
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="tim-icons icon-lock-circle" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter OTP"
                    type="text"
                    name="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </InputGroup>
              </CardBody>
              <CardFooter>
                <Button
                  block
                  className="mb-3"
                  color="primary"
                  href="#"
                  onClick={confirmSignUp}
                  size="lg"
                >
                  Verify
                </Button>
                <Button
                  block
                  className="mb-3"
                  color="secondary"
                  href="#"
                  onClick={resendOtp}
                  size="lg"
                >
                  Resend
                </Button>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Container>
    </div>
  );
}

export default OtpVerify;
