import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import BeaconService from "services/BeaconService";
import SWALService from "services/SWALService";

function GatewayBeaconTable({ data, loading, getData }) {
  const history = useHistory();

  const gatewayBeaconTableConfig = [
    { Header: "Beacon macID", accessor: "beacon" },
    {
      Header: "Gateways",
      accessor: "gateways",
    },
  ];

  return (
    <BaseTable
      data={data}
      tableConfig={gatewayBeaconTableConfig}
      loading={loading}
    />
  );
}

export default GatewayBeaconTable;
