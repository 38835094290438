import React, { useEffect, useState } from "react";
import BaseSelect from "./BaseSelect";

function CalenderEventSelect({ value, setValue, isMultiSelect }) {
  const Types = [
    { label: "HOLIDAY", value: "HOLIDAY" },
    { label: "SCHEDULE_EVENT", value: "SCHEDULE_EVENT" },
  ];

  return (
    <BaseSelect
      label={"Type"}
      dataSource={Types}
      placeholder={"Select type of event"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default CalenderEventSelect;
