import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "reactstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import GatewayService from "../../../../../services/GatewayService";
import Swal from "sweetalert2";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import FormHolder from "components/Holders/FormHolder/FormHolder";
import Forms from "../../../../../config/copies/forms";
import GatewayAttachmentSelect from "components/UserInputComponents/Selects/GatewayAttachmentSelect";
import BaseSwitchInput from "components/UserInputComponents/Inputs/Switch/BaseSwitchInput";
import BrandLoader from "views/custom_components/Loader";
import CSVupload from "views/components/CSVupload";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import { GATE_BULK_UPLOAD_URL } from "config/api";
import CSVuploadManual from "views/components/CSVuploadManual";
import SWALService from "services/SWALService";
import GatewayScopeSelect from "components/UserInputComponents/Selects/GatewayScopeSelect";

const gatewayTypeArr = [
  {
    value: "BLE_SNIFFER",
    label: "BLE_SNIFFER",
  },
  {
    value: "WIFI_SNIFFER",
    label: "WIFI_SNIFFER",
  },
  {
    value: "BLE_WIFI_SNIFFER",
    label: "BLE_WIFI_SNIFFER",
  },
];

const tableConfig = [
  {
    accessor: "name",
    Header: "Name",
  },
  {
    accessor: "macId",
    Header: "Mac Id",
  },
  {
    accessor: "model",
    Header: "Model",
  },
  {
    accessor: "type",
    Header: "Type",
  },
  {
    accessor: "cutoffRSSI",
    Header: "Cutoff RSSI",
  },
  {
    accessor: "attachmentType",
    Header: "Attachment Type",
  },
  {
    accessor: "assignedIP",
    Header: "Assigned IP",
  },
  {
    accessor: "enabled",
    Header: "Enabled",
  },
];

function GatewayForm() {
  const organization = useSelector((state) => state.organization);

  const [name, setName] = useState("");
  const [macId, setMacId] = useState("");
  const [model, setModel] = useState("");
  const [gatewayType, setGatewayType] = useState("");
  const [attachmentType, setAttachmentType] = useState("");
  const [cutOffRSSI, setCutOffRSSI] = useState("");
  const [assignedIP, setAssignedIP] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [gatewayData, setGatewayData] = useState([]);
  const [manualModal, setManualModal] = useState(false);

  const [scopes, setScopes] = useState([]);

  const { gatewayId } = useParams();

  const buttonText = gatewayId ? "Save changes" : "Submit";

  const { _id: organizationId } = useSelector((state) => state.organization);
  const history = useHistory();

  useEffect(() => {
    if (!gatewayId) return;

    GatewayService.getById(gatewayId)
      .then(({ data }) => {
        setName(data.name);
        setMacId(data.macId);
        setCutOffRSSI(data.cutOffRSSI);
        setModel(data.model);
        setGatewayType({ label: data.type, value: data.type });
        setAttachmentType(data.attachmentType);
        setEnabled(data.enabled);
        setScopes(data.scopes);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let gatewayObj = {};
      if (name !== "") {
        gatewayObj = {
          name: name,
          macId: macId,
          model: model,
          type: gatewayType?.value,
          cutOffRSSI: cutOffRSSI,
          organization: organizationId,
          attachmentType: attachmentType,
          assignedIP,
          enabled,
          scopes,
        };
      }

      if (!gatewayId)
        return GatewayService.post(gatewayObj)
          .then(({ message }) => {
            Swal.fire({
              icon: "success",
              title: "Gateway Create",
              text: message,
            });
            history.goBack();
          })
          .catch((error) => console.log(error.message));

      GatewayService.put(gatewayId, gatewayObj)
        .then(({ message }) =>
          Swal.fire({
            icon: "success",
            title: "Gateway Update",
            text: message,
          }).then(() => {
            history.push("/admin/gateway/manage");
          })
        )
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const clearEmptyRow = () => {
    const filterData = gatewayData.filter((object) => {
      return !!Object.keys(object).length;
    });
    setGatewayData(filterData);
  };

  const handleBlukSubmit = (e) => {
    e.preventDefault();
    clearEmptyRow();
    gatewayData.forEach((obj) => {
      obj.organization = organizationId;
      obj.enabled = obj.enabled === "TRUE";
    });
    const payload = gatewayData;
    GatewayService.doPost(GATE_BULK_UPLOAD_URL, payload, true)
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Gateway Added",
          text: response.msg,
        }).then(() => {
          history.push("/admin/gateway/manage");
        });
      })
      .catch((error) => {
        SWALService.showError(error.message);
      });
  };

  return (
    <div className="content">
      <Row>
        <CSVupload
          title="Upload Multiple Gateway"
          modalOpen={modalOpen}
          data={gatewayData}
          tableConfig={tableConfig}
          setData={setGatewayData}
          handleSubmit={handleBlukSubmit}
          setModalOpen={setModalOpen}
          setShowManual={setManualModal}
        />
        <CSVuploadManual
          setModalOpen={setManualModal}
          modalOpen={manualModal}
          gId={"1638335190"}
        />
        <FormHolder
          imgSrc={Forms.GATEWAY.illustration}
          formHeader={Forms.GATEWAY.title}
          formDescription={Forms.GATEWAY.desc}
          CardFooterComponent=""
          formActions={
            <IconButton
              icon={"upload_file"}
              onClick={() => setModalOpen(true)}
              color={"info"}
              outlined
            />
          }
        >
          {loading && <BrandLoader />}
          {!loading && (
            <Form onSubmit={handleSubmit}>
              <BaseTextInput
                label="Name"
                placeholder="Enter name"
                type="text"
                value={name}
                autoComplete="off"
                setValue={setName}
              />
              <BaseTextInput
                label="MAC Id"
                placeholder="Enter MAC Id"
                type="text"
                value={macId}
                autoComplete="off"
                setValue={setMacId}
              />
              <BaseTextInput
                label="Model"
                placeholder="Enter model"
                type="text"
                value={model}
                autoComplete="off"
                setValue={setModel}
              />

              <BaseTextInput
                label="Assigned IP"
                placeholder="enter the static IP assigned"
                type="text"
                value={assignedIP}
                autoComplete="off"
                setValue={setAssignedIP}
              />

              <GatewayAttachmentSelect
                value={attachmentType}
                setValue={setAttachmentType}
              />
              <Row className="separator">
                <Col sm="12">
                  <label>Type</label>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    name="floorName"
                    value={gatewayType}
                    onChange={(value) => setGatewayType(value)}
                    options={gatewayTypeArr}
                    placeholder="Type"
                  />
                </Col>
                <br />
              </Row>
              <BaseTextInput
                label="Cut-Off RSSI"
                placeholder="Enter Cut-Off RSSI"
                type="text"
                value={cutOffRSSI}
                autoComplete="off"
                setValue={setCutOffRSSI}
              />

              <GatewayScopeSelect value={scopes} setValue={setScopes} />

              <BaseSwitchInput
                value={enabled}
                setValue={setEnabled}
                label="Is this gateway active?"
              />

              <BaseButton color="info">{buttonText}</BaseButton>
            </Form>
          )}
        </FormHolder>
      </Row>
    </div>
  );
}

export default GatewayForm;
