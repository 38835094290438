import StatCard from "components/StatCard/StatCard";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import VisitorCountGraph from "./sub-components/VisitorCountGraph/VisitorCountGraph";
import VisitorPerTime from "./sub-components/VisitorPerTime/VisitorPerTime";
import MostVisited from "./sub-components/MostVisited/MostVisited";
import FrequentVisitors from "./sub-components/FrequentVisitors/FrequentVisitors";
import VisitorPurpose from "./sub-components/VisitorPurpose/VisitorPurpose";
import BaseSelect from "components/UserInputComponents/Selects/BaseSelect";
import "./VisitorDashBoard.scss";
import { Card, CardBody } from "reactstrap";
import VisitorReportService from "services/VisitorReportService";
import { useSelector } from "react-redux";
import DateTimeService from "services/DateTimeService";
import SWALService from "services/SWALService";
import BrandLoader from "views/custom_components/Loader";
import { useHistory } from "react-router-dom";
import { Divider } from "rsuite";
import ComingSoon from "components/ComingSoon/ComingSoon";
function VisitorDashBoard() {
  const history = useHistory();
  const organizationId = useSelector((s) => s.organization?._id);

  const dayOptions = [
    { value: "1", label: "Today" },
    { value: "6", label: "Last 7d" },
    { value: "29", label: "Last 30d" },
  ];

  const [loading, setLoading] = useState(false);

  const [day, setDay] = useState(dayOptions[0]?.value);

  const dayContext = dayOptions.find((el) => el.value === day)?.label;

  const [data, setData] = useState();

  const getReports = async () => {
    setLoading(true);

    const toTime = DateTimeService.getMidnightTime(new Date()).toISOString();

    const fromTime = DateTimeService.getMorningTime(
      DateTimeService.getDateTimeNDaysAgo(toTime, day)
    ).toISOString();

    const params = {
      organizationId,
      context: Object.values(VisitorReportService.contexts).toString(),
      fromTime: fromTime,
      toTime: toTime,
    };

    const { data } = await VisitorReportService.get(params).catch((err) =>
      SWALService.showError(err)
    );

    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    getReports();
  }, [day]);

  const toTime = DateTimeService.getMidnightTime(new Date()).toISOString();

  const fromTime = DateTimeService.getMorningTime(
    DateTimeService.getDateTimeNDaysAgo(toTime, day)
  ).toISOString();

  const handleVisitorRedirect = (context) => {
    if (context === "property")
      history.push({
        pathname: `/admin/visitors/log-book`,
        search: `?active=true&fromTime=${fromTime}&toTime=${toTime}`,
      });
    history.push({
      pathname: `/admin/visitors/log-book`,
      // search: `?active=true&fromTime=${fromTime}&toTime=${toTime}`,
    });
  };

  return (
    <div className="content">
      <BrandLoader loading={loading} />
      <Row>
        <Col md="3">
          <StatCard
            statTitle="Visitors in property"
            statValue={data?.VISITOR_IN_PREMISE}
            color="info"
            icon="people"
            onClick={() => handleVisitorRedirect("property")}
          />
        </Col>
        <Col md="3">
          <StatCard
            statTitle="Visitors today"
            statValue={data?.VISITOR_TOTAL_COUNT}
            color="success"
            icon="people"
            appearDelay={450}
            onClick={() => handleVisitorRedirect("today")}
          />
        </Col>
        <Col md="3">
          <StatCard
            statTitle="Violations by visitors"
            statValue={data?.VISITOR_VIOLATIONS}
            color="danger"
            icon="people"
            appearDelay={600}
          />
        </Col>
        <Col md="3">
          <ComingSoon>
            <StatCard
              statTitle="Expected Visitors Today"
              statValue={6}
              color="info"
              icon="people"
              appearDelay={750}
            />
          </ComingSoon>
        </Col>
      </Row>

      {/* <hr className="VisitorDashBoard__DatepickerSperator"></hr> */}
      <hr className="horizontal dark my-5" />
      <Divider orientation="horizontal" intensity="dark"></Divider>
      <Row>
        <Col md="3" className="mx-auto mr-0">
          <Card>
            <CardBody>
              <BaseSelect
                value={day}
                setValue={setDay}
                dataSource={dayOptions}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="6" className="d-flex align-items-stretch">
          <VisitorCountGraph
            data={data?.VISITOR_DISTRIBUTION}
            context={dayContext}
          />
        </Col>
        <Col md="6" className="d-flex align-items-stretch">
          <VisitorPerTime
            data={data?.VISITOR_COUNTS_BY_TIMEFRAME}
            context={dayContext}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6" className="d-flex align-items-stretch">
          <VisitorPurpose
            dateContext={day}
            data={data?.VISITING_PURPOSES_HISTOGRAM}
            context={dayContext}
          />
        </Col>
        <Col md="3" className="d-flex align-items-stretch">
          <MostVisited data={data?.VISITEE_HISTOGRAM} />
        </Col>
        <Col md="3" className="d-flex align-items-stretch">
          <FrequentVisitors data={data?.VISITOR_HISTOGRAM} />
        </Col>
      </Row>
    </div>
  );
}

export default VisitorDashBoard;
