import SubjectView from "components/PopoverViews/SubjectView/SubjectView";
import React from "react";

import DateTimeService from "services/DateTimeService";
import Avatar from "views/custom_components/Avatar/Avatar";

const DailyAttendanceDetailsTableConfig = [
  {
    Header: "#",
    width: 80,
    Cell: ({ original: { asset } }) => (
      <div>
        <Avatar
          height={34}
          src={asset?.attributes?.avatar || ""}
          alt={asset?.name || ""}
          gender={asset?.attributes?.gender}
        />
      </div>
    ),
  },
  {
    Header: "Name",
    accessor: "asset.name",
    Cell: ({ original: { asset } }) => (
      <SubjectView subjectId={asset._id} subjectData={asset}>
        {asset.name}
      </SubjectView>
    ),
  },
  {
    Header: "Unique ID",
    accessor: "asset.uniqueId",
  },
  {
    Header: "Role",
    accessor: "asset.role",
  },
  {
    Header: "Primary Group",
    accessor: ({ asset }) => asset?.primaryGroup?.name,
    Cell: ({ original: { asset } }) => <>{asset?.primaryGroup?.name}</>,
  },
  {
    Header: "Date",
    accessor: ({ targetDate }) =>
      !!targetDate ? DateTimeService.getDateString(targetDate) : "-",
  },
  {
    Header: "Entry",
    accessor: ({ entry }) =>
      !!entry ? DateTimeService.getDateTimeString(entry) : "-",
  },
  {
    Header: "Exit",
    accessor: ({ exit }) =>
      !!exit ? DateTimeService.getDateTimeString(exit) : "-",
  },
  {
    Header: "Present/Absent",
    accessor: ({ present }) => (present ? "PRESENT" : "ABSENT"),
  },
  // {
  //   Header: "On Time/Late",
  //   accessor: ({ onTime, present }) => {
  //     if (!present) return "-";
  //     return onTime ? "ON TIME" : "LATE";
  //   },
  // },
];

export default DailyAttendanceDetailsTableConfig;
