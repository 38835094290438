import { DomainSpecificUserRoles } from "./enums/Roles";

// const allowedTypes = [
//   "TEACHING_STAFF",
//   "NON_TEACHING_STAFF",
//   "STUDENT",
//   "EMPLOYEE",
//   "SECURITY",
//   "DRIVER",
//   "TRANSPORT_HELPER",
//   "ASSET",
//   "VISITOR",
// ];

const allowedTypes = DomainSpecificUserRoles.SCHOOL;

export default allowedTypes;
