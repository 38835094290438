import BaseTable from "components/Tables/BaseTable/BaseTable";
import React from "react";
import GatewayStateLogsTableConfig from "./GatewayStateLogsTableConfig";

function GatewayStateLogsTable({ data, loading }) {
  return (
    <>
      <BaseTable
        data={data}
        tableConfig={GatewayStateLogsTableConfig}
        loading={loading}
      />
    </>
  );
}

export default GatewayStateLogsTable;
