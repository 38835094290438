const bloodGroups = [
  "A Positive",
  "A Negative",
  " A Unknown",
  "B Positive",
  "B Negative",
  "B Unknown",
  "AB Positive",
  "AB Negative",
  "AB Unknown",
  "O Positive",
  "O Negative",
  "O Unknown",
  "Unknown",
];

export default bloodGroups;
