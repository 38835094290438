import { AREA_PRESENCE_URL } from "config/api";
import ApiService from "./ApiService";

class AreaPresenceService extends ApiService {
  constructor() {
    super(AREA_PRESENCE_URL);
  }
}

export default new AreaPresenceService();
