import { ATTENDANCE_URL } from "config/api";
import { BEACON_URL } from "config/api";
import ApiService from "./ApiService";
import BaseService from "./BaseService";

class BeaconService extends ApiService {
  constructor() {
    super(BEACON_URL);
    this.baseService = new BaseService();
  }

  createBeacon(url, data, headers) {
    return this.baseService
      .doPOST(url, "POST", data, headers)
      .then((response) => {
        if (!!response.data?.success) {
          return {
            msg: response.data.message,
            data: response.data.data,
          };
        }
      });
  }

  getBeaconFirstPings(params) {
    return this.baseService.doGET(`${ATTENDANCE_URL}/all`, "GET", params);
  }

  getRangeAttendance(params) {
    return this.baseService.doGET(`${ATTENDANCE_URL}`, "GET", params);
  }
}

export default new BeaconService();
