import React from "react";
import "material-icons/iconfont/material-icons.css";
import "./Icon.scss";

function Icon({ icon, clickable, size, gradient }) {
  return (
    <i
      className={`Icon material-icons-round ${clickable ? "clickable " : ""}${
        size || "md"
      }  ${!!gradient && "text-gradient-" + (gradient || "info")}`}
    >
      {icon}
    </i>
  );
}

export default Icon;
