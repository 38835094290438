import React, { useEffect, useState } from "react";
import BaseSelect from "./BaseSelect";
import { useSelector } from "react-redux";
import SubjectService from "services/SubjectService";

const subjectService = new SubjectService();
function SubjectSelect({ value, setValue, isMultiSelect, label }) {
  const [subjects, setSubjectID] = useState([]);

  const organizationId = useSelector((state) => state.organization._id);

  useEffect(() => {
    const params = { organizationId };
    subjectService
      .get(params)
      .then(({ data }) => {
        setSubjectID(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <BaseSelect
      label={label === "" ? "" : "Assets"}
      dataSource={subjects?.map((subjectId) => ({
        value: subjectId?._id,
        label: subjectId?.name,
      }))}
      placeholder={"Select assets"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default SubjectSelect;
