import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableAction from "components/Tables/subComponents/TableAction/TableAction";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardFooter } from "reactstrap";
import AssetChangeService from "services/AssetChangeService";
import CampusService from "services/CampusService";
import SWALService from "services/SWALService";
import CloseButton from "views/custom_components/AlertModal2/sub-components/CloseButton/CloseButton";
import OverlayView from "views/custom_components/OverlayView";
import ProfileInfo from "views/pages/Profile/ProfileInfo/ProfileInfo";

function Table({ getData, data, loading, tableConfig }) {
  const [openTicket, setOpenTicket] = useState(false);

  const viewTicket = (id) => setOpenTicket(id);
  const closeTicketDetail = () => setOpenTicket(false);

  const Modal = () => (
    <OverlayView fadeIn={openTicket}>
      <div
        style={{
          position: "fixed",
          maxWidth: "400px",
          // right: "1rem",
          // top: "1rem",
          zIndex: "99999999",
        }}
      >
        <CloseButton close={closeTicketDetail} />
        <Card>
          <CardBody>
            <ProfileInfo subjectId={openTicket?.asset} naked />
            <br />
            <div style={{ padding: "0 30px" }}>
              <h2>Changes Requested</h2>

              {openTicket.type === "ASSET_CONTACT_UPDATE" && (
                <>
                  <div>PHONE: {openTicket.changeRequest?.phoneNumber}</div>
                  <div>EMAIL: {openTicket.changeRequest?.email}</div>
                </>
              )}
              {openTicket.type === "ASSET_INFO_UPDATE" && (
                <>
                  <div>
                    primaryGroup:{" "}
                    {openTicket.changeRequest?.primaryGroup?.name ||
                      openTicket.changeRequest?.primaryGroup}
                  </div>
                  <div>ROLL: {openTicket.changeRequest?.roll}</div>
                </>
              )}
              <div>COMMENTS: {openTicket?.comments}</div>
              <br />
            </div>
          </CardBody>
          {openTicket.status === "PENDING" && (
            <CardFooter className="d-flex justify-content-center">
              <Button
                color="success"
                onClick={() => approveRequest(openTicket._id)}
              >
                Accept
              </Button>
              <div className="m-4"></div>
              <Button
                color="danger"
                onClick={() => rejectRequest(openTicket._id)}
              >
                Reject
              </Button>
            </CardFooter>
          )}
        </Card>
      </div>
    </OverlayView>
  );

  const markOption = async (id, status) => {
    try {
      const { success, message } = await AssetChangeService.put(id, {
        status,
      });

      if (!success) return toast.error(message);
      toast.success(message);
      closeTicketDetail();
      getData();
    } catch (err) {
      return toast.error(err);
    }
  };

  const approveRequest = (id) => markOption(id, "APPROVED");
  const rejectRequest = (id) => markOption(id, "REJECTED");

  const tableConfigWithActions = [
    ...tableConfig,
    // {
    //   Header: "Actions",
    //   Cell: (row) => (
    //     <>
    //       {row.original.status === "PENDING" && (
    //         <TableActions {...row}>
    //           <TableAction
    //             color="success"
    //             icon="done"
    //             onClick={approveRequest}
    //             name="Accept"
    //             {...row}
    //           />
    //           <TableAction
    //             color="danger"
    //             icon="do_disturb_alt"
    //             onClick={rejectRequest}
    //             name="Reject"
    //             {...row}
    //           />
    //         </TableActions>
    //       )}
    //     </>
    //   ),
    // },
  ];

  return (
    <>
      <BaseTable
        tableConfig={tableConfigWithActions}
        data={data}
        loading={loading}
        onRowClick={viewTicket}
      />
      {viewTicket && <Modal />}
    </>
  );
}

export default Table;
