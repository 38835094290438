import React, { useEffect, useState } from "react";
import BaseSelect from "./BaseSelect";
import axios from "axios";
import { BASE_URL } from "config/api";
import { useSelector } from "react-redux";

function TeacherSelect({ setValue, label, placeholder, value, isMultiSelect }) {
  const [teacherNames, setTeacherNames] = useState([]);
  const organizationId = useSelector((state) => state.organization._id);

  useEffect(() => {
    const params = { organizationId };
    axios
      .get(`${BASE_URL}/subject?role=TEACHING_STAFF`, { params })
      .then(({ data: { data } }) => {
        setTeacherNames(
          data?.map((teachers) => ({
            value: teachers?._id,
            label: teachers?.name,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <BaseSelect
      label={label || "Teacher"}
      dataSource={teacherNames}
      placeholder={placeholder || "Select Teacher"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default TeacherSelect;
