import bloodGroups from "config/bloodGroups";
import React from "react";
import BaseSelect from "./BaseSelect";

function BloodGroupSelect({ value, setValue, isMultiSelect }) {
  const bloodGroupOptions = bloodGroups.map((el) => ({
    value: el,
    label: el,
  }));
  return (
    <BaseSelect
      label={"Blood Group"}
      dataSource={bloodGroupOptions}
      placeholder={"Select blood group"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default BloodGroupSelect;
