import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import SWALService from "services/SWALService";
import axios from "axios";
import { BASE_URL } from "config/api";
import PolicyDiagnosisFilter from "./subComponents/PolicyDiagnosisFilter";
import PolicyDiagnosisTable from "./subComponents/PolicyDiagnosisTable";

function PolicyDiagnosis() {
  const [policyData, setPolicyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState();

  useEffect(() => {
    if (!!params) {
      getPolicyDiagnosis();
    }
    return;
  }, [params]);

  const getPolicyDiagnosis = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${BASE_URL}/diagnosis/acitive-rulesets-get`,
        { params }
      );
      setPolicyData(data?.data);
    } catch (err) {
      SWALService.showError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md={3}>
          <PolicyDiagnosisFilter setParams={setParams} />
        </Col>
        <Col md={9}>
          <Card>
            <CardHeader>
              <TableHeader title="Policy Diagnosis" />
            </CardHeader>
            <CardBody>
              <PolicyDiagnosisTable
                getData={getPolicyDiagnosis}
                data={policyData}
                loading={loading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PolicyDiagnosis;
