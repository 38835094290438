const { CAMPUS_URL, BUILDING_URL, FLOOR_URL } = require("config/api");
const { default: BuildingService } = require("services/BuildingService");
const { default: CampusService } = require("services/CampusService");
const { default: FloorPlanService } = require("services/FloorPlanService");

const floorPlanService = new FloorPlanService();

const getBuildings = (callback) =>
  new Promise((resolve, reject) => {
    const orgId = localStorage.getItem("Organization_ID");

    BuildingService
      .get({ orgId })
      .then(({data}) => {
        const buildingMenuItems = data.map((building) => ({
          id: building._id,
          handleClick: callback,
          label: building.name,
          campusId: building.campus?._id,
        }));

        resolve(buildingMenuItems);
      })
      .catch((err) => reject(err));
  });

const getCampuses = (callback) =>
  new Promise((resolve, reject) => {
    const orgId = localStorage.getItem("Organization_ID");

    CampusService.get()
      .then(({ data: campuses }) => {
        const campusMenuItems = campuses.map((campus) => ({
          id: campus._id,
          handleClick: callback,
          label: campus.name,
        }));

        resolve(campusMenuItems);
      })
      .catch((err) => reject(err));
  });

const getFloorPlans = (callback) =>
  new Promise((resolve, reject) => {
    const orgId = localStorage.getItem("Organization_ID");

    floorPlanService
      .getAllFloorPlan(FLOOR_URL, "GET", { organizationId: orgId })
      .then((floors) => {
        const floorMenuItems = floors.map((floor) => ({
          id: floor._id,
          handleClick: callback,
          label: floor.name,
          buildingId: floor.building?._id,
        }));

        resolve(floorMenuItems);
      })
      .catch((err) => reject(err));
  });

export default {
  getBuildings,
  getCampuses,
  getFloorPlans,
};
