import DateTimeService from "services/DateTimeService";

const GatewayStateLogsTableConfig = [
  { Header: "State", accessor: "state" },
  {
    Header: "From Time",
    accessor: ({ from }) => DateTimeService.getDateTimeString(from),
  },
  {
    Header: "To Time",
    accessor: ({ to }) => DateTimeService.getDateTimeString(to),
  },
];

export default GatewayStateLogsTableConfig;
