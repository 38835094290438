import Divider from "components/Divider/Divider";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Fade,
  Row,
} from "reactstrap";
import SettingCategoryItem from "../SettingCategoryItem/SettingCategoryItem";

function SettingCategoryHolder({
  children,
  title,
  imgSrc,
  desc,
  isActive,
  menuItems,
}) {
  return (
    <Fade
      timeout={150}
      className="position-absolute SettingCategoryHolder"
      in={isActive}
      mountOnEnter
      unmountOnExit
    >
      <Card>
        <CardHeader>
          <CardTitle tag="h4">{title}</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="4" className="p-md-5 my-auto">
              <img alt="" src={imgSrc} className="mb-4" />
              <p className="text-justify">{desc}</p>
              <Divider orientation="vertical" intensity="dark"></Divider>
            </Col>
            <Col md="8" className="p-md-5 my-auto">
              <Row className="mb-5">
                {children ||
                  menuItems?.map((menuItem) => (
                    <SettingCategoryItem {...menuItem} />
                  ))}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fade>
  );
}

export default SettingCategoryHolder;
