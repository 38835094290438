const settingsTabs = {
  INFRASTRUCTURE: "INFRASTRUCTURE",
  ORGANIZATION: "ORGANIZATION",
  DEVICES: "DEVICES",
  SECURITY: "SECURITY",
  DIAGNOSIS: "DIAGNOSIS",
};
// const settingsTabs = {
//   infra: "INFRASTRUCTURE",
//   organization: "ORGANIZATION",
//   devices: "DEVICES",
//   security: "SECURITY",
//   diagnosis: "DIAGNOSIS",
// };

export default settingsTabs;
