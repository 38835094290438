const initUser = {};

function userReducer(prevState = initUser, action) {
  switch (action.type) {
    case "SET_USER":
      return { ...prevState, ...action.payload };
    case "UNSET_USER":
      return initUser;
    default:
      return prevState;
  }
}

export default userReducer;
