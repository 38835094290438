import menuStages from "../sub-components/MenuStages/config";

const { BUILDING_URL } = require("config/api");
const { default: BuildingService } = require("services/BuildingService");
const { default: CampusService } = require("services/CampusService");



const orgId = localStorage.getItem("Organization_ID");

const getBuildingLocations = (callback) =>
  new Promise((resolve, reject) => {
    BuildingService
      .get({ organizationId: orgId })
      .then(({data}) => {
        const buildingMenuItems = data.map((building) => ({
          campusId: building?.campus?._id,
          label: building?.name,
          id: building?._id,
          stage: menuStages.buildings,
          coordinates: building.location?.coordinates,
          callback,
        }));

        resolve(buildingMenuItems);
      })
      .catch((err) => reject(err));
  });

const getCampusLocations = (callback) =>
  new Promise((resolve, reject) => {
    CampusService.get({ organizationId: orgId })
      .then(({ data: campuses }) => {
        const campusPlots = campuses.map((campus) => ({
          coordinates: campus?.location?.coordinates,
          label: campus.name,
          id: campus?._id,
          stage: menuStages.campuses,
          callback,
        }));
        resolve(campusPlots);
      })
      .catch((err) => reject(err));
  });

export default {
  getBuildingLocations,
  getCampusLocations,
};
