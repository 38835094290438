const visitorPurpose = [
  "BUSINESS_MEETING",
  "INTERVIEW",
  "FOOD_DELIVERY",
  "GENERIC_VISIT",
  "MAINTENANCE",
  "CLEANING",
  "PACKAGE_DELIVERY",
];

export default visitorPurpose;
