import ApiService from "./ApiService";
import { AUDITTRAIL_URL } from "config/api";

class AuditTrailService extends ApiService {
  constructor() {
    super(AUDITTRAIL_URL);
  }
}

export default new AuditTrailService();
