import React, { useEffect, useState } from "react";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import CommunicationService from "services/CommunicationService";
import SWALService from "services/SWALService";
import BaseTable from "components/Tables/BaseTable/BaseTable";
// import { useHistory } from "react-router-dom";
import DateTimeService from "services/DateTimeService";
import toast from "react-hot-toast";

function ParentConnectTable({ params, setTableData, setTableConfig }) {
  const toast_ID = "Parent_Connect";
  // const history = useHistory();
  const [parentTableData, setParentTableData] = useState([]);

  const [loading, setLoading] = useState(false);

  // const editParentData = (parentId) =>
  // history.push(`/admin/parent/edit/${parentId}`);

  const deleteParentData = async (parentId) => {
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await CommunicationService.delete(parentId).catch((err) =>
      toast.error(err.message || "Something went wrong", { id: toast_ID })
    );

    SWALService.showSuccess("Parent's Data has been deleted.", "Deleted!");
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await CommunicationService.get(params).catch((err) =>
        toast.error(err.message || "Something went wrong", { id: toast_ID })
      );
      setLoading(false);

      if (!data) return;
      setParentTableData(data);
    })();
  }, [params]);

  const parentTableConfig = [
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Sent On",
      accessor: (d) => DateTimeService.getDateTimeString(d.createdAt),
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          // editCallback={editParentData}
          deleteCallback={deleteParentData}
        />
      ),
    },
  ];

  useEffect(() => {
    setTableData(parentTableData);
    setTableConfig(parentTableConfig);
  }, [parentTableData]);

  return (
    <BaseTable
      data={parentTableData}
      loading={loading}
      tableConfig={parentTableConfig}
    />
  );
}

export default ParentConnectTable;
