import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Button,
  Table,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import BaseService from "../../../../../services/BaseService";
import UtilsService from "../../../../../services/UtilsService";
import { HOLIDAY_URL } from "../../../../../config/api";
import Swal from "sweetalert2";
// import Loader from "react-loader-spinner";
import Loader from "../../../../custom_components/Loader";
import styled from "styled-components";
import NoData from "views/custom_components/NoData";

function HolidayListing() {
  const [holidays, setHolidays] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    setLoading(true);
    const baseService = new BaseService();
    const utilsService = new UtilsService();
    const params = { all: true };
    /* baseService.doGET(HOLIDAY_URL, 'GET', params, true).then((response) => {
            if(!!response.data.success){
                let data = response.data.data;
                let holidayData = data.map((item) => {
                    item["createdOnView"] = utilsService.convertUTCToISTTimeStamp(item.createdOn);
                    return item;
                });
                setHolidays(holidayData);
            }
        }); */
  }, []);

  const editHoliday = (holidayId) => {
    history.push(`/admin/edit_holiday/${holidayId}`, { id: holidayId });
  };

  const deleteHoliday = (holidayId) => {
    const baseService = new BaseService();
    Swal.fire({
      title: "Are you sure to Delete it?",
      text: "You won't be able to revert this after deleting!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        baseService
          .doDELETE(HOLIDAY_URL, "DELETE", holidayId, true)
          .then(() => {
            Swal.fire("Deleted!", "Holiday has been deleted.", "success");
          });
      }
    });
  };

  return (
    <>
      <Card className="action-table">
        <CardHeader>
          <Row>
            <Col md={12}>
              <CardTitle tag="h4" className="caption-text">
                Holidays
              </CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {holidays.length > 0 ? (
            <Table responsive className="action-table">
              <thead className="text-primary">
                <tr className="d-flex">
                  <th className="col-5 text-left">Name</th>
                  <th className="col-3 text-left">From Date</th>
                  <th className="col-3 text-left">To Date</th>
                  <th className="text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                {holidays.map((holiday, idx) => {
                  return (
                    <tr className="d-flex" key={idx}>
                      <td className="col-5 text-left">{holiday.name}</td>
                      <td className="col-3 text-left">{holiday.fromDate}</td>
                      <td className="col-3 text-left">{holiday.toDate}</td>
                      <td className="text-right">
                        <Button
                          className="btn-link btn-icon"
                          color="success"
                          size="sm"
                          onClick={() => editHoliday(holiday._id)}
                        >
                          <i className="tim-icons icon-pencil" />
                        </Button>
                        <Button
                          className="btn-link"
                          color="danger"
                          size="sm"
                          onClick={() => deleteHoliday(holiday._id)}
                        >
                          <i className="tim-icons icon-simple-remove" />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <NoData />
          )}
        </CardBody>
      </Card>
    </>
  );
}

const FallbackContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default HolidayListing;
