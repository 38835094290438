import IconButton from "components/UserInputComponents/IconButton/IconButton";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Badge, CardBody, Collapse } from "reactstrap";
import SubjectService from "services/SubjectService";
import BasePopoverView from "../BasePopoverView/BasePopoverView";
import UserSelect from "components/UserInputComponents/Selects/UserSelect";
import BrandLoader from "views/custom_components/Loader";
import Swal from "sweetalert2";

const subjectService = new SubjectService();

const USER_ATTACH_TOAST = "USER_ATTACH_TOAST";
const USER_DEATTACH_TOAST = "USER_DEATTACH_TOAST";

const defaultCollapseProps = {
  unmountOnExit: true,
  mountOnEnter: true,
};

const UserTableView = ({ tagId, user }) => (
  <div id={tagId}>
    {!!user.length && (
      <Badge id={tagId} className="clickable px-3 py-2">
        <span>
          {user[0].firstName} {user[0]?.lastName}
        </span>
      </Badge>
    )}
    <span>{user.length > 1 && `+${user.length - 1}`}</span>
    {!user.length && (
      <Badge id={tagId} className="clickable px-3 py-2">
        <span className="text-muted font-weight-light">No user</span>
      </Badge>
    )}
  </div>
);

function UserConnectView({ assetId, userData }) {
  const [loading, setLoading] = useState(false);
  const [_users, setUsers] = useState([]);
  const [associatedUsers, setAssociatedUsers] = useState([]);
  const tagId = `user_${assetId}`;

  const getUsers = async () => {
    try {
      setLoading(true);
      const { success, data } = await subjectService.get({ id: assetId });
      if (!success) return;
      console.log("rohit", data[0].users);
      setUsers(data[0].users);
    } catch (err) {
      return toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDetachUser = (userId) => {
    Swal.fire({
      title: "Are you sure to Detach the user ?",
      text: "You won't be able to revert this after detaching!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, detach it!",
    })
      .then(async (result) => {
        if (!result.isConfirmed) return;
        toast.loading("Detaching User...", { id: USER_DEATTACH_TOAST });
        try {
          setLoading(true);
          const { success } = await subjectService.detachAssociatedUser(
            assetId,
            userId
          );
          if (!success) return;
          toast.success("User detached successfully", {
            id: USER_DEATTACH_TOAST,
          });
          getUsers();
        } catch (err) {
          toast.error(err || "User was not detached successfully", {
            id: USER_DEATTACH_TOAST,
          });
        } finally {
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const UserView = ({ user }) => (
    <>
      {!!user.length &&
        user.map((el, index) => (
          <>
            <Badge id={index} className="clickable px-3 py-2">
              {el.firstName} {el?.lastName}
            </Badge>
            <IconButton
              icon="close"
              color="danger"
              onClick={() => handleDetachUser(el._id)}
              className="mx-2"
            />
          </>
        ))}
      {!user.length && (
        <Badge className="clickable px-3 py-2">
          <span className="text-muted font-weight-light">No user</span>
        </Badge>
      )}
    </>
  );

  const handleUsers = (userId) => {
    Swal.fire({
      title: "Are you sure to attach the user ?",
      text: "The user will be associated with the asset!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, attach it!",
    })
      .then(async (result) => {
        if (!result.isConfirmed) return;
        toast.loading("Attaching users...", { id: USER_ATTACH_TOAST });
        try {
          const { success } = await subjectService.attachUser(
            assetId,
            userId.toString()
          );
          if (!success) return;
          getUsers();
          toast.success("User successfully mapped", { id: USER_ATTACH_TOAST });
        } catch (err) {
          return toast.error(err, { id: USER_ATTACH_TOAST });
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    setUsers(userData);

    return () => {
      setUsers([]);
    };
  }, [assetId, userData]);

  return (
    <div className="UserConnectView container">
      <UserTableView user={_users} tagId={tagId} />
      <BasePopoverView tagId={tagId}>
        <CardBody className="GatewayConnectView" style={{ minWidth: "400px" }}>
          <h4> Attach users for assets</h4>
          <Collapse isOpen={assetId} {...defaultCollapseProps}>
            <UserSelect
              label="Associated User"
              value={associatedUsers}
              setValue={handleUsers}
            />
          </Collapse>
          {loading && <BrandLoader noOverlay />}
          {!loading && (
            <Collapse isOpen={!!_users.length} {...defaultCollapseProps}>
              <div
                className="d-flex align-items-center"
                style={{ flexWrap: "wrap" }}
              >
                <UserView user={_users} />
              </div>
            </Collapse>
          )}
        </CardBody>
      </BasePopoverView>
    </div>
  );
}

export default UserConnectView;
