import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { configureAmplify, SetS3Config } from "../../configureAmplify";
import Storage from "@aws-amplify/storage";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import Loader from "views/custom_components/Loader";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.inputFileRef = React.createRef();
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      fileData: this.props.fileData,
      context: this.props.context,
      loading: false,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.triggerUpload = this.triggerUpload.bind(this);
  }

  componentDidMount() {
    console.log(this.props.fileData);
  }

  handleImageChange(e) {
    e.preventDefault();
    //this.handleRemove();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
    this.triggerUpload(file);
  }

  triggerUpload(file) {
    this.setState({ loading: true });
    SetS3Config("parchai-ble-floorplans", "public");
    Storage.put(`${this.state.context}/${file.name}`, file, {
      contentType: file.type,
      acl: "public-read",
    })
      .then((result) => {
        this.upload = null;
        this.setState({ response: "Success uploading file!" });
        Storage.get(result.key, { level: "public" })
          .then((response) => {
            console.log(response);
            this.props.parentCallback(response);
          })
          .catch((err) => {
            this.setState({ response: `Failed to fetch file: ${err}` });
          })
          .finally(() => {
            this.setState({ loading: false });
          });
        this.setState({ loading: true });
      })
      .catch((err) => {
        this.setState({ response: `Failed to upload file: ${err}` });
        this.setState({ loading: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleClick(e) {
    this.inputFileRef.current.focus();
    this.inputFileRef.current.click();
  }

  handleRemove() {
    this.setState({
      file: null,
      fileData: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
    });
    this.props.removePreviousImage();
    this.inputFileRef.current.value = null;
  }

  render() {
    return (
      <div className="fileinput text-center">
        {this.state.loading && <Loader />}
        <input
          type="file"
          onChange={this.handleImageChange}
          ref={this.inputFileRef}
        />
        {this.props.fileData ? (
          <div
            className={"thumbnail" + (this.props.fileData ? " img-square" : "")}
          >
            <img src={this.props.fileData} alt="..." />
          </div>
        ) : (
          <div
            className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}
          >
            <img src={this.state.imagePreviewUrl} alt="..." />
          </div>
        )}
        <div>
          {this.state.file === null && this.props.fileData === "" ? (
            <Button
              color={this.props.addBtnColor}
              className={this.props.addBtnClasses}
              onClick={() => this.handleClick()}
              disabled={this?.props?.editable}
            >
              {this.props.avatar ? "Add Photo" : "Select image"}
            </Button>
          ) : (
            <span>
              <Button
                color={this.props.changeBtnColor}
                className={this.props.changeBtnClasses}
                onClick={() => this.handleClick()}
              >
                Change
              </Button>
              {this.props.avatar ? <br /> : null}
              <Button
                color={this.props.removeBtnColor}
                className={this.props.removeBtnClasses}
                onClick={this.handleRemove}
              >
                <i className="fa fa-times" /> Remove
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

ImageUpload.defaultProps = {
  avatar: false,
  removeBtnClasses: "btn-round",
  removeBtnColor: "danger",
  addBtnClasses: "btn-round",
  addBtnColor: "primary",
  changeBtnClasses: "btn-round",
  changeBtnColor: "primary",
};

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  removeBtnClasses: PropTypes.string,
  removeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
  addBtnClasses: PropTypes.string,
  addBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
  changeBtnClasses: PropTypes.string,
  changeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
};

export default ImageUpload;
