import OrganizationForm from "views/pages/admin/Organization/subComponent/OrganizationForm";

export default class OnBoarding {
  Gateway;
  Beacon;
  Asset;
  Campus;
  // Organization;

  constructor({ BEACON, GATEWAY, ASSET, CAMPUS, ORGANIZATION }) {
    this.Beacon = BEACON;
    this.Gateway = GATEWAY;
    this.Asset = ASSET;
    this.Campus = CAMPUS;
    // this.Organization = ORGANIZATION;
  }

  static fromJSON(json) {
    return new OnBoarding(json);
  }

  getNextStep() {
    if (!this.Gateway) return "/admin/gateway/add";
    if (!this.Beacon) return "/admin/becaon/add";
    if (!this.Asset) return "/admin/asset/add";
    if (!this.Campus) return "/admin/campus/add";
    // if (!this.Organization) return OrganizationForm.routeName;
    return "";
  }

  getProgress() {
    const totalDone =
      Number(!!this.Beacon) +
      Number(!!this.Gateway) +
      Number(!!this.Campus) +
      Number(!!this.Gateway);
    // Number(!!this.Organization);

    return (totalDone / 4) * 100;
  }
}
