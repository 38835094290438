const infraMenuItems = [
  {
    title: "Manage Campus",
    icon: "maps_home_work",
    target: "/admin/campus/manage",
  },
  {
    title: "Manage Buildings",
    icon: "apartment",
    target: "/admin/building/manage",
  },
  {
    title: "Manage Floors",
    icon: "layers",
    target: "/admin/floor/manage",
  },
  {
    title: "Manage Floor Areas",
    icon: "night_shelter",
    target: "/admin/floorPlanSection/manage",
  },
  {
    title: "Manage Gates",
    icon: "door_sliding",
    target: "/admin/gate/manage",
  },
];

export default infraMenuItems;
