import Icon from "components/Icon/Icon";
import React from "react";
import { Card } from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import Fade from "reactstrap/lib/Fade";

import "./InfoCard.scss";

function InfoCard({
  centerStat,
  info,
  subtext,
  centerStatSuffix,
  color,
  isNonNegated,
  onClick,
  appearDelay,
}) {
  const colorClass = centerStat ? `text-${color || "info"}` : "text-success";

  return (
    <Fade in timeout={appearDelay || 100} className="flex-fill">
      <Card onClick={onClick} className="flex-fill InfoCard">
        <CardBody className="pt-5 pb-5">
          <h1
            className={`text-center InfoCard__text font-weight-bold ${colorClass}`}
          >
            {!centerStat ? (
              <>
                {/* {color === "danger" ? (
                  <Icon icon="thumb_down_alt" size="xxl" />
                ) : ( */}
                <Icon icon="thumb_up_alt" size="xxl" />
                {/* )} */}
              </>
            ) : (
              <>
                {centerStat}
                <span>{centerStatSuffix}</span>
              </>
            )}
          </h1>

          <h4 className="text-center InfoCard__text">
            {!isNonNegated && !centerStat && "No "}
            {info}
          </h4>
          <p className="text-center InfoCard__text text-muted text-capitalize">
            {subtext}
          </p>
        </CardBody>
      </Card>
    </Fade>
  );
}

export default InfoCard;
