import FormHolder from "components/Holders/FormHolder/FormHolder";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import Forms from "../../../../../config/copies/forms";
import React, { useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
// import { CALENDAR_URL } from "config/api"
import CalendarService from "services/CalendarService";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import DateTimeService from "services/DateTimeService";
import CalenderEventSelect from "components/UserInputComponents/Selects/CalenderEventSelect";
import BaseTextAreaInput from "components/UserInputComponents/Inputs/TextInputs/BestTextAreaInput";

function CalendarForm() {
  const organizationId = useSelector((state) => state.organization._id);

  const { calenderEventId } = useParams();

  const history = useHistory();

  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [type, setType] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");

  const buttonText = calenderEventId ? "Save Changes" : "Submit";

  useEffect(() => {
    if (!calenderEventId) return;
    CalendarService.getById(calenderEventId)
      .then(({ data }) => {
        setName(data.name);
        setDesc(data.desc);
        setType(data.type);
        setFromTime(DateTimeService.initDate(data.fromTime));
        setToTime(DateTimeService.initDate(data.toTime));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [calenderEventId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let calendarData = {
      name: name,
      desc: desc,
      type: type,
      fromTime: fromTime,
      toTime: toTime,
      organization: organizationId,
      organizationId,
    };

    if (!calenderEventId)
      return CalendarService.post(calendarData)
        .then(({ message }) => {
          history.goBack();
          Swal.fire({
            icon: "success",
            title: "Calendar Event Created",
            text: message,
          });
        })
        .catch((error) => console.log("err", error.message));

    CalendarService.put(calenderEventId, calendarData)
      .then(({ message }) => {
        history.goBack();
        Swal.fire({
          icon: "success",
          title: "Calendar Event Updated",
          text: message,
        });
      })
      .catch((error) => console.log("err", error));
  };

  return (
    <div className="content">
      <Row>
        <FormHolder
          imgSrc={Forms.CALENDAR.illustration}
          formHeader={Forms.CALENDAR.title}
          formDescription={Forms.CALENDAR.desc}
          CardFooterComponent=""
        >
          <Form onSubmit={handleSubmit}>
            <BaseTextInput
              label="Name"
              placeholder="Enter name"
              type="text"
              value={name}
              autoComplete="off"
              setValue={setName}
            />
            <BaseTextAreaInput
              label="Description"
              placeholder="Event description"
              value={desc}
              autoComplete="off"
              setValue={setDesc}
            />
            {/* <Row className="separator">
              <Col sm="12">
                <label>Type</label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  name="type"
                  value={type}
                  onChange={setType}
                  options={formatArr}
                  placeholder="Type"
                />
              </Col>
            </Row> */}
            <CalenderEventSelect value={type} setValue={setType} />

            <StartDatePicker date={fromTime} setDate={setFromTime} />
            <EndDatePicker date={toTime} setDate={setToTime} />
            <BaseButton type="submit" color="info">
              {buttonText}
            </BaseButton>
          </Form>
        </FormHolder>
      </Row>
    </div>
  );
}

export default CalendarForm;
