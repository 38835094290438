import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import BooleanSelect from "components/UserInputComponents/Selects/BooleanSelect";
import VisitPurposeSelect from "components/UserInputComponents/Selects/VisitPurposeSelect";
import EndTimeInput from "components/UserInputComponents/TimePickers/EndTimeInput";
import FromTimeInput from "components/UserInputComponents/TimePickers/FromTimeInput";
import FromTimePicker from "components/UserInputComponents/TimePickers/FromTimePicker";
import ToTimePicker from "components/UserInputComponents/TimePickers/ToTimePicker";
import useQuery from "customHooks/useQuery";
import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import DateTimeService from "services/DateTimeService";

import "./VisitorLogFilter.scss";

function VisitorLogFilter({ setParams }) {
  const [searchParams] = useQuery();

  const [purpose, setPurpose] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");

  const [active, setActive] = useState();

  useEffect(() => {
    if (!searchParams) return;
    setPurpose(searchParams.visitorPurpose);

    setFromDate(DateTimeService.initDate(searchParams.fromTime));
    setToDate(DateTimeService.initDate(searchParams.toTime));

    setFromTime(DateTimeService.initDate(searchParams.fromTime));
    setToTime(DateTimeService.initDate(searchParams.toTime));

    setActive(searchParams.active);
  }, [searchParams]);

  const onSubmitFilter = (e) => {
    e.preventDefault();

    const params = {
      purposeOfVisit: purpose,
      // fromDate: fromDate?.toISOString(),
      // toDate: toDate?.toISOString(),
      fromTime: fromTime?.toISOString(),
      toTime: toTime?.toISOString(),
      active: active,
    };

    setParams(params);
  };

  return (
    <Form onSubmit={onSubmitFilter}>
      <StartDatePicker date={fromDate} setDate={setFromDate} />
      <EndDatePicker date={toDate} setDate={setToDate} />
      {/* <FromTimePicker date={fromTime} setTime={setFromTime} /> */}
      <FromTimeInput value={fromTime} setValue={setFromTime} />
      <EndTimeInput value={toTime} setValue={setToTime} />
      <BooleanSelect
        label="Visitor status"
        placeholder="Visitor in premise"
        value={active}
        setValue={setActive}
      />
      <VisitPurposeSelect value={purpose} setValue={setPurpose} />
      <BaseButton> Apply </BaseButton>
    </Form>
  );
}

export default VisitorLogFilter;
