import React, { useState } from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import BrandLoader from "views/custom_components/Loader";
import NoData from "views/custom_components/NoData";
import InfraPresenceFilter from "./InfraPresenceFilter";
import DateTimeService from "../../../../../services/DateTimeService";
import BaseTable from "components/Tables/BaseTable/BaseTable";
import InfraPresenceService from "services/InfraPresence.service";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import toast from "react-hot-toast";

function InfraPresenceReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const getAlerts = (queryParams) => {
    setIsLoading(true);

    InfraPresenceService.get({ ...queryParams, hydrate: "asset,area" })
      .then(({ data }) => {
        if (!data.length) return toast.error("No data to show");
        setData(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const sosReportTableConfig = [
    {
      Header: "Beacon Mac",
      accessor: "beaconMac",
    },
    {
      Header: "Name",
      accessor: (original) => original?.asset?.name || "N/A",
    },
    {
      Header: "Unique ID",
      accessor: (original) => original?.asset?.uniqueId || "N/A",
    },
    {
      Header: "Entry Time",
      width: 200,
      accessor: (d) =>
        DateTimeService.getDateTimeString(d?.entryTime || d?.createdAt),
    },
    {
      Header: "Exit Time",
      width: 200,
      accessor: (d) =>
        !!d.resolved
          ? DateTimeService.getDateTimeString(d?.exitTime || d?.updatedAt)
          : `Inside ${d?.gatewayAttachmentType}`,
    },
  ];

  return (
    <>
      {isLoading && <BrandLoader />}
      <div className="content">
        <Row>
          <Col md={3}>
            <InfraPresenceFilter getAlerts={getAlerts} />
          </Col>
          <Col md={9}>
            <Card>
              <CardHeader>
                <TableHeader
                  title="Infra Presence Reports"
                  onClickDownload={true}
                  tableConfig={sosReportTableConfig}
                  tableData={data}
                />
              </CardHeader>
              <CardBody>
                {!!data?.length && (
                  <>
                    <BaseTable
                      tableConfig={sosReportTableConfig}
                      data={data}
                      loading={isLoading}
                    />
                  </>
                )}
                {!data.length && (
                  <NoData
                    imgSrc="/illustrations/warning.svg"
                    message="Select filters and press on 'get reports'"
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default InfraPresenceReport;
