import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";

import { latLngBounds } from "leaflet";

// import LeafletMapsService from "services/LeafletMapsService";
// import pastelColors from "config/pastelColors";

import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";

import "./EarthMapView.scss";
import ParchaiMarker from "views/custom_components/LeafletMaps/custom-components/ParchaiMarker";

function EarthMapView({ activeCampus, geoPoints, mapCenter }) {
  const [mapLayer, setMapLayer] = useState(null);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (!mapLayer && !isCampusActive) return;
  //   console.log("here is the map layer.....", mapLayer);
  //   isCampusActive ? mapLayer.zoomOut(2) : mapLayer.zoomIn(2);
  // }, [mapLayer, isCampusActive]);

  useEffect(() => {
    if (!mapLayer) return;
    // mapLayer.flyTo(mapCenter, 17, { duration: 3 });

    if (geoPoints) {
      const bounds = geoPoints
        ?.map(({ coordinates }) => coordinates)
        .filter(Boolean);

      if (!bounds.length) return;
      mapLayer.flyToBounds(latLngBounds(bounds), { duration: 1.5 });
    }
  }, [mapCenter, geoPoints]);

  const onMapLayerReady = (mapRef) => {
    setMapLayer(mapRef.target);
  };

  return (
    <div className="EarthMapView">
      <MapContainer
        whenReady={(mapRef) => onMapLayerReady(mapRef)}
        center={mapCenter}
        zoom={17}
        minZoom={10}
        className="MapContainer"
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://mts1.google.com/vt/lyrs=m@186112443&hl=x-local&src=app&x={x}&y={y}&z={z}&s=Galile"
        />

        {geoPoints?.map(
          ({ label, coordinates, callback, campusId, id }, idx) => {
            if (coordinates)
              return (
                <ParchaiMarker
                  label={label || "Unkown"}
                  position={coordinates}
                  onClick={callback}
                  data={{ campusId, id }}
                />
              );
          }
        )}
      </MapContainer>
    </div>
  );
}

export default EarthMapView;
