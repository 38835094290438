import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GatewayService from "services/GatewayService";
import LeafletToolTip from "views/custom_components/LeafletMaps/custom-components/LeafletToolTip";
import BrandLoader from "views/custom_components/Loader";

import "./OccupancyLabel.scss";

const second = 1000;

function OccupancyLabel({ floorPlanSectionId, children }) {
  const [occupancy, setOccupancy] = useState(0);
  const organizationId = useSelector((s) => s.organization._id);

  const fetchOccupancy = () =>
    GatewayService.getGatewayOccupancy({
      floorPlanSectionId,
      count: true,
      organizationId,
    })
      .then(({ data: { data } }) => {
        console.log({ params: { floorPlanSectionId, count: true }, data });
        setOccupancy(data.occupancy);
      })
      .catch((err) => console.log(err));

  useEffect(() => {
    fetchOccupancy();
    const theTimeout = setInterval(() => fetchOccupancy(), 15 * second);
    return () => {
      clearInterval(theTimeout);
    };
  }, [floorPlanSectionId]);

  return (
    <LeafletToolTip permanent>
      {children} ({occupancy})
    </LeafletToolTip>
  );
}

export default OccupancyLabel;
