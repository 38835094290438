import FilterTableHolder from "components/Holders/FilterTableHolder/FilterTableHolder";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SWALService from "services/SWALService";
import ShiftService from "../../../../services/ShiftService";
import ShiftFilter from "./subComponent/ShiftFilter";
import ShiftTable from "./subComponent/ShiftTable";
import CsvDownloader from "utils/CsvDownloader";
import getReportData from "utils/getReportData";
import CSVupload from "views/components/CSVupload";
import DateTimeService from "services/DateTimeService";

function ShiftListing() {
  const organizationId = useSelector((state) => state.organization._id);
  const defaultParams = { organizationId };
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ShiftParams, setShiftParams] = useState({});
  const [tableConfig, setTableConfig] = useState([]);

  useEffect(() => {
    fetchShift();
  }, [ShiftParams]);

  const fetchShift = async () => {
    setLoading(true);

    const params = {
      ...ShiftParams,
      ...defaultParams,
    };

    const { data } = await ShiftService.get(params).catch((err) =>
      SWALService.showError(err),
    );

    if (data) setData(data);
    setLoading(false);
  };

  const addShift = () => history.push("/admin/Shift/add");

  return (
    <FilterTableHolder>
      <ShiftFilter setShiftParams={setShiftParams} />
      <TableHeader
        title="Shift"
        onClickAdd={addShift}
        onClickDownload={true}
        tableConfig={tableConfig}
        tableData={data}
      />
      <ShiftTable
        data={data}
        getData={fetchShift}
        loading={loading}
        setTableConfig={setTableConfig}
      />
    </FilterTableHolder>
  );
}

export default ShiftListing;
