import RoutePoints from "views/RoutePoints/RoutePoints";
import Route from "./index";
import RouteAssetTable from "./subComponent/RouteAssetTable";
import RouteForm from "./subComponent/RouteForm";

const routeRoutes = [
  {
    path: "/admin/route/list",
    rtlName: "عالتسعير",
    component: Route,
    layout: "/admin",
    name: "Routes",
    showVisible: false,
  },
  {
    path: "/admin/route/add",
    name: "Add Route",
    rtlName: "عالتسعير",
    component: RouteForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/route/edit/:routeId",
    name: "Edit Route",
    rtlName: "عالتسعير",
    component: RouteForm,
    layout: "/admin",
    showVisible: false,
  },
  // for route stops
  {
    path: "/admin/route/stop/:routeId",
    name: "Add Stops",
    rtlName: "عالتسعير",
    component: RoutePoints,
    layout: "/admin",
    showVisible: false,
  },
  // for showing assets
  {
    path: "/admin/route/asset/:routeId",
    name: "Assigned Assets",
    rtlName: "عالتسعير",
    component: RouteAssetTable,
    layout: "/admin",
    showVisible: false,
  },
];

export default routeRoutes;
