import Icon from "components/Icon/Icon";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Collapse, FormText, Input, Row } from "reactstrap";
import OrganizationFormSectionHeader from "../OrganizationFormSectionHeader";

function OtherInfoOrganization({ setValue, editable }) {
  const [visible, setVisible] = useState(false);
  const [traxAPIKey, setTraxAPIKey] = useState("");
  const { organization } = useSelector((s) => s);

  useEffect(() => {
    setTraxAPIKey(organization?.attributes?.traxAPIKey || "");
  }, []);

  useEffect(() => {
    setValue({
      traxAPIKey,
    });
  }, [traxAPIKey]);

  return (
    <div className="content">
      <OrganizationFormSectionHeader
        title="Other Value Added Services"
        value={visible}
        setValue={setVisible}
      />
      <Collapse isOpen={visible}>
        <BaseTextInput
          value={traxAPIKey}
          setValue={setTraxAPIKey}
          editable={editable}
          label="Vehicle Tracking Module API Key"
          placeholder="Enter the API Key"
        />
      </Collapse>
    </div>
  );
}

export default OtherInfoOrganization;
