import BaseTable from "components/Tables/BaseTable/BaseTable";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import CSVparser from "./CSVparser";

const checkEnum = ["", null, [], undefined, {}];

function CSVupload({
  title,
  modalOpen,
  data,
  setData,
  handleSubmit,
  setModalOpen,
  selectFields,
  tableConfig,
  requiredFields,
  setShowManual,
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    console.log(requiredFields);
    const isEmpty = requiredFields?.some((e) => checkEnum.includes(e));
    console.log(isEmpty);
    if (!isEmpty) setShow(true);
    else setShow(false);
  }, [requiredFields]);
  return (
    <Modal isOpen={modalOpen}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <div className="content">{selectFields}</div>
        <Collapse isOpen={show}>
          <Link
            onClick={() => {
              setModalOpen(false);
              setShowManual(true);
            }}
          >
            Show Manual for CSV
          </Link>
          <CSVparser setData={setData} />
        </Collapse>
        {data.length > 0 && <BaseTable data={data} tableConfig={tableConfig} />}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Submit
        </Button>
        <Button onClick={() => setModalOpen(false)}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

export default CSVupload;
