import React from "react";
import BaseSelect from "./BaseSelect";

function AlertTypes({ value, setValue, isMultiSelect }) {
  const alertData = ["SOS", "LOCATION_VIOLATION"].map((v) => ({
    label: v.replace("_", " "),
    value: v,
  }));
  return (
    <BaseSelect
      label="Alert Type"
      value={value}
      setValue={setValue}
      isMultiSelect={isMultiSelect}
      dataSource={alertData}
    />
  );
}

export default AlertTypes;
