const setQueryParams = (payload) => {
  let queryParams = "?";

  const payloadKeys = Object.keys(payload);
  payloadKeys.forEach((key, idx) => {
    if (idx === payloadKeys.length - 1) {
      queryParams += key + "=" + payload[key];
    } else {
      queryParams += key + "=" + payload[key] + "&";
    }
  });

  return queryParams;
};

export default setQueryParams;
