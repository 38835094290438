import React from "react";

import allowedTypes from "config/allowedType";
import BaseSelect from "./BaseSelect";
import { useSelector } from "react-redux";
import firmAllowedTypes from "config/firmAllowedTypes";

function RoleSelect({
  value,
  setValue,
  isMultiSelect,
  noLabel,
  renderPortal,
  ...props
}) {
  const organizationCategory = useSelector(
    (state) => state.organization.category
  );

  const allowedTypesOptions =
    organizationCategory === "SCHOOL"
      ? allowedTypes.map((el) => ({
          value: el,
          label: el,
        }))
      : firmAllowedTypes.map((el) => ({
          value: el,
          label: el,
        }));

  return (
    <BaseSelect
      label={!noLabel && "Role"}
      dataSource={allowedTypesOptions}
      placeholder={"Select roles"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
      renderPortal={renderPortal}
      attribute="role"
      {...props}
    />
  );
}

export default RoleSelect;
