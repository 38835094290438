import React from "react";
import BaseDatePicker from "./BaseDatePicker";

function StartDatePicker({
  date,
  setDate,
  endDate,
  isClearable,
  bottomHintText,
  label,
  ...props
}) {
  return (
    <BaseDatePicker
      label={label || "Start Date"}
      placeholder="Select start date"
      date={date}
      setDate={setDate}
      maxDate={endDate}
      isClearable={isClearable}
      bottomHintText={bottomHintText}
      {...props}
    />
  );
}

export default StartDatePicker;
