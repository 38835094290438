import Icon from "components/Icon/Icon";
import React from "react";
import { Alert } from "reactstrap";

function OrganizationFormSectionHeader({ value, setValue, title }) {
  return (
    <>
      {value ? (
        <div className="d-flex  align-items-start justify-content-between">
          <h4>{title}</h4>
          <span className="pl-2" onClick={() => setValue(!value)}>
            <Icon clickable icon="arrow_drop_down_circle" />
          </span>
        </div>
      ) : (
        <Alert onClick={() => setValue(!value)} color="secondary">
          <div className="d-flex  align-items-start justify-content-between">
            <h4>{title}</h4>
            <span
              className="ml-auto text-dark"
              onClick={() => setValue(!value)}
            >
              <Icon clickable icon="arrow_drop_down_circle" />
            </span>
          </div>
        </Alert>
      )}
    </>
  );
}

export default OrganizationFormSectionHeader;
