import React from "react";
import { FormGroup, FormText, Label } from "reactstrap";
import Switch from "react-bootstrap-switch";

function BaseSwitchInput({
  label,
  value,
  setValue,
  error,
  bottomHintText,
  onText,
  offText,
}) {
  return (
    <div className="BaseInput">
      <FormGroup className="mb-4">
        <Label>{label}</Label>
        <div className="togglebutton">
          <Switch
            onChange={() => setValue((prev) => !prev)}
            value={value}
            onText={onText || "Yes"}
            offText={offText || "No"}
          />
        </div>

        <FormText color="danger">{error}</FormText>
        <FormText color="muted">{bottomHintText}</FormText>
      </FormGroup>
    </div>
  );
}

export default BaseSwitchInput;
