import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import AssetSelect from "components/UserInputComponents/Selects/AssetSelect";
import AttendanceSummarySelect from "components/UserInputComponents/Selects/AttendanceSummarySelect";
import RoleSelect from "components/UserInputComponents/Selects/RoleSelect";
import TagSelect from "components/UserInputComponents/Selects/TagSelect";
import useFilterContext from "customHooks/useFilterContext";
import useQuery from "customHooks/useQuery";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import setQueryParams from "utils/setQueryParams";

function DailyAttendanceFilter({ setFilterData, setFilterContext }) {
  const history = useHistory();
  const { _id: organizationId } = useSelector((s) => s.organization);

  const [{ subjectId: querySubjectId }] = useQuery();

  const today = new Date();
  const startDateStamp = DateTimeService.getMorningTime(today);
  const endDateStamp = DateTimeService.getMidnightTime(today);

  const [startDate, setStartDate] = useState(startDateStamp);
  const [endDate, setEndDate] = useState(endDateStamp);
  const [subjectId, setSubjectId] = useState(querySubjectId || "");
  const [attendanceType, setAttendanceType] = useState("DAILY_DETAIL");
  const [roles, setRoles] = useState([]);
  const [tags, setTags] = useState([]);

  const [filterContext, _setFilterContext] = useFilterContext([]);

  useEffect(() => {
    if (querySubjectId) setSubjectId(subjectId);
  }, [querySubjectId]);

  const handleOnSubmit = (e) => {
    e.preventDefault();

    const data = {
      organizationId,
      fromTime: startDate?.toISOString(),
      toTime: endDate?.toISOString(),
      assetId: subjectId,
      roles: roles?.join(",") || [],
      primaryGroup: tags,
      attendanceType,
    };

    history.push({
      pathname: "/admin/daily-attendance-log",
      search: `${setQueryParams(data)}`,
    });

    setFilterContext(filterContext);

    setFilterData(data);
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <AttendanceSummarySelect
        value={attendanceType}
        setValue={setAttendanceType}
        setFilterContext={_setFilterContext}
      />
      <StartDatePicker
        date={startDate}
        setDate={setStartDate}
        setFilterContext={_setFilterContext}
      />
      <EndDatePicker
        date={endDate}
        setDate={setEndDate}
        setFilterContext={_setFilterContext}
      />
      <RoleSelect
        value={roles}
        setValue={setRoles}
        isMultiSelect
        setFilterContext={_setFilterContext}
      />
      <AssetSelect
        value={subjectId}
        setValue={setSubjectId}
        setFilterContext={_setFilterContext}
      />
      <TagSelect
        value={tags}
        setValue={setTags}
        setFilterContext={_setFilterContext}
      />
      <BaseButton>Apply</BaseButton>
    </Form>
  );
}

export default DailyAttendanceFilter;
