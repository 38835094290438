import TableHeader from "components/Tables/TableHeader/TableHeader";
import { FLOORSECTION_URL } from "config/api";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import FloorPlanSectionService from "services/FloorPlanSectionService";
import SWALService from "services/SWALService";
import CsvDownloader from "views/custom_components/CsvDownloader";
import FloorPlanSectionFilter from "./FloorPlanSectionFilter";
import FloorPlanSectionTable from "./FloorPlanSectionTable";

function FloorPlanSectionListing() {
  const organizationId = useSelector((state) => state.organization._id);
  const defaultParams = { organizationId };
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [floorSectionParams, setFloorSectionParams] = useState(false);
  const [tableConfig, setTableConfig] = useState([]);

  useEffect(() => {
    fetchFloorPlanSection();
  }, [floorSectionParams]);

  const fetchFloorPlanSection = async () => {
    setLoading(true);

    const params = {
      ...floorSectionParams,
      ...defaultParams,
    };

    FloorPlanSectionService.get(params)
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => SWALService.showError(err));

    // if (data) setData(data);
    // setLoading(false);
  };

  const addFloorPlanSection = () => history.push("/admin/floorPlanSection/add");

  return (
    <div className="content">
      <Row>
        <Col md={3}>
          <FloorPlanSectionFilter
            setFloorSectionParams={setFloorSectionParams}
            data={data}
          />
        </Col>
        <Col md={9}>
          <Card>
            <CardHeader>
              <TableHeader
                title="Floor Areas"
                onClickAdd={addFloorPlanSection}
                onClickDownload={true}
                tableConfig={tableConfig}
                tableData={data}
              />
            </CardHeader>
            <CardBody>
              <FloorPlanSectionTable
                data={data}
                getData={fetchFloorPlanSection}
                loading={loading}
                setTableConfig={setTableConfig}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default FloorPlanSectionListing;
