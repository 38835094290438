/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import ENIRONMENTS_IS_DEV from "env";

const isProd = !ENIRONMENTS_IS_DEV;

const awsmobile = {
  aws_project_region: "ap-south-1",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: isProd ? "ap-south-1_ud57sUIIL" : "ap-south-1_0hfUWPErr",
  aws_user_pools_web_client_id: isProd
    ? "dl5gtcr032liopofbgnhh1j38"
    : "ld2mts3aqeqj99iqovs2s83uj",
  aws_cognito_identity_pool_id: isProd
    ? "ap-south-1:63d528ad-6728-40fd-a298-9b559283e651"
    : "ap-south-1:784f90d7-18b2-4649-a0ea-dbddf29034e6",
  oauth: {},
};

export default awsmobile;
