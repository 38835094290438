import Campus from "./Campus";
import CampusForm from "./subComponent/CampusForm";

const campusRoutes = [
  {
    path: Campus.routeName,
    rtlName: "عالتسعير",
    component: Campus,
    layout: "/admin",
    name: "Campuses",
    showVisible: false,
  },
  {
    path: "/admin/campus/add",
    name: "Add Campus",
    rtlName: "عالتسعير",
    component: CampusForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/campus/edit/:campusId",
    name: "Edit Campus",
    rtlName: "عالتسعير",
    component: CampusForm,
    layout: "/admin",
    showVisible: false,
  },
];

export default campusRoutes;
