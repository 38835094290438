import React from "react";
import { Tooltip } from "react-leaflet";

function LeafletToolTip({ label, permanent, children }) {
  return (
    <Tooltip color="#333333" permanent={permanent} direction="center">
      <div className="text-xl font-bold">{children || label}</div>
    </Tooltip>
  );
}

export default LeafletToolTip;
