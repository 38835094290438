import React from "react";
import {
  Row,
  Col,
  CardBody,
  Label,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormText,
} from "reactstrap";
import Select from "react-select";
import Switch from "react-bootstrap-switch";

import FormNarrator from "../FormNarrator/FormNarrator";

export default class PolicyTrigger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      POLICY_TYPES: [
        {
          value: "POSITION",
          label: "POSITION",
        },
        {
          value: "SIGNAL",
          label: "SIGNAL",
        },
      ],

      TRIGGER_OPERATOR: [
        // {
        //   value: "EQUALS",
        //   label: "EQUALS",
        // },
        {
          value: "GREATER_THAN",
          label: "GREATER_THAN",
        },
        {
          value: "LESSER_THAN",
          label: "LESSER_THAN",
        },
        // {
        //   value: "GREATER_THAN_EQUALS",
        //   label: "GREATER_THAN_EQUALS",
        // },
        // {
        //   value: "LESSER_THAN_EQUALS",
        //   label: "LESSER_THAN_EQUALS",
        // },
      ],
      TRIGGER_PARAMS: [
        {
          value: "POSITION",
          label: "POSITION",
        },
        {
          value: "BUTTON_CLICKED",
          label: "BUTTON_CLICKED",
        },
        // {
        //   value: "OCCUPANCY",
        //   label: "OCCUPANCY",
        // },
        // {
        //   value: "BATTERY",
        //   label: "BATTERY",
        // },
        // {
        //   value: "TEMPARATURE",
        //   label: "TEMPARATURE",
        // },
        // {
        //   value: "HUMIDITY",
        //   label: "HUMIDITY",
        // },
        // {
        //   value: "AQI",
        //   label: "AQI",
        // },
      ],
      // POLICY_OPERATORS: [
      //   {
      //     value: "AND",
      //     label: "AND",
      //   },
      //   {
      //     value: "OR",
      //     label: "OR",
      //   },
      // ],

      policy_type: "",
      policy_operator: "",
      policy_trigger_by: "",
      location: "",
      transmitter_scope: "",
      threshold: "",
      triggerOperator: "",
      proximity: "IMMEDIATE",
      assignedFloorPlanSections: [],
      assignedSubjects: [],
      assignedGroup: [],
      floorPlanSections: [],
      tempFloorPlanSections: [],
      subjectsData: [],
      tempSubjectsData: [],
      groupsData: [],
      tempGroupsData: [],
      isFloorSectionShow: false,
      isTransmitterShow: false,
      isGroupShow: false,
      isCompulsory: false,
    };
    // this.policyOperatorChange = this.policyOperatorChange.bind(this);
    this.policyTriggerParamChange = this.policyTriggerParamChange.bind(this);
    this.locationChange = this.locationChange.bind(this);
    this.transmitterChange = this.transmitterChange.bind(this);
    this.thresholdChange = this.thresholdChange.bind(this);
    this.triggerOperatorChange = this.triggerOperatorChange.bind(this);
    this.proximityChange = this.proximityChange.bind(this);
    // this.accessTypeChange = this.accessTypeChange.bind(this);
    this.policyTypeChange = this.policyTypeChange.bind(this);
  }

  componentDidMount() {
    const { policy } = this.props;
    if (!policy) return;

    this.policyTriggerParamChange({
      value: policy.triggerCondition_triggerParam,
    });

    const triggerOperatorObj = {
      value: policy.triggerCondition_config_operator,
      label: policy.triggerCondition_config_operator,
    };

    this.setState({
      triggerOperator: triggerOperatorObj,
      isCompulsory: policy.triggerCondition_config_compulsoryLocationPresence,
      threshold: policy.triggerCondition_config_threshold / 1000,
    });
  }

  policyTriggerParamChange = (event) => {
    let policy_trigger_by = { value: event.value, label: event.value };
    let policy_type = "";

    if (event.value === "POSITION") policy_type = this.state.POLICY_TYPES[0];
    if (event.value === "BUTTON_CLICKED")
      policy_type = this.state.POLICY_TYPES[1];

    this.setState({ policy_trigger_by, policy_type });
  };

  locationChange = (event) => {
    let locationobj = { value: event.value, label: event.value };
    this.setState({ location: locationobj });
    //this.props.onLocationChange(event.value);
    if (event.value.toString().toLowerCase() === "location_list")
      this.setState({ isFloorSectionShow: true });
    else this.setState({ isFloorSectionShow: false });
  };
  transmitterChange = (event) => {
    let transobj = { value: event.value, label: event.value };
    this.setState({ transmitter_scope: transobj });
    //this.props.onTransmitterChange(event.value);
    if (event.value.toString().toLowerCase() === "transmitter_list") {
      this.setState({ isTransmitterShow: true, isGroupShow: false });
    } else if (event.value.toString().toLowerCase() === "group") {
      this.setState({ isGroupShow: true, isTransmitterShow: false });
    } else {
      this.setState({ isGroupShow: false, isTransmitterShow: false });
    }
  };
  updateThresholdChange = (event) => {
    this.setState({ threshold: event.target.value });
    this.props.setPolicyThreshold(event.target.value);
  };
  thresholdChange = (event) => {
    this.setState({ threshold: event.target.value });
    //this.props.onThresholdChange(event.target.value);
  };
  updateTriggerOperatorChange = (event) => {
    let operatorObj = { value: event.value, label: event.value };
    this.setState({ triggerOperator: operatorObj });
    this.props.setPolicyTriggerOperator(event.value);
  };
  triggerOperatorChange = (event) => {
    let operatorObj = { value: event.value, label: event.value };
    this.setState({ triggerOperator: operatorObj });
    //this.props.onTriggerOperatorChange(event.value);
  };
  proximityChange = (event) => {
    let proximityObj = { value: event.value, label: event.value };
    this.setState({ proximity: proximityObj });
    //this.props.onProximityChange(event.value);
  };
  // accessTypeChange = (event) => {
  //   let accesstypeObj = { value: event.value, label: event.value };
  //   this.setState({ accessType: accesstypeObj });
  //   //this.props.onAccessTypeChange(event.value);
  // };

  updateCompulsoryChange = (el, state) => {
    this.setState({ isCompulsory: state });
    this.props.setPolicyIsCompulsory(state);
  };

  compulsoryChange = (el, state) => {
    this.setState({ isCompulsory: state });
    //this.props.onCompulsoryChange(state);
  };

  handleSubjectTransmitterAdd = (subjectTrans) => {
    this.setState({
      assignedSubjects: [...this.state.assignedSubjects, subjectTrans],
    });
    //this.props.onSubjectsTransmitter(this.state.assignedSubjects);
  };
  handleGroupAdd = (group) => {
    this.setState({ assignedGroup: [...this.state.assignedGroup, group] });
    //this.props.onGroup(this.state.assignedGroup);
  };

  policyTypeChange = (event) => {
    let typeobj = { value: event.value, label: event.value };
    this.setState({ policy_type: typeobj });
    //this.props.onPolicyTypeChange(event.value);
  };

  valueModifier = (val) => `<b><i>${val || "not entered"} &nbsp </i></b>`;

  render() {
    this.FormNarratorTemplate = `For this policy to trigger, the asset ${this.valueModifier(
      this.state.isCompulsory ? "must be" : "must not be"
    )} present in the selected locations for a duration of ${this.valueModifier(
      this.state.triggerOperator?.value
    )} ${this.valueModifier(this.state.threshold)} seconds`;

    return (
      <>
        <h4>Trigger conditions</h4>
        <br />
        {this.state.policy_trigger_by?.value === "POSITION" && (
          <CardBody>
            <FormNarrator>{this.FormNarratorTemplate}</FormNarrator>
          </CardBody>
        )}
        {/* <Col md={12}>
            <CardBody>
              <Label>Policy Type</Label>
              <FormGroup>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  name="selectedPolicyType"
                  value={this.state.policy_type}
                  onChange={this.policyTypeChange}
                  options={this.state.POLICY_TYPES}
                  placeholder="Lets have a type of this policy"
                />
              </FormGroup>
            </CardBody>
          </Col> */}
        <FormGroup>
          <Label>Trigger By</Label>
          <Select
            className="react-select info"
            classNamePrefix="react-select"
            name="selectedTriggerBy"
            value={this.state.policy_trigger_by}
            onChange={this.policyTriggerParamChange}
            options={this.state.TRIGGER_PARAMS}
            // placeholder=""
          />
          <FormText color="muted">What should trigger this policy?</FormText>
        </FormGroup>
        <>
          {this.state.policy_trigger_by?.value === "POSITION" && (
            <Row>
              <Col md={6}>
                <Label>Trigger Operator</Label>
                <FormGroup>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    name="selectedTriggerOp"
                    value={this.state.triggerOperator}
                    onChange={this.triggerOperatorChange}
                    options={this.state.TRIGGER_OPERATOR}
                    // placeholder=""
                  />
                  <FormText color="muted">Select Trigger Operator</FormText>
                </FormGroup>
              </Col>
              <Col md={6}>
                <Label>Threshold</Label>
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="number"
                      autoComplete="off"
                      value={this.state.threshold}
                      onChange={this.thresholdChange}
                      // placeholder=""
                    ></Input>
                    <InputGroupAddon addonType="append">
                      <InputGroupText className="pl-3">
                        <p>sec(s)</p>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <FormText color="muted">Enter thresold</FormText>
                </FormGroup>
              </Col>
            </Row>
          )}
        </>

        {this.state.policy_trigger_by?.value === "POSITION" && (
          <FormGroup>
            <Label>Asset should be present here?</Label>
            <Switch
              onChange={this.compulsoryChange}
              offColor=""
              value={this.state.isCompulsory}
              // defaultValue={true}
              offText={<i className="tim-icons icon-simple-remove" />}
              onColor=""
              onText={<i className="tim-icons icon-check-2" />}
            />
          </FormGroup>
        )}
      </>
    );
  }
}
