import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React from "react";
import { useHistory } from "react-router-dom";
import VehicleService from "services/VehicleService";
import SWALService from "services/SWALService";

function VehicleTable({ getData, data, loading }) {
  const history = useHistory();

  const editVehicle = (vehicleId) =>
    history.push(`/admin/vehicle/edit/${vehicleId}`);

  const deleteVehicle = async (vehicleId) => {
    console.log(vehicleId);
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await VehicleService.delete(vehicleId).catch((err) =>
      SWALService.showError(err)
    );

    getData();

    SWALService.showSuccess("Vehicle has been deleted.", "Deleted!");
  };

  const vehicleTableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "UID",
      accessor: "uid",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "BeaconMAC",
      accessor: "beaconMac",
    },
    {
      Header: "Gateway",
      accessor: "gateway",
    },
    {
      Header: "Entry",
      accessor: "entry",
    },
    {
      Header: "Exit",
      accessor: "exit",
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editVehicle}
          deleteCallback={deleteVehicle}
        />
      ),
    },
  ];

  return (
    <BaseTable
      isColorCoded
      tableConfig={vehicleTableConfig}
      data={data}
      loading={loading}
    />
  );
}

export default VehicleTable;
