import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Swal from "sweetalert2";
import { Form } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Forms from "config/copies/forms";
import FormHolder from "components/Holders/FormHolder/FormHolder";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

function ChangePassword() {
  const history = useHistory();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const changePassword = (e) => {
    e.preventDefault();
    if (!oldPassword.length || !newPassword.length || !confirmPassword.length)
      return toast.error("Fill all the form field to change the password");

    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then((data) => {
        console.log(data);
        Swal.fire({
          icon: "success",
          title: "Schedule Create",
          text: "Password changed successfully",
        });

        history.push("/admin/dashboard");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="content">
      <FormHolder
        imgSrc={Forms.PASSWORD.illustration}
        formHeader={Forms.PASSWORD.title}
        formDescription={Forms.PASSWORD.desc}
      >
        <Form onSubmit={changePassword}>
          <BaseTextInput
            type="password"
            value={oldPassword}
            setValue={setOldPassword}
            label={"Old Password"}
            placeholder={"Enter your old password"}
          />
          <BaseTextInput
            type="password"
            value={newPassword}
            setValue={setNewPassword}
            label={"New Password"}
            placeholder={"Enter your new password"}
          />
          <BaseTextInput
            type="password"
            value={confirmPassword}
            setValue={setConfirmPassword}
            label={"Confirm Password"}
            placeholder={"Re-enter your new password"}
          />
          {/* <InputGroup
                className={classnames({
                  "input-group-focus": this.state.oldpassFocus,
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-lock-circle" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Old Password"
                  type="password"
                  name="old_password"
                  onFocus={(e) => this.setState({ oldpassFocus: true })}
                  onBlur={(e) => this.setState({ oldpassFocus: false })}
                  onChange={this.onChangeOldPass}
                />
              </InputGroup>
              <InputGroup
                className={classnames({
                  "input-group-focus": this.state.passFocus,
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-lock-circle" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="New Password"
                  type="password"
                  name="new_password"
                  onFocus={(e) => this.setState({ passFocus: true })}
                  onBlur={(e) => this.setState({ passFocus: false })}
                  onChange={this.onChangeNewPass}
                />
              </InputGroup>
              <InputGroup
                className={classnames({
                  "input-group-focus": this.state.conpassFocus,
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-lock-circle" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Confirm Password"
                  type="password"
                  name="confirm_password"
                  onFocus={(e) => this.setState({ conpassFocus: true })}
                  onBlur={(e) => this.setState({ conpassFocus: false })}
                  onChange={this.onChangeConfPass}
                />
              </InputGroup> */}
          <BaseButton color="info">Change Password</BaseButton>
        </Form>
      </FormHolder>
    </div>
  );
}

export default ChangePassword;
