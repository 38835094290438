import ApiService from "./ApiService";
import BaseService from "./BaseService";
const { TAG_URL } = require("config/api");

class TagService extends ApiService {
  constructor() {
    super(TAG_URL);
    this.baseService = new BaseService();
  }

  createTag(url, data, headers) {
    return this.baseService
      .doPOST(url, "POST", data, headers)
      .then((response) => {
        if (!!response.data.success) {
          return {
            msg: response.data.message,
            data: response.data.data,
          };
        }
      })
      .catch((err) => console.log(err));
  }
}

export default new TagService();
