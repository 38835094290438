const { VISITOR_LOG } = require("config/api");
const { default: ApiService } = require("./ApiService");

class VisitorLogService extends ApiService {
  constructor() {
    super(VISITOR_LOG);
  }
}

export default new VisitorLogService();
