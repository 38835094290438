import axios from "axios";
import joinUrlPath from "utils/joinUrlPath";

import {
  doPost,
  doGet,
  doGetById,
  doPut,
  doPatch,
  doDelete,
} from "utils/ApiCallers";
import { useSelector } from "react-redux";

const getDefaultParams = () => {
  const organizationId = localStorage.getItem("Organization_ID");
  // const organizationId = useSelector((state) => state.organization._id);
  return organizationId
    ? {
        organizationId,
      }
    : {};
};

class ApiService {
  _url = "";

  constructor(url) {
    this._url = url;
    this._headers = {};

    this.cancelToken = "";

    this.doPost = doPost;

    this.doGet = doGet;
    this.doGetById = doGetById;

    this.doPut = doPut;
    this.doPatch = doPatch;

    this.doDelete = doDelete;
  }

  post(payload, params, headers) {
    return doPost(this._url, payload, params, { ...this._headers, ...headers });
  }

  get(params, headers, noOrgId) {
    const _params = { ...params, ...getDefaultParams() };

    if (noOrgId) delete _params.organizationId;

    return this.doGet(this._url, _params, headers, this.cancelToken);
  }

  getById(id, params, headers) {
    const _params = { ...params, id, ...getDefaultParams() };
    return this.doGetById(this._url, _params, headers);
  }

  put(id, payload, params, headers) {
    return this.doPut(joinUrlPath(this._url, id), payload, params, headers);
  }

  delete(id, params, headers) {
    return this.doDelete(joinUrlPath(this._url, id), params, headers);
  }

  patch(id, payload, params, headers) {
    return this.doPatch(joinUrlPath(this._url, id), payload, params, headers);
  }
}

export default ApiService;
