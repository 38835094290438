import types from "../types";

const initLoggedData = {
  token: localStorage.getItem("token") || "",
  isAuthenticated: false,
  cognitoUser: null,
};

function authenticationReducer(prevState = initLoggedData, action) {
  switch (action.type) {
    case types.LOGGED_IN:
      return {
        ...prevState,
        isAuthenticated: true,
        cognitoUser: action.payload,
      };

    case types.LOGGED_OUT:
      localStorage.clear();
      return initLoggedData;

    default:
      return prevState;
  }
}

export default authenticationReducer;
