import axios from "axios";
import InfoCard from "components/Widgets/InfoCard/InfoCard";
import { ATTENDANCE_URL } from "config/api";
import { EVENT_URL } from "config/api";
import { ATTENDANCE_NEW_URL } from "config/api";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import AlertService from "services/AlertService";
import BaseService from "services/BaseService";
import BeaconService from "services/BeaconService";
import DateTimeService from "services/DateTimeService";
import BrandLoader from "views/custom_components/Loader";

import "./DailyStats.scss";

const baseService = new BaseService();
const alertService = new AlertService();

function DailyStats({ date, subjectId }) {
  const orgId = useSelector((state) => state.organization._id);
  const fromTime = DateTimeService.getMorningTime(date).toISOString();
  const toTime = DateTimeService.getMidnightTime(date).toISOString();
  const [violations, setViolations] = useState();
  const [attendanceReport, setAttendanceReport] = useState();
  const [loading, setLoading] = useState(false);
  const [dailyData, setDailyData] = useState();

  const fetchDailySummary = async () => {
    const params = {
      organizationId: orgId,
      assetId: subjectId,
      fromTime,
      toTime,
      attendanceType: "DAILY_SUMMARY",
    };

    try {
      setLoading(true);
      const {
        data: { data },
      } = await axios.get(ATTENDANCE_URL, { params });

      if (data) {
        setAttendanceReport(data[0]);
        console.log("attendance", data[0]);
      }
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchDailyAnalytics = async () => {
    const params = {
      organizationId: orgId,
      assetId: subjectId,
      fromTime,
      toTime,
      attendanceType: "DAILY_DETAIL",
    };

    try {
      setLoading(true);
      const {
        data: { data },
      } = await axios.get(ATTENDANCE_URL, { params });

      if (data) {
        setDailyData(data[0]);
      }
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchAlerts = () => {
    setLoading(true);

    alertService
      .getAllAlerts(
        EVENT_URL,
        {
          eventTypes: "LOCATION_VIOLATION",
          hydrate: "asset,policy,area",
          fromTime,
          toTime,
        },
        true
      )
      .then((data) => {
        setViolations(data?.length);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDailyAnalytics();
    fetchAlerts();
    fetchDailySummary();
  }, [date]);

  return (
    <div className="DailyStats">
      {loading && <BrandLoader noOverlay />}
      {!loading && (
        <>
          <Row>
            <Col className="d-flex align-items-stretch justify-content-stretch">
              <InfoCard
                centerStat={0}
                info={dailyData?.present ? "Was Present" : "Was Absent"}
                color={"success"}
                isNonNegated
              />
            </Col>
            <Col className="d-flex align-items-stretch justify-content-stretch">
              {dailyData?.present && (
                <InfoCard
                  centerStat={DateTimeService.getTimeString(dailyData?.entry)}
                  info={dailyData?.onTime ? "On Time" : "Late"}
                  color={dailyData?.onTime ? "success" : "danger"}
                />
              )}
              {!dailyData?.present && (
                <InfoCard centerStat="N/A" color="danger" />
              )}
            </Col>
          </Row>
          <Row>
            <Col className="d-flex align-items-stretch justify-content-stretch">
              {/* please change later */}
              <InfoCard
                centerStat={attendanceReport?.absent}
                info="Unattended"
                subtext="Classes"
                color="warning"
              />
            </Col>
            <Col className="d-flex align-items-stretch justify-content-stretch">
              <InfoCard
                centerStat={violations}
                info="Violations"
                color="danger"
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default DailyStats;
