import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import DateTimeService from "services/DateTimeService";
import ScheduleService from "services/ScheduleService";
import SWALService from "services/SWALService";

function ScheduleTable({ getData, data, loading, setTableConfig }) {
  const history = useHistory();

  const today = new Date();

  const editSchedule = (scheduleId) =>
    history.push(`/admin/schedule/edit/${scheduleId}`);

  const deleteSchedule = async (scheduleId) => {
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await ScheduleService.delete(scheduleId).catch((err) =>
      SWALService.showError(err)
    );

    getData();

    SWALService.showSuccess("Schedule has been deleted.", "Deleted!");
  };

  const scheduleTableConfig = [
    { Header: "Name", accessor: "name" },
    {
      Header: "From Time",
      accessor: ({ fromTime }) =>
        DateTimeService.getTimeString(
          today.toISOString().slice(0, today.toISOString().indexOf("T") + 1) +
            fromTime +
            "Z"
        ),
    },
    {
      Header: "To Time",
      accessor: ({ toTime }) =>
        DateTimeService.getTimeString(
          today.toISOString().slice(0, today.toISOString().indexOf("T") + 1) +
            toTime +
            "Z"
        ),
    },
    {
      Header: "Applied Days",
      accessor: (d) => DateTimeService.getDaysFromArray(d?.daysOfWeek),
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editSchedule}
          deleteCallback={deleteSchedule}
        />
      ),
    },
  ];

  useEffect(() => {
    setTableConfig(scheduleTableConfig);
  }, []);

  return (
    <BaseTable
      tableConfig={scheduleTableConfig}
      data={data}
      loading={loading}
    />
  );
}

export default ScheduleTable;
