import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";
import AlertService from "services/AlertService";
import BrandLoader from "views/custom_components/Loader";
import NoData from "views/custom_components/NoData";
import { EVENT_URL } from "../../../../../config/api";
import SOSFilter from "../FilterPanel/SOSFilter";
import DateTimeService from "../../../../../services/DateTimeService";
import AlertModal from "views/custom_components/AlertModal2/AlertModal";
import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableHeader from "components/Tables/TableHeader/TableHeader";

function SOSReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [eventId, setEventId] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const alertService = new AlertService();

  const getAlerts = (queryParams) => {
    setIsLoading(true);

    alertService
      .getAllAlerts(
        EVENT_URL,
        { ...queryParams, hydrate: "asset,policy,area,asset.primaryGroup" },
        true
      )
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sosReportTableConfig = [
    {
      Header: "Name",
      accessor: "asset.name",
    },
    {
      Header: "Unique ID",
      accessor: "asset.uniqueId",
    },
    {
      Header: "Primary Group",
      accessor: "asset.primaryGroup.name",
    },
    {
      Header: "Place",
      accessor: "area.floorPlanSectionName",
    },
    {
      Header: "Rule",
      accessor: "policy.name",
    },
    {
      Header: "Gateway Id",
      accessor: "gatewayMac",
    },
    {
      Header: "Incident Time",
      accessor: (d) => DateTimeService.getDateTimeString(d?.createdAt),
    },
  ];

  const openAlertModal = (eventId) => {
    if (!eventId) return;
    setEventId(eventId);
    setModalIsOpen(true);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      {isLoading && <BrandLoader />}
      <div className="content">
        <Row>
          <Col md={3}>
            <SOSFilter getAlerts={getAlerts} />
          </Col>
          <Col md={9}>
            <Card>
              <CardHeader>
                <TableHeader
                  title="Alerts"
                  onClickDownload={true}
                  tableConfig={sosReportTableConfig}
                  tableData={data}
                />
              </CardHeader>
              <CardBody>
                {!!data?.length && (
                  <>
                    <BaseTable
                      tableConfig={sosReportTableConfig}
                      data={data}
                      loading={isLoading}
                      onRowClick={({ _id }) => openAlertModal(_id)}
                    />
                  </>
                )}
                {!data.length && (
                  <NoData
                    imgSrc="/illustrations/warning.svg"
                    message="Select filters and press on 'get reports'"
                  />
                )}
                <AlertModal
                  isOpen={modalIsOpen}
                  eventId={eventId}
                  close={handleModalClose}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SOSReport;
