import scope from "config/scope";
import React from "react";
import BaseSelect from "./BaseSelect";

function ScopeSelect({ value, setValue, isMultiSelect }) {
  const ScopeOptions = scope.map((el) => ({
    value: el,
    label: el,
  }));
  return (
    <BaseSelect
      label={"Scope"}
      dataSource={ScopeOptions}
      placeholder={"Select scope"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={false}
      value={value}
      setValue={setValue}
    />
  );
}

export default ScopeSelect;
