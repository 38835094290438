import React from "react";

function AuthBranding() {
  return (
    <img
      style={{
        position: "absolute",
        top: "6rem",
        left: "50%",
        transform: "translateX(-65%)",
        width: "12rem",
        height: "3rem",
        zIndex: "10",
        objectFit: "contain",
      }}
      alt="..."
      src={require("assets/img/parchai.png")}
    />
  );
}

export default AuthBranding;
