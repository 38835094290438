import React from "react";
import { Row, Col, Card } from "reactstrap";
import { useState } from "react";
import SettingCategoryHolder from "./subComponents/SettingCategoryHolder/SettingCategoryHolder";

import SideMenu from "views/custom_components/LeafletMaps/ViewMaps/sub-components/SideMenu/SideMenu";
import getSettingsMenuItems from "./menus/settingsMenu";
import infraMenuItems from "./menus/infraMenuItems";
import settingsTabs from "./menus/settingsTabs";
import devicesMenuItems from "./menus/devicesMenuItems";
import securityMenuItems from "./menus/securityMenuItems";
import organizationMenuItems from "./menus/organizationMenuItems";

import "./SettingsPage.scss";
import diagnosisMenuItems from "./menus/diagnosisMenuItems";
import useQuery from "customHooks/useQuery";
import { useEffect } from "react";

function SettingsPage() {
  const [activeTab, setActiveTab] = useState(
    settingsTabs[Object.values(settingsTabs)[0]]
  );

  const [queryParams, setQueryParams] = useQuery();

  const onMenuCallback = (tab) => {
    setActiveTab(tab);
    setQueryParams({ tab });
  };

  useEffect(() => {
    if (Object.keys(queryParams).length)
      setActiveTab(settingsTabs[queryParams.tab]);
    else setQueryParams({ tab: activeTab });
  }, []);

  const menuItems = getSettingsMenuItems(onMenuCallback);

  return (
    <div className="SettingsPage content">
      <Row>
        <Col md="3">
          <Card className="SideBarNavigation">
            <SideMenu
              menuItems={menuItems}
              menuLabel="Settings"
              activeId={activeTab}
            />
          </Card>
        </Col>
        <Col md="9">
          <SettingCategoryHolder
            isActive={activeTab === settingsTabs.INFRASTRUCTURE}
            title="Infrastructure"
            category={settingsTabs.INFRASTRUCTURE}
            imgSrc="/illustrations/infrastructure-2.svg"
            desc="Infrastructure is the set of fundamental facilities and systems that support the sustainable functionality of households and firms."
            menuItems={infraMenuItems}
          />

          <SettingCategoryHolder
            isActive={activeTab === settingsTabs.DEVICES}
            title="IoT Devices"
            category={settingsTabs.DEVICES}
            imgSrc="/illustrations/iot-devices.svg"
            desc="Configure and maintain your beacon and gateways"
            menuItems={devicesMenuItems}
          />

          <SettingCategoryHolder
            isActive={activeTab === settingsTabs.SECURITY}
            title="Security"
            category={settingsTabs.SECURITY}
            imgSrc="/illustrations/security.svg"
            desc="Configure and maintain your security policies"
            menuItems={securityMenuItems}
          />

          <SettingCategoryHolder
            isActive={activeTab === settingsTabs.ORGANIZATION}
            title="Organization"
            category={settingsTabs.ORGANIZATION}
            imgSrc="/illustrations/office.svg"
            desc="Configure and maintain your organization"
            menuItems={organizationMenuItems}
          />

          <SettingCategoryHolder
            isActive={activeTab === settingsTabs.DIAGNOSIS}
            title="System Diagnosis"
            category={settingsTabs.DIAGNOSIS}
            imgSrc="/illustrations/diagnosis.svg"
            desc="Configure and maintain everything"
            menuItems={diagnosisMenuItems}
          />
        </Col>
      </Row>
    </div>
  );
}

export default SettingsPage;
