import React from "react";
import ReactDOM from "react-dom";
import { Fade } from "reactstrap";

const overlayStyle = {
  height: "100vh",
  width: "100vw",
  backgroundColor: "rgba(0,0,0,0.9)",
  backdropFilter: "blur(5px)",
  display: "grid",
  placeItems: "center",
  position: "fixed",
  top: 0,
};

function OverlayView({ children, fadeIn }) {
  return ReactDOM.createPortal(
    <Fade unmountOnExit mountOnEnter in={fadeIn}>
      <div className="OverlayView__overlay" style={overlayStyle}>
        {children}
      </div>
    </Fade>,
    document.getElementById("portal")
  );
}

export default OverlayView;
