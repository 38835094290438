import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import { FLOOR_URL } from "config/api";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import FloorPlanService from "services/FloorPlanService";
import SWALService from "services/SWALService";

const floorPlanService = new FloorPlanService();
function FloorPlanTable({ getData, data, loading, setTableConfig }) {
  const history = useHistory();

  const editFloorPlan = (floorId) =>
    history.push(`/admin/floor/edit/${floorId}`);

  const deleteFloorPlan = async (floorId) => {
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await floorPlanService
      .deleteFloorPlan(FLOOR_URL, floorId, true)
      .then((res) => {
        SWALService.showSuccess("Floor has been deleted.", "Deleted!");
      })
      .catch((err) => SWALService.showError(err));

    getData();
  };

  const floorPlanTableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Campus",
      accessor: "campus.name",
    },
    {
      Header: "Building",
      accessor: "building.name",
    },
    {
      Header: "Offset",
      accessor: "offset",
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editFloorPlan}
          deleteCallback={deleteFloorPlan}
        />
      ),
    },
  ];

  useEffect(() => {
    setTableConfig(floorPlanTableConfig);
  }, []);

  return (
    <BaseTable
      tableConfig={floorPlanTableConfig}
      data={data}
      loading={loading}
    />
  );
}

export default FloorPlanTable;
