import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import NameInput from "components/UserInputComponents/Inputs/TextInputs/NameInput";
import UniqueIdInput from "components/UserInputComponents/Inputs/TextInputs/UniqueIdInput";
import BeaconMacSelect from "components/UserInputComponents/Selects/BeaconMacSelect";
import GatewaySelect from "components/UserInputComponents/Selects/GatewaySelect";
import RoleSelect from "components/UserInputComponents/Selects/RoleSelect";
import React, { useState } from "react";
// import BeaconAssignList from "./BeaconAssignList";
import { Card, CardHeader, CardBody, Form } from "reactstrap";

function OccupancyModalFilter({ setParams }) {
  const [name, setName] = useState("");
  const [uUId, setUUId] = useState("");
  const [role, setRole] = useState("");
  const [beaconMacId, setBeaconMacId] = useState("");
  const [gateway, setGateway] = useState("");

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setParams({ name, uUId, role, beaconMacId, gateway });
  };

  return (
    <Card style={{ width: "25rem" }}>
      <CardHeader>Filters</CardHeader>
      <CardBody>
        <Form onSubmit={handleOnSubmit}>
          <NameInput value={name} setValue={setName} />
          <UniqueIdInput value={uUId} setValue={setUUId} />
          <RoleSelect value={role} setValue={setRole} />
          <BeaconMacSelect value={beaconMacId} setValue={setBeaconMacId} />
          <GatewaySelect
            value={gateway}
            setValue={setGateway}
            pickKey="macId"
          />
          <BaseButton>Apply</BaseButton>
        </Form>
      </CardBody>
    </Card>
  );
}

export default OccupancyModalFilter;
