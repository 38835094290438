import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, FormGroup } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import RoleSelect from "components/UserInputComponents/Selects/RoleSelect";
import GroupEntitySelect from "components/UserInputComponents/Selects/GroupEntitySelect";
import LateTimeInput from "components/UserInputComponents/TimePickers/LateTimeInput";
import { useSelector } from "react-redux";
import FromTimeInput from "components/UserInputComponents/TimePickers/FromTimeInput";
import EndTimeInput from "components/UserInputComponents/TimePickers/EndTimeInput";
import BaseTimeInput from "components/UserInputComponents/TimePickers/BaseTimeInput";

function LeftSearchPanel({ onSubmit }) {
  const now = new Date();
  const todayNight = DateTimeService.getMidnightTime(now);
  const todayMorning = DateTimeService.getMorningTime(now);

  const [startDate, setStartDate] = useState(todayMorning - 12);
  const [endDate, setEndDate] = useState(todayNight);
  const [fromTime, setFromTime] = useState(todayMorning);
  const [toTime, setToTime] = useState(todayNight);
  const [criticalTime, setCriticalTime] = useState(todayMorning);

  const [roles, setRoles] = useState([]);
  const [mappedGE, setMappedGE] = useState();

  const orgId = useSelector((state) => state.organization._id);

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <StartDatePicker date={startDate} setDate={setStartDate} />
        <EndDatePicker date={endDate} setDate={setEndDate} />
        <FromTimeInput setValue={setFromTime} />
        <EndTimeInput setValue={setToTime} />
        <BaseTimeInput
          value={criticalTime}
          setValue={setCriticalTime}
          label="Critical Time"
          placeholder="Select the time threshold to search"
        />
        <RoleSelect value={roles} setValue={setRoles} isMultiSelect />
        {roles?.some((role) => role === "STUDENT") && (
          <GroupEntitySelect setValue={setMappedGE} isMultiSelect />
        )}
        <br />
        <Button
          color="info"
          onClick={() =>
            onSubmit?.({
              organizationId: orgId,
              fromTime: new Date(startDate).toISOString(),
              toTime: new Date(endDate).toISOString(),
              criticalTimeThreshold: new Date(criticalTime).toISOString(),
              role: roles.join(","),
            })
          }
        >
          Get Reports
        </Button>
      </CardBody>
    </Card>
  );
}

export default LeftSearchPanel;

// ×
// TypeError: Invalid attempt to spread non-iterable instance.
// In order to be iterable, non-array objects must have a [Symbol.iterator]() method.
