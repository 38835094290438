import { VEHICLE_URL } from "config/api";
import ApiService from "./ApiService";

class VehicleService extends ApiService {
  constructor() {
    super(VEHICLE_URL);
  }
}

export default new VehicleService();
