import BaseService from "./BaseService";

export default class AlertService {
  constructor() {
    this.baseService = new BaseService();
  }

  getAllAlerts(url, params, headers) {
    return this.baseService
      .doGET(url, "GET", params, headers)
      .then((response) => {
        if (!!response.data.success) {
          return response.data.data;
        }
      });
  }
}
