import BaseTable from "components/Tables/BaseTable/BaseTable";
import React, { useEffect, useState } from "react";

import VisitorLogService from "services/VisitorLogService";
import SWALService from "services/SWALService";
import DateTimeService from "services/DateTimeService";
import { useHistory, useLocation } from "react-router-dom";

function VisitorLogTable({ params, tableData, tableConfig }) {
  const history = useHistory();
  const [visitorLogs, setVisitorLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const visitorTableConfig = [
    {
      Header: "Visitor Name",
      accessor: "visitor.name",
    },
    {
      Header: "Contact",
      accessor: ({ visitor }) => visitor?.attributes?.phoneNumbers?.[0] || "-",
    },
    {
      Header: "Visitee",
      accessor: "visitee.name",
    },
    {
      Header: "Purpose",
      accessor: "purposeOfVisit",
    },
    {
      Header: "In Time",
      accessor: ({ inTime }) => DateTimeService.getDateTimeString(inTime),
    },
    {
      Header: "Out Time",
      accessor: ({ outTime }) => DateTimeService.getDateTimeString(outTime),
    },
    {
      Header: "",
    },
  ];

  useEffect(() => {
    if (!params.fromTime) return;
    (async () => {
      setLoading(true);
      const { data } = await VisitorLogService.get(params).catch((err) =>
        SWALService.showError(err)
      );
      setLoading(false);

      if (!data) return;
      setVisitorLogs(data);
      tableData(data);
      tableConfig(visitorTableConfig);
    })();
  }, [params]);

  const handleRedirect = ({ visitor }) => {
    history.push({
      pathname: `/admin/all_subjects`,
      search: `?name=${visitor.name}&&role=${visitor.role}&&id=${visitor.uniqueId}`,
    });
  };

  return (
    <BaseTable
      data={visitorLogs}
      loading={loading}
      tableConfig={visitorTableConfig}
      onRowClick={handleRedirect}
    />
  );
}

export default VisitorLogTable;
