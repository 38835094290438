import ListCard from "components/ListCard/ListCard";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import React, { useState } from "react";
import { Badge, Card, CardBody, Col, Collapse, Row } from "reactstrap";
import OverlayView from "views/custom_components/OverlayView";
import "./SearchModal.scss";
import SubjectService from "../../services/SubjectService";

const subjectService = new SubjectService();
function SearchModal({ isOpen = false, close }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSearchData = (searchTerm) => {
    const params = { uniqueId: searchTerm };
    setLoading(true);
    subjectService
      .get(params)
      .then(({ data }) => {
        setResults(data);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <OverlayView fadeIn={isOpen}>
      <div
        style={{
          position: "fixed",
          right: "1rem",
          top: "1rem",
          zIndex: "99999999",
        }}
      >
        <Badge onClick={close} color="danger" style={{ cursor: "pointer" }}>
          <i className="tim-icons icon-simple-remove" />
        </Badge>
      </div>
      <Row className="SearchModal__wrap">
        <Col className="mx-auto" md={6}>
          <Card>
            <CardBody>
              <Row className="d-flex align-items-center justify-content-around">
                <div className="SearchModal__searchInputContainer">
                  <BaseTextInput
                    value={searchTerm}
                    setValue={setSearchTerm}
                    placeholder="search"
                  />
                </div>

                <IconButton
                  onClick={() => getSearchData(searchTerm)}
                  color="info"
                  icon="search"
                />
              </Row>
            </CardBody>
          </Card>

          <Collapse isOpen={results.length}>
            <ListCard
              data={results?.map((el) => ({
                title: el.name,
                subtitle: el.role,
                imgSrc: el.attributes.avatar,
              }))}
            />
          </Collapse>
        </Col>
      </Row>
    </OverlayView>
  );
}

export default SearchModal;
