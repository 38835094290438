import React from 'react';
import HolidayListing from './subComponents/HolidayListing';
import FileUploader from './subComponents/FileUploader';
import {Row,Col,Button} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import './holiday.css';

function ManageHolidays() {
    return(
        <div className="content">
            <Row>
                <FileUploader/>
            </Row>
            <Row>
                <HolidayListing/>
            </Row>
        </div>
    )
}

export default ManageHolidays;