import React, { useEffect, useState } from "react";
import FilterTableHolder from "components/Holders/FilterTableHolder/FilterTableHolder";
import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import toast from "react-hot-toast";
import PeriodicAttendanceSummaryTableConfig from "./sub-components/PeriodicAttendanceSummaryTableConfig";
import PeriodicAttendanceFilter from "./sub-components/PeriodicAttendanceFilter";
import PeriodicAttendanceDetailsTableConfig from "./sub-components/PeriodicAttendanceDetailsTableConfig";
import AttendanceService from "services/AttendanceService";

const cleanObj = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

function PeriodicAttendance() {
  const [queryParams, setQueryParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [periodicAttendance, setPeriodicAttendance] = useState([]);

  const fetchDailyAttendance = async () => {
    const params = {
      ...queryParams,
    };

    try {
      setLoading(true);

      const { data, success } = await AttendanceService.get(cleanObj(params));

      if (!success) return toast.error("Something went wrong");

      if (!data.length) {
        setPeriodicAttendance([]);
        return toast.error("No data to show");
      }

      setPeriodicAttendance(data);
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(queryParams).length <= 0) return;
    fetchDailyAttendance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  return (
    <FilterTableHolder>
      <PeriodicAttendanceFilter setFilterData={setQueryParams} />
      <TableHeader
        title={
          queryParams?.attendanceType === "PERIODIC_SUMMARY"
            ? "Periodic Attendance Summary"
            : "Periodic Attendance Detail"
        }
        onClickDownload={true}
        tableConfig={
          queryParams?.attendanceType === "PERIODIC_SUMMARY"
            ? PeriodicAttendanceSummaryTableConfig
            : PeriodicAttendanceDetailsTableConfig
        }
        tableData={periodicAttendance}
      />
      <BaseTable
        data={periodicAttendance}
        tableConfig={
          queryParams?.attendanceType === "PERIODIC_SUMMARY"
            ? PeriodicAttendanceSummaryTableConfig
            : PeriodicAttendanceDetailsTableConfig
        }
        loading={loading}
      />
    </FilterTableHolder>
  );
}

export default PeriodicAttendance;
