import FilterTableHolder from "components/Holders/FilterTableHolder/FilterTableHolder";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SWALService from "services/SWALService";
import TransitItineraryFilter from "./subComponent/TransitItineraryFilter";
import TransitItineraryTable from "./subComponent/TransitItineraryTable";
import TransitItineraryService from "services/TransitItineraryService";

function TransitItineraryListing() {
  const organizationId = useSelector((state) => state.organization._id);
  const defaultParams = { organizationId };
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transitItineraryParams, setTransitItineraryParams] = useState({});
  const [tableConfig, setTableConfig] = useState([]);

  useEffect(() => {
    fetchShift();
  }, [transitItineraryParams]);

  const fetchShift = async () => {
    setLoading(true);

    const params = {
      ...transitItineraryParams,
      ...defaultParams,
    };

    const { data } = await TransitItineraryService.get(params).catch((err) =>
      SWALService.showError(err),
    );

    if (data) setData(data);
    setLoading(false);
  };

  const addShift = () => history.push("/admin/transitItinerary/add");

  return (
    <FilterTableHolder>
      <TransitItineraryFilter
        setTransitItineraryParams={setTransitItineraryParams}
      />
      <TableHeader
        title="TransitItinerary"
        onClickAdd={addShift}
        onClickDownload={true}
        tableConfig={tableConfig}
        tableData={data}
      />
      <TransitItineraryTable
        data={data}
        getData={fetchShift}
        loading={loading}
        setTableConfig={setTableConfig}
      />
    </FilterTableHolder>
  );
}

export default TransitItineraryListing;
