import BeaconInput from "components/UserInputComponents/Inputs/TextInputs/BeaconInput";
import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import ConfigureBeaconStatusSelect from "components/UserInputComponents/Selects/ConfigureBeaoncStatusSelect";

function ConfigureBeaconFilter({ setParams }) {
  const [configurationStatus, setConfigurationStatus] = useState();
  const [macId, setMacId] = useState("");

  const handleClick = () => {
    const _params = {};

    console.log(configurationStatus, "rohit");

    if (macId) {
      _params.macIds = [macId];
    }

    if (configurationStatus) {
      _params.status = configurationStatus;
    } else if (configurationStatus === 0) {
      _params.status = configurationStatus;
    }
    console.log(_params, "rohit");
    setParams(_params);
  };

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <BeaconInput
          className="mb-4"
          setValue={setMacId}
          value={macId}
          label={"Mac ID"}
          placeholder={"Enter mac id"}
        />
        <ConfigureBeaconStatusSelect
          value={configurationStatus}
          setValue={setConfigurationStatus}
        />
        <Button color="info" onClick={handleClick}>
          Apply
        </Button>
      </CardBody>
    </Card>
  );
}

export default ConfigureBeaconFilter;
