import { ROUTE_URL } from "config/api";
import ApiService from "./ApiService";

class RouteService extends ApiService {
  constructor() {
    super(ROUTE_URL);
  }
}

export default new RouteService();
