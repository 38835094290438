import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ConfigureBeaconService from "services/ConfigureBeaconService";
import SWALService from "services/SWALService";
import BrandLoader from "views/custom_components/Loader";
import GatewaySelect from "../../../src/components/UserInputComponents/Selects/GatewaySelect";

function ConfigureBeaconsModal({ modalOpen, setModalOpen, getData }) {
  const [gatewayMac, setGatewayMac] = useState();
  const [loading, setLoading] = useState(false);

  const configureBeacons = async (gatewayMac) => {
    setModalOpen(false);
    SWALService.showSuccess(`Beacons set for reconfiguration`, "Configured!");
    const {
      data: { success, data },
    } = await ConfigureBeaconService.configureBeacon(gatewayMac).catch((err) =>
      SWALService.showError(err)
    );
    // getData();
  };

  return (
    <Modal isOpen={modalOpen}>
      <ModalHeader>Configure Beacons</ModalHeader>
      <ModalBody>
        {loading && <BrandLoader noOverlay />}
        {!loading && (
          <GatewaySelect
            value={gatewayMac}
            setValue={setGatewayMac}
            pickKey="macId"
          />
          // <BaseTextInput
          //   label="Gateway MAC"
          //   placeholder="Set Gateway MAC"
          //   type="text"
          //   value={gatewayMac}
          //   autoComplete="off"
          //   setValue={setGatewayMac}
          // />
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={loading}
          color="primary"
          onClick={() => configureBeacons(gatewayMac)}
        >
          Configure
        </Button>
        <Button disabled={loading} onClick={() => setModalOpen(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ConfigureBeaconsModal;
