const diagnosisMenuItems = [
  {
    title: "Gateway and Beacon Diagonosis",
    icon: "manage_history",
    target: "/admin/gateway-beacon/diagnosis",
  },
  {
    title: "Policy Diagonosis",
    icon: "fact_check",
    target: "/admin/policy/diagnosis",
  },
  {
    title: "Live Pings",
    icon: "network_ping",
    target: "/admin/diagnosis/pings-gateway",
  },
  {
    title: "Gateway State Diagonosis",
    icon: "manage_history",
    target: "/admin/diagnosis/gateway-state",
  },
  {
    title: "Area Presence Report",
    icon: "night_shelter",
    target: "/admin/diagnosis/area-presence",
  },
  {
    title: "Infra Presence Report",
    icon: "maps_home_work",
    target: "/admin/diagnosis/infra-presence",
  },
  {
    title: "Building Presence Report",
    icon: "apartment",
    target: "/admin/diagnosis/building-presence",
  },
];

export default diagnosisMenuItems;
