import React from "react";
import BaseDatePicker from "./BaseDatePicker";

function RegisteredOnDatePicker({
  date,
  setDate,
  endDate,
  isClearable,
  bottomHintText,
}) {
  return (
    <BaseDatePicker
      label="Registered On"
      placeholder="Select the registered data"
      date={date}
      setDate={setDate}
      maxDate={endDate}
      isClearable={isClearable}
      bottomHintText={bottomHintText}
    />
  );
}

export default RegisteredOnDatePicker;
