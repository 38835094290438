import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import VehicleService from "services/VehicleService";
import SWALService from "services/SWALService";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import VehicleShiftsModal from "./VehicleShiftsModal";

function VehicleTable({ getData, data, loading, setTableConfig }) {
  const history = useHistory();
  const [showShifts, setShowShifts] = useState(false);
  const [vehicleId, setVehicleId] = useState();

  const editVehicle = (vehicleId) =>
    history.push(`/admin/vehicle/edit/${vehicleId}`);

  const manageVehicle = (vehicleId) => {
    history.push(`/admin/vehicle/manage/${vehicleId}`);
  };

  const deleteVehicle = async (vehicleId) => {
    console.log(vehicleId);
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await VehicleService.delete(vehicleId).catch((err) =>
      SWALService.showError(err)
    );

    getData();

    SWALService.showSuccess("Vehicle has been deleted.", "Deleted!");
  };

  const manageShifts = (vehicleId) => {
    setVehicleId(vehicleId);
    setShowShifts(true);
  };

  const handleClose = () => {
    setShowShifts(false);
  };

  const vehicleTableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Registration",
      accessor: "regNo",
    },
    {
      Header: "Tracking ID",
      accessor: "traxDeviceId",
    },
    {
      Header: "Route",
      accessor: (row) => row?.route?.name,
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editVehicle}
          deleteCallback={deleteVehicle}
          manageCallback={manageVehicle}
          miscellaneousCallback={manageShifts}
          icon="add_to_photos"
        />
      ),
    },
  ];

  useEffect(() => {
    setTableConfig(vehicleTableConfig);
  }, []);

  return (
    <>
      <BaseTable
        tableConfig={vehicleTableConfig}
        data={data}
        loading={loading}
      />
      <Modal size={"lg"} isOpen={showShifts}>
        <ModalHeader>Manage Shifts</ModalHeader>
        <ModalBody>
          <VehicleShiftsModal vehicleId={vehicleId} close={handleClose} />
        </ModalBody>
      </Modal>
    </>
  );
}

export default VehicleTable;
