import React, { useEffect, useLayoutEffect, useState } from "react";
import OverlayView from "./OverlayView";

import { Card, CardBody, CardTitle, Row, Col, Table, Badge } from "reactstrap";
import GatewayService from "services/GatewayService";
import NoData from "./NoData";
import DateTimeService from "services/DateTimeService";
import GatewayModalFilter from "./GatewayModalFilter";
import { useSelector } from "react-redux";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import { useHistory } from "react-router-dom";

const GatewayTable = ({
  gateways,
  activeSubject,
  setSelect,
  setSearchName,
  setmacId,
  gatewayStatus,
  showNetworkPings,
  showLogsStatus,
}) => (
  <Table responsive>
    <thead className="text-primary">
      <tr>
        {/* <th className="text-center">#</th> */}
        <th className="text-left">Location</th>
        <th className="text-left">Name</th>
        <th className="text-left">Model</th>
        <th className="text-left">MAC</th>
        <th className="text-left">IP</th>
        <th className="text-left">Sniffed</th>
        <th className="text-left">Last Updated</th>
        <th className="text-left">Live Pings</th>
        <th className="text-left">Log Status</th>
      </tr>
    </thead>
    <tbody>
      {gateways?.map((gateway, index) => {
        if (gateway?.isOnline === gatewayStatus) {
          return (
            <tr
              key={index}
              style={{
                cursor: "pointer",
                borderRadius: "10px",
                backgroundColor:
                  activeSubject === gateway?.name
                    ? `rgba(255, 255, 255, 0.2)`
                    : null,
              }}
            >
              <td className="text-left">
                {gateway?.location?.floorPlanSection || "Unknown"}
              </td>
              <td className="text-left">{gateway?.name || "Unknown"}</td>
              <td className="text-left">{gateway?.model || "Unknown"}</td>
              <td className="text-left">{gateway?.macId || "Unknown"}</td>
              <td className="text-left">{gateway?.assignedIP || "Unknown"}</td>
              <td className="text-left">
                {gateway?.uniqueSniffedElements || "Unknown"}
              </td>
              <td className="text-left">
                {gateway?.lastPinged
                  ? DateTimeService.getDateTimeString(gateway?.lastPinged)
                  : "-"}
              </td>
              <td className="text-right">
                <IconButton
                  icon="network_ping"
                  color="success"
                  onClick={() => showNetworkPings(gateway?.macId)}
                  naked
                />
              </td>
              <td className="text-right">
                <IconButton
                  icon="insights"
                  color="primary"
                  onClick={() => showLogsStatus(gateway?.macId)}
                  naked
                />
              </td>
            </tr>
          );
        }
      })}
    </tbody>
  </Table>
);

function GatewayView({
  context = {},
  isOpen,
  close,
  title,
  status,
  dataLength,
}) {
  const organizationId = useSelector((state) => state.organization._id);
  const history = useHistory();

  const [activeSubject, setActiveSubject] = useState(null);
  const [gateways, setGateways] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterParams, setFilterParams] = useState();

  const [displayableGateways, setDisplayableGateways] = useState(null);

  const [searchName, setSearchName] = useState("");
  const [macId, setmacId] = useState("");

  const showNetworkPings = (_macId) => {
    history.push({
      pathname: "/admin/diagnosis/pings-gateway",
      search: `?macId=${_macId}`,
    });
  };

  const showLogsStatus = (_macId) => {
    history.push({
      pathname: "/admin/diagnosis/gateway-state",
      search: `?macId=${_macId}`,
    });
  };

  const cleanObj = (obj) =>
    Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

  const setData = (data) => {
    setGateways(data);
    setDisplayableGateways(data);
    console.log("gateways", data);
  };

  useLayoutEffect(() => {
    if (!isOpen) {
      setData(null);
      return;
    }
    setLoading(true);
    GatewayService.getGatewayStatus({ ...cleanObj(context), organizationId })
      .then(({ data: { data } }) => setData(data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [isOpen, context]);

  useEffect(() => {
    if (!!filterParams) {
      GatewayService.getGatewayStatus(filterParams)
        .then(({ data: { data } }) => setData(data))
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [filterParams]);

  if (!isOpen) return <></>;

  return (
    <OverlayView fadeIn={isOpen}>
      <div
        style={{
          position: "fixed",
          right: "1rem",
          top: "1rem",
        }}
      >
        <Badge onClick={close} color="danger" style={{ cursor: "pointer" }}>
          <i className="tim-icons icon-simple-remove" />
        </Badge>
      </div>
      {/* {loading && <Loader />} */}
      <div style={{ height: "100vh", width: "98vw" }}>
        <Row className="p-md-4" style={{ height: "100%", width: "100%" }}>
          <Col md="3">
            <GatewayModalFilter setGatewayParams={setFilterParams} />
          </Col>
          <Col md="9">
            <Card
              className="p-md-2"
              style={{ height: "100%", maxHeight: "93vh", overflowY: "scroll" }}
            >
              <CardTitle tag="h4" className="p-3">
                {title}
              </CardTitle>

              <CardBody>
                {!!gateways?.length && !!dataLength && (
                  <GatewayTable
                    gateways={displayableGateways}
                    activeSubject={activeSubject}
                    setSearchName={setSearchName}
                    setmacId={setmacId}
                    gatewayStatus={status}
                    showNetworkPings={showNetworkPings}
                    showLogsStatus={showLogsStatus}
                  />
                )}
                {(!gateways?.length || !dataLength) && (
                  <NoData
                    message={`No ${title} to show here`}
                    imgSrc="/illustrations/signals.svg"
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </OverlayView>
  );
}

export default GatewayView;
