import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, CardHeader, Row, Col } from "reactstrap";
import { Pie } from "react-chartjs-2";
import GatewayService from "services/GatewayService";
import SubjectService from "services/SubjectService";
// import BrandLoader from "../Loader";
import Table from "reactstrap/lib/Table";
import chartConfig from "config/chart";
import Switch from "react-bootstrap-switch";
import Units from "config/Units";
import { useSelector } from "react-redux";
import ENUMS from "config/enums";

import "./PresenceInsider.scss";
import pastelColors from "config/pastelColors";
import NoData from "views/custom_components/NoData";
import OccupancyView from "views/custom_components/OccupancyView";

const subjectService = new SubjectService();

function PresenceInsider({ orgId }) {
  const { organization } = useSelector((s) => s);

  const [occupancy, setOccupancy] = useState([]);

  const [occupancyModal, setOccupancyModal] = useState(false);

  const [capacity, setCapacity] = useState([]);

  const [activeRole, setActiveRole] = useState("");

  const [loading, setLoading] = useState(true);

  const [stats, setStats] = useState({});
  const [capacities, setCapacities] = useState({});

  const [showAbsence, setShowAbsence] = useState(true);

  const [chartData, setChartData] = useState({});

  // stub

  const roles = ENUMS.DomainSpecificUserRoles[organization.category] || [];

  const colors = pastelColors?.slice(0, roles?.length);

  // const [orgId, setOrgId] = useState(localStorage.getItem("Organization_ID"));

  useEffect(() => {
    getOrganizationCapacity();

    fetchPresence();
    const liveFetcher = setInterval(
      () => fetchPresence(),
      5 * 60 * Units.Time.second
    );
    return () => {
      clearInterval(liveFetcher);
    };
  }, []);

  const filterOccupancyForRole = (roles) =>
    occupancy.filter(({ subject }) => roles.includes(subject?.role));

  const filterCapacityForRole = (roles) =>
    capacity.filter((subject) => roles.includes(subject?.role));

  const getOrganizationCapacity = () => {
    subjectService
      .getAllSubjects(null, null, { organizationId: orgId })
      .then((res) => setCapacity(res))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    let statToShow = {};
    let capacities = {};
    roles.forEach((role) => {
      const occupancy = filterOccupancyForRole(role).length;
      const capacity = filterCapacityForRole(role).length;
      statToShow[role] = Math.abs(+showAbsence * capacity - occupancy);
      capacities[role] = capacity;
    });

    setCapacities(capacities);
    setStats(statToShow);

    const _chartData = chartConfig.createData(
      roles,
      roles.map((role) => statToShow[role] || 0),
      colors,
      "Presence"
    );
    console.log("asfsdf", _chartData);
    setChartData(_chartData);

    return () => {};
  }, [occupancy, capacity, showAbsence]);

  const getOrganizationOccupancy = () => {
    GatewayService.getGatewayOccupancy({ organizationId: orgId }, false)
      .then(({ data: res }) => setOccupancy(res.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const fetchPresence = () => {
    setLoading(true);
    getOrganizationOccupancy();
  };

  const getPercentage = (role) => {
    if (capacities[role] > 0) {
      const percentage = (stats[role] / capacities[role]) * 100 || 0;
      return `${Math.round(percentage)}%`;
    }
    return `0%`;
  };

  const showOccupancy = (role) => {
    setOccupancyModal(true);
    setActiveRole(role);
  };

  const closeOccupancy = () => {
    setOccupancyModal(false);
  };

  return (
    <>
      <Card className="card-chart card-chart-pie">
        <CardHeader className="PresenceInsider__header">
          <CardTitle tag="h4">
            Campus Occupancy overview
            <span className="float-right">
              <Switch
                value={!showAbsence}
                onChange={(e) => setShowAbsence(e.props.value)}
                onText="P"
                offText="A"
              />
            </span>
          </CardTitle>
        </CardHeader>

        {
          <CardBody className="PresenceInsider__body p-3 d-flex align-items-center ">
            <Row className="flex-grow-1">
              <Col md="6" className="d-flex align-items-center">
                <div className="chart-area">
                  <Pie data={chartData} options={chartConfig.options} />
                </div>
              </Col>
              <Col md="6" className="d-flex align-items-center">
                <Table responsive className="cursor">
                  <tbody>
                    {roles?.map((role, idx) => (
                      <tr
                        onClick={() => showOccupancy(role)}
                        style={{ cursor: "pointer" }}
                        key={idx}
                      >
                        <td>
                          <div
                            className="PresenceInsider__legends"
                            style={{
                              backgroundColor: colors[idx],
                            }}
                          ></div>
                        </td>
                        <td className="text-capitalize">
                          {role.replace(/_/g, " ").toLowerCase()}
                        </td>
                        <td className="text-right PresenceInsider__percentages">
                          {getPercentage(role)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        }
      </Card>

      {!!activeRole && (
        <OccupancyView
          activeRole={activeRole}
          isOpen={occupancyModal}
          close={closeOccupancy}
        />
      )}
    </>
  );
}

export default PresenceInsider;
