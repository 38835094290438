import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import SWALService from "services/SWALService";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import GatewayBeaconTable from "./subComponents/GatewayBeaconTable";
import GatewayBeaconFilter from "./subComponents/GatewayBeaconFilter";
import axios from "axios";
import { BASE_URL } from "config/api";

function ManageGatewayBeacon() {
  const [gatewayBeacon, setGatewayBeacon] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState();

  const organizationId = useSelector((state) => state.organization._id);
  const defaultParams = { organizationId };

  useEffect(() => {
    if (!!params) {
      getGatewayBeacon();
    }
    return;
  }, [params]);

  const getGatewayBeacon = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${BASE_URL}/diagnosis/beacons-detected-get`,
        { params }
      );
      setGatewayBeacon(data?.data);
    } catch (err) {
      SWALService.showError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md={3}>
          <GatewayBeaconFilter setParams={setParams} />
        </Col>
        <Col md={9}>
          <Card>
            <CardHeader>
              <TableHeader
                title={
                  "Gateway and Beacon Diagnosis " + (gatewayBeacon?.total ?? 0)
                }
              />
            </CardHeader>
            <CardBody>
              <GatewayBeaconTable
                getData={getGatewayBeacon}
                data={gatewayBeacon.pings}
                loading={loading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ManageGatewayBeacon;
