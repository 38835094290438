import React from "react";
import ReactDatetime from "react-datetime";
import { FormGroup, FormText, Label } from "reactstrap";

function BaseDatePicker({
  date,
  setDate,
  placeholder,
  label,
  isClearable,
  minDate,
  maxDate,
  bottomHintText,
}) {
  return (
    <div className="BaseDatePicker">
      <FormGroup className="mb-4">
        <Label>{label}</Label>
        <ReactDatetime
          value={date}
          onChange={setDate}
          inputProps={{
            className: "form-control",
            placeholder: placeholder,
          }}
          dateFormat={"DD/MM/yyyy"}
          timeFormat={false}
          closeOnSelect={true}
          minDate={minDate}
          maxDate={maxDate}
          isClearable={isClearable}
        />
        <FormText color="muted">{bottomHintText}</FormText>
      </FormGroup>
    </div>
  );
}

export default BaseDatePicker;
