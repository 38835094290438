import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";
import AuditTrailLeftSearchPanel from "./AuditTrailLeftSearchPanel";
import BaseTable from "components/Tables/BaseTable/BaseTable";
import { useSelector } from "react-redux";
import AuditTrailService from "services/AuditTrailService";
import DateTimeService from "services/DateTimeService";
import TableHeader from "components/Tables/TableHeader/TableHeader";

function AuditTrailListing() {
  const organizationId = useSelector((state) => state.organization._id);
  const defaultParams = { organizationId };
  const [auditParams, setAuditParams] = useState({});
  const [auditTrail, setAuditTrail] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAuditTrail = () => {
    setLoading(true);
    AuditTrailService.get({ ...auditParams, ...defaultParams })
      .then(({ data }) => setAuditTrail(data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (Object.keys(auditParams).length === 0) return;
    getAuditTrail();
  }, [auditParams]);

  const AuditTrailTableConfig = [
    { Header: "Entity", accessor: "entity" },
    { Header: "Action", accessor: "action" },
    { Header: "Description", accessor: "description" },
    {
      Header: "Performed by",
      accessor: ({ user }) =>
        `${user?.firstName || ""} ${user?.lastName || ""}`,
    },
    {
      Header: "Date/Time",

      accessor: ({ createdOn }) => DateTimeService.getDateTimeString(createdOn),
    },
  ];

  return (
    <div className="content">
      <Row>
        <Col md={3}>
          <AuditTrailLeftSearchPanel setAuditParams={setAuditParams} />
        </Col>
        <Col md={9}>
          <Card>
            <CardHeader>
              <TableHeader
                title="Audit Trail"
                onClickDownload={true}
                tableConfig={AuditTrailTableConfig}
                tableData={auditTrail}
              />
            </CardHeader>
            <CardBody>
              <BaseTable
                tableConfig={AuditTrailTableConfig}
                data={auditTrail}
                loading={loading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default AuditTrailListing;
