import { Card, Col, Row, Button, CardTitle, CardBody } from "reactstrap";
import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  ImageOverlay,
  Rectangle,
  FeatureGroup,
  Polygon,
} from "react-leaflet";
import GeoJSON from "geojson";
import { EditControl } from "react-leaflet-draw";
import Loader from "views/custom_components/Loader";

import "./EditMaps.scss";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";

import LeafletMapsService from "../../../../services/LeafletMapsService";
import LeftSideBar from "./sub-components/LeftSideBar";

import pastelColors from "../../../../config/pastelColors";
import { FLOOR_URL } from "config/api";
import FloorPlanService from "services/FloorPlanService";
import { useParams } from "react-router";
import LeafletToolTip from "../custom-components/LeafletToolTip";

const floorPlanService = new FloorPlanService();

function EditMaps() {
  const ref = useRef();
  const leafletMapsService = new LeafletMapsService();
  const [showRectangle, setshowRectangle] = useState(false);
  const [plotShapes, setPlotShapes] = useState([]);
  const [shapedetails, setShapeDetails] = useState(false);
  const [shapeDataGeoJSON, setshapeDataGeoJSON] = useState();
  const [Temp_geoJSON, setTemp_geoJSON] = useState();
  const [ShapeLabel, setShapeLabel] = useState([]);

  const [loading, setLoading] = useState(false);

  const [floorSection, setFloorSection] = useState({});
  const [floorPlan, setFloorPlan] = useState();

  const [lastDrawnLayer, setLastDrawnLayer] = useState(null);
  const [fgLayer, setFgLayer] = useState(null);

  const paramId = useParams()?.id;

  const bounds = [
    [0, 0],
    [500, 500],
  ];

  useEffect(() => {
    leafletMapsService
      .getMapPlots(paramId)
      .then(({ data: { data: floorPlanSections } }) => {
        setPlotShapes(
          floorPlanSections.map((el) => el.floorPlanSectionPlot).filter(Boolean)
        );
        console.log("rohit", ref.current.clientHeight, ref.current.clientWidth);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const shapePathOptions = () => {
    const newColor =
      pastelColors[Math.floor(Math.random() * pastelColors.length)];

    return {
      color: newColor,
      fillColor: newColor,
      fillOpacity: 0.3,
    };
  };

  const shapeData = {
    id: 0,
    label: "",
    geo: {
      type: "",
      coordinates: [],
    },
  };

  const _onCreate = (e) => {
    setLastDrawnLayer(e.layer);
    setShapeDetails(true);
    const { layerType, layer } = e;
    const { _leaflet_id } = layer;
    shapeData.id = _leaflet_id;
    shapeData.geo.type = layerType;
    shapeData.geo.coordinates = layer.getLatLngs()[0];
    setTemp_geoJSON(GeoJSON.parse(shapeData, { GeoJSON: "geo" }));
    setshapeDataGeoJSON(GeoJSON.parse(shapeData, { GeoJSON: "geo" }));
  };

  const _onEdited = (e) => console.log(e);
  const _onDeleted = (e) => console.log(e);

  useEffect(() => {
    setLoading(true);
    floorPlanService
      .getFloorPlanByID(FLOOR_URL, { id: paramId })
      .then((data) => setFloorPlan(data[0]))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const handleSave = (shapeDataGeoJSON) => {
    setLoading(true);
    removeCreatedLayer();
    const payload = {
      ...shapeDataGeoJSON,
      properties: {
        ...shapeDataGeoJSON.properties,
        floorSectionId: floorSection.value,
        label: floorSection.label,
      },
    };

    leafletMapsService
      .patchFloorSectionPlot(payload, floorSection.value)
      .then((res) => {
        handleNewEntry(payload?.properties?.floorSectionId, payload);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const removePlotFromExisting = (floorSectionId) =>
    (!!plotShapes.length &&
      plotShapes?.filter(
        (el) => el?.properties?.floorSectionId !== floorSectionId
      )) ||
    [];

  const deletePlot = (floorSectionId) => {
    setPlotShapes(removePlotFromExisting(floorSectionId));
    leafletMapsService.patchFloorSectionPlot({}, floorSectionId);
  };

  const handleNewEntry = (floorSectionId, floorPlanSectionPlot) => {
    setPlotShapes([
      floorPlanSectionPlot,
      ...removePlotFromExisting(floorSectionId),
    ]);
    setShapeDetails(false);
  };

  const removeCreatedLayer = () => {
    setShapeDetails(!shapedetails);
    setTemp_geoJSON(null);

    fgLayer.removeLayer(lastDrawnLayer);
    setShapeDetails(false);
  };

  const onCancel = () => removeCreatedLayer();

  const onFeatureGroupReady = (reactFGref) => {
    setFgLayer(reactFGref);
  };

  return (
    <div className="content">
      {/* {loading && <Loader />} */}
      <div ref={ref} className="EditMaps">
        <Row>
          <Col md="3">
            <Card className="EditMaps__header">
              <div className="EditMaps__headerLabel">
                <h3>
                  <Row>
                    <Col md={10}>Floor Sections</Col>
                  </Row>
                </h3>
              </div>

              <LeftSideBar
                floorPlanId={paramId}
                onTileClick={setFloorSection}
                deletePlot={deletePlot}
              />
            </Card>
          </Col>

          <Col md="9" style={{ height: "85vh" }}>
            <Card style={{ overflow: "hidden", height: "100%" }}>
              <div className="EditMaps__mainContainer">
                <MapContainer
                  className="MapContainer"
                  style={{ position: "absolute" }}
                  center={{ lat: 50, lng: 250 }}
                  zoom={2}
                >
                  <FeatureGroup
                    ref={(featureGroupRef) => {
                      onFeatureGroupReady(featureGroupRef);
                    }}
                  >
                    {shapedetails ? (
                      <div className="EditMaps__popup">
                        <Card className="p-4">
                          {floorSection?.label ? (
                            <>
                              <CardTitle tag="h4">
                                <center>{`Save area for ${floorSection?.label}?`}</center>
                              </CardTitle>
                              <Button
                                color="primary"
                                onClick={() => handleSave(shapeDataGeoJSON)}
                                className="m-1"
                              >
                                Save
                              </Button>
                            </>
                          ) : (
                            <CardTitle tag="h4">
                              Please select a{" "}
                              <i>
                                <b>Floor Areas</b>
                              </i>{" "}
                              to continue
                            </CardTitle>
                          )}
                          <Button
                            color="secondary"
                            className="m-1"
                            onClick={() => onCancel()}
                          >
                            Cancel
                          </Button>
                        </Card>
                      </div>
                    ) : (
                      <></>
                    )}
                    <EditControl
                      position="topright"
                      onCreated={_onCreate}
                      onEdited={_onEdited}
                      onDeleted={_onDeleted}
                      draw={{
                        rectangle: true,
                        polyline: true,
                        circle: false,
                        circlemarker: false,
                        marker: false,
                      }}
                    />
                    {!!plotShapes &&
                      !!plotShapes?.length &&
                      plotShapes?.map((plotShape, idx) => {
                        return plotShape.geometry?.type?.toLowerCase() ===
                          "rectangle" ? (
                          <Rectangle
                            key={idx}
                            pathOptions={shapePathOptions()}
                            bounds={plotShape?.geometry?.coordinates}
                          >
                            <LeafletToolTip
                              key={idx}
                              label={plotShape?.properties?.label}
                            />
                          </Rectangle>
                        ) : (
                          <Polygon
                            key={idx}
                            pathOptions={shapePathOptions()}
                            positions={plotShape?.geometry?.coordinates}
                          >
                            <LeafletToolTip
                              key={idx}
                              label={plotShape?.properties?.label}
                            />
                          </Polygon>
                        );
                      })}
                  </FeatureGroup>
                  <ImageOverlay
                    className="object-contain my-4 relative OriginalMap"
                    url={floorPlan?.imgUrl || ""}
                    bounds={bounds}
                  />
                </MapContainer>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default EditMaps;
