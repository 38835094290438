import Divider from "components/Divider/Divider";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import React from "react";
import { Fade, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";
import "./FormHolder.scss";

export const FormHeaderIcon = ({ onClick, color, icon }) => <IconButton icon={icon} onClick={onClick} color={color || "info"} outlined />;

function FormHolder({ children, imgSrc, formDescription, formHeader, CardFooterComponent, formActions }) {
  return (
    <Fade in={true}>
      <Row className="FormHolder">
        <Col className="ml-auto mr-auto px-5" md="10">
          <Card>
            <CardHeader className="d-flex justify-content-between">
              <CardTitle tag="h3">{formHeader}</CardTitle>
              <div>{formActions}</div>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4" className="px-5 py-5">
                  <div className="FormHolder__infoContainer">
                    <img className="FormHolder__img" alt="..." src={imgSrc} />
                    <p className="FormHolder__desc text-muted">{formDescription}</p>
                  </div>
                  <Divider orientation="vertical" intensity="dark"></Divider>
                </Col>
                <Col md="8" className=" px-5">
                  {children}
                </Col>
              </Row>
            </CardBody>
            <CardFooter>{CardFooterComponent}</CardFooter>
          </Card>
        </Col>
      </Row>
    </Fade>
  );
}

export default FormHolder;
