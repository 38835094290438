import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import NameInput from "components/UserInputComponents/Inputs/TextInputs/NameInput";
import DaysSelect from "components/UserInputComponents/Selects/DaysSelect";
import MatterSelect from "components/UserInputComponents/Selects/MatterSelect";
import TagSelect from "components/UserInputComponents/Selects/TagSelect";
import TeacherSelect from "components/UserInputComponents/Selects/TeacherSelect";
import FromTimePicker from "components/UserInputComponents/TimePickers/FromTimePicker";
import ToTimePicker from "components/UserInputComponents/TimePickers/ToTimePicker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Col, Form, Row } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import ScheduleService from "services/ScheduleService";
import Swal from "sweetalert2";
import CloseButton from "views/custom_components/AlertModal2/sub-components/CloseButton/CloseButton";
import BrandLoader from "views/custom_components/Loader";
import OverlayView from "views/custom_components/OverlayView";

function ScheduleCalenderCreateEvent({ loading, isOpen, close, slotInfo }) {
  const orgId = useSelector((state) => state.organization._id);
  const [name, setName] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [groups, setGroups] = useState([]);
  const [teacher, setTeacher] = useState("");
  const [associateIncharges, setAssociateIncharges] = useState([]);
  const [matter, setMatter] = useState("");
  const [days, setDays] = useState([]);

  useEffect(() => {
    if (!Object.keys(slotInfo).length) return;
    const { start, end } = slotInfo;

    setFromTime(
      DateTimeService.getDateTimeFromTimeString(
        new Date(start).toLocaleTimeString("it-IT")
      )
    );
    setToTime(
      DateTimeService.getDateTimeFromTimeString(
        new Date(end).toLocaleTimeString("it-IT")
      )
    );
    //   for sundays
    const dayOfWeek = moment(start).day();
    if (dayOfWeek === 0) return setDays([moment(start).day() + 7]);
    setDays([moment(start).day()]);
  }, [slotInfo]);

  const handleSchedule = (e) => {
    e.preventDefault();
    try {
      const payload = {
        organization: orgId,
        name: name,
        fromTime: DateTimeService.get24TimeStringUTC(fromTime),
        toTime: DateTimeService.get24TimeStringUTC(toTime),
        groups: groups,
        incharge: teacher,
        daysOfWeek: days,
        matter: matter,
        associateIncharges,
      };

      ScheduleService.post(payload)
        .then(() => {
          close();
          Swal.fire({
            icon: "success",
            title: "Schedule Create",
            text: "Schedule created successfully",
          });
        })
        .catch((e) => {
          if (!e.data) return toast.error(e?.message);
          console.log(e.data);
          Swal.fire({
            icon: "error",
            title: e.message,
            html:
              `Similar Schedules:</br>
                </br>
                <ul>
                ` +
              e.data
                .map(
                  (el) =>
                    `
                      <li>
                      ${el.name} (${el.fromTime} - ${
                      el.toTime
                    }) on ${DateTimeService.getDaysFromArray(el.daysOfWeek)}
                      </li>
                      `
                )
                .join("</br>") +
              `
                  </ul>
                  `,
          });
        });
    } catch (error) {
      toast.error(error || "Something went wrong");
    }
  };

  return (
    <OverlayView fadeIn={isOpen}>
      {loading && <BrandLoader />}
      <CloseButton close={close} />
      <Card className="w-50">
        <CardTitle tag="h3" className="p-3">
          <center>Add New Event</center>
        </CardTitle>
        <CardBody>
          {!loading && (
            <Form onSubmit={handleSchedule}>
              <NameInput value={name} setValue={setName} />
              <Row>
                <Col>
                  <FromTimePicker date={fromTime} setDate={setFromTime} />
                </Col>
                <Col>
                  <ToTimePicker date={toTime} setDate={setToTime} />
                </Col>
              </Row>
              <TagSelect
                value={groups}
                setValue={setGroups}
                label="Groups"
                isMultiSelect
              />
              <Row>
                <Col>
                  <TeacherSelect
                    setValue={setTeacher}
                    label="Teacher Incharge"
                    value={teacher}
                    isMultiSelect={false}
                  />
                </Col>
                <Col>
                  <TeacherSelect
                    setValue={setAssociateIncharges}
                    label="Associate Incharges"
                    value={associateIncharges}
                    isMultiSelect={true}
                  />
                </Col>
              </Row>
              <DaysSelect setValue={setDays} value={days} />
              <MatterSelect value={matter} setValue={setMatter} />
              <BaseButton color="info">Submit</BaseButton>
            </Form>
          )}
        </CardBody>
      </Card>
    </OverlayView>
  );
}

export default ScheduleCalenderCreateEvent;
