import React, { useEffect, useState } from "react";
import "./OccupancyTimeline.scss";
import "./AssetTimeline.scss";
import SubjectService from "services/SubjectService";
import Timeline from "./Timeline/Timeline";
import DateTimeService from "services/DateTimeService";
import toast from "react-hot-toast";

const subjectService = new SubjectService();

function OccupancyTimeline({ date, subjectId, demoData, maxHeight }) {
  // const [date, setDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [avatar, setAvatar] = useState("");
  const [gender, setGender] = useState("");

  const fetchTimeline = () => {
    if (!date) {
      setLoading(true);
      subjectService
        .getSubjectTimeline(subjectId)
        .then(({ data: { data } }) => {
          setData(data);
        })
        .catch((err) => toast.error("Something went wrong"))
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      const fromTime = new Date(DateTimeService.getMorningTime(date));
      const toTime = new Date(DateTimeService.getMidnightTime(date));
      subjectService
        .getSubjectTimeline(subjectId, fromTime, toTime)
        .then(({ data: { data } }) => {
          setData(data);
        })
        .catch((err) => toast.error(err))
        .finally(() => setLoading(false));
    }
  };

  const fetchSubject = () => {
    subjectService
      .get({ id: subjectId })
      .then(({ data }) => {
        const { name, uniqueId, attributes } = data[0];
        setName(name);
        setUniqueId(uniqueId);
        setAvatar(attributes.avatar);
        setGender(attributes.gender);
      })
      .catch((e) => toast.error(e));
  };

  useEffect(() => {
    if (!subjectId) return;
    fetchTimeline();
    fetchSubject();
  }, [subjectId]);

  return (
    <Timeline
      data={data.length > 0 ? data : demoData}
      loading={loading}
      noDataMsg={"No timeline data to show"}
      header={"Movement Timeline"}
      maxHeight={maxHeight}
      assetDetails={{ name, uniqueId, avatar, gender }}
    />
  );
}

export default OccupancyTimeline;
