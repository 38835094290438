import React from "react";
import BaseTextInput from "./BaseTextInput";

function BeaconInput({ value, setValue, label, placeholder }) {
  return (
    <>
      <BaseTextInput
        label={label || "Beacon"}
        placeholder={placeholder || "Enter beacon name"}
        name={"beacon"}
        value={value}
        setValue={setValue}
      />
    </>
  );
}

export default BeaconInput;
