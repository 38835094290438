import React, { useEffect, useState } from "react";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardHeader from "reactstrap/lib/CardHeader";
import CardTitle from "reactstrap/lib/CardTitle";
import "./RecentAnnouncements.scss";
import Table from "reactstrap/lib/Table";

import "./RecentAnnouncements.scss";
import Icon from "components/Icon/Icon";
import EventAnnouncementBlock from "../WidgetFragments/EventAnnouncementBlock";
import BaseService from "services/BaseService";
import { useSelector } from "react-redux";
import UtilsService from "services/UtilsService";
import BrandLoader from "views/custom_components/Loader";
import { useHistory } from "react-router-dom";
import CommunicationService from "services/CommunicationService";
import NoData from "views/custom_components/NoData";

const utilsService = new UtilsService();

function RecentAnnouncements() {
  const history = useHistory();

  const orgId = useSelector((state) => state.organization?._id);
  const [loading, setLoading] = useState(false);
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    setLoading(true);

    const params = { organizationId: orgId };

    CommunicationService.get(params)
      .then((response) => {
        setAnnouncements(response.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Card
      className="RecentAnnouncements"
      onClick={() => history.push("/admin/communication/view")}
    >
      <CardHeader>
        <CardTitle tag="h4">Recent Announcements</CardTitle>
      </CardHeader>
      {!loading && (
        <CardBody>
          {announcements?.map((msg, index) => {
            if (index > 4) return <></>;
            return (
              <EventAnnouncementBlock
                isApproved={msg.isApproved}
                title={msg.title}
                date={utilsService.convertMomentTimestamp(msg.createdOn)}
              />
            );
          })}
          {announcements.length === 0 && (
            <NoData message={"Recent announcements will appear here"} />
          )}
        </CardBody>
      )}
    </Card>
  );
}

export default RecentAnnouncements;
