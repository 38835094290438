import TableHeader from "components/Tables/TableHeader/TableHeader";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import PolicyService from "services/PolicyService";
import SWALService from "services/SWALService";
import PolicyFilters from "./PolicyFilters";
import PolicyTable from "./PolicyTable";

function PolicyListing() {
  const organizationId = useSelector((state) => state.organization._id);
  const defaultParams = { organizationId };

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [policyData, setPolicyData] = useState([]);
  const [policyParams, setPolicyParams] = useState({});
  const [tableConfig, setTableConfig] = useState([]);

  useEffect(() => {
    getPolicy();
  }, [policyParams]);

  const getPolicy = async () => {
    setLoading(true);

    const params = {
      ...policyParams,
      ...defaultParams,
    };

    const { data } = await PolicyService.get(params).catch((err) =>
      SWALService.showError(err)
    );

    console.log("policy", { data });
    if (data) setPolicyData(data);
    setLoading(false);
  };

  const addPolicy = () => history.push(`/admin/policy/add`);
  return (
    <div className="content">
      <Row>
        <Col md={3}>
          <PolicyFilters setPolicyParams={setPolicyParams} />
        </Col>
        <Col md={9}>
          <Card>
            <CardHeader>
              <TableHeader
                title="Policies"
                onClickAdd={addPolicy}
                onClickDownload={true}
                tableConfig={tableConfig}
                tableData={policyData}
              />
            </CardHeader>
            <CardBody>
              <PolicyTable
                data={policyData}
                getData={getPolicy}
                loading={loading}
                setTableConfig={setTableConfig}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PolicyListing;
