import React, { useEffect, useState } from "react";

import CloseButton from "./sub-components/CloseButton/CloseButton";
import SubjectInfo from "./sub-components/SubjectInfo/SubjectInfo";

import { Card, CardBody, CardTitle, Row, Col, Input } from "reactstrap";
import OverlayView from "../OverlayView";

import "./AlertModal.scss";
import ActionPanel from "./sub-components/ActionPanel/ActionPanel";
import AlertSection from "./sub-components/AlertSection/AlertSection";
import { EVENT_URL } from "config/api";
import BaseService from "services/BaseService";
import UtilsService from "services/UtilsService";
import Loader from "../Loader";
import AlertBell from "./sub-components/AlertBell/AlertBell";

import AlertMapView from "./AlertMapView";
import Avatar from "../Avatar/Avatar";
import ListCard from "components/ListCard/ListCard";
const baseService = new BaseService();
const utilsService = new UtilsService();

function AlertModal({
  isOpen,
  close,
  eventId = "63807fc0a0d6d6000862b94d",
  fromSocket,
}) {
  const [eventData, setAlertData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    if (!isOpen) return setAlertData(null);
  }, [isOpen]);

  useEffect(() => {
    if (!eventId) return;
    setLoading(true);

    setTimeout(() => {
      baseService
        .doGET(
          EVENT_URL,
          "GET",
          { id: eventId, hydrate: "asset,area,policy" },
          true
        )
        .then((d) => {
          const {
            data: { data },
          } = d;

          const eventData = data[0];

          // if (!eventData) return close?.();
          eventData["createdOn"] =
            utilsService.getEightHoursPreviousTime(eventData);
          setAlertData(eventData);
          console.log("fsfs", eventData);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setShowData(true);
          setLoading(false);
        });
    }, 2000);
  }, [eventId, isOpen]);

  const assetImageBackup =
    "https://parchai-ble-floorplans.s3.ap-south-1.amazonaws.com/public/assetProfilePic/shemford-2022-2023-1004.jpg";

  if (!isOpen) return <></>;
  return (
    <OverlayView fadeIn={isOpen}>
      {loading && <Loader />}
      <CloseButton close={close} />
      <Card
        className={
          "AlertModal__coverModal" +
          (fromSocket ? " AlertModal__coverModal--alert" : "")
        }
      >
        {/* <CardTitle tag="h3" className="p-3">
          <center>Alert</center>
        </CardTitle> */}
        <CardBody>
          <Row>
            <AlertBell
              animate={fromSocket}
              label={`${eventData?.type?.replace("_", " ") || ""} Alert`}
            />
          </Row>
          <Row className="AlertModal__content">
            <Col
              md="4"
              className="mx-2 h-100 d-flex flex-column align-items-center justify-content-center border-right position-relative"
            >
              <div className="AlertModal__avatar">
                {/* <img src={eventData?.asset?.attributes?.avatar} alt="" /> */}
                <Avatar
                  height={34}
                  src={eventData?.asset?.attributes?.avatar || ""}
                  alt={eventData?.asset?.name || "asset image"}
                  gender={
                    eventData?.asset?.attributes?.gender || "asset gender"
                  }
                />
              </div>
              <h2>{eventData?.asset?.name || "Aniali Arora"}</h2>
              <h4>
                {eventData?.asset?.primaryGroup?.name || "CLASS 1A"}{" "}
                <span className="text-muted">|</span>{" "}
                {eventData?.asset?.attributes?.roll || ""}
              </h4>
              <br />
              <h4>
                {eventData?.asset?.role || "STUDENT"}{" "}
                <span className="text-muted">|</span>{" "}
                {eventData?.asset?.uniqueId || "ST-2022/001"}
              </h4>
              <br />
              <p>{eventData?.asset?.attributes?.phoneNumbers?.[0]}</p>
            </Col>

            <Col>
              <Row className="border-bottom align-items-center pb-4">
                <Col className="mx-2 d-flex flex-column justify-content-center">
                  <div className="AlertModal__floorSection">
                    {/* {!!eventData && (
                      <AlertMapView floorPlanId={eventData?.area?._id} />
                    )} */}
                    <img
                      alt="floorplan image"
                      src="https://parchai-ble-floorplans.s3.ap-south-1.amazonaws.com/public/floorImages/office-layout-plan.png"
                    />
                  </div>
                </Col>
                <Col className="mx-2 d-flex align-items-start flex-column justify-content-end AlertModal__floorSectionInfo">
                  <h2>
                    {eventData?.policy?.["name"]?.replaceAll("_", " ") || "SOS"}
                  </h2>

                  <h5 className="mb-1">Occured in</h5>
                  <h3>{`${
                    eventData?.area?.floorPlanSectionName || "Lobby"
                  }`}</h3>
                  <h3>
                    {fromSocket
                      ? "Now"
                      : "at " +
                        new Date(
                          eventData?.occuranceTime || new Date()
                        )?.toLocaleString()}
                  </h3>
                </Col>
              </Row>
              <Row className="d-flex pl-3 my-3">
                <ListCard
                  cardTitle="Actions taken"
                  data={eventData?.data?.logs?.map(
                    ({ status, actionName }) => ({
                      title: actionName,

                      icon: status === "fulfilled" ? "check_circle" : "cancel",
                      iconColor: status === "fulfilled" ? "success" : "danger",
                    })
                  )}
                  noDataMessage={"No actions have been taken by the system"}
                />
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </OverlayView>
  );
}

export default AlertModal;
