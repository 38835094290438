import FormHolder from "components/Holders/FormHolder/FormHolder";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import Forms from "config/copies/forms";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Collapse, Form, FormGroup, Label } from "reactstrap";

import ENUMS from "config/enums";
import BaseSelect from "components/UserInputComponents/Selects/BaseSelect";
import ActionService from "services/ActionService";
import SWALService from "services/SWALService";
import BaseMultiTextInput from "components/UserInputComponents/MultiTextInput/BaseMultiTextInput";
import Switch from "react-bootstrap-switch";
import Validate from "utils/validators";
import UserSelect from "components/UserInputComponents/Selects/UserSelect";
import BaseTextAreaInput from "components/UserInputComponents/Inputs/TextInputs/BestTextAreaInput";

const HTTP_METHODS = ["GET", "POST", "PUT", "DELETE"];

const HTTP_METHOD_OPTIONS = HTTP_METHODS.map((HTTP_METHOD) => ({
  value: HTTP_METHOD,
  label: HTTP_METHOD,
}));

const { ActionTypes, ActionScopes } = ENUMS;

const actionOptions = ActionTypes?.map((el) => ({
  value: el,
  label: el,
}));

// const ActionScopeOptions = ActionScopes?.map((el) => ({
//   value: el,
//   label: el,
// }));

function ActionForm() {
  const history = useHistory();

  const { _id: organization } = useSelector((s) => s.organization);

  const { actionId } = useParams();

  const buttonText = actionId ? "Save Changes" : "Submit";

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState(true);
  const [scope, setScope] = useState("LIST");
  // const [groups, setGroups] = useState();
  const [messageSubject, setMessageSubject] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [url, setUrl] = useState("");
  const [httpMethod, setHttpMethod] = useState("");
  const [params, setParams] = useState("");
  const [httpHeaders, setHttpHeaders] = useState("");
  const [httpBody, setHttpBody] = useState("");
  const [users, setUsers] = useState([]);
  const [phoneRecipientList, setPhoneRecipientList] = useState([]);
  const [emailRecipientList, setEmailRecipientList] = useState([]);
  const [payload, setPayload] = useState({});

  const getAndSetActions = async () => {
    if (!actionId) return;
    const { data } = await ActionService.getById(actionId);

    if (!data) return;

    const _type = data.type;

    setName(data.name);
    setType(_type);
    setStatus(data.status);

    const { context } = data;

    if (!context) return;
    const { scopes: scope, recipients, template } = context;

    setScope(scope);

    if (_type === "EMAIL") setEmailRecipientList(recipients);
    if (_type === "SMS") setPhoneRecipientList(recipients);

    if (["PUSH_NOTIFICATION", "WEBSOCKET"].includes(_type))
      setUsers(recipients);

    setMessageSubject(template.subject);
    setMessageBody(template.body);
    setUrl(context.url);
    setHttpMethod(context.method);
    setHttpHeaders(context.headers);
    setParams(context.params);
    setHttpBody(context.payload);
  };

  useLayoutEffect(() => {
    getAndSetActions();
  }, [actionId]);

  const handleAction = async (payload) => {
    console.log("Action oayload", payload);

    const { message, success } = await (actionId
      ? ActionService.put(actionId, payload)
      : ActionService.post(payload)
    ).catch((err) => SWALService.showError(err?.message || err));

    if (!success) return;

    history.goBack();
    SWALService.showSuccess(message);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const messageTemplate = {
      subject: messageSubject,
      body: messageBody,
    };

    const recipients = [];

    if (type === "EMAIL") recipients.push(...emailRecipientList);

    if (type === "SMS") recipients.push(...phoneRecipientList);

    if (["PUSH_NOTIFICATION", "WEBSOCKET"].includes(type))
      recipients.push(...users);

    const actionContext = {
      scopes: scope,
      recipients,
      template: messageTemplate,
      endpoint: url,
      method: httpMethod,
      headers: httpHeaders ? JSON.stringify(JSON.parse(httpHeaders)) : "{}",
      params: params ? JSON.stringify(JSON.parse(params)) : "{}",
      body: httpBody ? JSON.stringify(JSON.parse(httpBody)) : "{}",
    };

    if (type === "HTTP_REQUEST") actionContext.scopes = "DEFAULT";

    const payload = {
      name,
      type,
      status,
      organization,
      context: actionContext,
    };
    handleAction(payload);
  };

  return (
    <div className="content">
      <FormHolder
        imgSrc={Forms.ACTION.illustration}
        formHeader={Forms.ACTION.title}
        formDescription={Forms.ACTION.desc}
        CardFooterComponent=""
      >
        <Form onSubmit={handleSubmit}>
          <BaseTextInput
            value={name}
            setValue={setName}
            placeholder="Name of your action"
            label="Action name"
          />
          <FormGroup className="mb-4 d-flex align-items-center">
            <Label className="mr-3">Action Status</Label>
            <Switch value={status} onChange={(e) => setStatus(e.state.value)} />
          </FormGroup>
          <BaseSelect
            dataSource={actionOptions}
            value={type}
            setValue={setType}
            label="Action type"
            place="Action type"
          />
          <Collapse isOpen={["PUSH_NOTIFICATION", "WEBSOCKET"].includes(type)}>
            <UserSelect isMultiSelect value={users} setValue={setUsers} />
          </Collapse>
          <Collapse isOpen={type === "SMS"}>
            <BaseMultiTextInput
              label="Phone Number"
              value={phoneRecipientList}
              setValue={setPhoneRecipientList}
              validator={Validate.PhoneNumber}
            />
          </Collapse>
          <Collapse isOpen={type === "EMAIL"}>
            <BaseMultiTextInput
              label="Email Ids"
              value={emailRecipientList}
              setValue={setEmailRecipientList}
              validator={Validate.Email}
            />
          </Collapse>
          <Collapse
            isOpen={["EMAIL", "PUSH_NOTIFICATION", "WEBSOCKET"].includes(type)}
          >
            <BaseTextInput
              label="Message Subject"
              placeholder="Enter subject"
              value={messageSubject}
              setValue={setMessageSubject}
            />
          </Collapse>
          <Collapse
            isOpen={["SMS", "EMAIL", "PUSH_NOTIFICATION", "WEBSOCKET"].includes(
              type
            )}
          >
            <BaseTextAreaInput
              label="Body"
              placeholder="Enter message body"
              value={messageBody}
              setValue={setMessageBody}
            />
          </Collapse>
          <Collapse isOpen={type === "HTTP_REQUEST"}>
            <BaseTextInput
              label="URL"
              placeholder="Enter URL"
              value={url}
              setValue={setUrl}
            />

            <BaseSelect
              dataSource={HTTP_METHOD_OPTIONS}
              label="HTTP Method"
              placeholder="Enter HTTP method"
              value={httpMethod}
              setValue={setHttpMethod}
            />

            <BaseTextAreaInput
              label="Headers"
              placeholder="Enter Headers"
              value={httpHeaders}
              setValue={setHttpHeaders}
            />

            <BaseTextAreaInput
              label="Params"
              placeholder="Enter parameters"
              value={params}
              setValue={setParams}
              // bottomHintText={"Example: organization=123456 & count=1"}
            />
            <BaseTextAreaInput
              label="Body"
              placeholder="Enter Body"
              value={httpBody}
              setValue={setHttpBody}
            />
          </Collapse>
          <BaseButton>{buttonText}</BaseButton>
        </Form>
      </FormHolder>
    </div>
  );
}

export default ActionForm;
