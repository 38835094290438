import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import PolicyInput from "components/UserInputComponents/Inputs/TextInputs/PolicyInput";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Form } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import FloorSectionSelect from "components/UserInputComponents/Selects/FloorSectionSelect";
import FromTimePicker from "components/UserInputComponents/TimePickers/FromTimePicker";
import ToTimePicker from "components/UserInputComponents/TimePickers/ToTimePicker";
import DaysSelect from "components/UserInputComponents/Selects/DaysSelect";
import ScopeSelect from "components/UserInputComponents/Selects/ScopeSelect";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";

function PolicyFilters({ setPolicyParams }) {
  const now = new Date();
  const todayNight = DateTimeService.getMidnightTime(now);
  const todayMorning = DateTimeService.getMorningTime(now);
  const [startDate, setStartDate] = useState(todayMorning);
  const [endDate, setEndDate] = useState(todayNight);
  const [fromTime, setFromTime] = useState(todayMorning);
  const [toTime, setToTime] = useState(todayNight);
  const [floorSection, setFloorSection] = useState("");
  const [appliedDays, setAppliedDays] = useState([]);

  const orgId = useSelector((state) => state.organization._id);
  const [policyName, setPolicyName] = useState("");
  const [scope, setScope] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submitted");
    setPolicyParams({
      fromDate: startDate.toISOString(),
      toDate: endDate.toISOString(),
      fromTime: DateTimeService.get24TimeStringUTC(fromTime),
      toTime: DateTimeService.get24TimeStringUTC(toTime),
      appliedDays: appliedDays,
      name: policyName || "",
      organizationId: orgId,
      floorPlanSectionId: floorSection,
      accessType: scope,
    });
  };

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <StartDatePicker date={startDate} setDate={setStartDate} />
          <EndDatePicker date={endDate} setDate={setEndDate} />
          <FromTimePicker date={fromTime} setDate={setFromTime} />
          <ToTimePicker date={toTime} setDate={setToTime} />
          <DaysSelect value={appliedDays} setValue={setAppliedDays} />
          <FloorSectionSelect
            value={floorSection}
            setFloorSection={setFloorSection}
          />
          {/* Trigger parameter of the policy */}
          <PolicyInput
            className="mb-4"
            value={policyName}
            setValue={setPolicyName}
          />
          <ScopeSelect value={scope} setValue={setScope} />
          <BaseButton>Apply</BaseButton>
        </Form>
      </CardBody>
    </Card>
  );
}

export default PolicyFilters;
