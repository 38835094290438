import React from "react";
import ApiService from "./ApiService";
import { ACTION_URL } from "config/api";
import SWALService from "./SWALService";

class ActionService extends ApiService {
  constructor() {
    super(ACTION_URL);
  }

  async editAction(id, payload) {
    return await this.put(id, payload).catch((err) =>
      SWALService.showError(err)
    );
  }

  async addAction(id, payload) {
    return await this.post(payload).catch((err) =>
      SWALService.showError(err.message)
    );
  }
}

export default new ActionService();
