import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserService from "services/UserService";
import BaseSelect from "./BaseSelect";

function UserSelect({ value, setValue, isMultiSelect, label }) {
  const [_users, _setUsers] = useState([]);

  const organizationId = useSelector((state) => state.organization._id);

  useEffect(() => {
    const params = { organizationId };
    UserService.get(params)
      .then(({ data }) => _setUsers(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <BaseSelect
      label={label || "Users"}
      dataSource={_users?.map((user) => ({
        value: user?._id,
        label: `${user?.firstName} ${user?.lastName}`,
      }))}
      placeholder={"Select users"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default UserSelect;
