import { VEHICLE_SHIFT_URL } from "config/api";
import ApiService from "./ApiService";

class VehicleShiftService extends ApiService {
  constructor() {
    super(VEHICLE_SHIFT_URL);
  }
}

export default new VehicleShiftService();
