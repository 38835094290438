import React from "react";
import RestrictionList from "./subcomponents/RestrictionList";

function AddRestrictionMain(){
    return (
        <div className="content">
            <RestrictionList />
        </div>
    )
}

export default AddRestrictionMain;