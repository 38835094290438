import beaconTypes from "config/beaconTypes";
import React, { useState } from "react";
import BaseSelect from "./BaseSelect";

function BeaconModelSelect({ value, setValue, isMultiSelect }) {
  const BeaconModelsOptions = beaconTypes.map((el) => ({
    value: el,
    label: el,
  }));
  return (
    <BaseSelect
      label={"Model"}
      dataSource={BeaconModelsOptions}
      placeholder={"Select beacon model"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default BeaconModelSelect;
