import ApiService from "./ApiService";
import BaseService from "./BaseService";
const { MATTER_URL } = require("config/api");

class MatterService extends ApiService {
  constructor() {
    super(MATTER_URL);
    this.baseService = new BaseService();
  }

  createMatter(url, data, headers) {
    return this.baseService
      .doPOST(url, "POST", data, headers)
      .then((response) => {
        if (!!response.data?.success) {
          return {
            msg: response.data.message,
            data: response.data.data,
          };
        }
      });
  }
}

export default new MatterService();
