import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import BuildingSelect from "components/UserInputComponents/Selects/BuildingSelect";
import CampusSelect from "components/UserInputComponents/Selects/CampusSelect";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "reactstrap";

function GateFilter({ setParams }) {
  const [campusId, setCampusId] = useState();
  const [buildingId, setBuildingId] = useState();

  const orgId = useSelector((state) => state.organization._id);
  // const orgId = "621642aa5656450009819ffc";

  const onSubmit = (e) => {
    e.preventDefault();
    setParams({
      organizationId: orgId,
      campusId,
      buildingId,
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <CampusSelect value={campusId} setValue={setCampusId} />
      <BuildingSelect value={buildingId} setValue={setBuildingId} />
      <BaseButton>Apply</BaseButton>
    </Form>
  );
}

export default GateFilter;
