import React from "react";
import { connect } from "react-redux";

import { w3cwebsocket as W3CWebSocket } from "websocket";
import AlertModal from "./AlertModal2/AlertModal";
import toast from "react-hot-toast";
import { Col, Row } from "reactstrap";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import BaseService from "services/BaseService";
import { EVENT_URL } from "config/api";
const baseService = new BaseService();

const SOCKET_TOAST_ID = "SOCKET_TOAST_ID";

const RETRY_LIMIT = 10;

class SocketAlerts extends React.Component {
  constructor(props) {
    super(props);

    this.socket_url = `wss://vl2ysgo3sg.execute-api.ap-south-1.amazonaws.com/test?userId=${props.user._id}`;

    this.state = {
      eventId: null,
      isOpen: false,
      showReconnect: true,
      alive: true,
    };

    this.sound = new Audio("/alertSound.wav");
    this.retries = 0;
    this.client = new W3CWebSocket(this.socket_url);
  }

  reconnect = () => {
    this.client = new W3CWebSocket(this.socket_url);
    console.log("Socket reconnection tried...");
  };

  onSocketDisconnect = () => {
    toast(
      () => (
        <Row className="d-flex justify-content-between align-items-center flex-nowrap p-2">
          <Col>
            <p>SOS channel disconnected</p>
            <p color="muted">refresh to reconnect</p>
          </Col>
          <IconButton
            color="danger"
            onClick={() => {
              window.location.reload();

              toast.dismiss(SOCKET_TOAST_ID);
            }}
            icon="refresh"
          />
        </Row>
      ),
      { duration: Infinity, id: SOCKET_TOAST_ID }
    );
  };

  componentDidMount() {
    this.client.onmessage = (message) => {
      const { eventId } = JSON.parse(message.data);
      if (!eventId) return;
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        this.sound?.play();
        this.setState({ eventId, isOpen: true });
      }, 2000);
    };

    this.client.onopen = () => {
      console.log("Socket got connected...");
      this.setState({ showReconnect: false });
    };

    this.client.onclose = (e) => {
      if (e.reason === "forced" || e.reason === "") return;
      if (this.retries >= RETRY_LIMIT) return this.onSocketDisconnect();
      this.reconnect();
    };

    this.connector = setInterval(() => {
      if (this.client?.readyState !== 1) {
        if (this.retries >= RETRY_LIMIT) return this.onSocketDisconnect();
        this.reconnect();
      }
    }, 5000);
  }

  componentWillUnmount() {
    this.onSocketDisconnect = () => {};
    this.sound.pause();
    // this.client.close();
    clearInterval(this.connector);

    toast.dismiss(SOCKET_TOAST_ID);
  }

  close = () => this.setState({ isOpen: false });

  render() {
    return (
      <>
        <AlertModal
          isOpen={this.state.isOpen}
          eventId={this.state?.eventId}
          close={this.close}
          fromSocket={true}
        />
        {/* {this.state.showReconnect && <SocketDisconnected />} */}
      </>
    );
  }
}

// function mapDispatchToProps(dispatch) {
//   return {
//     saveAlertModalStatus: (alertModalState) =>
//       dispatch(saveAlertModalStatus(alertModalState)),
//   };
// }

const mapStateToProps = (state) => {
  return {
    organization: state.organization,
    user: state.user,
  };
};

export default connect(mapStateToProps)(SocketAlerts);

// import React, { useEffect, useRef, useState } from "react";
// import AlertsDetailModals from "views/components/AlertsDetailModals";

// import io from "socket.io-client";

// function SocketAlerts() {
//   const [alert, setAlert] = useState({
//     alertId: null,
//     openModal: false,
//     modal: false,
//     toggle: false,
//     backdrop: false,
//     keyboard: false,
//     type: "alert",
//     alertsData: null,
//   });

//   const toggleChange = () =>
//     setAlert({
//       openModal: !alert.openModal,
//       modal: !alert.modal,
//       toggle: !alert.toggle,
//       backdrop: !alert.backdrop,
//       keyboard: !alert.keyboard,
//       alertsData: null,
//       alertId: null,
//     });

//   const socketRef = useRef();

//   useEffect(() => {
//     socketRef.current = io.connect(
//       "wss://vl2ysgo3sg.execute-api.ap-south-1.amazonaws.com/test?userId=60ddadbfc68326000828b9d8",
//       {
//         transports: ["websocket"],
//       }
//     );
//     socketRef.current.on("name", ({ alertId }) => {
//       setAlert({
//         openModal: !alert.openModal,
//         modal: !alert.modal,
//         toggle: !alert.toggle,
//         backdrop: !alert.backdrop,
//         keyboard: !alert.keyboard,
//         alertsData: { alertId },
//         alertId,
//       });
//     });
//     return () => socketRef.current.disconnect();
//   }, []);

//   return (
//     <>
//       {!!true && (
//         <AlertsDetailModals
//           openModal={alert.openModal}
//           modal={alert.modal}
//           toggle={alert.toggle}
//           backdrop={alert.backdrop}
//           keyboard={alert.keyboard}
//           toggleAlertChange={toggleChange}
//           alertData={alert.alertsData}
//         />
//       )}
//     </>
//   );
// }

// export default SocketAlerts;
