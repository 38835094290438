import { GATE_URL } from "config/api";
import ApiService from "./ApiService";

class GateServuce extends ApiService {
  constructor() {
    super(GATE_URL);
  }
}

export default new GateServuce();
