import React from "react";
import {
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  NavLink,
} from "reactstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function Controls(props) {
  const user = useSelector((state) => state.user);
  const history = useHistory();

  return (
    <UncontrolledDropdown nav>
      <DropdownToggle
        caret
        color="default"
        data-toggle="dropdown"
        nav
        onClick={(e) => e.preventDefault()}
      >
        <div className="photo">
          <img
            alt="..."
            src={
              user?.attributes?.avatar ||
              require("assets/img/default-avatar.png")
            }
          />
        </div>
        <b className="caret d-none d-lg-block d-xl-block" />
        <p className="d-lg-none">Log out</p>
      </DropdownToggle>
      <DropdownMenu className="dropdown-navbar" right tag="ul">
        <h5 className="mb-0 py-3 px-4">
          <b>{user.firstName || "Unkown"}</b>
        </h5>

        <DropdownItem
          className="nav-item"
          onClick={() => {
            history.push("/admin/change_password");
          }}
        >
          Change Password
        </DropdownItem>

        <DropdownItem divider tag="li" />

        <DropdownItem className="nav-item" onClick={props.logOutUser}>
          Log out
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default Controls;
