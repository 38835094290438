import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Collapse } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import SubjectService from "../../../../../services/SubjectService";
import {
  SUBJECT_BULK_UPLOAD_URL,
  SUBJECT_URL,
} from "../../../../../config/api";
import ImageUpload from "../../../../../components/CustomUpload/ImageUpload";
import UtilsService from "services/UtilsService";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import FormHolder from "components/Holders/FormHolder/FormHolder";
import Forms from "../../../../../config/copies/forms";
import RoleSelect from "components/UserInputComponents/Selects/RoleSelect";
import GenderSelect from "components/UserInputComponents/Selects/GenderSelect";
import TagSelect from "components/UserInputComponents/Selects/TagSelect";
import Validate from "utils/validators";
import BaseMultiTextInput from "components/UserInputComponents/MultiTextInput/BaseMultiTextInput";
import BloodGroupSelect from "components/UserInputComponents/Selects/BloodGroupSelect";
import Divider from "components/Divider/Divider";
import Switch from "react-bootstrap-switch";
import toast from "react-hot-toast";
import BrandLoader from "views/custom_components/Loader";
import RouteSelect from "components/UserInputComponents/Selects/RouteSelect";
import BaseDatePicker from "components/UserInputComponents/DatePickers/BaseDatePicker";
import DateTimeService from "services/DateTimeService";
import axios from "axios";
import CSVupload from "views/components/CSVupload";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import CSVuploadManual from "views/components/CSVuploadManual";
import SWALService from "services/SWALService";

const subjectService = new SubjectService();

function SubjectForm() {
  const cannotBeCaretaker = ["STUDENT", "ASSET"];
  const { id: subjectId } = useParams();
  const buttonText = !subjectId ? "Save Changes" : "Submit";
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [name, setName] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [role, setRole] = useState();
  // const [guardianContact, setGuardianContact] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState();
  const [roll, setRoll] = useState("");
  const [dob, setDob] = useState();
  const [groups, setGroups] = useState([]);
  const [primaryGroup, setPrimaryGroup] = useState("");
  const [phoneNumber, setPhoneNumber] = useState([]);
  const [emailId, setEmailId] = useState([]);

  const [isCaretaker, setIsCaretaker] = useState(false);

  const [route, setRoute] = useState();

  const [modalOpen, setModalOpen] = useState(false);
  const [manualModal, setManualModal] = useState(false);
  const [assetData, setAssetData] = useState([]);

  const roles = [
    "TEACHING_STAFF",
    "NON_TEACHING_STAFF",
    "STUDENT",
    "EMPLOYEE",
    "SECURITY",
    "DRIVER",
    "VISITOR",
    // "ASSET",
  ];

  const tableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Unique ID",
      accessor: "uniqueId",
    },
  ];

  const organization_id = useSelector((state) => state.organization._id);

  useEffect(() => {
    if (subjectId) fetchSubjects(subjectId);
  }, [subjectId]);

  const deleteObjectAttributes = (property = [], obj) => {
    property.map((p) => {
      delete obj[p];
    });
  };

  const clearEmptyRow = () => {
    const filterData = assetData.filter((object) => {
      return !!Object.keys(object).length;
    });
    return filterData;
  };

  useEffect(() => {
    if (assetData.length > 0) {
      const newAssetData = clearEmptyRow();
      try {
        newAssetData.forEach((object) => {
          object.attributes = {
            bloodGroup: object?.bloodGroup,
            roll: object?.roll,
            avatar: object?.avatar,
            phoneNumbers: object?.phone ? [object?.phone] : [],
            emailIds: object?.email ? [object?.email] : [],
          };
          if (object?.gender) {
            object.attributes.gender = object?.gender;
          }
          if (object?.dob) {
            object.attributes.dob = DateTimeService.toServerDate(object?.dob);
          }
          object.primaryGroup = primaryGroup;
          object.isCaretaker = object.isCaretaker === "TRUE";
          object.organization = organization_id;
          deleteObjectAttributes(
            ["bloodGroup", "dob", "gender", "roll", "avatar", "assignedRoute"],
            object,
          );
          console.log(object);
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [assetData, primaryGroup, route, role]);

  const fetchSubjects = (subjectId) => {
    try {
      subjectService
        .getById(subjectId)
        .then(({ data }) => {
          console.log(
            "subjectData",
            data.groups?.map((g) => g?._id),
          );
          setName(data.name);
          setIsCaretaker(data.isCaretaker);
          setUniqueId(data.uniqueId);
          setGroups(data.groups?.map((g) => g?._id));
          // setAge(data.attributes.age);
          setRole(data?.role);
          setGender(data?.attributes?.gender);
          setDob(new Date(data?.attributes?.dob));
          setBloodGroup(data?.attributes?.bloodGroup);
          setRoll(data?.attributes?.roll);
          setProfilePic(data?.attributes?.avatar);
          setPrimaryGroup(data?.primaryGroup?._id || "");

          setPhoneNumber(data?.attributes?.phoneNumbers);
          setEmailId(data?.attributes?.emailIds);

          setRoute(data?.attributes?.assignedRoute._id);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log("subjectData", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name === "") return toast.error("Name is required");
    if (!uniqueId) return toast.error("Unique ID is required");
    if (!primaryGroup) return toast.error("Primary Group is required");

    const payload = {
      name,
      uniqueId,
      groups,
      primaryGroup,
      isCaretaker: !cannotBeCaretaker.includes(role) && isCaretaker,
      attributes: {
        assignedRoute: route,
        phoneNumbers: phoneNumber,
        emailIds: emailId,
        bloodGroup: bloodGroup,
        // age: age,
        dob: dob === "Invalid date" ? undefined : dob,
        gender: gender,
        avatar: profilePic,
        roll: roll,
      },
      role: role,
      organization: organization_id,
    };
    const subjectService = new SubjectService();
    try {
      if (!subjectId) {
        await axios
          .post(SUBJECT_URL, payload)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Asset Created Successfully",
              text: response.msg,
            }).then(() => {
              history.push("/admin/all_subjects");
            });
          })
          .catch((error) => toast.error(error?.response?.data?.data));
      } else {
        await subjectService
          .updateSubject(SUBJECT_URL, payload, subjectId, true)
          .then(async (response) => {
            Swal.fire({
              icon: "success",
              title: "Asset Updated Successfully",
              text: response.msg,
            }).then(() => {
              history.push("/admin/all_subjects");
            });
          })
          .catch((error) => toast.error(error));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const parentCallback = (data) => {
    removePreviousImage();
    const utilsService = new UtilsService();
    const profilePic = utilsService.removeURLQueryStringParams(data);
    setProfilePic(profilePic);
  };

  const removePreviousImage = () => {
    if (profilePic !== "") {
      setProfilePic("");
    }
  };

  const handleBlukSubmit = (e) => {
    e.preventDefault();
    const payload = assetData;
    subjectService
      .doPost(SUBJECT_BULK_UPLOAD_URL, payload, true)
      .then((response) => {
        console.log(response);
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Assets Added",
          text: response?.msg,
        }).then(() => {
          history.push("/admin/all_subjects");
        });
      })
      .catch((error) => {
        SWALService.showError(error.message);
      });
  };

  return (
    <div className="content">
      <CSVupload
        title="Upload Multiple Asset"
        modalOpen={modalOpen}
        data={assetData}
        tableConfig={tableConfig}
        requiredFields={[primaryGroup]}
        selectFields={
          <>
            <TagSelect
              value={primaryGroup}
              setValue={setPrimaryGroup}
              label="Primary Group"
              // isCreatable
            />
          </>
        }
        setData={setAssetData}
        handleSubmit={handleBlukSubmit}
        setModalOpen={setModalOpen}
        setShowManual={setManualModal}
      />
      <CSVuploadManual
        setModalOpen={setManualModal}
        modalOpen={manualModal}
        gId={"1880975447"}
      />
      <FormHolder
        imgSrc={Forms.SUBJECT.illustration}
        formHeader={Forms.SUBJECT.title}
        formDescription={Forms.SUBJECT.desc}
        CardFooterComponent=""
        formActions={
          <IconButton
            icon={"upload_file"}
            onClick={() => setModalOpen(true)}
            color={"info"}
            outlined
          />
        }
      >
        {/* groups */}
        <h4>Asset Type</h4>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <RoleSelect value={role} setValue={setRole} />

          <Collapse isOpen={role && !cannotBeCaretaker.includes(role)}>
            <>
              <Label>Can this person be a caretaker?</Label>
              <div className="togglebutton">
                <Switch
                  onChange={() => setIsCaretaker((prev) => !prev)}
                  value={isCaretaker}
                  onText="Yes"
                  offText="No"
                />
              </div>
            </>
          </Collapse>

          <Divider className="my-4" />

          {/* basic info */}
          <h4>Basic Information</h4>
          <Label>Profile Pic</Label>
          <FormGroup>
            <ImageUpload
              addBtnColor="default"
              changeBtnColor="default"
              fileData={profilePic}
              context="assetProfilePic"
              parentCallback={parentCallback}
              removePreviousImage={removePreviousImage}
            />
          </FormGroup>

          <BaseTextInput
            label="Name"
            placeholder="Enter Name"
            type="text"
            value={name}
            autoComplete="off"
            setValue={setName}
          />

          <BaseTextInput
            label="Unique ID"
            placeholder="Enter Unique ID"
            type="text"
            value={uniqueId}
            autoComplete="off"
            setValue={setUniqueId}
          />

          <Collapse isOpen={roles.includes(role)}>
            {/* <BaseTextInput
              label="Age"
              placeholder="Enter Age"
              type="number"
              value={age}
              autoComplete="off"
              setValue={setAge}
              validator={Validate.Age}
              min="0"
              max="100"
            /> */}

            <BaseDatePicker
              date={dob}
              setDate={setDob}
              placeholder="Enter the Date of birth"
              label="D.O.B."
              maxDate={new Date()}
            />

            <BloodGroupSelect value={bloodGroup} setValue={setBloodGroup} />

            <GenderSelect value={gender} setValue={setGender} />
          </Collapse>

          <Collapse isOpen={role === "STUDENT"}>
            <BaseTextInput
              label="Roll No"
              placeholder="Enter Roll No"
              type="text"
              value={roll}
              autoComplete="off"
              setValue={setRoll}
            />
          </Collapse>

          <Divider className="my-4" />
          {/* contact */}
          <Collapse isOpen={roles.includes(role)}>
            <h4>Contact Information</h4>
            {loading ? (
              <BrandLoader noOverlay />
            ) : (
              <>
                <BaseMultiTextInput
                  label="Phone Numbers"
                  value={phoneNumber}
                  setValue={setPhoneNumber}
                  validator={Validate.PhoneNumber}
                />
                <BaseMultiTextInput
                  label="Email Ids"
                  value={emailId}
                  setValue={setEmailId}
                  validator={Validate.Email}
                />
              </>
            )}
          </Collapse>

          <Divider className="my-4" />
          {/* groups */}
          <h4>Tags</h4>
          <TagSelect
            value={primaryGroup}
            setValue={setPrimaryGroup}
            label="Primary Group"
            // isCreatable
          />

          <TagSelect
            value={groups}
            setValue={setGroups}
            label="Tags"
            isMultiSelect
            isCreatable
          />

          <Divider className="my-4" />

          {/* <h4>Vehicles</h4>
          <VehicleSelect value={vehicle} setValue={setVehicle} /> */}

          <h4>Routes</h4>
          <RouteSelect value={route} setValue={setRoute} />

          <BaseButton color="info">{buttonText}</BaseButton>
        </Form>
      </FormHolder>
    </div>
  );
}

export default SubjectForm;
