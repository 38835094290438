import React from "react";
import { UncontrolledPopover } from "reactstrap";

function BasePopoverView({ tagId, children, trigger, container }) {
  return (
    <UncontrolledPopover
      popperClassName="popover-default"
      placement="auto"
      target={tagId}
      container={container}
      boundariesElement={`#${tagId}`}
      boundary={`#${tagId}`}
      content="element"
      trigger={trigger || "click"}
      delay={{
        hide: 300,
      }}
      // hideArrow={true}
    >
      {children}
    </UncontrolledPopover>
  );
}

export default BasePopoverView;
