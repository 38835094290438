import FormHolder from "components/Holders/FormHolder/FormHolder";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import Forms from "../../../../../config/copies/forms";
import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
// import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import UserService from "../../../../../services/UserService";
import UserRoleSelect from "components/UserInputComponents/Selects/UserRoleSelect";
import { useSelector } from "react-redux";

function UserForm() {
  const history = useHistory();
  const organization = useSelector((s) => s.organization._id);
  const { userId } = useParams();

  const [subjects, setSubjects] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userRole, setUserRole] = useState("");
  const [isEditable, setIsEditable] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  const buttonText = userId ? "Save Changes" : "Submit";

  useEffect(() => {
    if (!userId) return;

    UserService.getById(userId)
      .then(({ data }) => {
        if (data.length < 0) return;
        console.log("userdata", data);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        setUserName(data.username);
        setPhoneNumber(data.phoneNumber);
        setUserRole(data.role);
        setIsEnabled(data.enabled);
        setIsConfirmed(data.userConfirmed);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let userData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      username: userName,
      role: userRole,
      enabled: isEnabled,
      userConfirmed: isConfirmed,
      organization,
    };

    UserService.put(userId, userData)
      .then(({ message }) => {
        history.goBack();
        Swal.fire({
          icon: "success",
          title: "User Details Updated",
          text: message,
        });
      })
      .catch((error) => console.log("err", error));
  };

  return (
    <div className="content">
      <Row>
        <FormHolder
          imgSrc={Forms.USER.illustration}
          formHeader={Forms.USER.title}
          formDescription={Forms.USER.desc}
          CardFooterComponent=""
        >
          <Form onSubmit={handleSubmit}>
            {/* <BaseTextInput
              label="Subject"
              placeholder="Enter subject"
              type="text"
              value={subjects}
              autoComplete="off"
              setValue={setSubjects}
            /> */}
            <BaseTextInput
              label="First Name"
              placeholder="Enter first name"
              type="text"
              value={firstName}
              autoComplete="off"
              setValue={setFirstName}
            />
            <BaseTextInput
              label="Last Name"
              placeholder="Enter last name"
              type="text"
              value={lastName}
              autoComplete="off"
              setValue={setLastName}
            />
            <BaseTextInput
              placeholder="Enter email"
              type="text"
              label="Email"
              value={email}
              autoComplete="off"
              setValue={setEmail}
            />
            <BaseTextInput
              label="User Name"
              placeholder="Enter username"
              type="text"
              value={userName}
              autoComplete="off"
              setValue={setUserName}
              editable={isEditable}
            />
            <BaseTextInput
              label="Phone Number"
              placeholder="Enter phone number"
              type="text"
              value={phoneNumber}
              autoComplete="off"
              setValue={setPhoneNumber}
              editable={isEditable}
            />
            <UserRoleSelect
              value={userRole}
              setValue={setUserRole}
              editable={isEditable}
            />
            <Row>
              <Col md={6}>
                <FormGroup check>
                  <Label check>
                    Confirmed
                    <Input
                      type="checkbox"
                      style={{ display: "none" }}
                      checked={isConfirmed}
                      onChange={() => setIsConfirmed(!isConfirmed)}
                    />
                    <span className="form-check-sign">
                      <span className="check"></span>
                    </span>
                  </Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup check>
                  <Label check>
                    Enabled
                    <Input
                      type="checkbox"
                      style={{ display: "none" }}
                      checked={isEnabled}
                      onChange={() => setIsEnabled(!isEnabled)}
                    />
                    <span className="form-check-sign">
                      <span className="check"></span>
                    </span>
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <BaseButton type="submit" color="info">
              {buttonText}
            </BaseButton>
          </Form>
        </FormHolder>
      </Row>
    </div>
  );
}

export default UserForm;
