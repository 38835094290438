import React from "react";
import BaseSelect from "./BaseSelect";

function GatewayAttachmentSelect({
  value,
  setValue,
  label,
  placeholder,
  bottomHintText,
  isMultiSelect,
}) {
  const GatewayAttachmentValues = [
    { label: "CAMPUS", value: "CAMPUS" },
    { label: "TRANSPORT", value: "TRANSPORT" },
  ];

  return (
    <BaseSelect
      label={label || "Attached to"}
      dataSource={GatewayAttachmentValues}
      placeholder={placeholder || "Where is the gateway attached"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
      bottomHintText={bottomHintText}
    />
  );
}

export default GatewayAttachmentSelect;
