import React from "react";
import "react-times/css/material/default.css";
import "react-times/css/classic/default.css";
import ReactDatetime from "react-datetime";
import { Label } from "reactstrap";

function BaseTimePicker({ label, value, changeValue, placeholder, name }) {
  return (
    <div className="mb-4">
      <Label> {label} </Label>
      <ReactDatetime
        name={name}
        value={value}
        onChange={changeValue}
        dateFormat={false}
        inputProps={{
          className: "form-control",
          placeholder: placeholder,
        }}
      />
    </div>
  );
}

export default BaseTimePicker;
