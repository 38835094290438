import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import CreatableSelect from "react-select/creatable";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import TagService from "services/TagService";
import CreateTags from "views/pages/Tags/subComponents/CreateTags";
import BaseSelect from "./BaseSelect";

function TagSelect({
  value,
  setValue,
  isMultiSelect,
  noLabel,
  isCreatable,
  label,
  placeholder,
  noPopulate,
  context = {},
  ...props
}) {
  const organizationId = useSelector((state) => state.organization._id);
  const [_value, _setValue] = useState();
  const [_tags, _setTags] = useState([]);
  const [isPrimary, setIsPrimary] = useState(false);
  const [createTags, setCreateTags] = useState(false);
  const [newTagView, setNewTagView] = useState(false);
  const [tagName, setTagName] = useState("");

  const fetchTags = () => {
    const params = { ...(context || {}), organizationId };
    TagService.get(params)
      .then(({ data }) => {
        const options = isCreatable
          ? data.map((t) => ({ label: t.name, value: t._id }))
          : data;
        _setTags(options);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!value || !_tags) return;
    const initValues = !!isMultiSelect ? value : [value];
    const preSelectValues = _tags?.filter((el) =>
      initValues?.includes(el.value)
    );
    _setValue(preSelectValues);
  }, [value, _tags]);

  const _setData = (selectedValue) => {
    if (!selectedValue) return;
    _setValue(selectedValue);

    if (isMultiSelect)
      setValue(selectedValue?.map((objValue) => objValue?.value));
    else setValue(selectedValue.value);
  };

  const onAddTag = (confirmCallback) => {
    setNewTagView(true);
    setCreateTags(true);
  };

  const handleChange = (values) => {
    if (!values?.length) return _setData([]);
    const newValue = values.pop();
    setTagName(newValue.value);

    if (!newValue.__isNew__) return _setData([...values, newValue]);

    const confirmCallback = () => {
      TagService.post({
        organization: organizationId,
        name: newValue.value,
        qualifier: isPrimary ? "PRIMARY" : "OTHER",
      }).then(({ data }) => {
        _setData([...values, newValue]);
        fetchTags();
      });
    };

    onAddTag(confirmCallback);
  };

  useEffect(() => {
    if (!noPopulate) fetchTags();
  }, [...Object.values(context), noPopulate]);

  return (
    <div className="TagSelect">
      {newTagView && (
        <CreateTags
          showCreateTags={createTags}
          setShowCreateTags={setCreateTags}
          fetchTags={fetchTags}
          tagValue={tagName}
        />
      )}

      {isCreatable ? (
        <FormGroup className="mb-4">
          <Label className="mb-2"> {!noLabel && (label || "Groups")}</Label>
          <CreatableSelect
            options={_tags}
            placeholder={"Select Tag"}
            closeMenuOnSelect={false}
            isMulti={isMultiSelect}
            value={_value}
            onChange={handleChange}
            isClearable
          />
        </FormGroup>
      ) : (
        <BaseSelect
          dataSource={_tags?.map((tag) => ({
            value: tag?._id,
            label: tag?.name,
          }))}
          label={noLabel ? "" : label || "Groups"}
          placeholder={placeholder || "Select Tag"}
          closeMenuOnSelect={!isMultiSelect}
          isMultiSelect={isMultiSelect}
          value={value}
          setValue={setValue}
          name="Tag"
          attribute="group"
          {...props}
        />
      )}
    </div>
  );
}

export default TagSelect;
