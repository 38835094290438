import {} from "config/api";
import { STATUS_LOG_URL } from "config/api";
import { GATEWAY_URL, OCCUPANCY_URL, STATUS_URL } from "config/api";
import ApiService from "./ApiService";
import BaseService from "./BaseService";

class GatewayService extends ApiService {
  constructor() {
    super(GATEWAY_URL);
    this.baseService = new BaseService();
  }

  createGateway(url, data, headers) {
    return this.baseService
      .doPOST(url, "POST", data, headers)
      .then((response) => {
        if (!!response.data?.success) {
          return {
            msg: response.data.message,
            data: response.data.data,
          };
        }
      });
  }

  getGatewayOccupancy(params, onlyCount = true, headers) {
    return this.baseService.doGET(
      OCCUPANCY_URL,
      "GET",
      { ...params, count: onlyCount },
      headers,
    );
  }

  getGatewayStatus(params, headers) {
    return this.baseService.doGET(STATUS_URL, "GET", { ...params }, headers);
  }

  getGatewayLogsStatus(params, headers) {
    return this.baseService.doGET(
      STATUS_LOG_URL,
      "GET",
      { ...params },
      headers,
    );
  }
}

export default new GatewayService();
