import React, { useEffect, useState } from "react";
import VehicleService from "services/VehicleService";
import BaseSelect from "./BaseSelect";

function VehicleSelect({
  value,
  setValue,
  isMultiSelect,
  noLabel,
  noPopulate,
}) {
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    if (noPopulate) return;
    VehicleService.get()
      .then((res) => {
        setVehicles(res.data?.map((el) => ({ value: el._id, label: el.name })));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [noPopulate]);

  return (
    <BaseSelect
      label={!noLabel && "Vehicle"}
      dataSource={vehicles}
      placeholder={"Select Vehicle"}
      closeMenuOnSelect={false}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default VehicleSelect;
