import React from "react";
import {
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormText,
} from "reactstrap";
import ActionSelect from "components/UserInputComponents/Selects/ActionSelect";
import Units from "config/Units";

export default class PolicyActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      priority: 2,
      status: true,
      rateLimiter: {
        number: 1,
        duration: "",
      },
    };
  }

  componentDidMount() {
    const { policy } = this.props;
    if (!policy) return;

    const { rateLimiter } = policy;

    this.setState({
      actions: policy.actions,
      priority: policy.priority,
      status: policy.status,
      rateLimiter: {
        number: rateLimiter.number,
        duration: rateLimiter.duration / 1000,
      },
    });
  }

  updateAlertIntervalChange = (event) => {
    this.props.setPolicyActionsInterval(event.target.value);
    this.setState({ alertInterval: event.target.value });
  };

  alertIntervalChange = (event) => {
    this.setState({ alertInterval: event.target.value });
  };

  updateAlertLimitChange = (event) => {
    this.props.setPolicyActionsLimit(event.target.value);
    this.setState({ alertLimit: event.target.value });
  };

  alertLimitChange = (event) => {
    this.setState({ alertLimit: event.target.value });
  };

  isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  render() {
    return (
      <>
        <h4>Action that follow</h4>
        <br />

        <ActionSelect
          value={this.state.actions}
          setValue={(val) => this.setState({ actions: val })}
          bottomHintText="Select Action to link this policy with"
          isMultiSelect
        />

        <br />
        <Row>
          <Col md={12}>
            <Label>Notification Interval</Label>
            <FormGroup>
              <InputGroup>
                <Input
                  type="number"
                  autoComplete="off"
                  value={this.state.rateLimiter.duration}
                  onChange={(e) =>
                    this.setState({
                      rateLimiter: {
                        ...this.state.rateLimiter,
                        duration: e.target.value,
                      },
                    })
                  }
                  // placeholder=""
                ></Input>
                <InputGroupAddon addonType="append">
                  <InputGroupText className="pl-3">
                    <p> sec</p>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <FormText color="muted">
                Time interval between each notification
              </FormText>
            </FormGroup>
          </Col>
          {/* <Col md={6}>
            <Label>Notification Limit</Label>
            <FormGroup>
              <InputGroup>
                <Input
                  type="number"
                  autoComplete="off"
                  value={this.state.rateLimiter.number}
                  onChange={(e) =>
                    this.setState({
                      rateLimiter: {
                        ...this.state.rateLimiter,
                        number: e.target.value,
                      },
                    })
                  }
                  // placeholder=""
                ></Input>
                <InputGroupAddon addonType="append">
                  <InputGroupText className="pl-3">
                    <p> times</p>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <FormText color="muted">
                How many notifications would be send out in a specific interval
              </FormText>
            </FormGroup>
          </Col> */}
        </Row>
      </>
    );
  }
}
