import ManageVehicles from "../ManageVehicles";
import Vehicle from "./index";
import VehicleForm from "./subComponent/VehicleForm";

const vehicleRoutes = [
  {
    path: "/admin/vehicle/list",
    rtlName: "عالتسعير",
    component: Vehicle,
    layout: "/admin",
    name: "Vehiclees",
    showVisible: false,
  },
  {
    path: "/admin/vehicle/add",
    name: "Add Vehicle",
    rtlName: "عالتسعير",
    component: VehicleForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/vehicle/edit/:vehicleId",
    name: "Edit Vehicle",
    rtlName: "عالتسعير",
    component: VehicleForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/vehicle/manage/:vehicleId",
    name: "Vehicle Occupancy",
    rtlName: "عالتسعير",
    component: ManageVehicles,
    layout: "/admin",
    showVisible: false,
  },
];

export default vehicleRoutes;
