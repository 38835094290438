import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import TransitItineraryService from "services/TransitItineraryService";
import SWALService from "services/SWALService";

function TransitItineraryTable({ getData, data, loading, setTableConfig }) {
  const history = useHistory();

  const editTransitItinerary = (transitItineraryId) =>
    history.push(`/admin/transitItinerary/edit/${transitItineraryId}`);

  const deleteTransitItinerary = async (transitItineraryId) => {
    console.log(transitItineraryId);
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await TransitItineraryService.delete(transitItineraryId).catch((err) =>
      SWALService.showError(err),
    );

    getData();

    SWALService.showSuccess("transitItinerary has been deleted.", "Deleted!");
  };

  const TransitItineraryTableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },

    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editTransitItinerary}
          deleteCallback={deleteTransitItinerary}
        />
      ),
    },
  ];

  useEffect(() => {
    setTableConfig(TransitItineraryTableConfig);
  }, []);

  return (
    <BaseTable
      tableConfig={TransitItineraryTableConfig}
      data={data}
      loading={loading}
    />
  );
}

export default TransitItineraryTable;
