import FilterTableHolder from "components/Holders/FilterTableHolder/FilterTableHolder";
import React, { useState } from "react";
import ParentConnectTable from "./subComponent/ParentConnectTable";
import ParentConnectFilter from "./subComponent/ParentConnectFilter";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import { useHistory } from "react-router-dom";

function ParentConnect() {
  const [params, setParams] = useState({});
  const history = useHistory();
  const onClickSend = () => history.push(`/admin/parent-connect/send`);
  const [tableData, setTableData] = useState([]);
  const [tableConfig, setTableConfig] = useState([]);

  return (
    <FilterTableHolder>
      <ParentConnectFilter setParams={setParams} />
      <TableHeader
        title="Communication"
        onClickSend={onClickSend}
        onClickDownload={true}
        tableConfig={tableConfig}
        tableData={tableData}
      />
      <ParentConnectTable
        params={params}
        setTableData={setTableData}
        setTableConfig={setTableConfig}
      />
    </FilterTableHolder>
  );
}

export default ParentConnect;
