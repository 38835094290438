import React, { useEffect, useState } from "react";

import { Router, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import AuthLayout from "layouts/Auth/Auth.js";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { login } from "data/actions/authActions";
import SWALService from "services/SWALService";
import UserService from "services/UserService";
import { setOrganization } from "data/actions/organizationAction";
import { setUser } from "data/actions/userActions";

import AppLoader from "components/AppLoader/AppLoader";
import { createBrowserHistory } from "history";
import PrivateRoute from "util-components/ProtectedRoute";
import ToastHolder from "views/custom_components/ToastHolder";

const browserHistory = createBrowserHistory();

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const query = window.location.search;

  const urlQueryComponent = query
    ? `?${new URLSearchParams(query).toString()}`
    : "";

  const [firstPath] = useState(
    `${window.location.pathname}${urlQueryComponent}`
  );

  const dispatch = useDispatch();

  const [appLoading, setAppLoading] = useState(true);

  const setLoadingFalse = () => setTimeout(() => setAppLoading(false), 3000);

  useEffect(() => {
    (async () => {
      setAppLoading(true);
      const congnitoUser = await Auth.currentAuthenticatedUser().catch((err) =>
        console.log(err)
      );

      if (!congnitoUser) return setAppLoading(false);

      const session_key =
        congnitoUser?.signInUserSession?.accessToken?.payload?.event_id;
      localStorage.setItem("session_key", session_key);

      const { data } = await UserService.get({
        userSub: congnitoUser?.attributes?.sub,
      }).catch((err) => SWALService.showError(err));
      const user = data[0];
      if (!user) return setAppLoading(false);
      const organization = user?.organization;
      if (!organization) return setAppLoading(false);

      localStorage.setItem("Organization_ID", organization._id);
      localStorage.setItem("USER_ID", user._id);
      localStorage.setItem("ORG_NAME", organization.brandName);
      localStorage.setItem("ORG_LOGO", organization.attributes?.logo);
      dispatch(setOrganization(organization));
      dispatch(setUser(user));
      dispatch(login(congnitoUser));

      dispatch(login(congnitoUser));
      setLoadingFalse();
    })();

    return () => {};
  }, []);

  return (
    <Router history={browserHistory}>
      <Switch>
        {!isAuthenticated && (
          <>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Redirect from="/" to="/auth/login" />
          </>
        )}
        <PrivateRoute path="/admin" component={AdminLayout} />
        <Redirect
          from="/"
          to={
            firstPath.match(/^\/auth\/\w*|^\/$/)
              ? "/admin/dashboard"
              : firstPath
          }
        />
      </Switch>
      <AppLoader loading={appLoading} />
      <ToastHolder />
    </Router>
  );
}

export default App;
