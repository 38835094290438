import React, { useEffect, useMemo, useState } from "react";

import SubjectView from "components/PopoverViews/SubjectView/SubjectView";
import TagSelect from "components/UserInputComponents/Selects/TagSelect";
import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import Collapse from "reactstrap/lib/Collapse";
import Button from "reactstrap/lib/Button";
import TooltipAction from "views/custom_components/TooltipAction";
import SubjectService from "services/SubjectService";
import { SUBJECT_URL } from "config/api";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import SubjectFilter from "../../SubjectFilter/SubjectFilter";
import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CardBody from "reactstrap/lib/CardBody";
import DateTimeService from "services/DateTimeService";
import { Badge } from "reactstrap";
import TagView from "components/PopoverViews/TagView/TagView";
import BeaconConnectView from "components/PopoverViews/BeaconConnectView/BeaconConnectView";
import Avatar from "views/custom_components/Avatar/Avatar";
import toast from "react-hot-toast";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import UserConnectView from "components/PopoverViews/UserConnectView/UserConnectView";

const Actions = ({ subjectId, editData, deleteData }) => (
  <div className="actions-right">
    <Button
      onClick={() => editData(subjectId)}
      color="warning"
      size="sm"
      className="btn-icon btn-link btn-success "
    >
      <i id="editSubject" className="tim-icons icon-pencil" />
    </Button>
    <TooltipAction target="editSubject" textContent="Edit Subject" />
    {/* use this button to remove the data row */}
    <Button
      onClick={() => deleteData(subjectId)}
      color="danger"
      size="sm"
      className="btn-icon btn-link btn-danger"
    >
      <i id="deleteSubject" className="tim-icons icon-trash-simple" />
    </Button>
    <TooltipAction target="deleteSubject" textContent="Delete Subject" />
  </div>
);

const subjectService = new SubjectService();

function SubjectListing() {
  const organizationId = useSelector((state) => state.organization._id);
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedAssets, setCheckedAssets] = useState([]);
  const [allCheckedId, setAllCheckedId] = useState([]);
  const [tags, setTags] = useState([]);

  const [queryParams, setQueryParams] = useState({ organizationId });

  useEffect(() => {
    getSubjects();
  }, [queryParams]);

  const editSubject = (id) => {
    history.push(`/admin/editsubject/${id}`);
  };

  const addSubject = () => {
    history.push("/admin/addsubject", { usecase: "add" });
  };

  const addGroup = () => {
    if (!tags.length) return toast.error("Select a group to add");
    const payload = {
      groups: tags,
      assets: allCheckedId,
    };
    if (!!payload) {
      subjectService
        .createGroup(SUBJECT_URL, payload, true)
        .then((res) => {
          setTags([]);
          setCheckedAssets([]);
          setAllCheckedId([]);
          Swal.fire("Created!", res?.msg, "success");
        })
        .catch((err) => {
          toast.error(err?.message || "Something went wrong");
        });
    }
  };

  const deleteSubject = (subjectId) => {
    Swal.fire({
      title: "Are you sure to Delete it?",
      text: "You won't be able to revert this after deleting!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        subjectService
          .deleteSubject(SUBJECT_URL, subjectId, true)
          .then((res) => {
            Swal.fire("Deleted!", "Asset has been deleted.", "success");
          });
      }
    });
  };

  // const capitalizeNames = (names) => {
  //   let properName = "";
  //   const capitalizeName = names.replaceAll("_", " ").split(" ");
  //   capitalizeName.forEach((capitalName) => {
  //     properName =
  //       properName +
  //       " " +
  //       capitalName.charAt(0).toUpperCase() +
  //       capitalName.slice(1);
  //   });
  //   // console.log(`%c rohit`, "fontWeight:bold", names, properName);
  //   return properName;
  // };

  const onItemCheck = (row) => {
    let _checkedAssets = [...checkedAssets];
    let _allCheckedId = [...allCheckedId, row.original.id];
    setAllCheckedId(_allCheckedId);
    const keyIndex = checkedAssets.indexOf(row.original.uniqueId);
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      _checkedAssets = [
        ...checkedAssets.slice(0, keyIndex),
        ...checkedAssets.slice(keyIndex + 1),
      ];
    } else {
      // it does not exist so add it
      _checkedAssets.push(row.original.uniqueId);
    }
    // update the state
    setCheckedAssets(_checkedAssets);
  };

  const getSubjects = () => {
    try {
      setFilterLoading(true);
      subjectService
        .get(queryParams)
        .then(({ data }) => {
          const tempSubjects = data.map((subject) => ({
            id: subject._id,
            name: subject.name,
            groups: subject.groups,
            uniqueId: subject.uniqueId,
            standard: subject.mappedGE?.title,
            age: subject.attributes?.age,
            avatar: subject.attributes?.avatar,
            gender: subject.attributes?.gender,
            role: subject.role,
            email: subject?.attributes?.emailIds?.join(" "),
            phone: subject?.attributes?.phoneNumbers?.join(" "),
            roll: subject.attributes?.roll,
            bloodGroup: subject.attributes?.bloodGroup,
            beacon: subject?.beacon,
            primaryGroup: subject?.primaryGroup,
            users: subject?.users,
            createdOn: subject?.createdOn,
          }));
          setData(tempSubjects);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
          setFilterLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const tableConfig = [
    {
      Header: "Select",
      Cell: (row) => {
        return (
          <div>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  style={{ display: "none" }}
                  checked={checkedAssets.includes(row.original.uniqueId)}
                  key={row.original.uniqueId}
                  onChange={() => onItemCheck(row)}
                />
                <span className="form-check-sign">
                  <span className="check"></span>
                </span>
              </Label>
            </FormGroup>
          </div>
        );
      },
    },
    {
      Header: "#",
      Cell: (row) => {
        return (
          <div>
            <Avatar
              height={34}
              src={row.original.avatar}
              alt={row.original.name}
              gender={row.original.gender}
            />
          </div>
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ original }) => (
        <SubjectView subjectId={original.id} subjectData={original}>
          {original.name}
        </SubjectView>
      ),
    },
    {
      Header: "Unique ID",
      accessor: "uniqueId",
    },
    {
      Header: "Beacon MacId",
      accessor: "beacon.macId",
      Cell: ({ original }) => {
        return (
          <BeaconConnectView
            assetId={original.id}
            reloadData={getSubjects}
            beaconMac={original?.beacon?.macId}
          />
        );
      },
      width: 120,
    },
    {
      Header: "User",
      Cell: ({ original }) => {
        return (
          <UserConnectView assetId={original.id} userData={original.users} />
        );
      },
      width: 170,
    },
    {
      Header: "Groups",
      // accessor: "groups",
      Cell: ({ original }) => {
        const tags = original?.groups;
        const primaryGroup = original?.primaryGroup?.name;
        const tagsSize = tags.length;

        const divContent = (
          <>
            <p className="text-muted">
              {!primaryGroup && !tags[0]?.name && "-"}
            </p>

            <p style={{ whiteSpace: "nowrap" }}>
              <Badge color="info">
                {!!primaryGroup ? primaryGroup : tags[0]?.name}
              </Badge>
              {!!tagsSize && <span className="ml-2">+{tagsSize}</span>}
            </p>
          </>
        );

        return (
          <TagView tagData={tags} tagId={original.id} getData={getSubjects}>
            <div className="clickable">{divContent}</div>
          </TagView>
        );
      },
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Created On",
      accessor: (row) => DateTimeService.getDateTimeString(row.createdOn),
    },
    {
      Header: "Actions",

      Cell: ({ original: { id } }) => (
        <Actions
          subjectId={id}
          editData={editSubject}
          deleteData={deleteSubject}
        />
      ),
    },
  ];

  return (
    <>
      <div className="content wrapTable">
        <Row className="ml-auto">
          <Col md={3}>
            <SubjectFilter getSubjects={(params) => setQueryParams(params)} />
          </Col>
          <Col xs={9} md={9} className="ml-auto">
            <Card>
              <CardHeader>
                <Collapse isOpen={checkedAssets.length === 0}>
                  <TableHeader
                    title={`Assets`}
                    onClickAdd={addSubject}
                    onClickDownload={true}
                    context="asset"
                    tableData={data}
                    fileTitle="Assets"
                  >
                    <>
                      <IconButton
                        icon="manage_accounts"
                        onClick={() =>
                          history.push("/admin/asset/change-request")
                        }
                        color="success"
                        outlined
                        id="change_request"
                      />
                      <TooltipAction
                        target="change_request"
                        textContent="Change Requests"
                      />
                    </>
                  </TableHeader>
                </Collapse>

                <Collapse isOpen={checkedAssets.length > 0}>
                  <Row>
                    <Col md="6">
                      <TagSelect
                        value={tags}
                        isMultiSelect
                        isCreatable
                        setValue={setTags}
                      />
                    </Col>
                    <Col>
                      <Button
                        color="success"
                        className="right_alg_button"
                        onClick={addGroup}
                      >
                        <i className="tim-icons icon-simple-add" />
                        Create Groups
                      </Button>
                    </Col>
                  </Row>
                </Collapse>
              </CardHeader>
              <CardBody>
                <BaseTable
                  tableConfig={tableConfig}
                  data={data}
                  loading={loading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SubjectListing;
