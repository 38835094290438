import types from "../types";

export const setUser = (payload) => {
  return {
    type: types.SET_USER,
    payload: payload,
  };
};

export const unsetUser = () => {
  return {
    type: types.UNSET_ORGANIZATION,
  };
};

const authActions = {
  setUser,
  unsetUser,
};

export default authActions;
