import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import RoleSelect from "components/UserInputComponents/Selects/RoleSelect";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import AssetSelect from "components/UserInputComponents/Selects/AssetSelect";
import TagSelect from "components/UserInputComponents/Selects/TagSelect";
import Divider from "components/Divider/Divider";
import { useHistory } from "react-router-dom";
import setQueryParams from "utils/setQueryParams";
import GatewayAttachmentSelect from "components/UserInputComponents/Selects/GatewayAttachmentSelect";

function InfraPresenceFilter({ getAlerts }) {
  const history = useHistory();
  const orgId = useSelector((state) => state.organization?._id);

  const now = new Date();
  const todayNight = DateTimeService.getMidnightTime(now);
  const todayMorning = DateTimeService.getMorningTime(now);
  const [startDate, setStartDate] = useState(todayMorning);
  const [endDate, setEndDate] = useState(todayNight);
  const [roles, setRoles] = useState([]);
  const [assetId, setAssetId] = useState();

  const [primaryGroup, setPrimaryGroup] = useState();

  const [beacons, setBeacons] = useState();
  const [gatewayAttachType, setGatewayAttachType] = useState();

  const handleFilters = () => {
    if (!(assetId || beacons || primaryGroup || roles))
      return toast.error(
        "Select an Asset, Primary Group, Role,or a Beacon to proceed"
      );

    const payload = {
      organizationId: orgId,
      fromTime: startDate.toISOString(),
      toTime: endDate.toISOString(),
      assetId,
      beacons,
      gatewayAttachType,
      primaryGroup,
      roles: roles?.join(","),
      showAll: true,
    };

    history.push({
      pathname: "/admin/diagnosis/infra-presence",
      search: `${setQueryParams(payload)}`,
    });

    getAlerts(payload);
  };

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <StartDatePicker date={startDate} setDate={setStartDate} />
        <EndDatePicker date={endDate} setDate={setEndDate} />

        <Divider className="my-4" />

        <GatewayAttachmentSelect
          value={gatewayAttachType}
          setValue={setGatewayAttachType}
        />

        <Divider className="my-4" />

        <TagSelect
          label="Primary Group"
          value={primaryGroup}
          setValue={setPrimaryGroup}
        />

        <RoleSelect value={roles} setValue={setRoles} isMultiSelect />

        <AssetSelect value={assetId} setValue={setAssetId} />

        {/* <BeaconMacSelect value={beacons} setValue={setBeacons} /> */}

        <br />
        <Button color="info" onClick={handleFilters}>
          Get Reports
        </Button>
      </CardBody>
    </Card>
  );
}

export default InfraPresenceFilter;
