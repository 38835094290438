import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import React, { useEffect, useState } from "react";
import Switch from "react-bootstrap-switch";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import SWALService from "services/SWALService";
import TagService from "services/TagService";
import BrandLoader from "views/custom_components/Loader";

function CreateTags({
  showCreateTags,
  setShowCreateTags,
  fetchTags,
  tagValue,
  tagId,
}) {
  const [tag, setTag] = useState("");
  const [isPrimary, setIsPrimary] = useState(false);
  const [_loading, _setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Create");

  const { _id: organization } = useSelector((s) => s.organization);

  useEffect(() => {
    setTag(tagValue);
  }, [tagValue]);

  useEffect(() => {
    if (!tagId) return;
    _setLoading(true);
    setButtonText("Update");
    try {
      TagService.get({ id: tagId })
        .then(({ data }) => {
          const { name, qualifier } = data[0];
          setTag(name);
          setIsPrimary(qualifier === "PRIMARY");
        })
        .catch((e) => toast.error(e?.message || "Something went wrong"));
    } catch (e) {
      toast.error(e?.message || "Something went wrong");
    } finally {
      _setLoading(false);
    }
  }, [tagId]);

  const handleCreate = () => {
    if (!tagId) {
      TagService.post({
        name: tag,
        qualifier: isPrimary ? "PRIMARY" : "OTHER",
        organization,
      })
        .then(({ data }) => {
          // _setData([...values, newValue]);
          setShowCreateTags(false);
          SWALService.showSuccess("New tag created.", "Created");
          fetchTags();
        })
        .catch((e) => toast.error(e?.message || "Something went wrong"));
    } else {
      TagService.put(tagId, {
        name: tag,
        qualifier: isPrimary ? "PRIMARY" : "OTHER",
        organization,
      })
        .then(({ data }) => {
          setShowCreateTags(false);
          SWALService.showSuccess("Tag updated.", "Updated");
          fetchTags();
        })
        .catch((e) => toast.error(e?.message || "Something went wrong"));
    }
  };
  return (
    <div className="d-flex justify-content-center py-4">
      <Modal
        // size={"lg"}
        isOpen={showCreateTags}
      >
        {/* <ModalHeader>Create Tags</ModalHeader> */}
        {_loading && <BrandLoader noOverlay />}
        {!_loading && (
          <>
            <ModalBody>
              <BaseTextInput
                label="Tag"
                placeholder="Enter the tag"
                value={tag}
                setValue={setTag}
              />
              <Row className="ml-1">
                <p className="mr-4">Make primary tag?</p>
                <Switch
                  value={isPrimary}
                  onChange={(e) => setIsPrimary(!isPrimary)}
                />
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleCreate}>
                {buttonText}
              </Button>
              <Button onClick={() => setShowCreateTags(false)}>Cancel</Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </div>
  );
}

export default CreateTags;
