import React from "react";
import SubjectListing from "./subcomponents/subjectList";
import {Row,Col} from "reactstrap";

class Subject extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: ''
    }
  }

  componentDidMount(){
    console.log('mounted');
  }

  render(){
    return(
      <div className="content">
        <SubjectListing/>
      </div>
    )
  }
}

export default Subject;
