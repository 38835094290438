import ENIRONMENTS_IS_DEV from "env";

export const STAGE_URL = "https://api-dev.parchai.io/v1";
export const PROD_URL = "https://api.parchai.io/v1";

export const LOCAL_BASE_URL = "http://localhost:9090/dev";

const isProd = !ENIRONMENTS_IS_DEV;

export const BASE_URL = isProd ? PROD_URL : STAGE_URL;

export const GATEWAY_URL = `${BASE_URL}/gateway`;
export const CONFIGURE_BEACON_URL = `${BASE_URL}/mqtt`;
export const BEACON_URL = `${BASE_URL}/beacon`;
export const BEACON_BULK_UPLOAD_URL = `${BASE_URL}/bulk/beacon`;
export const USER_URL = `${BASE_URL}/user`;
export const ORG_URL = `${BASE_URL}/organization`;
export const FLOOR_URL = `${BASE_URL}/floorplan`;
export const FLOOR_BULK_UPLOAD_URL = `${BASE_URL}/bulk/floorplan`;
export const FLOORSECTION_URL = `${BASE_URL}/floorplansection`;
export const FLOORSECTION_BULK_UPLOAD_URL = `${BASE_URL}/bulk/floorplansection`;
export const GATE_URL = `${BASE_URL}/gate`;
export const CAMPUS_URL = `${BASE_URL}/campus`;
export const BUILDING_URL = `${BASE_URL}/building`;
export const SUBJECT_URL = `${BASE_URL}/subject`;
export const SHIFTS_URL = `${BASE_URL}/shift`;
export const SHIFTS_BULK_UPLOAD_URL = `${BASE_URL}/bulk/shift`;
export const SUBJECT_BULK_UPLOAD_URL = `${BASE_URL}/bulk/subject`;
export const GROUPS_URL = `${BASE_URL}/groups`;
export const ACTION_URL = `${BASE_URL}/actions`;
export const POLICY_URL = `${BASE_URL}/policy`;
export const HOLIDAY_URL = `${BASE_URL}/holiday`;
export const COMMUNICATION_URL = `${BASE_URL}/communication`;
export const ATTENDANCE_URL = `${BASE_URL}/attendance`;
export const ATTENDANCE_NEW_URL = `${BASE_URL}/attendance2`;
export const ATTENDANCE_LOG_URL = `${ATTENDANCE_URL}/logs`;
export const ATTENDANCE_PERIODIC_URL = `${ATTENDANCE_URL}/periodic`;
export const SOS_ALERT_URL = `${BASE_URL}/alert`;
export const MASTER_DATA_URL = `${BASE_URL}/masterdata`;
export const MODULES_URL = `${BASE_URL}/masterdata/modules`;
export const OCCUPANCY_URL = `${BASE_URL}/gateway/occupancy`;
export const STATUS_URL = `${BASE_URL}/gateway/status`;
export const STATUS_LOG_URL = `${BASE_URL}/gateway/status/logs`;
//export const GATEWAYS_URL = 'https://q0qn30bqy5.execute-api.ap-south-1.amazonaws.com/dev/gateways';
export const GATEWAYS_URL = `${BASE_URL}/gateway`;
export const GATE_BULK_UPLOAD_URL = `${BASE_URL}/bulk/gateway`;
export const CALENDAR_URL = `${BASE_URL}/calendar`;
export const SCHEDULES_URL = `${BASE_URL}/schedule/event`;
export const AUDITTRAIL_URL = `${BASE_URL}/auditTrail`;
export const TAG_URL = `${BASE_URL}/groups`;
export const TAG_BULK_UPLOAD_URL = `${BASE_URL}/bulk/groups`;
export const MATTER_URL = `${BASE_URL}/matter`;
export const Matter_BULK_UPLOAD_URL = `${BASE_URL}/bulk/matter`;
export const HOOK_URL = `${BASE_URL}/hooks`;
export const VISITOR_LOG = `${BASE_URL}/visitor/log`;
export const VISITOR_REPORT = `${BASE_URL}/visitor/report`;
export const ONBOARDING_URL = `${ORG_URL}/onboardingStatus`;
export const ASSET_URL = `${BASE_URL}/asset`;
export const VEHICLE_URL = `${BASE_URL}/vehicle`;
export const ROUTE_URL = `${BASE_URL}/route`;
export const ROUTESTOP_URL = `${BASE_URL}/route-point`;
export const ROUTE_STOP_ASSET_URL = `${BASE_URL}/route-point/asset/map`;
export const EVENT_URL = `${BASE_URL}/event`;
export const AREA_PRESENCE_URL = `${BASE_URL}/area-presence`;
export const INFRA_PRESENCE_URL = `${BASE_URL}/infra-presence`;
export const BUILDING_PRESENCE_URL = `${BASE_URL}/building-presence`;
export const NOTIFICATION_URL = `${BASE_URL}/notification/log`;
export const ASSET_CHANGE_URL = `${BASE_URL}/ticket`;
export const TRANSITITINERARY_URL = `${BASE_URL}/transitItinerary`;
export const TRANSITITINERARY_BULK_UPLOAD_URL = `${BASE_URL}/transitItinerary`;
export const VEHICLE_SHIFT_URL = `${LOCAL_BASE_URL}/vehicle/shift`;

export const GATEWAYBEACON_PING_URL =
  "https://q0qn30bqy5.execute-api.ap-south-1.amazonaws.com/dev/BLEScan";
export const GEOCODE_URL =
  "https://nx7uaz2sl3.execute-api.ap-south-1.amazonaws.com/prod/locations";
