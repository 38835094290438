import TransitItinerary from "./index";
import TransitItineraryForm from "./subComponent/TransitItineraryForm";

const TransitItineraryRoutes = [
  {
    path: "/admin/transitItinerary/list",
    rtlName: "عالتسعير",
    component: TransitItinerary,
    layout: "/admin",
    name: "TransitItineraries",
    showVisible: false,
  },
  {
    path: "/admin/transitItinerary/add",
    name: "Add TransitItinerary",
    rtlName: "عالتسعير",
    component: TransitItineraryForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/transitItinerary/edit/:transitItineraryId",
    name: "Edit TransitItinerary",
    rtlName: "عالتسعير",
    component: TransitItineraryForm,
    layout: "/admin",
    showVisible: false,
  },
];

export default TransitItineraryRoutes;
