import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GatewayService from "services/GatewayService";
import BaseSelect from "./BaseSelect";

function GatewaySelect({
  value,
  setValue,
  context = {},
  noPopulate,
  deactive,
  inService,
  isMultiSelect,
  pickKey = "_id",
  labelKey = "macId",
}) {
  const organizationId = useSelector((state) => state.organization._id);
  const [gateways, setGateways] = useState([]);

  useEffect(() => {
    if (noPopulate) return;

    (async () => {
      const params = { ...(context || {}), organizationId };

      if (inService) params.active = true;
      if (deactive) params.unassigned = true;

      const { data } =
        (await GatewayService.get(params).catch((err) => console.log(err))) ||
        {};

      if (data)
        setGateways(
          data.map((el) => ({ value: el[pickKey], label: el[labelKey] }))
        );
    })();
  }, [...Object.values(context), noPopulate]);

  return (
    <BaseSelect
      label={"Gateway"}
      dataSource={gateways}
      placeholder={"Select gateway"}
      closeMenuOnSelect={false}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default GatewaySelect;
