import React, { useState } from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import BrandLoader from "views/custom_components/Loader";
import NoData from "views/custom_components/NoData";
import DateTimeService from "../../../../../services/DateTimeService";
import BaseTable from "components/Tables/BaseTable/BaseTable";
import AreaPresenceService from "services/AreaPresence.service";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import toast from "react-hot-toast";
import AssetPresenceFilter from "./AssetPresenceFilter";
import Avatar from "views/custom_components/Avatar/Avatar";
import SubjectView from "components/PopoverViews/SubjectView/SubjectView";

function AssetPresenceReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const getAlerts = (queryParams) => {
    setIsLoading(true);

    AreaPresenceService.get({ ...queryParams, hydrate: "asset,area" })
      .then(({ data }) => {
        if (!data.length) return toast.error("No data to show");
        setData(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const sosReportTableConfig = [
    {
      Header: "#",
      Cell: (row) => {
        return (
          <div>
            <Avatar
              height={34}
              src={row.original.asset?.attributes?.avatar}
              alt={row.original.asset?.name}
              gender={row.original.asset?.gender}
            />
          </div>
        );
      },
    },
    {
      Header: "Name",
      accessor: "asset.name",
      Cell: ({ original }) => (
        <SubjectView subjectId={original.asset._id} subjectData={original}>
          {original?.asset?.name}
        </SubjectView>
      ),
    },
    {
      Header: "Place",
      accessor: (d) => d?.area?.floorPlanSectionName || "N/A",
    },
    {
      Header: "Place Type",
      accessor: "gatewayAttachmentType",
    },
    {
      Header: "Entry Time",
      width: 200,
      accessor: (d) => DateTimeService.getDateTimeString(d?.createdAt),
    },
    {
      Header: "Exit Time",
      width: 200,
      accessor: (d) =>
        !!d.resolved
          ? DateTimeService.getDateTimeString(d?.updatedAt)
          : "NOT EXITED YET",
    },
  ];

  return (
    <>
      {isLoading && <BrandLoader />}
      <div className="content">
        <Row>
          <Col md={3}>
            <AssetPresenceFilter getAlerts={getAlerts} />
          </Col>
          <Col md={9}>
            <Card>
              <CardHeader>
                <TableHeader
                  title="Asset Presence Reports"
                  onClickDownload={true}
                  tableConfig={sosReportTableConfig}
                  tableData={data}
                />
              </CardHeader>
              <CardBody>
                {!!data?.length && (
                  <>
                    <BaseTable
                      tableConfig={sosReportTableConfig}
                      data={data}
                      loading={isLoading}
                    />
                  </>
                )}
                {!data.length && (
                  <NoData
                    imgSrc="/illustrations/warning.svg"
                    message="Select filters and press on 'get reports'"
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AssetPresenceReport;
