import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import React, { useState } from "react";
import { Form } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import TitleInput from "components/UserInputComponents/Inputs/TextInputs/TitleInput";
import MessageTypeSelect from "components/UserInputComponents/Selects/MessageTypeSelect";
import GroupEntitySelect from "components/UserInputComponents/Selects/GroupEntitySelect";
import TeacherSelect from "components/UserInputComponents/Selects/TeacherSelect";
import { useSelector } from "react-redux";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import TagSelect from "components/UserInputComponents/Selects/TagSelect";

function ParentConnectFilter({ setParams }) {
  const now = new Date();
  const todayNight = DateTimeService.getMidnightTime(now);
  const todayMorning = DateTimeService.getMorningTime(now);
  const [startDate, setStartDate] = useState(todayMorning);
  const [endDate, setEndDate] = useState(todayNight);

  const orgId = useSelector((state) => state.organization._id);

  const [msgtitle, setMsgTitle] = useState("");
  const [msgType, setMsgType] = useState([]);

  const [groups, setGroups] = useState([]);
  const [sender, setSender] = useState([]);

  const onSubmitFilter = (e) => {
    e.preventDefault();
    setParams({
      organizationId: orgId,
      title: msgtitle || "",
      targetFromTime: startDate.toISOString(),
      targetToTime: endDate.toISOString(),
      type: msgType,
      senderId: sender?.join(","),
      groups,
    });
  };

  return (
    <Form onSubmit={onSubmitFilter}>
      <StartDatePicker
        date={startDate}
        setDate={setStartDate}
        endDate={endDate}
      />
      <EndDatePicker
        date={endDate}
        setDate={setEndDate}
        startDate={startDate}
      />
      <TitleInput value={msgtitle} setValue={setMsgTitle} />
      <MessageTypeSelect value={msgType} setValue={setMsgType} />
      <TagSelect value={groups} setValue={setGroups} />
      <TeacherSelect
        value={sender}
        setValue={setSender}
        label={"Sender"}
        placeholder={"Select Sender"}
      />
      <BaseButton> Apply </BaseButton>
    </Form>
  );
}

export default ParentConnectFilter;
