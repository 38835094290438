import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { useSelector } from "react-redux";
import BeaconMacSelect from "components/UserInputComponents/Selects/BeaconMacSelect";
import GatewaySelect from "components/UserInputComponents/Selects/GatewaySelect";

function PolicyDiagnosisFilter({ setParams }) {
  const orgId = useSelector((state) => state.organization._id);
  const [beaconMac, setBeaconMac] = useState([]);
  const [gatewayMac, setGatewayMac] = useState("");

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <GatewaySelect value={gatewayMac} setValue={setGatewayMac} />
        <BeaconMacSelect
          value={beaconMac}
          setValue={setBeaconMac}
          isMultiSelect
          pickKey="macId"
        />
        <Button
          color="info"
          onClick={() =>
            setParams({
              organizationId: orgId,
              beaconMacs: beaconMac?.join(",") || "",
              gatewayMacs: gatewayMac || "",
            })
          }
        >
          Apply
        </Button>
      </CardBody>
    </Card>
  );
}

export default PolicyDiagnosisFilter;
