// considering system takes/understands only miliseconds
const Time = {
  milisecond: 1,
  second: 1_000,
  minute: 60_000,
  hour: 36_00_000,
  day: 8_64_00_000,
};

// considering system takes/understands only metres

const Distance = {
  meter: 1,
  kilometer: 1_000,
};

// considering system takes/understands only grams

const Weight = {};

const Units = {
  Time,
  Distance,
};

export default Units;
