import React, { useEffect, useMemo, useState } from "react";
import ScheduleService from "../../../../services/ScheduleService";
import { Card, CardBody, CardHeader, Row, Col, ButtonGroup } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import SWALService from "services/SWALService";

import ScheduleTable from "./sub-components/ScheduleTable";
import ScheduleFilter from "./sub-components/ScheduleFilter";
import TableHeader from "components/Tables/TableHeader/TableHeader";

import IconButton from "components/UserInputComponents/IconButton/IconButton";
import ScheduleCalendar from "./sub-components/ScheduleCalendar";

function ScheduleListing() {
  const organizationId = useSelector((state) => state.organization._id);
  const defaultParams = { organizationId };

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [scheduleParams, setScheduleParams] = useState({});
  const [tableConfig, setTableConfig] = useState([]);
  const [isCalenderView, setIsCalenderView] = useState(false);

  useEffect(() => {
    if (!Object.keys(scheduleParams).length) return;
    getSchedule();
  }, [scheduleParams]);

  const getSchedule = async () => {
    setLoading(true);

    const params = {
      ...scheduleParams,
      ...defaultParams,
    };

    const { data } = await ScheduleService.get(params).catch((err) =>
      SWALService.showError(err)
    );

    if (data) setSchedule(data);
    setLoading(false);
  };

  const addSchedule = () => history.push(`/admin/schedule/add`);

  return (
    <div className="content">
      <Row>
        <Col md={3}>
          <ScheduleFilter
            setScheduleParams={setScheduleParams}
            data={schedule}
          />
        </Col>
        <Col md={9}>
          <Card>
            <CardHeader>
              <TableHeader
                title="Schedules"
                onClickAdd={addSchedule}
                onClickDownload={true}
                tableConfig={tableConfig}
                tableData={schedule}
              >
                <ButtonGroup
                  style={{ marginRight: "45vw" }}
                  aria-label="Basic example"
                >
                  <IconButton
                    icon="calendar_view_week"
                    color={isCalenderView ? "success" : "info"}
                    onClick={() => setIsCalenderView(true)}
                  />
                  <IconButton
                    icon="table_rows"
                    color={!isCalenderView ? "success" : "info"}
                    onClick={() => setIsCalenderView(false)}
                  />
                </ButtonGroup>
              </TableHeader>
            </CardHeader>
            <CardBody>
              {isCalenderView && (
                <ScheduleCalendar
                  data={schedule}
                  getData={getSchedule}
                  loading={loading}
                />
              )}
              {!isCalenderView && (
                <ScheduleTable
                  data={schedule}
                  getData={getSchedule}
                  loading={loading}
                  setTableConfig={setTableConfig}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ScheduleListing;
