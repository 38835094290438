import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Select from "react-select";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import setQueryParams from "utils/setQueryParams";
import { useHistory } from "react-router-dom";

const formatArr = [
  {
    value: "CREATED",
    label: "CREATED",
  },
  {
    value: "UPDATED",
    label: "UPDATED",
  },
  {
    value: "DELETED",
    label: "DELETED",
  },
  {
    value: "USER_LOGIN",
    label: "USER_LOGIN",
  },
  {
    value: "USER_LOGOUT",
    label: "USER_LOGOUT",
  },
];

function AuditTrailLeftSearchPanel({ setAuditParams }) {
  const history = useHistory();
  const orgId = useSelector((state) => state.organization._id);

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [action, setAction] = useState();
  const [entity, setEntity] = useState();

  const applyFilters = (e) => {
    e.preventDefault();
    const payload = {
      fromDate: fromDate?.toISOString(),
      toDate: toDate?.toISOString(),
      action: action?.value,
      entity: entity,
      organizationId: orgId,
    };

    history.push({
      pathname: "/admin/audit_trail",
      search: `${setQueryParams(payload)}`,
    });

    setAuditParams(payload);
  };

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <Row className="separator">
          <Col sm="12">
            <label>Action</label>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              name="floorName"
              value={action}
              onChange={setAction}
              options={formatArr}
              placeholder="Action"
            />
          </Col>
        </Row>
        <BaseTextInput
          label="Entity"
          placeholder="Entity"
          type="text"
          value={entity}
          autoComplete="off"
          setValue={setEntity}
        />
        <StartDatePicker setDate={setFromDate} />
        <EndDatePicker setDate={setToDate} />
        <br />
        <Button color="info" onClick={applyFilters} disabled={false}>
          Get Audit Trail
        </Button>
      </CardBody>
    </Card>
  );
}

export default AuditTrailLeftSearchPanel;
