import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

export default function OccupancyTimer({ callApi, isComplete, seconds }) {
  const handleComplete = () => {
    callApi(!isComplete);
    return [true, 0];
  };
  return (
    <>
      <CountdownCircleTimer
        isPlaying
        strokeWidth={3}
        size={50}
        onComplete={() => handleComplete()}
        duration={seconds}
        colors={[
          ["#237cf6", 0.33],
          ["#F7B801", 0.33],
          ["#f23a3f", 0.33],
        ]}
      >
        {({ remainingTime }) => remainingTime}
      </CountdownCircleTimer>
    </>
  );
}
