import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Row } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import Swal from "sweetalert2";
import CloseButton from "views/custom_components/AlertModal2/sub-components/CloseButton/CloseButton";
import BrandLoader from "views/custom_components/Loader";
import OverlayView from "views/custom_components/OverlayView";
import ScheduleCalenderCreateEvent from "./ScheduleCalenderCreateEvent";

function ScheduleCalendar({ data, loading, getData }) {
  const localizer = momentLocalizer(moment);
  const history = useHistory();
  const [_localEvents, _setLocalEvents] = useState([]);
  const [minTimeBound, setMinTimeBound] = useState();
  const [maxTimeBound, setMaxTimeBound] = useState();
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [eventSlotInfo, setEventSlotInfo] = useState({});

  useEffect(() => {
    if (!data.length) return;
    getCurrentWeek();
  }, [data]);

  const getCurrentWeek = () => {
    var currentDate = moment();

    var weekStart = currentDate.clone().startOf("isoWeek");
    var weekEnd = currentDate.clone().endOf("isoWeek");

    var days = [];

    for (var i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, "days"));
    }
    onRangeChange(days);
  };

  const editSchedule = (scheduleId) =>
    history.push(`/admin/schedule/edit/${scheduleId}`);

  const selectedEvent = (event) => {
    const { incharge, matter } = event.resource;
    const fromTime = DateTimeService.getDateTimeString(event.start);
    const toTime = DateTimeService.getDateTimeString(event.end);

    Swal.fire({
      title: event.title,
      html: ` <p>From: ${fromTime}</p>
              <p>To: ${toTime}</p>
              <p>Room: 30</p>
              <p>Teacher: ${incharge.name}</p>
              <p>Subject: ${matter.name}</p>
              <p>Venue: Floor-3</p>`,
      icon: "info",
      confirmButtonText: "Edit",
      cancelButtonText: "Close",
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) return editSchedule(event.id);
    });
  };

  const addNewEventAlert = async (slotInfo) => {
    console.log("rohit", slotInfo);
    setEventSlotInfo(slotInfo);
    setIsEventModalOpen(true);
    // if (eventTitle) {
    //   addNewEvent(eventTitle, slotInfo);
    // }
  };

  const closeEventModal = () => setIsEventModalOpen(false);

  const addNewEvent = (e, slotInfo) => {
    _setLocalEvents((prevState) => [
      ...prevState,
      { id: 0, title: e, start: slotInfo.start, end: slotInfo.end },
    ]);
  };

  const onRangeChange = (range) => {
    let newEvents = [];
    range.forEach((date) => {
      data.map((event) => {
        const _dayOfWeek = moment(date).day() - 1;
        if (event.daysOfWeek.includes(_dayOfWeek)) {
          newEvents.push({
            id: event._id,
            title: event.name,
            start: new Date(
              date.toISOString().slice(0, date.toISOString().indexOf("T") + 1) +
                event.fromTime +
                "Z"
            ),
            end: new Date(
              date.toISOString().slice(0, date.toISOString().indexOf("T") + 1) +
                event.toTime +
                "Z"
            ),
            resource: event,
          });
        }
      });
    });

    let minTime = newEvents[0]?.start;
    let maxTime = newEvents[0]?.end;
    newEvents.forEach((event) => {
      const time = new Date(event.start).toLocaleTimeString("it-IT");
      const endTime = new Date(event.end).toLocaleTimeString("it-IT");
      const minTimeCheck = new Date(minTime).toLocaleTimeString("it-IT");
      const maxTimeCheck = new Date(maxTime).toLocaleTimeString("it-IT");

      if (time < minTimeCheck) {
        minTime = event.start;
      }
      if (endTime > maxTimeCheck) {
        maxTime = event.end;
      }
    });
    setMinTimeBound(minTime);
    setMaxTimeBound(maxTime);
    _setLocalEvents(newEvents);
  };

  return (
    <>
      {loading && <BrandLoader noOverlay />}
      {!loading && (
        <>
          <ScheduleCalenderCreateEvent
            loading={loading}
            isOpen={isEventModalOpen}
            close={closeEventModal}
            slotInfo={eventSlotInfo}
          />
          <Calendar
            selectable
            dayLayoutAlgorithm="no-overlap"
            min={minTimeBound}
            max={maxTimeBound}
            localizer={localizer}
            onRangeChange={onRangeChange}
            events={_localEvents}
            defaultView="week"
            views={["week"]}
            scrollToTime={new Date(1970, 1, 1, 6)}
            defaultDate={new Date()}
            onSelectEvent={(event) => selectedEvent(event)}
            onSelectSlot={(slotInfo) => addNewEventAlert(slotInfo)}
            // eventPropGetter={eventColors}
          />
        </>
      )}
    </>
  );
}

export default ScheduleCalendar;
