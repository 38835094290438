import user from "./user";
import organization from "./organization";
import auth from "./auth";

const combinedTypes = {
  ...user,
  ...organization,
  ...auth,
};

export default combinedTypes;
