import ChartColors from "config/charts/ChartColors";
import LineChartConfig from "config/charts/LineChartConfig";
import React from "react";
import { Line } from "react-chartjs-2";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import NoData from "views/custom_components/NoData";

import "./VisitorCountGraph.scss";

function VisitorCountGraph({ data = [], context }) {
  const labels = data?.map(
    (el) =>
      `${DateTimeService.getTimeString(
        el.fromTime
      )} to ${DateTimeService.getTimeString(el.toTime)}`
  );
  const chartData = data?.map((el) => el.count);

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Visitor Peak Periods</CardTitle>
        <p className="text-muted">Visitors ~ Time </p>
      </CardHeader>
      <CardBody>
        {!!data?.length && (
          <div className="chart-area">
            <Line
              options={LineChartConfig.options}
              data={(c) =>
                LineChartConfig.createData(
                  c,
                  labels,
                  chartData,
                  "Visitors",
                  ChartColors
                )
              }
            />
          </div>
        )}
        {!data?.length && (
          <NoData
            show={!data?.length}
            message={`No visitors for ${context}`}
            imgSrc="/illustrations/empty_street.svg"
          />
        )}
      </CardBody>
    </Card>
  );
}

export default VisitorCountGraph;
