import BeaconInput from "components/UserInputComponents/Inputs/TextInputs/BeaconInput";
import NameInput from "components/UserInputComponents/Inputs/TextInputs/NameInput";
import AgeInput from "components/UserInputComponents/Inputs/NumberInputs/AgeInput";
import UniqueIdInput from "components/UserInputComponents/Inputs/TextInputs/UniqueIdInput";
import GenderSelect from "components/UserInputComponents/Selects/GenderSelect";
import RoleSelect from "components/UserInputComponents/Selects/RoleSelect";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Form } from "reactstrap";
import { useSelector } from "react-redux";
import TagSelect from "components/UserInputComponents/Selects/TagSelect";
import PhoneNumberInput from "components/UserInputComponents/Inputs/NumberInputs/PhoneNumberInput";
import useQuery from "customHooks/useQuery";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";

function SubjectFilter({ getSubjects }) {
  const [roles, setRoles] = useState([]);
  const [names, setNames] = useState("");
  const [gender, setGender] = useState("");
  const [beacons, setBeacons] = useState([]);
  const [groups, setGroups] = useState([]);
  const [uniqueIDs, setUniqueIDs] = useState("");
  const [age, setAge] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [searchParams] = useQuery();

  const organizationId = useSelector((state) => state.organization._id);

  useEffect(() => {
    if (!searchParams) return;
    getSubjects({
      organizationId,
      role: searchParams?.role,
      name: searchParams?.name,
      uniqueId: searchParams?.id,
    });

    setNames(searchParams?.name);
    setRoles([searchParams?.role]);
    setUniqueIDs(searchParams?.id);
  }, [searchParams]);

  const handleSubmit = (e) => {
    e.preventDefault();
    getSubjects({
      organizationId,
      role: roles?.join(",") || "",
      groups: groups?.join(",") || "",
      gender: gender || "",
      name: names || "",
      age: age || "",
      beaconMac: beacons || "",
      uniqueId: uniqueIDs || "",
      phoneNumber: phoneNumber,
    });
  };

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <RoleSelect
            value={roles}
            className="mb-4"
            setValue={setRoles}
            isMultiSelect
          />
          <TagSelect value={groups} setValue={setGroups} isMultiSelect />
          <GenderSelect className="mb-4" value={gender} setValue={setGender} />
          <NameInput className="mb-4" setValue={setNames} value={names} />
          <AgeInput setValue={setAge} value={age} />
          <PhoneNumberInput setValue={setPhoneNumber} value={phoneNumber} />
          <BeaconInput
            className="mb-4"
            label="Beacon Mac Id"
            placeholder="Enter beacon macId"
            setValue={setBeacons}
            value={beacons}
          />
          <UniqueIdInput
            className="mb-4"
            setValue={setUniqueIDs}
            value={uniqueIDs}
          />
          <BaseButton>Apply</BaseButton>
        </Form>
      </CardBody>
    </Card>
  );
}

export default SubjectFilter;
