import Image from "components/Image/Image";
import BaseTable from "components/Tables/BaseTable/BaseTable";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import SheetImg from "../../assets/img/ss_sheet.png";

function CSVuploadManual({ setModalOpen, modalOpen, title, gId }) {
  return (
    <Modal isOpen={modalOpen}>
      <ModalHeader>{title || "CSV Upload Manual"}</ModalHeader>
      <ModalBody>
        <Image src={SheetImg} />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            window.open(
              `https://docs.google.com/spreadsheets/d/1OuZnDOFWj0P1wuNb0D1z7lCws61y-u4G8-QTQLatqjo/export?format=csv&gid=${gId}`,
            );

            setModalOpen(false);
          }}
        >
          Download Sample CSV
        </Button>
        <Button
          color="info"
          onClick={() => {
            window.open(
              `https://docs.google.com/spreadsheets/d/1OuZnDOFWj0P1wuNb0D1z7lCws61y-u4G8-QTQLatqjo/edit?usp=sharing#gid=${gId}`,
            );
            setModalOpen(false);
          }}
        >
          Clone Sample CSV
        </Button>
        <Button
          onClick={() => {
            setModalOpen(false);
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default CSVuploadManual;
