import PhoneNumberInput from "components/UserInputComponents/Inputs/NumberInputs/PhoneNumberInput";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import EmailInput from "components/UserInputComponents/Inputs/TextInputs/EmailInput";
import BooleanSelect from "components/UserInputComponents/Selects/BooleanSelect";
import UserRoleSelect from "components/UserInputComponents/Selects/UserRoleSelect";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader } from "reactstrap";

function UserFilters({ getUsers }) {
  const orgId = useSelector((state) => state.organization._id);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setUserRole] = useState("");
  const [isActive, setIsActive] = useState("");
  const [isConfirmed, setIsConfirmed] = useState("");

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <BaseTextInput
          label="First Name"
          placeholder="Search with first name"
          type="text"
          value={firstName}
          autoComplete="off"
          setValue={setFirstName}
        />
        <BaseTextInput
          label="Last Name"
          placeholder="Search with last name"
          type="text"
          value={lastName}
          autoComplete="off"
          setValue={setLastName}
        />
        <UserRoleSelect value={role} setValue={setUserRole} />
        <EmailInput value={email} setValue={setEmail} />
        <PhoneNumberInput value={phoneNumber} setValue={setPhoneNumber} />
        <BooleanSelect
          value={isActive}
          setValue={setIsActive}
          label={"Active"}
          bottomHintText={"Search by active or inactive users"}
        />
        <BooleanSelect
          value={isConfirmed}
          setValue={setIsConfirmed}
          label={"Confirm"}
          bottomHintText={"Search by confirmed or denied users"}
        />

        <Button
          color="info"
          onClick={() =>
            getUsers({
              organization: orgId,
              firstName: firstName,
              lastName: lastName,
              role: role,
              email: email,
              phoneNumber: phoneNumber,
              isActive: isActive,
              isConfirmed: isConfirmed,
            })
          }
        >
          Apply
        </Button>
      </CardBody>
    </Card>
  );
}

export default UserFilters;
