import React from 'react'
import Loader from 'react-spinners/PuffLoader';
import { Button, FormGroup } from 'reactstrap';
import './LoadButton.css'

function LoadButton({ color, children, loading}) {
  return (
    <div className="BaseButton">
      <FormGroup>
        <Button
          className={`float-right loadButton`}
          color={color || "info"}
          type="submit"
        >
          {!loading ? (
            children
          ) : (
            <div className="loader">
              <Loader color={'white'} />
            </div>
          )}
        </Button>
      </FormGroup>
    </div>
  );
}

export default LoadButton