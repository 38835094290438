import CampusSelect from "components/UserInputComponents/Selects/CampusSelect";
import FloorPlanSelect from "components/UserInputComponents/Selects/FloorPlanSelect";
import React, { useState } from "react";
import { Card, Row, Col } from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import CardHeader from "reactstrap/lib/CardHeader";
import CardTitle from "reactstrap/lib/CardTitle";
import MapView from "views/custom_components/LeafletMaps/ViewMaps/sub-components/MapView/MapView";
import NoData from "views/custom_components/NoData";

import OccupancyView from "views/custom_components/OccupancyView";

import "./FloorSurvillence.scss";

function FloorSurvillence() {
  const [floorPlan, setFloorPlan] = useState();
  const [campus, setCampus] = useState();

  const [floorSectionId, setFloorSectionId] = useState(null);

  const onCloseModal = () => setFloorSectionId(null);
  const onClickShape = (id) => setFloorSectionId(id);

  return (
    <div className="FloorSurvillence">
      <Card>
        <CardHeader className="FloorSurvillence__CardHeader">
          <Row className="d-flex justify-content-between align-items-center">
            <CardTitle tag="h4">Sixth Sense Surveillance</CardTitle>
            <div className="float-right">
              <Row className="FloorSurvillence__Actions">
                <Col>
                  <CampusSelect value={campus} setValue={setCampus} noLabel />
                </Col>
                <Col>
                  <FloorPlanSelect
                    value={floorPlan}
                    setValue={setFloorPlan}
                    context={{ campusId: campus }}
                    noLabel
                  />
                </Col>
              </Row>
            </div>
          </Row>
        </CardHeader>
        <CardBody className="FloorSurvillence__CardBody">
          {!!floorPlan && (
            <MapView activeFloorPlan={floorPlan} onClickShape={onClickShape} />
          )}
          {!floorPlan && (
            <NoData
              message="Select a floorplan to start"
              imgSrc="/illustrations/current_location.svg"
            />
          )}
        </CardBody>
      </Card>

      {!!floorSectionId && (
        <OccupancyView
          context={{ floorPlanSectionId: floorSectionId }}
          isOpen={!!floorSectionId}
          close={onCloseModal}
        />
      )}
    </div>
  );
}

export default FloorSurvillence;
