import ImageUpload from "components/CustomUpload/ImageUpload";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import MatterService from "services/MatterService";
import SWALService from "services/SWALService";
import UtilsService from "services/UtilsService";

function CreateMatter({
  showCreateMatters,
  setShowCreateMatters,
  fetchMatters,
  tagValue,
}) {
  const [matter, setMatter] = useState("");
  const [avatar, setAvatar] = useState("");

  const { _id: organization } = useSelector((s) => s.organization);

  useEffect(() => {
    setMatter(tagValue);
  }, [tagValue]);

  const handleCreate = () => {
    MatterService.post({
      name: matter,
      attributes: {
        avatar,
      },
      organization,
    }).then(({ data }) => {
      // _setData([...values, newValue]);
      setShowCreateMatters(false);
      SWALService.showSuccess("New matter created.", "Created");
      fetchMatters();
    });
  };

  const parentCallback = (data) => {
    removePreviousImage();
    const utilsService = new UtilsService();
    const icon = utilsService.removeURLQueryStringParams(data);
    setAvatar(icon);
  };

  const removePreviousImage = () => {
    if (avatar !== "") {
      setAvatar("");
    }
  };

  return (
    <div className="d-flex justify-content-center py-4">
      <Modal
        // size={"lg"}
        isOpen={showCreateMatters}
      >
        {/* <ModalHeader>Create Tags</ModalHeader> */}
        <ModalBody>
          <BaseTextInput
            label="Matter"
            placeholder="Enter the matter"
            value={matter}
            setValue={setMatter}
          />
          <Label>Icon</Label>
          <FormGroup>
            <ImageUpload
              addBtnColor="default"
              changeBtnColor="default"
              fileData={avatar}
              context="matterAvatarPic"
              parentCallback={parentCallback}
              removePreviousImage={removePreviousImage}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCreate}>
            Create
          </Button>
          <Button onClick={() => setShowCreateMatters(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CreateMatter;
