import IconButton from "components/UserInputComponents/IconButton/IconButton";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import BaseTimePicker from "components/UserInputComponents/TimePickers/BaseTimePicker";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Button, Col, Row } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import VehicleService from "services/VehicleService";
import VehicleShiftService from "services/VehicleShiftService";

function VehicleShiftsModal({ close, vehicleId }) {
  const [shiftDetails, setShiftDetails] = useState([
    { shiftName: `Shift-1`, shiftStart: "", shiftEnd: "" },
  ]);

  useEffect(() => {
    (async () => {
      if (vehicleId) {
        const { data } = await VehicleService.getById(vehicleId);
        const formattedData = data?.shifts.map((el) => {
          return {
            shiftName: el.shiftName,
            shiftStart: DateTimeService.getDateTimeFromTimeString(
              el.shiftStart
            ),
            shiftEnd: DateTimeService.getDateTimeFromTimeString(el.shiftEnd),
          };
        });
        setShiftDetails(formattedData);
      }
    })();
  }, [vehicleId]);

  const handleChange = (e, index) => {
    const prevShifts = [...shiftDetails];
    prevShifts[index][e.target.name] = e.target.value;
    setShiftDetails(prevShifts);
  };

  const handleStartTime = (val, index) => {
    const prevShifts = [...shiftDetails];
    prevShifts[index].shiftStart = val;
    setShiftDetails(prevShifts);
  };

  const handleEndTime = (val, index) => {
    const prevShifts = [...shiftDetails];
    prevShifts[index].shiftEnd = val;
    setShiftDetails(prevShifts);
  };

  const addShifts = () => {
    const prevShifts = [...shiftDetails];
    prevShifts.push({
      shiftName: `Shift-${prevShifts.length + 1}`,
      shiftStart: "",
      shiftEnd: "",
    });
    setShiftDetails(prevShifts);
  };

  const removeShifts = (index) => {
    const prevShifts = [...shiftDetails];
    prevShifts.splice(index, 1);
    setShiftDetails(prevShifts);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedData = shiftDetails?.map((el) => {
      return {
        shiftName: el.shiftName,
        shiftStart: DateTimeService.get24TimeStringUTC(el.shiftStart),
        shiftEnd: DateTimeService.get24TimeStringUTC(el.shiftEnd),
      };
    });
    console.log("date", formattedData);
    const { message } = await VehicleShiftService.put(vehicleId, formattedData);
    close();
    toast.success(message);
  };

  return (
    <div className="container">
      {shiftDetails.map((el, index) => {
        return (
          <Row className="align-items-center justify-content-center">
            <Col md={4}>
              <BaseTextInput
                name="shiftName"
                label="Shift Name"
                value={el?.shiftName}
                changeMethod={(e) => handleChange(e, index)}
              />
            </Col>
            <Col md={3}>
              <BaseTimePicker
                name="startTime"
                label="Shift Start"
                value={el?.shiftStart}
                changeValue={(val) => handleStartTime(val, index)}
              />
            </Col>
            <Col md={3}>
              <BaseTimePicker
                name="endTime"
                label="Shift End"
                value={el?.shiftEnd}
                changeValue={(val) => handleEndTime(val, index)}
              />
            </Col>
            <Col md={1}>
              <IconButton
                onClick={() => addShifts(index)}
                icon="add_circle"
                color="success"
              />
            </Col>
            <Col md={1}>
              <IconButton
                onClick={() => removeShifts(index)}
                icon="do_not_disturb_on"
                color="danger"
              />
            </Col>
          </Row>
        );
      })}

      <Button onClick={handleSubmit} color="success">
        Submit
      </Button>

      <Button color="danger" onClick={() => close()}>
        Cancel
      </Button>
    </div>
  );
}

export default VehicleShiftsModal;
