import React, { useEffect, useState } from "react";
// reactstrap components
import { Form } from "reactstrap";
// core components
import TransitItineraryService from "../../../../../services/TransitItineraryService";
import { useHistory, useParams } from "react-router-dom";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput.js";
import FormHolder from "components/Holders/FormHolder/FormHolder.js";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton.js";
import Forms from "../../../../../config/copies/forms";
import SWALService from "services/SWALService";
import { useSelector } from "react-redux";
import Validate from "utils/validators";
import CSVupload from "views/components/CSVupload";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import CSVuploadManual from "views/components/CSVuploadManual";
import { SHIFTS_BULK_UPLOAD_URL } from "config/api";
import Swal from "sweetalert2";
import AssetSelect from "components/UserInputComponents/Selects/AssetSelect";
import RouteSelect from "components/UserInputComponents/Selects/RouteSelect";
import ShiftSelect from "components/UserInputComponents/Selects/ShiftSelect";

function TransitItineraryForm() {
  const organizationId = useSelector((state) => state.organization._id);

  const { transitItineraryId } = useParams();
  const history = useHistory(); //browserHistory stack

  const buttonText = !!transitItineraryId ? "Save Changes" : "Submit";

  const [transitItinerarytName, setTransitItineraryName] = useState("");
  const [onboarders, setOnboarders] = useState([]);
  const [shift, setShift] = useState([]);
  const [route, setRoute] = useState("");
  const [helpers, setHelpers] = useState([]);
  const [driver, setDriver] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [manualModal, setManualModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transitItineraryData, setTransitItineraryData] = useState([]);

  const table = [
    {
      Header: "Name",
      accessor: "name",
    },
  ];

  useEffect(() => {
    if (!transitItineraryId) return;

    (async () => {
      const { data } = await TransitItineraryService.getById(
        transitItineraryId,
      ).catch((err) => SWALService.showError(err));
      if (!data) return;

      setTransitItineraryName(data?.name);
      setDriver(data?.driver);
      setHelpers(data?.helpers);
      setOnboarders(data?.onboarders);
      setRoute(data?.route);
      setShift(data?.shift);
    })();
  }, [transitItineraryId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (transitItinerarytName === "") return;

    const TransitItineraryObj = {
      name: transitItinerarytName,
      shift: shift,
      route: route,
      helpers: helpers,
      driver: driver,
      onboarders: onboarders,
      organization: organizationId,
    };

    if (!transitItineraryId)
      return TransitItineraryService.post(TransitItineraryObj)
        .then(({ message }) => {
          history.goBack();
          SWALService.showSuccess(message);
        })
        .catch((error) => {
          SWALService.showError(error.message);
        });

    TransitItineraryService.put(transitItineraryId, TransitItineraryObj)
      .then(({ message }) => {
        history.goBack();
        SWALService.showSuccess(message);
      })
      .catch((err) => SWALService.showError(err));
  };

  const handelFileUpload = () => {
    transitItineraryData.forEach((transitItinerary) => {
      transitItinerary.organization = organizationId;
    });
    TransitItineraryService.doPost(
      SHIFTS_BULK_UPLOAD_URL,
      transitItineraryData,
      true,
    )
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Shift Added",
          text: response.msg,
        }).then(() => {
          history.push("/admin/transitItinerary/list");
        });
      })
      .catch((error) => {
        SWALService.showError(error.message);
      });
    console.log(transitItineraryData);
  };

  return (
    <div className="content">
      <CSVupload
        title="TransitItinerary Data Upload"
        modalOpen={modalOpen}
        data={transitItineraryData}
        setData={setTransitItineraryData}
        handleSubmit={handelFileUpload}
        setModalOpen={setModalOpen}
        tableConfig={table}
        setShowManual={setManualModal}
      />
      <CSVuploadManual
        setModalOpen={setManualModal}
        modalOpen={manualModal}
        gId={"1262306929"}
      />
      <FormHolder
        imgSrc={Forms.TRANSITITINERARY.illustration}
        formHeader={Forms.TRANSITITINERARY.title}
        formDescription={Forms.TRANSITITINERARY.desc}
        formActions={
          <IconButton
            icon={"upload_file"}
            onClick={() => {
              setModalOpen(true);
              setTransitItineraryData([]);
            }}
            color={"info"}
            outlined
          />
        }
      >
        <Form onSubmit={handleSubmit}>
          <BaseTextInput
            label="TransitItinerary Name"
            placeholder="Enter TransitItinerary name"
            value={transitItinerarytName}
            setValue={setTransitItineraryName}
            validator={Validate.Name}
          />
          <AssetSelect
            value={onboarders}
            setValue={setOnboarders}
            label="Onboarders"
            isMultiSelect={true}
          />
          <AssetSelect value={driver} setValue={setDriver} label="Driver" />
          <AssetSelect
            value={helpers}
            setValue={setHelpers}
            label="Helpers"
            isMultiSelect={true}
          />
          <RouteSelect value={route} setValue={setRoute} />
          <ShiftSelect value={shift} setValue={setShift} isMultiSelect={true} />
          <BaseButton color="info">{buttonText}</BaseButton>
        </Form>
      </FormHolder>
    </div>
  );
}

export default TransitItineraryForm;
