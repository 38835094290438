import FormHolder from "components/Holders/FormHolder/FormHolder";
import Forms from "config/copies/forms";
import React from "react";
import { Row } from "reactstrap";

function UserAdd() {
  return (
    <div className="content">
      <Row>
        <FormHolder
          imgSrc={Forms.USER.illustration}
          formHeader={Forms.USER.title}
          formDescription={Forms.USER.desc}
          CardFooterComponent=""
        >
          <h4 className="font-weight-bold mt-n8 text-center">
            <span>Your code for joining is : abcdRohit</span>
          </h4>
        </FormHolder>
      </Row>
    </div>
  );
}

export default UserAdd;
