import Icon from "components/Icon/Icon";
import React from "react";

function EventAnnouncementBlock({ isApproved, title, date }) {
  return (
    <div className="d-flex mb-4" data-metatip="true">
      <div className="icon-container">
        <div className="icon icon-shape bg-info-soft shadow text-center border-radius-xs shadow-none">
          <Icon
            icon="campaign"
            size="lg"
            gradient={`${isApproved ? "success" : "info"}`}
          />
        </div>
      </div>
      <div className="ms-3">
        <div className="numbers">
          <h5 className="mb-1 text-dark text-sm">{title}</h5>
          <span className="text-sm">{date}</span>
        </div>
      </div>
    </div>
  );
}

export default EventAnnouncementBlock;
