import SubjectView from "components/PopoverViews/SubjectView/SubjectView";
import React from "react";
import Avatar from "views/custom_components/Avatar/Avatar";

const DailyAttendanceSummaryTableConfig = [
  {
    Header: "#",
    width: 80,
    Cell: ({ original: { asset } }) => {
      console.log("asset", asset);
      return (
        <div>
          <Avatar
            height={34}
            src={asset?.attributes?.avatar || ""}
            alt={asset?.name || ""}
            gender={asset?.attributes?.gender}
          />
        </div>
      );
    },
  },
  {
    Header: "Name",
    accessor: "asset.name",
    Cell: ({ original: { asset } }) => (
      <SubjectView subjectId={asset._id} subjectData={asset}>
        {asset.name}
      </SubjectView>
    ),
  },
  {
    Header: "Unique ID",
    accessor: "asset.uniqueId",
  },
  {
    Header: "Role",
    accessor: "asset.role",
    excludeFromUI: true,
  },
  {
    Header: "Primary Group",
    accessor: ({ asset }) => asset?.primaryGroup?.name,
    Cell: ({ original: { asset } }) => <>{asset?.primaryGroup?.name}</>,
  },
  {
    Header: "Present",
    accessor: "present",
    width: 80,
  },
  {
    Header: "Absent",
    accessor: "absent",
    width: 80,
  },
  {
    Header: "On Time",
    accessor: "onTime",
    // Cell: ({ original }) => <>{Math.ceil(0.8 * Number(original.present))}</>,
    width: 80,
  },
  {
    Header: "Late",
    accessor: "isLate",
    Cell: ({ original }) => <>{Math.floor(0.2 * Number(original.present))}</>,
    width: 80,
  },
];

export default DailyAttendanceSummaryTableConfig;
