import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import GatewayService from "services/GatewayService";
import SWALService from "services/SWALService";

function GatewayTable({ getGateway, gatewayData, loading, setTableConfig }) {
  const history = useHistory();
  const gatewayConfig = [
    {
      Header: "MAC",
      accessor: "macId",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Model",
      accessor: "model",
    },
    {
      Header: "Assigned IP",
      accessor: "assignedIP",
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editGateway}
          deleteCallback={deleteGateway}
          // manageCallback={manageGateway}
        />
      ),
    },
  ];

  useEffect(() => {
    setTableConfig(gatewayConfig);
  }, []);

  // const manageGateway = () => {
  //   history.push({
  //     pathname: "/admin/gateway-beacon/diagnosis",
  //     search: `?context=gateway&gatewayMac=1234`,
  //   });
  // };

  const editGateway = (gatewayId) =>
    history.push(`/admin/gateway/edit/${gatewayId}`);

  const deleteGateway = async (gatewayId) => {
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    const { success } = await GatewayService.delete(gatewayId).catch((err) =>
      SWALService.showError(err)
    );

    if (!success) return;

    getGateway();

    SWALService.showSuccess("Gateway has been deleted.", "Deleted!");
  };

  return (
    <BaseTable
      tableConfig={gatewayConfig}
      data={gatewayData}
      loading={loading}
    />
  );
}

export default GatewayTable;
