import React from "react";
import { Badge } from "reactstrap";

function CloseButton({ close }) {
  return (
    <div
      style={{
        position: "fixed",
        right: "1rem",
        top: "1rem",
        zIndex: "99999999",
      }}
    >
      <Badge onClick={close} color="danger" style={{ cursor: "pointer" }}>
        <i className="tim-icons icon-simple-remove" />
      </Badge>
    </div>
  );
}

export default CloseButton;
