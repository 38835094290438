import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import BaseSelect from "components/UserInputComponents/Selects/BaseSelect";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "reactstrap";
import DateTimeService from "services/DateTimeService";

const statusOptions = ["PENDING", "APPROVED", "REJECTED"].map((e) => ({
  value: e,
  label: e,
}));

function Filter({ setParams }) {
  const orgId = useSelector((state) => state.organization._id);

  const currentTimestamp = new Date();

  const todayNight = DateTimeService.getMidnightTime(currentTimestamp);
  const todayMorning = DateTimeService.getMorningTime(currentTimestamp);

  const [status, setStatus] = useState("PENDING");
  const [fromTime, setFromTime] = useState(todayMorning);
  const [toTime, setToTime] = useState(todayNight);
  // const orgId = "621642aa5656450009819ffc";

  const changeParams = () =>
    setParams({
      toTime: toTime.toISOString(),
      fromTime: fromTime.toISOString(),
      organizationId: orgId,
      status,
    });

  const onSubmit = (e) => {
    e.preventDefault();
    changeParams();
  };

  useEffect(() => {
    changeParams();
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      <StartDatePicker date={fromTime} setDate={setFromTime} />
      <EndDatePicker date={toTime} setDate={setToTime} />

      <BaseSelect
        value={status}
        setValue={setStatus}
        dataSource={statusOptions}
        label="Status"
      />

      <BaseButton>Apply</BaseButton>
    </Form>
  );
}

export default Filter;
