import { FLOORSECTION_URL } from "config/api";
import BaseService from "./BaseService";

export default class LeafletMapsService {
  url = FLOORSECTION_URL;

  constructor(method, headers, data) {
    this.baseService = new BaseService();
  }

  async getMapPlot(floorPlanSectionId, params = {}, headers) {
    return (
      await this.baseService.doGet(
        this.url,
        "GET",
        { ...params, floorPlanSectionId },
        headers
      )
    ).floorPlanSectionPlot;
  }

  async getMapPlots(floorPlanId, params = {}, headers) {
    return await this.baseService.doGET(
      this.url,
      "GET",
      { ...params, floorPlanId },
      headers
    );
  }

  async putMapPlots(floorPlanSectionId, data, headers) {
    return await this.baseService.doPUT(
      this.url,
      "PUT",
      data,
      floorPlanSectionId,
      headers
    );
  }

  async deleteMapPlots(floorPlanSectionId, deleteKeys, headers) {
    return await this.baseService.doDELETE(
      this.url,
      "DELETE",
      { deleteKeys },
      floorPlanSectionId,
      headers
    );
  }

  async patchFloorSectionPlot(data, urlParam, headers) {
    return await this.baseService.doPATCH(
      `${FLOORSECTION_URL}/${urlParam}/plot`,
      data,
      headers
    );
  }
}
