import React, { useEffect, useState } from "react";
import BaseSelect from "./BaseSelect";

function MasterDataSelect({ value, setValue, isMultiSelect, label }) {
  const Types = [
    { value: "ROLE", label: "ROLE" },
    { value: "GROUPED ENTITY", label: "GROUPED_ENTITY" },
    { value: "ASSET_TYPE", label: "ASSET_TYPE" },
  ];

  return (
    <BaseSelect
      label={label}
      dataSource={Types}
      placeholder={"Select type of Role"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default MasterDataSelect;
