import React, { useEffect, useState } from "react";
// reactstrap components
import { Form } from "reactstrap";
// core components
import CampusService from "../../../../../services/CampusService";
import { useHistory, useParams } from "react-router-dom";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput.js";
import FormHolder from "components/Holders/FormHolder/FormHolder.js";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton.js";
import LocationSelector from "../../../../../components/UserInputComponents/LocationSelector/LocationSelector";
import Forms from "../../../../../config/copies/forms";
import SWALService from "services/SWALService";
import { useSelector } from "react-redux";
import Validate from "utils/validators";
import BaseTextAreaInput from "components/UserInputComponents/Inputs/TextInputs/BestTextAreaInput";

function CampusForm() {
  const organizationId = useSelector((state) => state.organization._id);

  const { campusId } = useParams();
  const history = useHistory(); //browserHistory stack

  const buttonText = !!campusId ? "Save Changes" : "Submit";

  const [campusName, setcampusName] = useState("");
  const [campusDesc, setcampusDesc] = useState("");
  const [location, setLocation] = useState({});

  useEffect(() => {
    if (!campusId) return;

    (async () => {
      const { data } = await CampusService.getById(campusId).catch((err) =>
        SWALService.showError(err)
      );
      if (!data) return;

      setcampusName(data?.name || "");
      setcampusDesc(data?.desc || "");
      setLocation(data?.location);
    })();
  }, [campusId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (campusName === "") return;

    const campusObj = {
      name: campusName,
      desc: campusDesc,
      location,
      organization: organizationId,
    };

    if (!campusId)
      return CampusService.post(campusObj).then(({ message }) => {
        history.goBack();
        SWALService.showSuccess(message);
      });

    CampusService.put(campusId, campusObj).then(({ message }) => {
      history.goBack();
      SWALService.showSuccess(message);
    });
  };

  return (
    <div className="content">
      <FormHolder
        imgSrc={Forms.CAMPUS.illustration}
        formHeader={Forms.CAMPUS.title}
        formDescription={Forms.CAMPUS.desc}
      >
        <Form onSubmit={handleSubmit}>
          <BaseTextInput
            label="Campus Name"
            placeholder="Enter campus name"
            value={campusName}
            setValue={setcampusName}
            validator={Validate.Name}
          />

          <BaseTextAreaInput
            label="Campus Description (*)"
            placeholder="Add a short description"
            value={campusDesc}
            setValue={setcampusDesc}
          />

          <LocationSelector
            label="Address"
            setValue={setLocation}
            location={location}
          />
          <BaseButton color="info">{buttonText}</BaseButton>
        </Form>
      </FormHolder>
    </div>
  );
}

export default CampusForm;
