import RedirectWidgetHolder from "components/Holders/RedirectWidgetHolder/RedirectWidgetHolder";
import Icon from "components/Icon/Icon";
import React, { useState } from "react";
import {
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  NavLink,
  Row,
  Col,
} from "reactstrap";

import "./NotificationPrompt.scss";

function NotificationPrompt() {
  const [deleteNotifications, setDeleteNotifications] = useState(false);
  return (
    <UncontrolledDropdown nav>
      <DropdownToggle caret color="default" data-toggle="dropdown" nav>
        <i className="tim-icons icon-sound-wave" />
        <p className="d-lg-none">Notifications</p>
      </DropdownToggle>
      <DropdownMenu className="dropdown-navbar px-4" right tag="ul">
        <div className="NotificationPrompt__group">
          <Row className="NotificationPrompt__group--body">
            <Col md={6}>
              <RedirectWidgetHolder
                icon={"groups"}
                text={"Add Asset"}
                redirectTo={"/admin/addsubject"}
              />
            </Col>
            <Col md={6}>
              <RedirectWidgetHolder
                icon={"settings_remote"}
                text={"Add Beacon"}
                redirectTo={"/admin/beacon/add"}
              />
            </Col>
          </Row>
          <Row className="NotificationPrompt__group--body">
            <Col md={6}>
              <RedirectWidgetHolder
                icon={"router"}
                text={"Add Gateway"}
                redirectTo={"/admin/gateway/add"}
              />
            </Col>
            <Col md={6}>
              <RedirectWidgetHolder
                icon={"policy"}
                text={"Add Policy"}
                redirectTo={"/admin/policy/add"}
              />
            </Col>
          </Row>
          <Row className="NotificationPrompt__group--body">
            <Col md={6}>
              <RedirectWidgetHolder
                icon={"account_tree"}
                text={"Add Action"}
                redirectTo={"/admin/gateway/add"}
              />
            </Col>
            <Col md={6}>
              <RedirectWidgetHolder
                icon={"calendar_month"}
                text={"Add Schedule"}
                redirectTo={"/admin/schedule/add"}
              />
            </Col>
          </Row>
        </div>

        {/* <div className="NotificationPrompt__group">
          <h1>
            <span>Organization</span>
          </h1>
          <div className="NotificationPrompt__group--body">
            <RedirectWidgetHolder
              text={"Add Organization"}
              redirectTo={"/admin/manage-organizations"}
            />
            <RedirectWidgetHolder
              text={"Add Calender"}
              redirectTo={"/admin/calender-event/add"}
            />
            <RedirectWidgetHolder
              text={"Add Schedule"}
              redirectTo={"/admin/schedule/add"}
            />
            <RedirectWidgetHolder
              text={"Add Asset"}
              redirectTo={"/admin/addsubject"}
            />
            <RedirectWidgetHolder
              text={"Add User"}
              redirectTo={"/admin/addUser"}
            />
            <RedirectWidgetHolder
              text={"Add Tag"}
              redirectTo={"/admin/all_tags"}
            />
          </div>
        </div>

        <div className="NotificationPrompt__group">
          <h1>
            <span>IOT Devices</span>
          </h1>
          <div className="NotificationPrompt__group--body">
            <RedirectWidgetHolder
              text={"Add Beacon"}
              redirectTo={"/admin/beacon/add"}
            />
            <RedirectWidgetHolder
              text={"Add Gateway"}
              redirectTo={"/admin/gateway/add"}
            />
          </div>
        </div>

        <div className="NotificationPrompt__group">
          <h1>
            <span>Security</span>
          </h1>
          <div className="NotificationPrompt__group--body">
            <RedirectWidgetHolder
              text={"Add Policy"}
              redirectTo={"/admin/policy/add"}
            />
            <RedirectWidgetHolder
              text={"Add Action"}
              redirectTo={"/admin/action/add"}
            />
          </div>
        </div> */}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default NotificationPrompt;
