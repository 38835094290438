import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { S3 } from "aws-sdk";
import JSZip from "jszip";
import toast from "react-hot-toast";

const s3 = new S3({
  accessKeyId: "AKIASCJGTJTMAN56VYXD",
  secretAccessKey: "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",
  region: "ap-south-1",
});

function S3FileUpload({
  title,
  modalOpen,
  data,
  setData,
  setModalOpen,
  selectFields,
  tableConfig,
  requiredFields,
}) {
  const [show, setShow] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [textFile, setTextFile] = useState(null);
  const [file, setFile] = useState({
    fileContent: null,
    fileName: "",
    fileType: "",
  });

  const handleCSVFileChange = (event) => {
    const targetFile = event.target.files[0];

    if (!targetFile) return;

    setCsvFile(targetFile);
  };

  const handleTextFileChange = (event) => {
    const targetFile = event.target.files[0];

    if (!targetFile) return;

    setTextFile(targetFile);
  };

  const handleFileUpload = async () => {
    if (csvFile && textFile) {
      const zip = new JSZip();
      zip.file(csvFile.name, csvFile);
      zip.file(textFile.name, textFile);
      const zipFile = await zip.generateAsync({ type: "blob" });

      const params = {
        Bucket: "mqtt-configurations",
        Key: `${new Date().toString()}.zip`,
        Body: zipFile,
        Type: "zip",
        ACL: "public-read",
      };

      console.log(params, "params");

      try {
        s3.upload(params, (err, data) => {
          if (err) {
            console.log(err);
          } else {
            const uploadedFile = data;
            setModalOpen(false);
            toast.success("Files uploaded successfully");
            console.log(uploadedFile, "file uploaded");
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <Modal isOpen={modalOpen}>
      <ModalHeader>{title ?? "Upload File"}</ModalHeader>
      <ModalBody>
        <div>
          <p>CSV File</p>
          <input type="file" accept=".csv" onChange={handleCSVFileChange} />
        </div>
        <div className="mt-4">
          <p>Text File</p>
          <input type="file" accept=".txt" onChange={handleTextFileChange} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleFileUpload}>
          Upload
        </Button>
        <Button onClick={() => setModalOpen(false)}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

export default S3FileUpload;
