import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "./Dashboard.css";
import LocalStorage from "../services/storage";
import StatView from "components/Widgets/StatView/StatView";
import TimeWidget from "components/Widgets/TimeWidget/TimeWidget";
import FloorSurvillence from "components/Widgets/FloorSurvillence/FloorSurvillence";
import UpcomingEvents from "components/Widgets/UpcomingEvents/UpcomingEvents";
import RecentAnnouncements from "components/Widgets/RecentAnnouncements/RecentAnnouncements";
import PresenceInsider from "components/Widgets/PresenceInsider/PresenceInsider";
import OnboardingWidget from "components/Widgets/OnboardingWidget/OnboardingWidget";
// import OnboardingWidget from "components/Widgets/OnboardingWidget/OnboardingWidget";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgId: null,
      bigChartData: "data1",
      alert: {
        alertId: null,
        openModal: false,
        modal: false,
        toggle: false,
        backdrop: false,
        keyboard: false,
        type: "alert",
      },
      violation: {
        violationId: null,
        openModal: false,
        modal: false,
        toggle: false,
        backdrop: false,
        keyboard: false,
        type: "violation",
      },
      activeFloorSectionId: null,
    };
  }

  setBgChartData = (name) => {
    this.setState({
      bigChartData: name,
    });
  };

  handleSOSAlertCallback = (alert_Id) => {
    let alertId = alert_Id;
    this.setState((prevState) => ({
      ...prevState,
      alert: {
        alertId: alertId,
        openModal: !this.state.alert.openModal,
        modal: !this.state.alert.modal,
        toggle: !this.state.alert.toggle,
        backdrop: !this.state.alert.backdrop,
        keyboard: !this.state.alert.keyboard,
      },
    }));
    setTimeout(() => {
      this.props.saveAlertModalStatus(this.state.alert);
    }, 2000);
  };

  handleViolationAlertCallback = (violation_Id) => {
    let violationId = violation_Id;
    this.setState((prevState) => ({
      ...prevState,
      violation: {
        violationId: violationId,
        openModal: !this.state.openModal,
        modal: !this.state.modal,
        toggle: !this.state.toggle,
        backdrop: !this.state.backdrop,
        keyboard: !this.state.keyboard,
      },
    }));
    setTimeout(() => {
      this.props.saveViolationModalStatus(this.state.violation);
    }, 2000);
  };

  submitAction = () => {
    console.log("submit action");
  };

  render() {
    console.log(this.props);
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <OnboardingWidget isOpen={!this.props.organization.isOnboarded} />
          </Col>
          <Col md="4">
            <TimeWidget userName={this.props.user?.firstName} />
            <StatView orgId={this.props.organization?._id ?? null} />
          </Col>
          <Col md="8" className="d-flex align-items-stretch">
            <FloorSurvillence orgId={this.props.organization?._id ?? null} />
          </Col>
          <Col md="6" className="d-flex align-items-stretch">
            <PresenceInsider orgId={this.props.organization?._id ?? null} />
          </Col>
          <Col md="3" className="d-flex align-items-stretch">
            <UpcomingEvents />
          </Col>
          <Col md="3" className="d-flex align-items-stretch">
            <RecentAnnouncements />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
    organization: state.organization,
  };
};

export default connect(mapStateToProps)(Dashboard);
