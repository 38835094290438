import IconButton from "components/UserInputComponents/IconButton/IconButton";
import React, { useMemo } from "react";
import {
  Row,
  CardTitle,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
} from "reactstrap";
import CsvDownloader from "utils/CsvDownloader";
import getAssetData from "utils/getAssetData";
import getReportData from "utils/getReportData";
import PdfDownloader from "utils/PdfDownloader";
import TooltipAction from "views/custom_components/TooltipAction";

function TableHeader({
  fileTitle,
  title,
  onClickAdd,
  onClickSend,
  onClickDownload,
  onClickFileUpload,
  onClickAction,
  context,

  tableConfig = [],
  tableData = [],
  toolTipContent,
  icon,
  children,
  countData,
}) {
  const dataReport = useMemo(() => {
    if (context === "asset") {
      return getAssetData(tableData);
    } else {
      return getReportData(tableConfig, tableData);
    }
  }, [tableConfig, tableData]);

  return (
    <CardTitle className="d-flex justify-content-between" tag="h4">
      <div>
        {title}-({countData || tableData.length})
      </div>
      <Row className="px-4">
        {children}
        {!!onClickAdd && (
          <>
            <IconButton
              icon="add"
              onClick={onClickAdd}
              color="success"
              outlined
              id="add"
            />
            <TooltipAction target="add" textContent="Add" />
          </>
        )}
        {!!onClickFileUpload && (
          <>
            <IconButton
              icon="upload_file"
              onClick={onClickFileUpload}
              color="info"
              outlined
              id="fileupload"
            />
            <TooltipAction target="fileupload" textContent="fileupload" />
          </>
        )}
        {!!onClickAction && (
          <>
            <IconButton
              icon={icon}
              onClick={onClickAction}
              color="info"
              outlined
              id="onClickAction"
            />
            <TooltipAction
              target="onClickAction"
              textContent={toolTipContent}
            />
          </>
        )}
        {!!onClickSend && (
          <>
            <IconButton
              icon="send"
              onClick={onClickSend}
              color="success"
              outlined
              id="send"
            />
            <TooltipAction target="send" textContent="Send" />
          </>
        )}
        {!!onClickDownload && (
          <UncontrolledDropdown>
            <DropdownToggle data-toggle="dropdown" tag="span">
              <IconButton id="export" icon="save_alt" color="info" outlined />
              <TooltipAction target="export" textContent="Export" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() =>
                  CsvDownloader({
                    fileTitle: !!fileTitle ? `${fileTitle}` : `${title}`,
                    data: dataReport,
                  })
                }
              >
                <p>Export CSV</p>
              </DropdownItem>

              <DropdownItem
                onClick={() =>
                  PdfDownloader({
                    fileTitle: !!fileTitle ? `${fileTitle}` : `${title}`,
                    data: dataReport,
                  })
                }
              >
                <p>Export PDF</p>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </Row>
    </CardTitle>
  );
}

export default TableHeader;
