import React from "react";
import BaseTextInput from "./BaseTextInput";

function GstinInput({ value, setValue, editable }) {
  return (
    <BaseTextInput
      label={"GSTIN"}
      placeholder={"A gstin number for your company"}
      name={"gstin"}
      value={value}
      setValue={(val) => setValue(val)}
      editable={editable}
    />
  );
}

export default GstinInput;
