import FilterTableHolder from "components/Holders/FilterTableHolder/FilterTableHolder";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SWALService from "services/SWALService";
import AssetChangeService from "services/AssetChangeService";
import Filter from "./subComponent/Filter";
import Table from "./subComponent/Table";
import tableConfig from "./subComponent/tableConfig";

function CampusListing() {
  const organizationId = useSelector((state) => state.organization._id);
  // const organizationId = "621642aa5656450009819ffc";
  const defaultParams = { organizationId };
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assetChangeParams, setAssetChangeParams] = useState({});

  useEffect(() => {
    fetchChangeRequests();
  }, [assetChangeParams]);

  const fetchChangeRequests = async () => {
    setLoading(true);

    const params = {
      ...assetChangeParams,
      ...defaultParams,
    };

    const { data } = await AssetChangeService.get(params).catch((err) =>
      SWALService.showError(err)
    );

    if (data) setData(data);
    setLoading(false);
  };

  return (
    <FilterTableHolder>
      <Filter setParams={setAssetChangeParams} />
      <TableHeader
        title="Change Requests"
        onClickDownload={true}
        tableConfig={tableConfig}
        tableData={data}
      />
      <Table
        data={data}
        getData={fetchChangeRequests}
        loading={loading}
        tableConfig={tableConfig}
      />
    </FilterTableHolder>
  );
}

CampusListing.routeName = "/admin/asset/change-request";

export default CampusListing;
