import React from "react";
import Avatar from "views/custom_components/Avatar/Avatar";

const PeriodicAttendanceSummaryTableConfig = [
  {
    Header: "#",
    width: 80,
    Cell: ({ original: { asset } }) => {
      return (
        <div>
          <Avatar
            height={34}
            src={asset?.attributes?.avatar || ""}
            alt={asset?.name || ""}
            gender={asset?.attributes?.gender}
          />
        </div>
      );
    },
  },
  {
    Header: "Name",
    accessor: "asset.name",
  },
  {
    Header: "Unique ID",
    accessor: "asset.uniqueId",
  },
  {
    Header: "Primary Group",
    accessor: ({ asset }) => asset?.primaryGroup?.name,
    // Cell: ({ original: { asset } }) => <>{asset?primaryGroup?name}</>,
  },

  {
    Header: "Present",
    accessor: "present",
    width: 80,
  },
  {
    Header: "Absent",
    accessor: "absent",
    width: 80,
  },
  {
    Header: "On Time",
    accessor: (original) => original?.onTime || "N/A",
    // Cell: ({ original }) => <>{Math.ceil(0.8 * Number(original.present))}</>,
    width: 80,
  },
  {
    Header: "Late",
    accessor: (original) => original?.isLate || "N/A",
    // Cell: ({ original }) => <>{Math.floor(0.2 * Number(original.present))}</>,
    width: 80,
  },
];

export default PeriodicAttendanceSummaryTableConfig;
