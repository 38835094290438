const GatewayScope = {
  SOS: "SOS",
  PRESENCE_VIOLATION: "PRESENCE_VIOLATION",
  PRESENCE: "PRESENCE",
  PORTAL: "PORTAL",
  ENTRY: "ENTRY",
  EXIT: "EXIT",
};

const gatewayScopes = Object.values(GatewayScope);
export default gatewayScopes;
