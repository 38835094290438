import React from 'react'
import { Col, Row, Card, CardBody, CardHeader, CardTitle, FormGroup, Label, Input } from 'reactstrap'

const RenderRoomsSelect = ({selectables, onPressHandler}) => selectables.map((selectable)=>
    <Col md={3}>
      <FormGroup check>
          <Label check>
              <Input type="checkbox" value={selectable._id} onChange={(e) => onPressHandler(e)}/>
              <span className="form-check-sign" />
              {selectable.floorPlanSectionName}
          </Label>
      </FormGroup>
    </Col>
)

function SelectionRoomCard({selectables, header, onPressHandler}) {
  return (
    <Row>
      <Col md="3">
        <Label md="8">{header}</Label>
      </Col>
      <Col md="9">
        <Row>
          <RenderRoomsSelect selectables={selectables} onPressHandler={onPressHandler}/>
        </Row>
      </Col>
    </Row>
  )
}

export default SelectionRoomCard;
