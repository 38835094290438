import React, { useEffect, useState } from "react";
import RouteService from "services/RouteService";
import BaseSelect from "./BaseSelect";

function RouteSelect({ value, setValue, isMultiSelect, noLabel, noPopulate }) {
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    if (noPopulate) return;
    RouteService.get()
      .then((res) => {
        setRoutes(res.data?.map((el) => ({ value: el._id, label: el.name })));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [noPopulate]);

  return (
    <BaseSelect
      label={!noLabel && "Route"}
      dataSource={routes}
      placeholder={"Select Route"}
      closeMenuOnSelect={false}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default RouteSelect;
