import gatewayTypes from "config/gatewayTypes";
import React, { useState } from "react";
import BaseSelect from "./BaseSelect";

function GatewayModelSelect({ value, setValue }) {
  const GatewayModelsOptions = gatewayTypes.map((el) => ({
    value: el,
    label: el,
  }));

  return (
    <BaseSelect
      label={"Model"}
      dataSource={GatewayModelsOptions}
      placeholder={"Select gateway model"}
      closeMenuOnSelect={false}
      isMultiSelect={true}
      value={value}
      setValue={setValue}
    />
  );
}

export default GatewayModelSelect;
