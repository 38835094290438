import React, { useEffect, useState } from "react";
// reactstrap components
import { Form } from "reactstrap";
// core components
import ShiftService from "../../../../../services/ShiftService";
import { useHistory, useParams } from "react-router-dom";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput.js";
import FormHolder from "components/Holders/FormHolder/FormHolder.js";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton.js";
import Forms from "../../../../../config/copies/forms";
import SWALService from "services/SWALService";
import { useSelector } from "react-redux";
import Validate from "utils/validators";
import DaysSelect from "components/UserInputComponents/Selects/DaysSelect";
import ToTimePicker from "components/UserInputComponents/TimePickers/ToTimePicker";
import FromTimePicker from "components/UserInputComponents/TimePickers/FromTimePicker";
import DateTimeService from "services/DateTimeService";
import CSVupload from "views/components/CSVupload";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import CSVuploadManual from "views/components/CSVuploadManual";
import { SHIFTS_BULK_UPLOAD_URL } from "config/api";
import Swal from "sweetalert2";

function ShiftForm() {
  const organizationId = useSelector((state) => state.organization._id);

  const { shiftId } = useParams();
  const history = useHistory(); //browserHistory stack

  const buttonText = !!shiftId ? "Save Changes" : "Submit";

  const [ShiftName, setShiftName] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [days, setDays] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [manualModal, setManualModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const today = new Date();

  const table = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Start Time",
      accessor: ({ startTime }) =>
        DateTimeService.getTimeString(
          today.toISOString().slice(0, today.toISOString().indexOf("T") + 1) +
            startTime +
            "Z",
        ),
    },
    {
      Header: "End Time",
      accessor: ({ endTime }) =>
        DateTimeService.getTimeString(
          today.toISOString().slice(0, today.toISOString().indexOf("T") + 1) +
            endTime +
            "Z",
        ),
    },
    {
      Header: "Applied Days",
      accessor: "daysOfWeek",
    },
  ];

  useEffect(() => {
    if (!shiftId) return;

    (async () => {
      const { data } = await ShiftService.getById(shiftId).catch((err) =>
        SWALService.showError(err),
      );
      if (!data) return;
      setStartTime(DateTimeService.getDateTimeFromTimeString(data?.startTime));
      setEndTime(DateTimeService.getDateTimeFromTimeString(data?.endTime));
      setDays(data?.daysOfWeek);
      setShiftName(data?.name);
    })();
  }, [shiftId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (ShiftName === "") return;

    const ShiftObj = {
      name: ShiftName,
      organization: organizationId,
      startTime: DateTimeService.get24TimeStringUTC(startTime),
      endTime: DateTimeService.get24TimeStringUTC(endTime),
      daysOfWeek: days,
    };

    if (!shiftId)
      return ShiftService.post(ShiftObj).then(({ message }) => {
        history.goBack();
        SWALService.showSuccess(message);
      });

    ShiftService.put(shiftId, ShiftObj)
      .then(({ message }) => {
        history.goBack();
        SWALService.showSuccess(message);
      })
      .catch((err) => SWALService.showError(err));
  };

  const handelFileUpload = () => {
    shiftData.forEach((shift) => {
      shift.daysOfWeek = DateTimeService.getIndexfromDays(shift.daysOfWeek);
      shift.organization = organizationId;
    });
    ShiftService.doPost(SHIFTS_BULK_UPLOAD_URL, shiftData, true)
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Shift Added",
          text: response.msg,
        }).then(() => {
          history.push("/admin/shift/list");
        });
      })
      .catch((error) => {
        SWALService.showError(error.message);
      });
    console.log(shiftData);
  };

  return (
    <div className="content">
      <CSVupload
        title="Shift Data Upload"
        modalOpen={modalOpen}
        data={shiftData}
        setData={setShiftData}
        handleSubmit={handelFileUpload}
        setModalOpen={setModalOpen}
        tableConfig={table}
        setShowManual={setManualModal}
      />
      <CSVuploadManual
        setModalOpen={setManualModal}
        modalOpen={manualModal}
        gId={"1262306929"}
      />
      <FormHolder
        imgSrc={Forms.SHIFT.illustration}
        formHeader={Forms.SHIFT.title}
        formDescription={Forms.SHIFT.desc}
        formActions={
          <IconButton
            icon={"upload_file"}
            onClick={() => {
              setModalOpen(true);
              setShiftData([]);
            }}
            color={"info"}
            outlined
          />
        }
      >
        <Form onSubmit={handleSubmit}>
          <BaseTextInput
            label="Shift Name"
            placeholder="Enter Shift name"
            value={ShiftName}
            setValue={setShiftName}
            validator={Validate.Name}
          />
          <FromTimePicker date={startTime} setDate={setStartTime} />
          <ToTimePicker date={endTime} setDate={setEndTime} />
          <DaysSelect setValue={setDays} value={days} />
          <BaseButton color="info">{buttonText}</BaseButton>
        </Form>
      </FormHolder>
    </div>
  );
}

export default ShiftForm;
