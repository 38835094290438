import React, { useEffect, useState } from "react";
import { Card, Collapse } from "reactstrap";
import BrandLoader from "views/custom_components/Loader";

import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";

import "./SideBarSelect.scss";

import MenuItemGetters from "../../utils/getMenuItems";
import BuildingMenu from "../MenuStages/BuildingMenu";
import CampusMenu from "../MenuStages/CampusMenu";
import menuStages from "../MenuStages/config";
import FloorPlanMenu from "../MenuStages/FloorMenu";

function SideBarSelect({
  selectFloorplan,
  activeFloorPlan,
  activeMenu,
  setActiveMenu,
  onCampusSelect,
  onBuildingSelect,
}) {
  const buildingsPromise = MenuItemGetters.getBuildings;
  const campusPromises = MenuItemGetters.getCampuses;
  const floorPlansPromise = MenuItemGetters.getFloorPlans;

  const [loading, setLoading] = useState(true);
  const [menuHeight, setMenuHeight] = useState(null);

  const [campuses, setCampuses] = useState();
  const [buildings, setBuildings] = useState();
  const [floorPlans, setFloorPlans] = useState();

  const [activeBuildingId, setActiveBuildingId] = useState();
  const [activeCampusId, setActiveCampusId] = useState();
  const [prevMenu, setPrevMenu] = useState(menuStages.floorplans);

  const displayMenuForCampus = (campusId) => {
    setActiveMenu((prevState) => {
      setPrevMenu(prevState);
      return menuStages.buildings;
    });
    onCampusSelect(campusId);
    setActiveCampusId(campusId);
  };

  const displayMenuForBuilding = (buildingId) => {
    setActiveMenu((prevState) => {
      setPrevMenu(prevState);
      return menuStages.floorplans;
    });
    onBuildingSelect(buildingId);
    setActiveBuildingId(buildingId);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      campusPromises(displayMenuForCampus),
      buildingsPromise(displayMenuForBuilding),
      floorPlansPromise(selectFloorplan),
    ])
      .then(([campuses, buildings, floorPlans]) => {
        setCampuses(campuses);
        setBuildings(buildings);
        setFloorPlans(floorPlans);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!!activeFloorPlan) {
      console.log("activeFloorPlan", activeFloorPlan);
    }
  }, [activeFloorPlan]);

  useEffect(() => {
    if (activeMenu !== menuStages.floorplans) selectFloorplan(null);
  }, [activeMenu]);

  return (
    <Card className="SideBarSelect" style={{ height: menuHeight }}>
      {loading && (
        <div className="px-4 py-5">
          <Skeleton height="2rem" />
          <br />

          {Array(3)
            .fill(0)
            .map((_) => (
              <p className="py-3">
                <Skeleton />
                <br />
              </p>
            ))}
        </div>
      )}
      <Collapse isOpen={!loading}>
        <CampusMenu
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          campuses={campuses}
          setMenuHeight={setMenuHeight}
        />

        <BuildingMenu
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          buildings={buildings}
          setMenuHeight={setMenuHeight}
          campusId={activeCampusId}
          prevMenu={prevMenu}
        />

        <FloorPlanMenu
          activeMenu={activeMenu}
          setActiveMenu={(menuStage) => {
            setPrevMenu(menuStages.floorplans);
            setActiveMenu(menuStage);
          }}
          floorPlans={floorPlans}
          setMenuHeight={setMenuHeight}
          buildingId={activeBuildingId}
          activeFloorPlanId={activeFloorPlan}
        />
      </Collapse>
    </Card>
  );
}

export default SideBarSelect;
