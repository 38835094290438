import React from "react";
import BaseSelect from "./BaseSelect";
import ENUMS from "config/enums";

function ActionTypeSelect({ value, setValue, isMultiSelect }) {
  const { ActionTypes, ActionScopes } = ENUMS;

  const actionOptions = ActionTypes?.map((el) => ({
    value: el,
    label: el,
  }));

  return (
    <BaseSelect
      label={"Action Type"}
      dataSource={actionOptions}
      placeholder={"Select action type"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default ActionTypeSelect;
