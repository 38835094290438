import BaseSelect from "components/UserInputComponents/Selects/BaseSelect";
import SubjectSelect from "components/UserInputComponents/Selects/SubjectSelect";
import TagSelect from "components/UserInputComponents/Selects/TagSelect";
import React, { Component } from "react";
import { Collapse } from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import FormNarrator from "../FormNarrator/FormNarrator";

const TRANSMITTER_SCOPES = [
  { value: "ANY", label: "ANY" },
  { value: "TRANSMITTER_LIST", label: "TRANSMITTER_LIST" },
  { value: "GROUP", label: "GROUP" },
];

export default class PolicyDevices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      triggerCondition_config_transmitterScope: "",
      triggerCondition_config_subjects: [],
      triggerCondition_config_groups: [],
    };
  }

  componentDidMount() {
    const { policy } = this.props;

    if (!policy) return;

    this.setState({
      triggerCondition_config_transmitterScope:
        policy.triggerCondition_config_transmitterScope,
      triggerCondition_config_subjects: policy.triggerCondition_config_subjects,
      triggerCondition_config_groups: policy.triggerCondition_config_groups,
    });
  }

  render() {
    const t = this.state.triggerCondition_config_transmitterScope;
    const devices = this.state.triggerCondition_config_subjects;
    this.FormNarratorTemplate = `<b><i>${t === "ANY" ? "All the" : "The "} ${
      devices?.length >= 0 ? devices?.length : 0
    }</b></i> devices selected will need to abide by this policy.`;

    return (
      <>
        <h4>Associate devices</h4>
        <br />
        <FormNarrator>{this.FormNarratorTemplate}</FormNarrator>
        <BaseSelect
          dataSource={TRANSMITTER_SCOPES}
          value={this.state.triggerCondition_config_transmitterScope}
          setValue={(val) =>
            this.setState({
              triggerCondition_config_transmitterScope: val,
            })
          }
          label="Transmitter Scope"
          placeholder="Select the transmitter scope"
        />
        <Collapse
          isOpen={
            this.state.triggerCondition_config_transmitterScope ===
            "TRANSMITTER_LIST"
          }
        >
          <SubjectSelect
            isMultiSelect
            value={this.state.triggerCondition_config_subjects}
            setValue={(val) =>
              this.setState({
                triggerCondition_config_subjects: val,
              })
            }
            bottomHintText="Tell us who can transmit this policy"
          />
        </Collapse>

        <Collapse
          isOpen={
            this.state.triggerCondition_config_transmitterScope === "GROUP"
          }
        >
          <TagSelect
            isMultiSelect
            value={this.state.triggerCondition_config_groups}
            setValue={(val) =>
              this.setState({
                triggerCondition_config_groups: val,
              })
            }
            bottomHintText="Tell us who can transmit this policy"
          />
        </Collapse>
      </>
    );
  }
}
