import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import { Matter_BULK_UPLOAD_URL } from "config/api";
import React, { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import MatterService from "services/MatterService";
import SWALService from "services/SWALService";
import Swal from "sweetalert2";
import CSVupload from "views/components/CSVupload";
import CSVuploadManual from "views/components/CSVuploadManual";

import CreateMatter from "./CreateMatter";
import EditMatter from "./EditMatter";

function MatterListing() {
  const history = useHistory();
  const organizationId = useSelector((state) => state.organization._id);
  const params = { organizationId };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createMatter, setCreateMatter] = useState(false);
  const [editMatter, setEditMatter] = useState(false);
  const [matterData, setMatterData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [mattersData, setMattersData] = useState([]);
  const [manualModal, setManualModal] = useState(false);

  const fetchMatters = () => {
    setLoading(true);
    MatterService.get(params)
      .then(({ data }) => {
        if (data.length < 0) return;
        setData(data);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchMatterById = (id) => {
    setLoading(true);
    MatterService.getById(id, params)
      .then(({ data }) => {
        if (data.length < 0) return;
        setMatterData(data);
        setEditMatter(true);
        history.push(`/admin/all_matters/edit/${id}`);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchMatters();
  }, []);

  const addMatter = () => {
    setCreateMatter(true);
    history.push(`/admin/all_matters`);
  };

  const editMatters = (matterID) => {
    fetchMatterById(matterID);
  };

  const deleteMatter = (id) => {
    Swal.fire({
      title: "Are you sure to Delete it?",
      text: "You won't be able to revert this after deleting!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          MatterService.delete(id)
            .then(({ message }) => {
              Swal.fire("Deleted!", "Matter has been deleted.");
              fetchMatters();
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Error occured",
              });
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      filterable: false,
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editMatters}
          deleteCallback={deleteMatter}
        />
      ),
    },
  ];

  const fileuploadTableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
  ];
  const clearEmptyRow = () => {
    const filterData = mattersData.filter((object) => {
      return !!Object.keys(object).length;
    });
    setMattersData(filterData);
  };

  const handleBlukSubmit = (e) => {
    e.preventDefault();
    clearEmptyRow();
    mattersData.forEach((matter) => {
      matter.attributes = {
        avatar: matter.avatar,
      };
      matter.organization = organizationId;
      delete matter.avatar;
    });
    const payload = mattersData;

    MatterService.doPost(Matter_BULK_UPLOAD_URL, payload, true)
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Assets Added",
          text: response.msg,
        }).then(() => {
          setModalOpen(false);
          fetchMatters();
        });
      })
      .catch((error) => {
        SWALService.showError(error.message);
      });
  };

  return (
    <>
      <div className="content wrapTable">
        <CreateMatter
          showCreateMatters={createMatter}
          setShowCreateMatters={setCreateMatter}
          fetchMatters={fetchMatters}
        />
        <CSVupload
          title="Upload Multiple Matter"
          modalOpen={modalOpen}
          data={mattersData}
          setData={setMattersData}
          tableConfig={fileuploadTableConfig}
          handleSubmit={handleBlukSubmit}
          setModalOpen={setModalOpen}
          setShowManual={setManualModal}
        />
        <CSVuploadManual
          setModalOpen={setManualModal}
          modalOpen={manualModal}
          gId={"1780437334"}
        />
        {!!matterData && (
          <EditMatter
            showEditMatters={editMatter}
            setShowEditMatters={setEditMatter}
            fetchMatters={fetchMatters}
            matterData={matterData}
          />
        )}
        <Row className="mx-auto">
          <Col xs={5} md={5} className="mx-auto">
            <Card>
              <CardHeader>
                <TableHeader
                  title="Matter"
                  onClickAdd={addMatter}
                  onClickFileUpload={() => {
                    setMattersData([]);
                    setModalOpen(true);
                  }}
                  tableData={matterData}
                />
              </CardHeader>
              <CardBody>
                <BaseTable
                  data={data}
                  tableConfig={tableConfig}
                  loading={loading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default MatterListing;
