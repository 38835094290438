import axios from "axios";
import Icon from "components/Icon/Icon";
import { ATTENDANCE_URL } from "config/api";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { Row, Col, Card, CardTitle, CardBody } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import BrandLoader from "views/custom_components/Loader";

function ProfileAnalyticsMonthly({ subjectId, selectedDate }) {
  const organizationId = useSelector((state) => state.organization._id);

  const [loading, setLoading] = useState(false);
  const [monthlyData, setMonthlyData] = useState();

  const fetchMonthlyAnalytics = async () => {
    const fromTime = DateTimeService.getDateTimeNDaysAgo(
      selectedDate,
      29
    ).toISOString();
    const toTime = DateTimeService.getMidnightTime(selectedDate).toISOString();
    const params = {
      organizationId,
      assetId: subjectId,
      fromTime,
      toTime,
      attendanceType: "DAILY_SUMMARY",
    };

    try {
      setLoading(true);
      const {
        data: { data },
      } = await axios.get(ATTENDANCE_URL, { params });

      if (data) {
        setMonthlyData(data[0]);
      }
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchMonthlyAnalytics();
  }, [selectedDate]);
  return (
    <Row>
      <Col md="12">
        <h2>
          Trend
          <small> Last 30 days</small>
        </h2>
      </Col>
      {loading && <BrandLoader noOverlay />}
      {!loading && (
        <>
          <Col md="6" lg="4">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="5">
                    <div class="info-icon text-center icon-success">
                      <Icon icon="how_to_reg" />
                    </div>
                  </Col>
                  <Col md="7">
                    <div class="numbers">
                      <CardTitle tag="h3">
                        {monthlyData?.present}
                        <small>/30</small>
                      </CardTitle>
                      <p class="card-category">days present</p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" lg="4">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="5">
                    <div class="info-icon text-center icon-warning">
                      <Icon icon="no_meeting_room" />
                    </div>
                  </Col>
                  <Col md="7">
                    <div class="numbers">
                      <CardTitle tag="h3">{monthlyData?.isLate}</CardTitle>
                      <p class="card-category">
                        Days&nbsp;
                        <span>Arrived Late</span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-md-6 col-lg-4">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="5">
                    <div class="info-icon text-center icon-danger">
                      <Icon icon="dangerous" />
                    </div>
                  </Col>
                  <Col class="col-7">
                    <div class="numbers">
                      <CardTitle tag="h3">{monthlyData?.absent}</CardTitle>
                      <p class="card-category">Unattended Classes</p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </>
      )}
    </Row>
  );
}

export default ProfileAnalyticsMonthly;
