import shift from "./index";
import shiftForm from "./subComponent/ShiftForm";

const shiftRoutes = [
  {
    path: "/admin/shift/list",
    rtlName: "عالتسعير",
    component: shift,
    layout: "/admin",
    name: "shiftes",
    showVisible: false,
  },
  {
    path: "/admin/shift/add",
    name: "Add shift",
    rtlName: "عالتسعير",
    component: shiftForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/shift/edit/:shiftId",
    name: "Edit shift",
    rtlName: "عالتسعير",
    component: shiftForm,
    layout: "/admin",
    showVisible: false,
  },
];

export default shiftRoutes;
