import React from "react";
import BaseTimePicker from "./BaseTimePicker";

function FromTimePicker({ date, setDate, bottomHintText }) {
  return (
    <BaseTimePicker
      placeholder="From Time"
      label="From Time"
      value={date || null}
      changeValue={(val) => setDate(val)}
      bottomHintText={bottomHintText}
    />
  );
}

export default FromTimePicker;
