const securityMenuItems = [
  {
    title: "Manage Policies",
    icon: "policy",
    target: "/admin/policy/manage",
  },
  {
    title: "Manage Actions",
    icon: "account_tree",
    target: "/admin/action/manage",
  },
];

export default securityMenuItems;
