import React from "react";
import { FormText, Input, Label } from "reactstrap";
import { useState } from "react";

function BaseTextInput({
  label,
  placeholder,
  name,
  value,
  setValue,
  changeMethod,
  type,
  validator,
  editable,
  bottomHintText,
  min,
  max,
}) {
  const [error, setError] = useState();

  const onChange = (e) => {
    setValue(e.target.value);
    if (error) {
      validateInput(e);
    }
  };

  const validateInput = (e) => setError(validator?.(e.target.value));

  return (
    <div className="BaseInput">
      <div className="mb-4">
        <Label className="mb-2">{label}</Label>
        <Input
          placeholder={placeholder}
          type={type || "text"}
          name={name}
          value={value}
          onChange={changeMethod || onChange}
          onBlur={validateInput}
          disabled={editable}
          className={editable ? "form-control-plaintext" : ""}
          readOnly={editable}
          min={min}
          max={max}
        />
        <FormText color="danger">{error}</FormText>
        <FormText color="muted">{bottomHintText}</FormText>
      </div>
    </div>
  );
}

export default BaseTextInput;
