import React from "react";
import "react-times/css/material/default.css";
import "react-times/css/classic/default.css";
import ReactDatetime from "react-datetime";
import { FormGroup, FormText, Label } from "reactstrap";

function BaseTimeInput({
  label,
  value,
  setValue,
  placeholder,
  format,
  bottomHintText,
}) {
  return (
    <div className="BaseTimeInput mb-4">
      <FormGroup>
        <Label> {label} </Label>
        <ReactDatetime
          value={value}
          onChange={setValue}
          dateFormat={false}
          timeFormat={true}
          inputProps={{
            className: "form-control",
            placeholder: { placeholder },
          }}
        />
        <FormText color="muted">{bottomHintText}</FormText>
      </FormGroup>
    </div>
  );
}

export default BaseTimeInput;
