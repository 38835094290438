import React from "react";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import NoData from "views/custom_components/NoData";
import ListTile from "./sub-components/ListTile/ListTile";

function ListCard({
  data = [],
  cardTitle,
  children,
  noDataMessage,
  isClick,
  handleClick,
  maxHeight = "",
}) {
  return (
    <Card
      style={{ height: "100%", maxHeight: `${maxHeight}`, overflowY: "scroll" }}
    >
      <CardHeader>
        <CardTitle tag="h4">{cardTitle}</CardTitle>
      </CardHeader>
      <CardBody>
        {data?.map(({ title, subtitle, imgSrc, icon, iconColor, count }) => (
          <ListTile
            title={title}
            subtitle={subtitle}
            imgSrc={imgSrc}
            icon={icon}
            iconColor={iconColor}
            count={count}
            isClick={isClick}
            handleClick={handleClick}
          />
        ))}
        {children}
        {!data?.length && (
          <NoData
            show={!data?.length}
            message={noDataMessage}
            imgSrc="/illustrations/empty_street.svg"
          />
        )}
      </CardBody>
    </Card>
  );
}

export default ListCard;
