import FilterTableHolder from "components/Holders/FilterTableHolder/FilterTableHolder";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SWALService from "services/SWALService";
import CampusService from "services/CampusService";
import CampusFilter from "./subComponent/CampusFilter";
import CampusTable from "./subComponent/CampusTable";
import CsvDownloader from "utils/CsvDownloader";
import getReportData from "utils/getReportData";

function CampusListing() {
  const organizationId = useSelector((state) => state.organization._id);
  // const organizationId = "621642aa5656450009819ffc";
  const defaultParams = { organizationId };
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [campusParams, setCampusParams] = useState({});
  const [tableConfig, setTableConfig] = useState([]);

  useEffect(() => {
    fetchCampus();
  }, [campusParams]);

  const fetchCampus = async () => {
    setLoading(true);

    const params = {
      ...campusParams,
      ...defaultParams,
    };

    const { data } = await CampusService.get(params).catch((err) =>
      SWALService.showError(err)
    );

    if (data) setData(data);
    setLoading(false);
  };

  const addCampus = () => history.push("/admin/campus/add");

  return (
    <FilterTableHolder>
      <CampusFilter setCampusParams={setCampusParams} />
      <TableHeader
        title="Campus"
        onClickAdd={addCampus}
        onClickDownload={true}
        tableConfig={tableConfig}
        tableData={data}
      />
      <CampusTable
        data={data}
        getData={fetchCampus}
        loading={loading}
        setTableConfig={setTableConfig}
      />
    </FilterTableHolder>
  );
}

CampusListing.routeName = "/admin/campus/manage";

export default CampusListing;
