import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import GatewaySelect from "components/UserInputComponents/Selects/GatewaySelect";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Badge, CardBody, Collapse } from "reactstrap";
import FloorPlanSectionService from "services/FloorPlanSectionService";
import BasePopoverView from "../BasePopoverView/BasePopoverView";

import "./GatewayConnectView.scss";

const GATEWAY_ATTACH_TOAST = "GATEWAY_ATTACH_TOAST";
const GATEWAY_DEATTACH_TOAST = "GATEWAY_DEATTACH_TOAST";

const GatewayMacView = ({ tagId, gatewayMac }) => (
  <Badge id={tagId} className="clickable px-3 py-2">
    {gatewayMac || (
      <span className="text-muted font-weight-light">No gateway</span>
    )}
  </Badge>
);

function GatewayConnectView({ gatewayMac, floorSectionId, reloadData }) {
  const [gateway, setGateway] = useState();

  const tagId = `floorSection_${floorSectionId}`;

  const resetGateways = () => setGateway(gatewayMac);

  const saveAttachedGateway = async () => {
    toast.loading("Attaching gateway...", { id: GATEWAY_ATTACH_TOAST });
    const { success } = await FloorPlanSectionService.attachGateway(
      gateway,
      floorSectionId
    ).catch((err) =>
      toast.error(err || "Could not attach gateway", {
        id: GATEWAY_ATTACH_TOAST,
      })
    );

    if (!success) return;

    toast.success("New gateway attached", { id: GATEWAY_ATTACH_TOAST });

    reloadData();
  };

  const deleteGateway = async () => {
    toast.loading("Detaching gateway...", { id: GATEWAY_DEATTACH_TOAST });
    const { success } = await FloorPlanSectionService.detachGateway(
      floorSectionId
    ).catch((err) =>
      toast.error(err || "Could not detach gateway", {
        id: GATEWAY_DEATTACH_TOAST,
      })
    );

    if (!success) return;

    toast.success(`Detached gateway ${gatewayMac}`, {
      id: GATEWAY_DEATTACH_TOAST,
    });

    reloadData();
  };

  const defaultCollapseProps = {
    unmountOnExit: true,
    mountOnEnter: true,
  };

  return (
    <div className="GatewayConnect">
      <GatewayMacView gatewayMac={gatewayMac} tagId={tagId} />
      <BasePopoverView tagId={tagId}>
        <CardBody className="GatewayConnectView" style={{ minWidth: "400px" }}>
          <h4> Configure gateway for floor section</h4>
          <Collapse isOpen={!!gatewayMac} {...defaultCollapseProps}>
            <div className="d-flex align-items-center">
              <GatewayMacView gatewayMac={gatewayMac} tagId={tagId} />

              <IconButton
                icon="close"
                onClick={deleteGateway}
                className="mx-2"
              />
            </div>
          </Collapse>
          <Collapse isOpen={!gatewayMac} {...defaultCollapseProps}>
            <GatewaySelect value={gateway} setValue={setGateway} deactive />
            <BaseButton
              disabled={!gateway}
              color="success"
              onClick={saveAttachedGateway}
            >
              Attach
            </BaseButton>
          </Collapse>
        </CardBody>
      </BasePopoverView>
    </div>
  );
}

export default GatewayConnectView;
