import React from "react";
import BaseSelect from "./BaseSelect";

function NotificationCommentTypeSelect({
  value,
  setValue,
  bottomHintText,
  isMultiSelect,
}) {
  const NotificationCommentTypes = [
    { label: "NO_USER", value: "NO_USER" },
    { label: "NO_FCM", value: "NO_FCM" },
    { label: "SUCCESS", value: "SUCCESS" },
    { label: "ODD_TIME", value: "ODD_TIME" },
  ];

  return (
    <BaseSelect
      label="Reason"
      dataSource={NotificationCommentTypes}
      placeholder="Select the reason"
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
      bottomHintText={bottomHintText}
    />
  );
}

export default NotificationCommentTypeSelect;
