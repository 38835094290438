import React from "react";
import BaseTextInput from "./BaseTextInput";

function PolicyInput({ value, setValue, label, placeholder }) {
  return (
    <BaseTextInput
      label={label || "Policy Name"}
      placeholder={placeholder || "Enter policy name"}
      name={"policy"}
      value={value}
      setValue={setValue}
    />
  );
}

export default PolicyInput;
