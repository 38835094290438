import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import BaseSelect from "components/UserInputComponents/Selects/BaseSelect";
import BeaconMacSelect from "components/UserInputComponents/Selects/BeaconMacSelect";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Badge, CardBody, Collapse } from "reactstrap";
import SubjectService from "services/SubjectService";
import { useHistory } from "react-router-dom";

import BasePopoverView from "../BasePopoverView/BasePopoverView";

const subjectService = new SubjectService();

const BEACON_ATTACH_TOAST = "BEACON_ATTACH_TOAST";
const BEACON_DEATTACH_TOAST = "BEACON_DEATTACH_TOAST";

const defaultCollapseProps = {
  unmountOnExit: true,
  mountOnEnter: true,
};

const BeaconMacView = ({ tagId, beaconMac }) => (
  <Badge id={tagId} className="clickable px-3 py-2">
    {beaconMac || (
      <span className="text-muted font-weight-light">No beacon</span>
    )}
  </Badge>
);

function BeaconConnectView({ assetId, beaconMac, reloadData }) {
  const [demo, setAssetValue] = useState("");
  const [beacon, setBeacon] = useState([]);
  const tagId = `subject_${assetId}`;
  const history = useHistory();
  // const tagId = `Tooltip-${Math.random().toString(36).slice(0, 5)}`;
  // const organizationId = useSelector((state) => state.organization?._id);
  useEffect(() => {
    console.log("assetId", tagId);
  }, [beaconMac, beacon]);

  const saveAttachedBeacon = async () => {
    toast.loading("Attaching beacon...", { id: BEACON_ATTACH_TOAST });
    const { success } = await subjectService
      .attachBeacon(beacon, assetId)
      .catch((err) =>
        toast.error(err || "Could not attach beacon", {
          id: BEACON_ATTACH_TOAST,
        })
      );

    if (!success) return;

    toast.success("New beacon attached", { id: BEACON_ATTACH_TOAST });
  };

  const deleteBeacon = async () => {
    toast.loading("Detaching beacon...", { id: BEACON_DEATTACH_TOAST });
    const { success } = await subjectService
      .detachBeacon(assetId)
      .catch((err) =>
        toast.error(err || "Could not detach beacon", {
          id: BEACON_DEATTACH_TOAST,
        })
      );

    if (!success) return;

    toast.success(`Detached beacon ${beaconMac}`, {
      id: BEACON_DEATTACH_TOAST,
    });

    // refreshing page to prevent popover error
    history.go(0);
  };

  return (
    <div className="BeaconConnectView">
      <BeaconMacView beaconMac={beaconMac} tagId={tagId} />
      <BasePopoverView tagId={tagId}>
        <CardBody className="GatewayConnectView" style={{ minWidth: "400px" }}>
          <h4> Configure beacon for subject</h4>
          <Collapse isOpen={!!beaconMac} {...defaultCollapseProps}>
            <div className="d-flex align-items-center">
              <BeaconMacView beaconMac={beaconMac} tagId={tagId} />

              <IconButton
                icon="close"
                onClick={deleteBeacon}
                className="mx-2"
              />
            </div>
          </Collapse>
          <Collapse isOpen={!beaconMac} {...defaultCollapseProps}>
            <BeaconMacSelect
              value={beacon}
              setValue={setBeacon}
              pickKey="_id"
              unassigned
            />
            <BaseButton
              disabled={!beacon}
              color="success"
              onClick={saveAttachedBeacon}
            >
              Attach
            </BaseButton>
          </Collapse>
        </CardBody>
      </BasePopoverView>
    </div>
  );
}

export default BeaconConnectView;
