import axios from "axios";
import { MASTER_DATA_URL } from "config/api";
import ApiService from "./ApiService";
import BaseService from "./BaseService";

export default class MasterDataService extends ApiService {
  constructor() {
    super(MASTER_DATA_URL);
    this.baseService = new BaseService();
  }

  getAllMasterData(url, params, headers) {
    return this.baseService
      .doGET(url, "GET", params, headers)
      .then((response) => {
        if (!!response.data.success) {
          return response.data.data;
        }
      });
  }

  getMasterDataNameID(url, params, headers) {
    return this.baseService
      .doGET(url, "GET", params, headers)
      .then((response) => {
        if (!!response.data.success) {
          let data = response.data.data;
          let masterdatas_array = [];
          data.forEach((item) => {
            let masterdata_obj = { label: item.title, value: item._id };
            masterdatas_array.push(masterdata_obj);
          });
          return masterdatas_array;
        }
      });
  }

  getMasterDataByID(url, params, headers) {
    return this.baseService
      .doGET(url, "GET", params, headers)
      .then((response) => {
        if (!!response.data.success) {
          return response.data.data;
        }
      });
  }

  getMasterDataByBuildingID(url, params, headers) {
    return this.baseService
      .doGET(url, "GET", params, headers)
      .then((response) => {
        if (!!response.data.success) {
          return response.data.data;
        }
      });
  }

  getAllowedModules(url, params, headers) {
    return this.baseService
      .doGET(url, "GET", params, headers)
      .then((response) => {
        if (!!response.data.success) {
          return response.data.data;
        }
      });
  }

  createMasterData(url, data, headers) {
    return this.baseService
      .doPOST(url, "POST", data, headers)
      .then((response) => {
        if (!!response.data.success) {
          return {
            msg: response.data.message,
            data: response.data.data,
          };
        }
      });
  }

  updateMasterData(url, data, params, headers) {
    return this.baseService
      .doPUT(url, "PUT", data, params, headers)
      .then((response) => {
        if (!!response.data.success) {
          return {
            msg: response.data.message,
            data: response.data.data,
          };
        }
      });
  }

  deleteMasterData(url, params, headers) {
    return this.baseService
      .doDELETE(url, "DELETE", params, headers)
      .then((response) => {
        if (!!response.data.success) {
          return {
            msg: response.data.message,
          };
        }
      });
  }
}
