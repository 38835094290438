import { POLICY_URL } from "config/api";
import ApiService from "./ApiService";
import BaseService from "./BaseService";
class PolicyService extends ApiService {
  constructor() {
    super(POLICY_URL);
    this.baseService = new BaseService();
  }
}

export default new PolicyService();
