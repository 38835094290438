import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import CampusService from "services/CampusService";
import SWALService from "services/SWALService";

function CampusTable({ getData, data, loading, setTableConfig }) {
  const history = useHistory();

  const editCampus = (campusId) =>
    history.push(`/admin/campus/edit/${campusId}`);

  const deleteCampus = async (campusId) => {
    console.log(campusId);
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await CampusService.delete(campusId).catch((err) =>
      SWALService.showError(err)
    );

    getData();

    SWALService.showSuccess("Campus has been deleted.", "Deleted!");
  };

  const campusTableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "desc",
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editCampus}
          deleteCallback={deleteCampus}
        />
      ),
    },
  ];

  useEffect(() => {
    setTableConfig(campusTableConfig);
  }, []);

  return (
    <BaseTable tableConfig={campusTableConfig} data={data} loading={loading} />
  );
}

export default CampusTable;
