import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import EmailInput from "components/UserInputComponents/Inputs/TextInputs/EmailInput";
import NameInput from "components/UserInputComponents/Inputs/TextInputs/NameInput";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardTitle,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import AuthBranding from "../sub-components/AuthBranging";
import SignUpSignInService from "../../../../services/SignUpSignInService";
import { USER_URL } from "config/api";
import Swal from "sweetalert2";
import { Auth } from "aws-amplify";
import SWALService from "services/SWALService";
import OrganizationService from "services/OrganizationService";
import OrganizationCategorySelect from "components/UserInputComponents/Selects/OrganizationCategorySelect";
import Validate from "utils/validators";
import toast from "react-hot-toast";
import UserService from "services/UserService";

// import "./SignUp.scss";

const signUpSignInService = new SignUpSignInService();

function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [tradeName, setTradeName] = useState();
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  const history = useHistory();

  const onSubmit = async () => {
    localStorage.clear();
    setLoading(true);

    const newOrganization = {
      tradeName,
      contactPrimary: phone,
      emailId: email,
      category,
    };

    const {
      success: successOrgCreate,
      data: { _id: orgId },
    } = await OrganizationService.post(newOrganization);

    if (!successOrgCreate) return toast.error("OOPs! something went wrong");

    const {
      user: cognitoUser,
      userSub,
      userConfirmed,
      codeDeliveryDetails,
    } = await Auth.signUp({
      username: phone,
      password,
      attributes: {
        email,
        phone_number: phone,
      },
    }).catch((err) => SWALService.showError(err.message));

    if (!cognitoUser) {
      setLoading(false);
      return SWALService.showError("Failed to Sign Up");
    }

    let userObj = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      username: phone,
      phoneNumber: phone,
      role: "ADMIN",
      userPoolId: cognitoUser.pool.userPoolId,
      userSub,
      userConfirmed,
      organization: orgId,
    };

    try {
      SWALService.showLoading("Signing Up");
      const {
        success,
        message,
        data: [userData],
      } = await signUpSignInService.get({
        userSub,
      });
      if (!success) {
        return toast.error("OOPs! something went wrong");
      }

      const { data } = await UserService.put(userData._id, userObj);

      if (!data) return toast.error("User details not uploaded properly");

      SWALService.showSuccess("User created successfully");
      history.push(`/auth/verify-otp/${userData._id}`, {
        reciever: codeDeliveryDetails.Destination,
      });
    } catch (err) {
      toast.error(err || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <Container>
        <Row>
          <AuthBranding />
          <Col className="ml-auto mr-auto" md="5">
            <Card className="card-register card-white">
              <CardHeader className="position-relative">
                <img alt="..." src={require("assets/img/card-primary.png")} />
                {/* <CardImg
                  alt="..."
                  src={require("assets/img/card-primary.png")}
                /> */}
                <CardTitle className="position-absolute top-0" tag="h1">
                  Register
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form">
                  <Row>
                    <Col>
                      <BaseTextInput
                        label="First Name"
                        value={firstName}
                        setValue={setFirstName}
                        placeholder="Enter first name"
                        validator={Validate.Name}
                      />
                    </Col>
                    <Col>
                      <BaseTextInput
                        label="Last Name"
                        value={lastName}
                        setValue={setLastName}
                        placeholder="Enter last name"
                        validator={Validate.Name}
                      />
                    </Col>
                  </Row>

                  <BaseTextInput
                    label="Phone Number"
                    value={phone}
                    setValue={setPhone}
                    placeholder="Enter phone number"
                    bottomHintText="Enter number with country code"
                    validator={Validate.PhoneNumber}
                  />

                  <BaseTextInput
                    label="Email"
                    value={email}
                    setValue={setEmail}
                    placeholder="Enter your email"
                    validator={Validate.Email}
                  />

                  <BaseTextInput
                    label="Password"
                    type="password"
                    value={password}
                    setValue={setPassword}
                    placeholder="Enter a password"
                    validator={Validate.Password}
                  />

                  <BaseTextInput
                    label="Company Name"
                    value={tradeName}
                    setValue={setTradeName}
                    placeholder="Enter Company Name"
                  />

                  <OrganizationCategorySelect setValue={setCategory} editable />

                  <Button
                    className="btn-block"
                    onClick={onSubmit}
                    disabled={loading}
                    size="lg"
                    color="primary"
                  >
                    {loading ? "Loading..." : "Sign Up"}
                  </Button>
                </Form>
              </CardBody>
              <CardFooter>
                <div className="text-center">
                  <span>Already Registered?</span>
                  <span className="mx-2">
                    <Link className="link footer-link" to="/auth/login">
                      Sign In
                    </Link>
                  </span>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SignUp;
