import ChartColors from "config/charts/ChartColors";
import LineChartConfig from "config/charts/LineChartConfig";
import React from "react";
import { Line } from "react-chartjs-2";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import NoData from "views/custom_components/NoData";

import "./VisitorPerTime.scss";

function VisitorPerTime({ data, context }) {
  const labels = Object.keys(data?.histogram || {})?.map((el) =>
    data?.context === "hourly"
      ? DateTimeService.getTimeString(el)
      : DateTimeService.getDateString(el)
  );
  const chartData = Object.values(data?.histogram || {});

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Visitors Trends</CardTitle>
        <p className="text-muted">Visitors ~ Time </p>
      </CardHeader>
      <CardBody>
        {data?.histogram && (
          <div className="chart-area">
            <Line
              options={LineChartConfig.options}
              data={(ctx) =>
                LineChartConfig.createData(
                  ctx,
                  labels,
                  chartData,
                  "Visitors",
                  ChartColors.warning
                )
              }
            />
          </div>
        )}
        {!data?.histogram && (
          <NoData
            show={!data?.histogram}
            message={`No visitors for ${context}`}
            imgSrc="/illustrations/empty_street.svg"
          />
        )}
      </CardBody>
    </Card>
  );
}

export default VisitorPerTime;
