import DateTimeService from "services/DateTimeService";

function getAssetData(tableData) {
  const headers = [
    [
      "UniqueId",
      "Name",
      "Roll",
      "Email",
      "Phone",
      "Role",
      "Class",
      "Assigned Beacon",
      "Gender",
      "Created On",
    ],
  ];
  const data = tableData.map((el, idx) => {
    return [
      el.uniqueId,
      el.name,
      el?.roll,
      el?.email,
      el?.phone,
      el.role,
      el?.primaryGroup?.name || "",
      el?.beacon?.macId || "",
      el?.gender || "",
      DateTimeService.getDateTimeString(el.createdOn),
    ];
  });
  return { headers, data };
}

export default getAssetData;
