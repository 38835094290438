import React, { useEffect, useState } from "react";
// reactstrap components
import { Form } from "reactstrap";
// core components
import RouteService from "../../../../../services/RouteService";
import { useHistory, useParams } from "react-router-dom";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput.js";
import FormHolder from "components/Holders/FormHolder/FormHolder.js";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton.js";
import Forms from "../../../../../config/copies/forms";
import { useSelector } from "react-redux";
import Validate from "utils/validators";
import toast from "react-hot-toast";
import SWALService from "services/SWALService";
import BaseTextAreaInput from "components/UserInputComponents/Inputs/TextInputs/BestTextAreaInput";

function RouteForm() {
  const organizationId = useSelector((state) => state.organization._id);

  const { routeId } = useParams();
  const history = useHistory();

  const buttonText = !!routeId ? "Save Changes" : "Submit";

  const [routeName, setrouteName] = useState("");
  const [encodedPolyline, setEncodedPolyline] = useState("");
  const [desc, setDesc] = useState("");
  const [geofence, setGeofence] = useState("");

  useEffect(() => {
    if (!routeId) return;

    (async () => {
      const { data } = await RouteService.getById(routeId).catch((err) =>
        toast.error(err)
      );
      if (!data) return;

      setrouteName(data?.name || "");
      setEncodedPolyline(data?.encodedPolyline || "");
      setGeofence(data?.geofence || "");
      setDesc(data?.desc || "");
    })();
  }, [routeId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const routeObj = {
      name: routeName,
      desc,
      encodedPolyline,
      routePoints: [],
      organization: organizationId,
    };

    console.log("finalPayload", routeObj);

    if (routeName === "") return;

    if (!routeId)
      return RouteService.post(routeObj).then(({ message }) => {
        SWALService.showSuccess(message).then(() => history.goBack());
      });

    RouteService.put(routeId, routeObj).then(({ message }) => {
      SWALService.showSuccess(message).then(() => history.goBack());
    });
  };

  return (
    <div className="content">
      <FormHolder
        imgSrc={Forms.ROUTE.illustration}
        formHeader={Forms.ROUTE.title}
        formDescription={Forms.ROUTE.desc}
      >
        <Form onSubmit={handleSubmit}>
          <BaseTextInput
            label="Route Name (*)"
            placeholder="Enter route name"
            value={routeName}
            setValue={setrouteName}
            validator={Validate.Name}
          />

          <BaseTextAreaInput
            label="Description (*)"
            placeholder="Enter description"
            value={desc}
            setValue={setDesc}
          />

          <BaseTextInput
            label="Encoded Polyline"
            placeholder="Provide the encoded polyline of the route"
            value={encodedPolyline}
            setValue={setEncodedPolyline}
          />

          {/* <BaseTextInput
            label="Route geofence (*)"
            placeholder="Provide the geofence of the route"
            value={geofence}
            setValue={setGeofence}
          /> */}

          <BaseButton color="info">{buttonText}</BaseButton>
        </Form>
      </FormHolder>
    </div>
  );
}

export default RouteForm;
