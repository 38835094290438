import organizationType from "config/organizationType";
import React, { useState } from "react";
import BaseSelect from "./BaseSelect";

function OrganizationCategorySelect({ value, setValue, editable }) {
  const OrganizationTypeOptions = organizationType.map((el) => ({
    value: el,
    label: el,
  }));

  return (
    <BaseSelect
      label={"Category"}
      dataSource={OrganizationTypeOptions}
      placeholder={"Select category for your firm"}
      closeMenuOnSelect={false}
      isMultiSelect={false}
      value={value}
      setValue={setValue}
      editable={editable}
    />
  );
}

export default OrganizationCategorySelect;
