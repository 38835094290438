import React from "react";
import { Col, Label, Row } from "reactstrap";
import AlertBell from "../AlertBell/AlertBell";

import "./AlertSection.scss";

const AlertAttraction = ({ isSOS }) => (
  <AlertBell>
    <Row>
      <h3 className="AlertAttraction__label">
        {isSOS ? "SOS " : "Violation "} Alert
      </h3>
    </Row>
  </AlertBell>
);

function AlertSection({ policy, policyType }) {
  return (
    <div className="AlertSection" policyType={policyType}>
      <AlertAttraction />
      <Row className="SubjectInfoSection__infoLine">
        <Label className="pr-2">Policy Name:</Label>
        {policy?.name}
      </Row>
      <Row className="SubjectInfoSection__infoLine">
        <Label className="pr-2">Policy description:</Label>
        {policy?.description}
      </Row>
    </div>
  );
}

export default AlertSection;
