const pastelColors = [
  "#ff8779",
  "#2a84e9",
  "#e2e2e2",
  "#7A0FB4",
  "#FFB7B2",
  "#FFDAC1",
  "#E2F0CB",
  "#B5EAD7",
  "#C7CEEA",
];

export default pastelColors;
