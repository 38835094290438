import { INFRA_PRESENCE_URL } from "config/api";
import ApiService from "./ApiService";

class InfraPresenceService extends ApiService {
  constructor() {
    super(INFRA_PRESENCE_URL);
  }
}

export default new InfraPresenceService();
