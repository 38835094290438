import GateListing from "./index";
import GateForm from "./subComponent/GateForm";

const gateRoutes = [
  {
    path: "/admin/gate/manage",
    rtlName: "عالتسعير",
    component: GateListing,
    layout: "/admin",
    name: "Gates",
    showVisible: false,
  },
  {
    path: "/admin/gate/add",
    name: "Add Gate",
    rtlName: "عالتسعير",
    component: GateForm,
    layout: "/admin",
    showVisible: false,
  },
  {
    path: "/admin/gate/edit/:gateId",
    name: "Edit Gate",
    rtlName: "عالتسعير",
    component: GateForm,
    layout: "/admin",
    showVisible: false,
  },
];

export default gateRoutes;
