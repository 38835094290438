import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import Avatar from "../Avatar/Avatar";
import BrandLoader from "../Loader";
import NoData from "../NoData";
import "./Timeline.scss";

function Timeline({
  data = [],
  loading,
  noDataMsg,
  header,
  maxHeight = "",
  assetDetails = {},
}) {
  const getDuration = (duration) => {
    const durationHour = Math.round(duration / (1000 * 60 * 60));
    const duratonMin = Math.round((duration / (1000 * 60)) % 60);
    const durationFormat =
      `${durationHour}`.length === 2
        ? `${durationHour}:${duratonMin}`
        : `0${durationHour}:${duratonMin}`;

    return durationFormat;
  };

  return (
    <Card>
      <>
        <CardHeader>
          <CardTitle tag={"h4"}>{header}</CardTitle>
          {!!assetDetails.name && (
            <Row className="mt-4">
              <Col md={2}>
                <Avatar
                  height={34}
                  src={assetDetails.avatar}
                  alt={assetDetails.name}
                  gender={assetDetails.gender}
                />
              </Col>
              <Col>
                <div style={{ fontWeight: "bold" }}>{assetDetails.name}</div>
                <div className="text-muted text-sm">
                  {assetDetails.uniqueId}
                </div>
              </Col>
            </Row>
          )}
        </CardHeader>
        <CardBody>
          {data?.length === 0 && !loading ? (
            <NoData
              message={noDataMsg || "No timeline. Please select a date to view"}
            />
          ) : (
            <div>
              {loading && <BrandLoader />}
              {!loading && (
                <div
                  className="timelineBox"
                  style={{ maxHeight: `${maxHeight}` }}
                >
                  <div className="timelineBody">
                    <ul className="timeline">
                      {data.map((el, index) => {
                        return (
                          <li>
                            <div class="timelineDot"></div>
                            <div class="timelineDate">
                              <p className="timeline__date">
                                {/* {new Date(el?.startTime).toLocaleString()} */}
                                {DateTimeService.getDateTimeString(
                                  el?.startTime
                                )}
                                <br></br>-<br></br>
                                {data.length === index + 1 &&
                                el.status !== "EXIT_CONFIRM"
                                  ? "now"
                                  : DateTimeService.getDateTimeString(
                                      el?.endTime
                                    )}
                              </p>
                              <p className="timeline__date">
                                duration: {getDuration(el?.duration)}
                              </p>
                            </div>
                            <div class="timelineWork">
                              <span class="badge badge-info">
                                {el?.floorPlanSection?.name}
                              </span>
                              <small>{`${el?.campus} > ${el.building} > ${el.floor}`}</small>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}
        </CardBody>
      </>
    </Card>
  );
}
export default Timeline;
