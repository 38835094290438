import React from 'react';
import {Row,Col} from 'reactstrap';

class ManageUserRole extends React.Component { 
    render() {
        return (
            <React.Fragment>
                <div className="content">
                    <Row>
                        <Col md={12}>
                            
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

export default ManageUserRole;