import React, { useEffect, useState } from "react";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { FormText } from "react-bootstrap";

function BaseSelect({
  label,
  dataSource,
  placeholder,
  isMultiSelect,
  value,
  setValue,
  name,
  isDisabled,
  renderPortal,
  bottomHintText,
  ...props
}) {
  const [_value, _setValue] = useState();

  const { setFilterContext, attribute } = props;

  const _setFilterContext = (activeSelectOption) => {
    if (!(attribute && setFilterContext)) return;

    const _filterContext = !isMultiSelect
      ? {
          [attribute]: {
            value: activeSelectOption?.value,
            label: activeSelectOption?.label,
          },
        }
      : {
          [attribute]: {
            value: activeSelectOption.map((el) => el?.value).join("|"),
            label: activeSelectOption.map((el) => el?.label).join("|"),
          },
        };

    setFilterContext(_filterContext);
  };

  useEffect(() => {
    if (!value) return;
    const initValues = !!isMultiSelect ? value : [value];
    const preSelectValues = dataSource?.filter((el) =>
      initValues.includes(el.value)
    );
    _setValue(preSelectValues);
  }, [value, dataSource]);

  const setData = (selectValue) => {
    _setValue(selectValue);
    setValue(
      !isMultiSelect
        ? selectValue?.value
        : selectValue?.map((objValue) => objValue?.value)
    );

    _setFilterContext(selectValue);
  };

  return (
    <div className="BaseSelect">
      <FormGroup className="mb-4">
        <Label className="mb-2"> {label} </Label>
        <Select
          className="react-select info"
          classNamePrefix="react-select"
          placeholder={!!placeholder ? placeholder : "Select"}
          name={name}
          closeMenuOnSelect={!isMultiSelect}
          isMulti={isMultiSelect}
          value={_value}
          onChange={setData}
          options={dataSource}
          isDisabled={isDisabled}
          isClearable
          captureMenuScroll
          menuPortalTarget={renderPortal && document.getElementById("portal")}
        />
        <FormText color="muted">{bottomHintText}</FormText>
      </FormGroup>
    </div>
  );
  // }
}

export default BaseSelect;
