import React, { useEffect, useState } from "react";
import ScheduleService from "services/ScheduleService";
import BaseSelect from "./BaseSelect";

function ScheduleSelect({
  value,
  setValue,
  isMultiSelect,
  noLabel,
  context = {},
  noPopulate,
}) {
  const [_scheduleEvents, _setScheduleEvents] = useState([]);

  useEffect(() => {
    if (noPopulate) return;
    const params = { ...(context || {}) };

    ScheduleService.get(params)
      .then(({ data }) => _setScheduleEvents(data))
      .catch((err) => console.log(err));
  }, [...Object.values(context), noPopulate]);

  return (
    <BaseSelect
      label={!noLabel && "Schedule"}
      dataSource={_scheduleEvents?.map((scheduleEvent) => ({
        value: scheduleEvent?._id,
        label: scheduleEvent?.name,
      }))}
      placeholder={"Select Schedule"}
      closeMenuOnSelect={false}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default ScheduleSelect;
