import React from "react";
import {
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
} from "reactstrap";
import { useState } from "react";

function BaseTextAreaInput({
  label,
  placeholder,
  name,
  value,
  setValue,
  validator,
  editable,
  bottomHintText,
  min,
  max,
}) {
  const [error, setError] = useState();

  const onChange = (e) => {
    setValue(e.target.value);
    if (error) {
      validateInput(e);
    }
  };

  const validate = () => {
    console.log("its beautiful");
  };

  const validateInput = (e) => setError(validator?.(e.target.value));

  return (
    <div className="BaseInput">
      <Form Group className="mb-4">
        <Label className="mb-2">{label}</Label>
        <Input
          placeholder={placeholder}
          type="textarea"
          name={name}
          value={value}
          onChange={onChange}
          onBlur={validateInput}
          disabled={editable}
          style={{ padding: "0.5rem 0.7rem" }}
          className={editable ? "form-control-plaintext" : ""}
          readOnly={editable}
          min={min}
          max={max}
        />
        <FormText color="danger">{error}</FormText>
        <FormText color="muted">{bottomHintText}</FormText>
      </Form>
    </div>
  );
}

export default BaseTextAreaInput;
