import ApiService from "./ApiService";
import { SHIFTS_URL } from "config/api";
import BaseService from "./BaseService";

class ShiftService extends ApiService {
  constructor() {
    super(SHIFTS_URL);
    this.baseService = new BaseService();
  }
  createShift(url, data, headers) {
    return this.baseService
      .doPOST(url, "POST", data, headers)
      .then((response) => {
        if (!!response.data?.success) {
          return {
            msg: response.data.message,
            data: response.data.data,
          };
        }
      });
  }
}

export default new ShiftService();
