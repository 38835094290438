import messageType from "config/messageType";
import React, { useState } from "react";
import BaseSelect from "./BaseSelect";

function MessageTypeSelect({ value, setValue }) {
  const MessageTypeOptions = messageType.map((el) => ({
    value: el,
    label: el,
  }));
  return (
    <BaseSelect
      label={"Type"}
      dataSource={MessageTypeOptions}
      placeholder={"Select type of message"}
      closeMenuOnSelect={false}
      isMultiSelect={false}
      value={value}
      setValue={setValue}
    />
  );
}

export default MessageTypeSelect;
