import React, { useState } from "react";
import DateTimeService from "services/DateTimeService";
import BaseTimeInput from "./BaseTimeInput";

function EndTimeInput({ value, setValue }) {
  const now = new Date();
  const todayNight = DateTimeService.getMidnightTime(now);
  const [endTime, setEndTime] = useState(todayNight);

  const changeTime = (selectedTime) => {
    setEndTime(selectedTime);
    setValue(selectedTime);
  };
  return (
    <>
      <BaseTimeInput
        label={"To Time"}
        placeholder={"Select to time"}
        value={value || endTime}
        setValue={(val) => changeTime(val)}
      />
    </>
  );
}

export default EndTimeInput;
