import React from "react";

function PolicyViolationTableValues({
  imgUrls,
  nameList,
  uniqueIDList,
  placeList,
  ruleList,
  gatewayIDList,
  incidentTimeList,
}) {
  return (
    <>
      {imgUrls.map((imgUrl, index) => (
        <tr key={index}>
          <td className="text-center">
            <div className="photo">
              <img
                alt="..."
                src={require(`../../../../../assets/img/${imgUrl}`)}
              />
            </div>
          </td>
          <td className="text-center">{nameList[index]}</td>
          <td className="text-center">{uniqueIDList[index]}</td>
          <td className="text-center">{placeList[index]}</td>
          <td className="text-center">{ruleList[index]}</td>
          <td className="text-center">{gatewayIDList[index]}</td>
          <td className="text-center">{incidentTimeList[index]}</td>
        </tr>
      ))}
    </>
  );
}

export default PolicyViolationTableValues;
