import { EVENT_URL } from "config/api";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import BaseService from "services/BaseService";
import DateTimeService from "services/DateTimeService";
import GatewayService from "services/GatewayService";
import UtilsService from "services/UtilsService";
import GatewayView from "views/custom_components/GatewayView";
import InfoCard from "../InfoCard/InfoCard";

import "./StatView.scss";

const baseService = new BaseService();

function StatView({ orgId }) {
  const history = useHistory();
  const [downGateways, setDownGateways] = useState(0);
  const [violations, setViolations] = useState(0);
  const [sosAlerts, setSosAlerts] = useState(0);
  const [lowBeacons, setLowBeacons] = useState(0);
  const [isOfflineGateway, setIsOfflineGateway] = useState(false);

  useEffect(() => {
    GatewayService.getGatewayStatus({ organizationId: orgId, count: true })
      .then(({ data: { data } }) => setDownGateways(data.offline))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const today = new Date();
    const fromTime = DateTimeService.getMorningTime(today).toISOString();
    const toTime = DateTimeService.getMidnightTime(today).toISOString();

    const params = {
      organizationId: orgId,
      fromTime: fromTime,
      toTime: toTime,
    };

    baseService
      .doGET(EVENT_URL, "GET", { ...params, eventTypes: "SOS" }, true)
      .then(({ data: { data } }) => setSosAlerts(data.length))
      .catch((error) => console.log(error));

    baseService
      .doGET(
        EVENT_URL,
        "GET",
        { ...params, eventTypes: "LOCATION_VIOLATION" },
        true
      )
      .then(({ data: { data } }) => setViolations(data.length))
      .catch((error) => console.log(error));
  }, []);

  const closeOfflineGateway = () => setIsOfflineGateway(false);

  const context = {
    organizationId: orgId,
  };

  const showDownGateways = () => {
    setIsOfflineGateway(true);
  };

  const redirectSos = () => {
    history.push({
      pathname: "/admin/alerts",
      search: "?alertType=SOS",
    });
  };
  const redirectVioltation = () => {
    history.push({
      pathname: "/admin/alerts",
      search: "?alertType=LOCATION_VIOLATION",
    });
  };

  return (
    <div className="StatView">
      <Row>
        <Col className="d-flex align-items-stretch justify-content-stretch">
          <InfoCard
            centerStat={sosAlerts}
            info="SOS Alerts"
            color="danger"
            onClick={redirectSos}
          />
        </Col>
        <Col className="d-flex align-items-stretch justify-content-stretch">
          <InfoCard
            centerStat={violations}
            info="Violations"
            color="danger"
            onClick={redirectVioltation}
            appearDelay={400}
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex align-items-stretch">
          <InfoCard
            centerStat={downGateways}
            info="Down"
            subtext="gateways"
            color="warning"
            onClick={showDownGateways}
            appearDelay={600}
          />
        </Col>
        <Col className="d-flex align-items-stretch">
          <InfoCard
            centerStat={lowBeacons}
            info="Low Battery"
            subtext="Beacons"
            color="warning"
            appearDelay={400}
          />
        </Col>
      </Row>
      <GatewayView
        context={context}
        isOpen={isOfflineGateway}
        close={closeOfflineGateway}
        title={"Offline Gateway List"}
        status={false}
        dataLength={downGateways}
      />
    </div>
  );
}

export default StatView;
