import IconButton from "components/UserInputComponents/IconButton/IconButton";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";

import NoData from "views/custom_components/NoData";

import "./SideMenu.scss";

function DefaultTrailingComponent() {
  return <i className="tim-icons icon-minimal-right float-right"></i>;
}

export function MenuItem({ children, isActive, handleClick, trailingComponent, optionalTrailingComponent, noTrailing, menuId }) {
  return (
    <div className={`MenuItem${isActive ? " MenuItem--active" : ""}`} id={menuId} onClick={() => handleClick()}>
      <p className="mb-0">{children}</p>
      {!noTrailing && (
        <p className="MenuItem__trailing">
          {optionalTrailingComponent}
          {trailingComponent || <DefaultTrailingComponent />}
        </p>
      )}
    </div>
  );
}

function SideMenu({ menuItems, goPrevCallback, menuLabel, activeId, children }) {
  // const history = useHistory();

  // const onClickRedirect = (menuLabel) => {
  //   if (menuLabel === "campuses") history.push(`/admin/campus/add`);
  //   else if (menuLabel === "buildings") history.push(`/admin/building/add`);
  //   else history.push(`/admin/floor/add`);
  // };

  // const handlePrevRedirect = (menuLabel) => {
  //   if (menuLabel === "buildings") history.push(`/admin/smart-location/campus`);
  // };

  return (
    <div className="SideMenu">
      <div className="SideMenu__header" onClick={goPrevCallback}>
        <h3 className="SideMenu__headerLabel" style={goPrevCallback && { cursor: "pointer" }}>
          <Row>
            <Col md={10}>
              {goPrevCallback && <i className="tim-icons icon-minimal-left "></i>}
              {menuLabel}
            </Col>
            {/* <Col md={2}>
              <IconButton
                icon="add"
                onClick={() => onClickRedirect(menuLabel)}
                color="success"
                outlined
              />
            </Col> */}
          </Row>
        </h3>
      </div>
      {!!menuItems &&
        menuItems?.map(({ handleClick, label, id }) => (
          <MenuItem isActive={activeId === id} handleClick={() => handleClick(id)} menuId={`menu-${id}`} menuName={menuLabel}>
            {label.replaceAll("_", " ")}
          </MenuItem>
        ))}
      {children}
      {!children && !menuItems?.length && <NoData message={`No ${menuLabel} here`} imgSrc="/illustrations/empty_street.svg" />}
    </div>
  );
}

export default SideMenu;
