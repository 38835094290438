import React, { useEffect, useState } from "react";
import DateTimeService from "services/DateTimeService";

import { Card, Row, Col } from "reactstrap";

import "./TimeWidget.scss";
import CardBody from "reactstrap/lib/CardBody";
import Units from "config/Units";

function TimeWidget({ userName }) {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    setInterval(() => setNow(new Date()), Units.Time.minute);
  }, []);

  return (
    <Card className="TimeWidget">
      <CardBody>
        <Row>
          <Col className="float-left">
            <h2 className="mb-2 mt-2">
              <span className="font-weight-bold">Hello </span>
              {userName || "Admin"}
            </h2>
            <h4 className="text-muted mb-2 font-weight-light">
              {DateTimeService.getDateString(now)}{" "}
              {DateTimeService.getTimeString(now)}
            </h4>
          </Col>
          <Col className="float-right"></Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default TimeWidget;
