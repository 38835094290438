import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
// core components
import VehicleService from "../../../../../services/VehicleService";
import { useHistory, useParams } from "react-router-dom";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput.js";
import FormHolder from "components/Holders/FormHolder/FormHolder.js";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton.js";
import Forms from "../../../../../config/copies/forms";
import SWALService from "services/SWALService";
import { useSelector } from "react-redux";
import Validate from "utils/validators";
import DriverSelect from "components/UserInputComponents/Selects/DriverSelect";
import RouteSelect from "components/UserInputComponents/Selects/RouteSelect";
import VehicleTraxIdSelect from "components/UserInputComponents/Selects/VehicleTraxIdSelect";
import HelperSelect from "components/UserInputComponents/Selects/HelperSelect";
import toast from "react-hot-toast";

import FloorSectionSelect from "components/UserInputComponents/Selects/FloorSectionSelect";
import classNames from "classnames";
import { Tab, Tabs } from "react-bootstrap";

function VehicleForm() {
  const organizationId = useSelector((state) => state.organization._id);

  const { vehicleId } = useParams();
  const history = useHistory(); //browserHistory stack

  const buttonText = !!vehicleId ? "Save Changes" : "Submit";

  const [vehicleName, setvehicleName] = useState("");
  const [regNo, setRegNo] = useState("");
  const [traxDeviceId, setTraxDeviceId] = useState("");
  const [driver, setDriver] = useState();
  const [helpers, setHelpers] = useState([]);
  const [route, setRoute] = useState();
  const [area, setArea] = useState();
  const [thirdPartyTrackingAPIEndpoint, setThirdPartyTrackingAPIEndpoint] =
    useState();

  const [isThirdPartyTracked, setTsThirdPartyTracked] = useState(false);

  const onClickSetThridParty = (val) => () => setTsThirdPartyTracked(val);

  useEffect(() => {
    if (!vehicleId) return;

    (async () => {
      const { data } = await VehicleService.getById(vehicleId).catch((err) =>
        SWALService.showError(err)
      );
      if (!data) return;
      const transportHelpers = data?.helpers.map((helper) => helper._id);

      setvehicleName(data?.name || "");
      setTraxDeviceId(data?.traxDeviceId || "");
      setRoute(data?.route?._id || []);
      setDriver(data?.driver?._id || []);
      setHelpers(transportHelpers || []);
      setRegNo(data?.regNo || "");
      setArea(data?.area);
      setThirdPartyTrackingAPIEndpoint(data?.thirdPartyTrackingAPIEndpoint);
      setTsThirdPartyTracked(data?.isThirdPartyTracked);
    })();
  }, [vehicleId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (vehicleName === "") return;

    const vehicleObj = {
      name: vehicleName,
      regNo,
      traxDeviceId,
      driver,
      helpers,
      route,
      area,
      organization: organizationId,
      isThirdPartyTracked,
      thirdPartyTrackingAPIEndpoint,
    };

    if (!vehicleId)
      return VehicleService.post(vehicleObj)
        .then(({ message }) => {
          history.goBack();
          SWALService.showSuccess(message);
        })
        .catch((err) => SWALService.showError(err));

    VehicleService.patch(vehicleId, vehicleObj)
      .then(({ message }) => {
        history.goBack();
        SWALService.showSuccess(message);
      })
      .catch((err) => SWALService.showError(err));
  };

  return (
    <div className="content">
      <FormHolder
        imgSrc={Forms.VEHICLE.illustration}
        formHeader={Forms.VEHICLE.title}
        formDescription={Forms.VEHICLE.desc}
      >
        <Form onSubmit={handleSubmit}>
          <BaseTextInput
            label="Vehicle Name"
            placeholder="Enter vehicle name"
            value={vehicleName}
            setValue={setvehicleName}
            validator={Validate.Name}
          />

          <BaseTextInput
            label="Vehicle Registration (*)"
            placeholder="Enter vehicle registration"
            value={regNo}
            setValue={setRegNo}
          />

          {/* <BaseTextInput
            label="Vehicle traxId (*)"
            placeholder="Provide the tracking ID power by traxsmart"
            value={traxDeviceId}
            setValue={setTraxDeviceId}
          /> */}

          <ButtonGroup className="pb-3">
            <Button
              className={!isThirdPartyTracked ? "btn-fill" : "btn-simple"}
              color="primary"
              onClick={onClickSetThridParty(false)}
            >
              Traxsmart
            </Button>
            <Button
              className={isThirdPartyTracked ? "btn-fill" : "btn-simple"}
              color="primary"
              onClick={onClickSetThridParty(true)}
            >
              Other
            </Button>
          </ButtonGroup>

          <Collapse isOpen={!isThirdPartyTracked}>
            <VehicleTraxIdSelect
              setValue={setTraxDeviceId}
              value={traxDeviceId}
            />
          </Collapse>

          <Collapse isOpen={isThirdPartyTracked}>
            <BaseTextInput
              value={thirdPartyTrackingAPIEndpoint}
              setValue={setThirdPartyTrackingAPIEndpoint}
              label="https URL"
              placeholder="Enter URL to recieve tracking data"
            />
            {/* <Row>
              <Col>
                <BaseTextInput
                  label="Latitude key"
                  placeholder="Enter key to get latitude"
                />
              </Col>
              <Col>
                <BaseTextInput
                  label="Longitude key"
                  placeholder="Enter key to get longitude"
                />
              </Col>
            </Row> */}
          </Collapse>

          <DriverSelect value={driver} setValue={setDriver} />

          <HelperSelect value={helpers} setValue={setHelpers} isMultiSelect />

          <RouteSelect value={route} setValue={setRoute} />

          <FloorSectionSelect value={area} setValue={setArea} />

          <BaseButton color="info">{buttonText}</BaseButton>
        </Form>
      </FormHolder>
    </div>
  );
}

export default VehicleForm;

// {
//   "address": {
//     "lng": 90
//   }
// }

// address.lng

// _.get('address.lng')

// lng
