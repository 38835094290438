import { TRANSITITINERARY_URL } from "config/api";
import ApiService from "./ApiService";
import BaseService from "./BaseService";

class TransitItineraryService extends ApiService {
  constructor() {
    super(TRANSITITINERARY_URL);
    this.baseService = new BaseService();
  }
}

export default new TransitItineraryService();
