import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import routes from "routes.js";
import logo from "assets/img/react-logo.png";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "../../aws-exports";
import { connect } from "react-redux";
import UtilsService from "../../services/UtilsService";
import SocketAlerts from "views/custom_components/SocketAlerts";
import OccupancyView from "views/custom_components/OccupancyView";
import building from "assets/img/building.jpg";

Amplify.configure(awsconfig);

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeColor: "blue",
      sidebarMini: true,
      opacity: 0,
      sidebarOpened: false,
      loaded: false,
      isAuthenticated: false,
      lockScreenStat: false,
      orgName:
        this.props.organization?.brandName ||
        this.props.organization?.tradeName,
      orgLogo: this.props.organization?.attributes?.logo || building,
      orgWebsite: this.props.organization?.attributes?.website,
    };
  }

  componentDidMount() {
    // startListeningToAlert()
    this.handleMiniClick();
    this.setState({ lockScreenStat: this.props.lockScreenStat });
    window.addEventListener("scroll", this.showNavbarButton);
    this.authenticate();
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser()
        .then((user) => console.log("user: ", user))
        .catch(() => {
          if (this.state.isAuthenticated)
            this.setState({ isAuthenticated: false });
        });
    });
    this.setState({ lockScreenStat: this.props.lockScreenStat });
    const utils = new UtilsService();
    if (!utils.CheckUserAlreadyLoggedIn()) {
      this.props.history.push({ pathname: "/admin/dashboard" });
    }

    const isDarkModePref = localStorage.getItem("darkMode");

    if (isDarkModePref !== "true") {
      document.body.classList.add("white-content");
    } else {
      document.body.classList.remove("white-content");
    }
  }

  authenticate() {
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.setState({ loaded: true, isAuthenticated: true });
      })
      .catch(() => this.props.history.push("/auth"));
  }

  componentDidUpdate(e) {
    if (e.location.pathname !== e.history.location.pathname) {
      console.log(e.location.pathname);
    }
  }

  showNavbarButton = () => {
    console.log("navbar");
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.showNavbarButton);
    this.unlisten = null;
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (!!prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        if (this.state.loaded === true && this.state.isAuthenticated === true) {
          if (prop.showVisible === true) {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          } else if (prop.showVisible === false) {
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            );
          } else {
            return null;
          }
        }
      }
    });
  };

  getActiveRoute = (routes) => {
    let activeRoute = "";
    let regex = /\/\:\w*/;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].path.replace(routes[i].path.match(regex), "")
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  handleActiveClick = (color, themeColor) => {
    this.setState({ activeColor: color });
    localStorage.setItem("themeColor", themeColor);
  };

  handleMiniClick = () => {
    let notifyMessage = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
      notifyMessage += "deactivated...";
    } else {
      this.setState({ sidebarMini: true });
      notifyMessage += "activated...";
    }
    let options = {};
    options = {
      place: "tr",
      message: notifyMessage,
      type: "primary",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    // this.refs.notificationAlert.notificationAlert(options);
    document.body.classList.toggle("sidebar-mini");
  };

  toggleSidebar = () => {
    this.setState({
      sidebarOpened: !this.state.sidebarOpened,
    });
    document.documentElement.classList.toggle("nav-open");
  };

  closeSidebar = () => {
    this.setState({
      sidebarOpened: false,
    });
    document.documentElement.classList.remove("nav-open");
  };

  conditonalRender = () => {
    if (!!this.state.loaded && !!this.state.isAuthenticated) {
      if (!!this.props.lockScreenStat) {
        return (
          <div className="wrapper">
            <div className="rna-container">
              <NotificationAlert ref="notificationAlert" />
            </div>
            <div
              className="navbar-minimize-fixed"
              style={{ opacity: this.state.opacity }}
            >
              <button
                className="minimize-sidebar btn btn-link btn-just-icon"
                onClick={this.handleMiniClick}
              >
                <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
                <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
              </button>
            </div>
            <Sidebar
              {...this.props}
              routes={routes}
              activeColor={this.state.activeColor}
              logo={{
                outterLink: "https://www.parchai.io",
                text: "Parchai",
                imgSrc: logo,
              }}
              closeSidebar={this.closeSidebar}
            />
            <div
              id="scroll-content"
              className="main-panel"
              ref="mainPanel"
              data={this.state.activeColor}
            >
              <AdminNavbar
                {...this.props}
                handleMiniClick={this.handleMiniClick}
                brandText={this.getActiveRoute(routes)}
                sidebarOpened={this.state.sidebarOpened}
                toggleSidebar={this.toggleSidebar}
              />
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from="*" to="/admin/dashboard" />
              </Switch>
              {
                // we don't want the Footer to be rendered on full screen maps page
                this.props.location.pathname.indexOf("full-screen-map") !==
                -1 ? null : (
                  <Footer fluid />
                )
              }
            </div>
            <FixedPlugin
              activeColor={this.state.activeColor}
              sidebarMini={this.state.sidebarMini}
              handleActiveClick={this.handleActiveClick}
              handleMiniClick={this.handleMiniClick}
            />
          </div>
        );
      } else if (!this.props.lockScreenStat) {
        return (
          <div className="wrapper">
            <div className="rna-container">
              <NotificationAlert ref="notificationAlert" />
            </div>
            <div
              className="navbar-minimize-fixed"
              style={{ opacity: this.state.opacity }}
            >
              <button
                className="minimize-sidebar btn btn-link btn-just-icon"
                onClick={this.handleMiniClick}
              >
                <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
                <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
              </button>
            </div>
            <Sidebar
              {...this.props}
              routes={routes}
              activeColor={this.state.activeColor}
              logo={{
                outterLink: "/admin/organization/manage",
                text: `${this.state.orgName}`,
                imgSrc: `${this.state.orgLogo}`,
              }}
              closeSidebar={this.closeSidebar}
            />
            <PerfectScrollbar>
              <div
                className="main-panel"
                ref="mainPanel"
                data={this.state.activeColor}
              >
                <AdminNavbar
                  {...this.props}
                  handleMiniClick={this.handleMiniClick}
                  brandText={this.getActiveRoute(routes)}
                  sidebarOpened={this.state.sidebarOpened}
                  toggleSidebar={this.toggleSidebar}
                />
                <Switch>
                  {this.getRoutes(routes)}
                  <Redirect from="*" to="/admin/dashboard" />
                </Switch>
                {
                  // we don't want the Footer to be rendered on full screen maps page
                  this.props.location.pathname.indexOf("full-screen-map") !==
                  -1 ? null : (
                    <Footer fluid />
                  )
                }
              </div>
            </PerfectScrollbar>
            <SocketAlerts />
            <OccupancyView />
          </div>
        );
      } else {
        this.props.history.push("/auth/unlock_screen");
      }
    }
  };

  render() {
    return <div>{this.conditonalRender()}</div>;
  }
}

const mapStateToProps = (state) => {
  const user = state.user;
  return user;
};

export default connect(mapStateToProps)(Admin);
