import React, { useState, useEffect } from "react";
// reactstrap components
import { Form, Row } from "reactstrap";
// react plugin used to create DropdownMenu for selecting items
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import FloorPlanSectionService from "../../../../../services/FloorPlanSectionService";
import Swal from "sweetalert2";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import FormHolder from "components/Holders/FormHolder/FormHolder";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import Forms from "../../../../../config/copies/forms";
import CampusSelect from "components/UserInputComponents/Selects/CampusSelect";
import BuildingSelect from "components/UserInputComponents/Selects/BuildingSelect";
import FloorPlanSelect from "components/UserInputComponents/Selects/FloorPlanSelect";
import BaseSelect from "components/UserInputComponents/Selects/BaseSelect";
import { Modal } from "react-bootstrap";
import NoData from "views/custom_components/NoData";
import EditMapsModal from "views/custom_components/LeafletMaps/EditMapsModal/EditMapsModal";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import CSVupload from "views/components/CSVupload";
import { FLOORSECTION_BULK_UPLOAD_URL } from "config/api";
import CSVuploadManual from "views/components/CSVuploadManual";
import SWALService from "services/SWALService";

const areaTypeOpts = [
  {
    value: "PRODUCTIVE",
    label: "PRODUCTIVE",
  },
  {
    value: "NON_PRODUCTIVE",
    label: "NON_PRODUCTIVE",
  },
];

const sectionTypeOpts = [
  {
    value: "INDOOR",
    label: "INDOOR",
  },
  {
    value: "OUTDOOR",
    label: "OUTDOOR",
  },
  {
    value: "TRANSPORT",
    label: "TRANSPORT",
  },
];

function FloorPlanSectionForm() {
  const organization = useSelector((state) => state.organization._id);

  const history = useHistory();

  const { floorPlanSectionId } = useParams();

  const buttonText = floorPlanSectionId ? "Save Changes" : "Submit";

  const tableConfig = [
    {
      Header: "Name",
      accessor: "floorPlanSectionName",
    },
    {
      Header: "Section Type",
      accessor: "sectionTypes",
    },
    {
      Header: "Area Types",
      accessor: "areaTypes",
    },
  ];

  const [floorPlanSectionName, setFloorPlanSectionName] = useState("");
  const [campus, setCampus] = useState();
  const [building, setBuilding] = useState();
  const [floorPlan, setFloorPlan] = useState("");
  const [gateway, setGateway] = useState("");
  const [sectionTypes, setSectionTypes] = useState();
  const [areaTypes, setAreaTypes] = useState("");
  const [primaryGroup, setPrimaryGroup] = useState("");
  const [show, setShow] = useState(false);
  const [floorAreaId, setFloorAreaId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [floorPlanData, setFloorPlanData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [manualModal, setManualModal] = useState(false);

  useEffect(() => {
    if (!floorPlanSectionId) return;

    FloorPlanSectionService.getById(floorPlanSectionId).then(({ data }) => {
      setFloorPlanSectionName(data.floorPlanSectionName);
      setCampus(data.campus?._id);
      setBuilding(data.building?._id);
      setFloorPlan(data.floorPlan?._id);
      setSectionTypes(data.sectionTypes);
      setAreaTypes(data.areaTypes);
      setPrimaryGroup(data.primaryGroup);
    });
  }, [floorPlanSectionId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (floorPlanSectionName === "") return;

    const payload = {
      campus,
      building,
      floorPlan,
      sectionTypes: sectionTypes,
      // mappedGE,
      areaTypes: areaTypes,
      floorPlanSectionName,
      organization,
      // gateway,
      // primaryGroup,
    };

    if (!floorPlanSectionId)
      return FloorPlanSectionService.post(payload).then(({ message, data }) => {
        setFloorAreaId(data._id);
        Swal.fire({
          icon: "success",
          title: "FloorPlanSection Create",
          text: message,
        });
      });
    // .finally(() => {
    //   history.goBack();
    // });

    FloorPlanSectionService.put(floorPlanSectionId, payload)
      .then(({ message }) => {
        setLoading(true);
        setFloorAreaId(floorPlanSectionId);
        // toast.success("Floor Plan Area Updated Successfully");
        Swal.fire({
          icon: "success",
          title: "FloorPlanSection Update",
          text: "Floor Plan Area Updated Successfully",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const purifyDatasets = (arr) => {
    return arr.map((el) => ({
      ...el,
      campus: campus,
      building: building,
      floorPlan: floorPlan,
      sectionTypes: el.sectionTypes.toUpperCase(),
      areaTypes: el.areaTypes.toUpperCase(),
      organization: organization,
    }));
  };

  const checkSectionTypeValidity = (item) => {
    return ["INDOOR", "OUTDOOR", "TRANSPORT"].includes(item.sectionTypes);
  };

  const checkAreaTypeValidity = (item) => {
    return ["PRODUCTIVE", "NON_PRODUCTIVE"].includes(item.areaTypes);
  };

  const checkValidity = (payload) => {
    payload.forEach((el) => {
      if (!checkSectionTypeValidity(el)) {
        throw new Error("Section Type column is not valid");
      } else if (!checkAreaTypeValidity(el)) {
        throw new Error("Area Type column is not valid");
      } else if (el.floorPlanSectionName === "") {
        throw new Error("Name coulmn is not valid");
      }
    });
  };

  const clearEmptyRow = () => {
    const filterData = floorPlanData.filter((object) => {
      return !!Object.keys(object).length;
    });
    setFloorPlanData(filterData);
  };

  const handleBlukSubmit = async (e) => {
    e.preventDefault();
    clearEmptyRow();
    const payload = purifyDatasets(floorPlanData);
    try {
      checkValidity(payload);
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: err.message,
      });
      return;
    }

    await FloorPlanSectionService.doPost(
      FLOORSECTION_BULK_UPLOAD_URL,
      payload,
      true,
    )
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "FloorPlans Section Added",
          text: response.msg,
        }).then(() => {
          history.push("/admin/floorPlanSection/manage");
        });
      })
      .catch((error) => {
        SWALService.showError(error.message);
      });
  };

  return (
    <div className="content">
      <Row>
        <CSVupload
          title="Upload Multiple Floor"
          modalOpen={modalOpen}
          data={floorPlanData}
          setData={setFloorPlanData}
          handleSubmit={handleBlukSubmit}
          setModalOpen={setModalOpen}
          tableConfig={tableConfig}
          requiredFields={[building, campus, floorPlan]}
          setShowManual={setManualModal}
          selectFields={
            <div>
              <CampusSelect setValue={setCampus} value={campus} />

              <BuildingSelect
                setValue={setBuilding}
                value={building}
                context={{ campusId: campus }}
                noPopulate={!campus}
              />
              <FloorPlanSelect
                setValue={setFloorPlan}
                value={floorPlan}
                context={{ buildingId: building }}
                noPopulate={!building}
              />
            </div>
          }
        />
        <CSVuploadManual
          setModalOpen={setManualModal}
          modalOpen={manualModal}
          gId={"123599277"}
        />
        <FormHolder
          imgSrc={Forms.ROOM.illustration}
          formHeader={Forms.ROOM.title}
          formDescription={Forms.ROOM.desc}
          formActions={
            <IconButton
              icon={"upload_file"}
              onClick={() => setModalOpen(true)}
              color={"info"}
              outlined
            />
          }
        >
          {!loading && (
            <Form onSubmit={handleSubmit}>
              <BaseTextInput
                label="Area Name"
                placeholder="Enter the name of the area"
                value={floorPlanSectionName}
                setValue={setFloorPlanSectionName}
              />
              <CampusSelect setValue={setCampus} value={campus} />

              <BuildingSelect
                setValue={setBuilding}
                value={building}
                context={{ campusId: campus }}
                noPopulate={!campus}
              />
              <FloorPlanSelect
                setValue={setFloorPlan}
                value={floorPlan}
                context={{ buildingId: building }}
                noPopulate={!building}
              />
              <BaseSelect
                value={sectionTypes}
                setValue={setSectionTypes}
                placeholder="Select a type of area this is"
                label="Section Type"
                dataSource={sectionTypeOpts}
              />
              <BaseSelect
                setValue={setAreaTypes}
                value={areaTypes}
                placeholder="Choose Area Types"
                label="Area Types"
                dataSource={areaTypeOpts}
              />

              <Modal.Dialog size="xl">
                <Modal.Header>
                  <Modal.Title>Plot Floor Area</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  {!!floorPlan && (
                    <EditMapsModal
                      activeFloorPlan={floorPlan}
                      floorAreaName={floorPlanSectionName}
                      floorAreaId={floorAreaId}
                    />
                  )}
                  {!floorPlan && (
                    <NoData
                      message="Select a floorplan to start"
                      imgSrc="/illustrations/current_location.svg"
                    />
                  )}
                </Modal.Body>

                {/* <Modal.Footer>
                <Button variant="secondary">Close</Button>
                <Button variant="primary">Save changes</Button>
              </Modal.Footer> */}
              </Modal.Dialog>

              {/* <TagSelect
              label="Primary Group"
              value={primaryGroup}
              setValue={setPrimaryGroup}
            /> */}
              {/* <GroupEntitySelect value={mappedGE} setValue={setMappedGE} /> */}
              <BaseButton color="info">{buttonText}</BaseButton>
            </Form>
          )}
        </FormHolder>
      </Row>
    </div>
  );
}

export default FloorPlanSectionForm;
