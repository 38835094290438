import React from "react";
import { Card, CardBody } from "reactstrap";

import "./FormNarrator.scss";

function FormNarrator({ children }) {
  //   const NoDataTextStyle = {
  //     backgroundColor: invert ? "rgba(0,0,0,0.6)" : "rgba(255,255,255,0.2)",

  //   };

  return (
    <Card className="FormNarrator">
      <p
        className="FormNarrator__text"
        dangerouslySetInnerHTML={{ __html: children }}
        style={{ color: "blue" }}
      ></p>
    </Card>
  );
}

export default FormNarrator;
