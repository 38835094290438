import { VISITOR_REPORT } from "config/api";
import ApiService from "./ApiService";

class VisiotrReportService extends ApiService {
  contexts = {
    VISITOR_IN_PREMISE: "VISITOR_IN_PREMISE",
    VISITOR_TOTAL_COUNT: "VISITOR_TOTAL_COUNT",
    VISITOR_HISTOGRAM: "VISITOR_HISTOGRAM",
    VISITEE_HISTOGRAM: "VISITEE_HISTOGRAM",
    VISITING_PURPOSES_HISTOGRAM: "VISITING_PURPOSES_HISTOGRAM",
    VISITOR_VIOLATIONS: "VISITOR_VIOLATIONS",
    VISITOR_EXPECTED: "VISITOR_EXPECTED",
    VISITOR_COUNTS_BY_TIMEFRAME: "VISITOR_COUNTS_BY_TIMEFRAME",
    VISITOR_DISTRIBUTION: "VISITOR_DISTRIBUTION",
  };

  constructor() {
    super(VISITOR_REPORT);
  }
}

export default new VisiotrReportService();
