import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";
import SignIn from "views/pages/auth/signIn/signIn";
import SignUp from "views/pages/auth/SignUp/SignUp";
import OtpVerify from "views/pages/auth/OtpVerify/OtpVerify";
import routes from "routes.js";
import ForgotPassword from "views/pages/auth/forgotPass/forgotPass";

class Authenticity extends React.Component {
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getActiveRoute = (routes) => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          activeRoute = collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          activeRoute = routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  getFullPageName = (routes) => {
    let pageName = this.getActiveRoute(routes);
    switch (pageName) {
      case "Pricing":
        return "pricing-page";
      case "Login":
        return "login-page";
      case "Register":
        return "register-page";
      case "Lock Screen":
        return "lock-page";
      default:
        return "Default Brand Text";
    }
  };
  componentDidMount() {
    document.documentElement.classList.remove("nav-open");
  }
  render() {
    return (
      <>
        <AuthNavbar brandText={this.getActiveRoute(routes)} />
        <div className="wrapper wrapper-full-page" ref="fullPages">
          <div className={"full-page " + this.getFullPageName(routes)}>
            <Switch>
              {/* {this.getRoutes(routes)} */}
              {/* <Route path="/auth" exact component={SignIn}/> */}
              <Route path="/auth/register" exact component={SignUp} />
              <Route
                path="/auth/verify-otp/:userId"
                exact
                component={OtpVerify}
              />
              <Route
                path="/auth/forgotPassword"
                exact
                component={ForgotPassword}
              />
              <Route path="/auth" exact component={SignIn} />
              <Route path="/auth/login" exact component={SignIn} />
            </Switch>
            <Footer fluid />
          </div>
        </div>
      </>
    );
  }
}

export default Authenticity;
