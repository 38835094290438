import { ROUTESTOP_URL } from "config/api";
import ApiService from "./ApiService";

class RoutePointService extends ApiService {
  constructor() {
    super(ROUTESTOP_URL);
  }
}

export default new RoutePointService();
