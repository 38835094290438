import gender from "config/gender";
import React from "react";
import BaseSelect from "./BaseSelect";

function GenderSelect({ value, setValue, isMultiSelect }) {
  const GenderOptions = gender.map((el) => ({
    value: el,
    label: el,
  }));

  return (
    <BaseSelect
      label={"Gender"}
      dataSource={GenderOptions}
      placeholder={"Select gender"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={false}
      value={value}
      setValue={setValue}
    />
  );
}

export default GenderSelect;
