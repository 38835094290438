import Joi from "joi";

// const Joi = require("joi");

const customMessages = {
  "string.min": `Cannot be less than {#limit} characters`,
};

const handleValidated = ({ error }) => {
  if (!error) return "";
  return error.details[0]?.message;
};

export default class Validate {
  static Email(value) {
    const validated = Joi.string()
      .email({ tlds: { allow: false } })
      .validate(value);
    return handleValidated(validated);
  }

  static Password(value) {
    const validated = Joi.string()
      .prefs({ messages: customMessages })
      .min(3)
      .max(16)
      .validate(value);
    return handleValidated(validated);
  }

  static Name(value) {
    const validated = Joi.string()
      .prefs({ messages: customMessages })
      .min(3)
      .validate(value);
    return handleValidated(validated);
  }

  static PhoneNumber(value) {
    const validated = Joi.string()
      .pattern(/^\+(0|91)(\d{10})/)
      .messages({ "string.pattern.base": `Not a valid phone number.` })
      .validate(value);
    return handleValidated(validated);
  }

  static Time(value) {
    const validated = Joi.string()
      .pattern(/(\d{2}):(\d{2})$/)
      .messages({ "string.pattern.base": `Time must be in HH:mm format.` })
      .validate(value);
    return handleValidated(validated);
  }

  static Age(value) {
    const validated = Joi.number()
      .integer()
      .prefs({ messages: customMessages })
      .min(0)
      .max(100)
      .validate(value);
    return handleValidated(validated);
  }
}
