import React from "react";

import "./FrequentVisitors.scss";
import ListCard from "components/ListCard/ListCard";
import { useHistory } from "react-router-dom";

function FrequentVisitors({ data = [] }) {
  const history = useHistory();
  const summary_data = data.slice(0, 5);
  const layoutData = summary_data?.map(({ context, count }) => ({
    title: context?.name,
    imgSrc: context?.attributes?.avatar,
    subtitle: context?.attributes?.phoneNumbers?.slice(0, 1).join(" "),
    count: count,
  }));

  const handleRedirect = (name, contact) => {
    history.push({
      pathname: `/admin/visitors/records`,
      search: `?contact=${contact}`,
    });
  };

  return (
    <ListCard
      cardTitle="Frequent Visitors"
      data={layoutData}
      isClick={true}
      handleClick={handleRedirect}
    />
  );
}

export default FrequentVisitors;
