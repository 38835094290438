import TableHeader from "components/Tables/TableHeader/TableHeader";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import AssetPojoSelect from "components/UserInputComponents/Selects/AssetPojoSelect";
import BooleanSelect from "components/UserInputComponents/Selects/BooleanSelect";
import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Form,
  Row,
} from "reactstrap";
import RoutePointAssetService from "services/RoutePointAssetService";
import RoutePointService from "services/RoutePointService";
import RouteService from "services/RouteService";
import SWALService from "services/SWALService";
import BrandLoader from "views/custom_components/Loader";
import RoutePointAssetTable from "./subComponents/RoutePointAssetTable";
import RoutePointTable from "./subComponents/RoutePointTable";

function RoutePoints() {
  const organizationId = useSelector((state) => state.organization._id);
  const { routeId } = useParams();

  const [routePoints, setRoutePoints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [routePointId, setRoutePointId] = useState("");
  const [buttonText, setButtonText] = useState("Add Stop");
  const [assetsState, setAssetsState] = useState(false);
  const [assets, setAssets] = useState([]);
  const [routePointAssetDetails, setRoutePointAssetDetails] = useState([]);
  const [unMapAssets, setUnMapAssets] = useState([]);
  const [assetCopiedState, setAssetCopiedState] = useState([]);

  // route stop states
  const [stopName, setStopName] = useState("");
  //   const [tags, setTags] = useState([]);
  const [active, setActive] = useState();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");

  useEffect(() => {
    setAssetCopiedState((prevState) =>
      _.uniq([...prevState, ...(assets || [])])
    );
  }, [assets]);

  const getRoutePoints = () => {
    setLoading(true);
    try {
      RouteService.get({ id: routeId }).then(({ data }) => {
        setRoutePoints(data[0].routePoints);
      });
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  // const getRoutePointDetails = () => {
  //   if (!routePointId) return;
  //   try {
  //     setLoading(true);
  //     RoutePointService.get({ id: routePointId }).then(({ data }) => {
  //       setRoutePointDetails(data[0].assets);
  //     });
  //   } catch (e) {
  //     toast.error(e);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getRouteAssetMapping = () => {
    if (!routePointId) return;
    setLoading(true);
    try {
      RoutePointService.get({ id: routePointId }).then(({ data }) => {
        // setRoutePoints(data[0].routePoints);
        setAssetCopiedState(data[0]?.assets);
      });
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  const updateRoutePoints = (id, payload) => {
    setLoading(true);
    try {
      RoutePointService.put(id, payload).then((data) => {
        SWALService.showSuccess(
          "Route Stop is updated successfully",
          "Success!"
        ).then(() => {
          getRoutePoints();
        });
      });
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  const mapAssetsToRoutePoint = (payload) => {
    setLoading(true);
    try {
      RoutePointAssetService.post(payload)
        .then((data) => {
          SWALService.showSuccess(
            "Changes updated successfully",
            "Success!"
          ).then(() => {
            getRouteAssetMapping();
            setAssets();
          });
        })
        .catch((e) => toast.error(e.message));
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!routePointId) return;

    setLoading(true);
    try {
      RoutePointService.get({ id: routePointId }).then(({ data }) => {
        setStopName(data[0].name);
        setActive(data[0].active);
        setLatitude(data[0].location?.geometry?.coordinates[0].lat);
        setLongitude(data[0].location?.geometry?.coordinates[0].lng);
        setArrivalTime(data[0].STA);
        setButtonText("Save Changes");
      });
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  }, [routePointId]);

  useEffect(() => {
    getRoutePoints();
  }, []);

  useEffect(() => {
    getRouteAssetMapping();
  }, [routePointId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const routePoint = {
      active,
      organization: organizationId,
      name: stopName,
      route: routeId,
      //   tags: tags,
      location: {
        geometry: {
          coordinates: [
            {
              lat: latitude,
              lng: longitude,
            },
          ],
          type: "Point",
        },
        type: "Point",
      },
      STA: arrivalTime,
    };

    if (routePointId) {
      updateRoutePoints(routePointId, routePoint);
    } else {
      try {
        setLoading(true);
        RoutePointService.post(routePoint).then((data) => {
          SWALService.showSuccess(
            "Route Stop is created successfully",
            "Success!"
          ).then(() => {
            getRoutePoints();
          });
        });
      } catch (e) {
        toast.error(e?.message || e);
      } finally {
        setLoading(false);
      }
    }
    setButtonText("Add Stop");
  };

  const handleAssetSubmit = (e) => {
    e.preventDefault();
    const assetRoutePointMap = {
      organization: organizationId,
      routePoint: routePointId,
      assets: {},
      route: routeId,
    };
    assetRoutePointMap.assets.map =
      assetCopiedState.length > 0
        ? assetCopiedState.map((asset) => asset._id)
        : [];
    assetRoutePointMap.assets.unmap = unMapAssets.length > 0 ? unMapAssets : [];

    if (!routePointId) return;
    mapAssetsToRoutePoint(assetRoutePointMap);
  };

  return (
    <div className="content">
      <Card>
        <CardHeader>
          <CardTitle tag="h3">Route Stop</CardTitle>
        </CardHeader>
        <CardBody>
          {loading && <BrandLoader />}
          {!loading && (
            <Row>
              <Col md={4}>
                <Collapse isOpen={!assetsState}>
                  <Form onSubmit={handleSubmit}>
                    <BaseTextInput
                      value={stopName}
                      setValue={setStopName}
                      placeholder="Enter stop name"
                      label="Stop Name"
                    />
                    {/* <TagSelect
                    value={tags}
                    setValue={setTags}
                    isMultiSelect
                    label="Tags"
                  /> */}
                    <BooleanSelect
                      value={active}
                      setValue={setActive}
                      label="Active"
                    />
                    <Row>
                      <Col>
                        <BaseTextInput
                          value={latitude}
                          setValue={setLatitude}
                          placeholder="Enter latitude"
                          label="Latitude"
                        />
                      </Col>
                      <Col>
                        <BaseTextInput
                          value={longitude}
                          setValue={setLongitude}
                          placeholder="Enter longitude"
                          label="Longitude"
                        />
                      </Col>
                      <Col>
                        <BaseTextInput
                          value={arrivalTime}
                          setValue={setArrivalTime}
                          placeholder="Enter time of arrival"
                          label="Time of Arrival"
                        />
                        {/* <BaseTimeInput
                        value={arrivalTime}
                        setValue={setArrivalTime}
                        placeholder="Enter time of arrival"
                        label="Time of Arrival"
                      /> */}
                      </Col>
                    </Row>
                    <BaseButton color="info">{buttonText}</BaseButton>
                  </Form>
                </Collapse>
                <Collapse isOpen={assetsState}>
                  <Form onSubmit={handleAssetSubmit}>
                    <Row>
                      <Col md={12}>
                        <AssetPojoSelect
                          setValue={setAssets}
                          value={assets}
                          isMultiSelect
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col md={12}>
                        <TableHeader title="Assigned Assets" />
                        <RoutePointAssetTable
                          loading={loading}
                          data={assetCopiedState}
                          getData={getRouteAssetMapping}
                          setUnMapData={setUnMapAssets}
                          setData={setAssetCopiedState}
                        />
                        <BaseButton color="info">{buttonText}</BaseButton>
                      </Col>
                    </Row>
                  </Form>
                </Collapse>
              </Col>
              <Col md={1}></Col>
              <Col md={7}>
                <Card>
                  <CardHeader>
                    <TableHeader title="Route Stop" />
                  </CardHeader>
                  <CardBody>
                    <RoutePointTable
                      getData={getRoutePoints}
                      data={routePoints}
                      loading={loading}
                      setRoutePointId={setRoutePointId}
                      setAssetsState={setAssetsState}
                      activeRoutePointId={routePointId}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default RoutePoints;
