const VisitingPurposes = [
  "BUSINESS_MEETING",
  "INTERVIEW",
  "FOOD_DELIVERY",
  "GENERIC_VISIT",
  "MAINTENANCE",
  "CLEANING",
  "PACKAGE_DELIVERY",
];

const VisitorReportContexts = [
  "VISITOR_IN_PREMISE",
  "VISITOR_TOTAL_COUNT",
  "VISITOR_VIOLATIONS",
  "VISITOR_EXPECTED",
  "VISITOR_HISTOGRAM",
  "VISITEE_HISTOGRAM",
  "VISITING_PURPOSES_HISTOGRAM",
  "VISITOR_COUNTS_BY_TIMEFRAME",
  "VISITOR_DISTRIBUTION",
];

export { VisitingPurposes, VisitorReportContexts };
