import React from "react";
import BaseTextInput from "./BaseTextInput";

function TitleInput({ value, setValue }) {
  return (
    <BaseTextInput
      label={"Title"}
      placeholder={"Enter message title"}
      name={"title"}
      value={value}
      setValue={setValue}
    />
  );
}

export default TitleInput;
