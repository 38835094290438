import { convertToRaw, EditorState } from "draft-js";
import React from "react";
import { FormGroup, Label } from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

function BaseTextBoxInput({ setValue, value }) {
  const onEditorStateChange = (editorState) => {
    setValue(editorState);
  };
  return (
    <FormGroup className="mb-4">
      <Label className="mb-2">Message</Label>
      <Editor
        editorState={value}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ["inline", "list", "textAlign"],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["bold", "italic", "underline"],
          },
          list: {
            inDropdown: true,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["unordered", "ordered"],
          },
          textAlign: {
            inDropdown: true,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["left", "center", "right"],
          },
        }}
        editorStyle={{
          backgroundColor: "Whitesmoke",
          height: "300px",
          padding: "15px",
        }}
      />
    </FormGroup>
  );
}

export default BaseTextBoxInput;
