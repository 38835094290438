import React from "react";
import { Row, Col } from 'reactstrap';
import MasterDataListing from './subComponent/MasterDataListing';

function ManageMasterData() {
  return (
    <div className="content">
      <Row>
          <Col md="12">
            <MasterDataListing />
          </Col>
      </Row>
    </div>
  );
}

export default ManageMasterData;