import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import calcHeight from "../../utils/calcHeight";
import SideMenu from "../SideMenu/SideMenu";
import menuStages from "./config";

function BuildingMenu({
  activeMenu,
  setActiveMenu,
  buildings,
  setMenuHeight,
  campusId,
  prevMenu,
}) {
  const [makePrimary, setMakePrimary] = useState(false);

  useEffect(() => {
    if (prevMenu === menuStages.campuses)
      setTimeout(() => setMakePrimary(true), 500);
  }, [prevMenu]);

  useEffect(() => {
    if (activeMenu == menuStages.floorplans) setMakePrimary(true);
    if (activeMenu == menuStages.campuses) setMakePrimary(false);
  }, [activeMenu]);

  return (
    <CSSTransition
      in={activeMenu === menuStages.buildings}
      timeout={200}
      classNames={makePrimary ? "Menu--primary" : "Menu--secondary"}
      unmountOnExit
      onEnter={(el) => calcHeight(el, setMenuHeight)}
    >
      <div>
        <SideMenu
          menuItems={buildings?.filter(
            (building) => campusId == building.campusId
          )}
          menuLabel={menuStages.buildings}
          goPrevCallback={() => {
            setActiveMenu(menuStages.campuses);
            setMakePrimary(true);
          }}
        />
      </div>
    </CSSTransition>
  );
}

export default BuildingMenu;
