import React, { useEffect, useState } from "react";
import BuildingService from "services/BuildingService";
import BaseSelect from "./BaseSelect";

function BuildingSelect({
  value,
  setValue,
  isMultiSelect,
  noLabel,
  context = {},
  noPopulate,
}) {
  const [buildings, setBuildings] = useState([]);

  useEffect(() => {
    if (noPopulate) return;

    const params = { ...(context || {}) };
    BuildingService.get(params)
      .then(({ data }) => setBuildings(data))
      .catch((err) => console.log(err));
  }, [...Object.values(context), noPopulate]);

  return (
    <BaseSelect
      label={!noLabel && "Building"}
      dataSource={buildings?.map((buidling) => ({
        value: buidling?._id,
        label: buidling?.name,
      }))}
      placeholder={"Select Building"}
      closeMenuOnSelect={false}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default BuildingSelect;
