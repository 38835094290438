import React from "react";

import Image from "components/Image/Image";
import Icon from "components/Icon/Icon";

import "./ListTile.scss";

function ListTile({
  imgSrc,
  title,
  subtitle,
  icon,
  iconColor,
  count,
  isClick,
  handleClick,
}) {
  return (
    <div
      onClick={isClick ? () => handleClick(title, subtitle) : ""}
      className="d-flex justify-content-between align-items-center"
    >
      <div className="d-flex mb-4 align-items-center" data-metatip="true">
        <div className="icon-container">
          <div
            className={`ListTile__img bg-${
              iconColor || "info"
            }-soft  shadow text-center border-radius-xs shadow-none d-flex justify-content-center align-items-center`}
          >
            {icon && <Icon icon={icon} size="lg" gradient={iconColor} />}
            {imgSrc && <Image src={imgSrc} />}
          </div>
        </div>
        <div className="ms-3">
          <div className="numbers">
            <h5 className="mb-1 text-dark text-sm">{title}</h5>
            {subtitle && <span className="text-sm">{subtitle}</span>}
          </div>
        </div>
      </div>
      <div className="ListTile__count">{count}</div>
    </div>
  );
}

export default ListTile;
