import React, { useEffect, useState } from "react";
import BaseSelect from "./BaseSelect";
import ShiftService from "services/ShiftService";

function ShiftSelect({ value, setValue, isMultiSelect, label }) {
  const [shifts, setShifts] = useState([]);

  useEffect(() => {
    ShiftService.get()
      .then((res) => {
        setShifts(res.data?.map((el) => ({ value: el._id, label: el.name })));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <BaseSelect
      label={label ? label : "Shift"}
      dataSource={shifts}
      placeholder={"Select Shift"}
      closeMenuOnSelect={false}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default ShiftSelect;
