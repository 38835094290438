import BaseService from "./BaseService";
import { SUBJECT_URL } from "config/api";
import DateTimeService from "./DateTimeService";
import ApiService from "./ApiService";
import { ASSET_URL } from "config/api";

export default class SubjectService extends ApiService {
  constructor() {
    super(SUBJECT_URL);
    this.baseService = new BaseService();
  }

  getAllSubjects(url, method, params, headers) {
    return this.baseService
      .doGET(SUBJECT_URL, "GET", params, headers)
      .then((response) => {
        if (!!response.data.success) {
          return response.data.data;
        }
      });
  }

  getSubjects(params, headers) {
    return this.baseService
      .doGET(SUBJECT_URL, "GET", params, headers)
      .then((response) => {
        if (!!response.data.success) {
          return response.data.data;
        }
      });
  }

  getSubjectByID(url, params, headers) {
    return this.baseService
      .doGET(SUBJECT_URL, "GET", params, headers)
      .then((response) => {
        if (!!response.data.success) {
          return response.data.data;
        }
      });
  }

  getAllSubjectsByOrgID(url, params, headers) {
    return this.baseService
      .doGET(SUBJECT_URL, "GET", params, headers)
      .then((response) => {
        if (!!response.data.success) {
          return response.data.data;
        }
      });
  }

  getAllSubjectsByRoomID(url, params, headers) {
    return this.baseService
      .doGET(SUBJECT_URL, "GET", params, headers)
      .then((response) => {
        if (!!response.data.success) {
          return response.data.data;
        }
      });
  }

  createSubject(url, data, headers) {
    return this.baseService
      .doPOST(url, "POST", data, headers)
      .then((response) => {
        if (!!response.data.success) {
          return {
            msg: response.data.message,
            data: response.data.data,
          };
        }
      })
      .catch((err) => console.log(err));
  }

  createGroup(url, data, headers) {
    return this.baseService
      .doPOST(SUBJECT_URL + "/groups/add", "POST", data, headers)
      .then((response) => {
        console.log(response);
        if (!!response.data.success) {
          return {
            msg: response.data.message,
            data: response.data.data,
          };
        }
      });
  }

  updateSubject(url, data, params, headers) {
    return this.baseService
      .doPUT(SUBJECT_URL, "PUT", data, params, headers)
      .then((response) => {
        if (!!response.data.success) {
          return {
            msg: response.data.message,
            data: response.data.data,
          };
        }
      });
  }

  deleteSubject(url, params, headers) {
    return this.baseService
      .doDELETE(SUBJECT_URL, "DELETE", params, headers)
      .then((response) => {
        if (!!response?.data?.success) {
          return {
            msg: response?.data?.message,
          };
        }
      });
  }

  getSubjectTimeline(subjectId, fromTime, toTime) {
    const today = new Date();

    const params = {
      fromTime: fromTime || DateTimeService.getMorningTime(today).toISOString(),
      toTime: toTime || DateTimeService.getMidnightTime(today).toISOString(),
    };

    return this.baseService.doGET(`${SUBJECT_URL}/timeline`, "GET", {
      ...params,
      subjectId,
    });
  }

  attachBeacon(beacon, asset, params, headers) {
    return this.doPost(
      `${ASSET_URL}/beacon/map`,
      { beacon, asset },
      params,
      headers,
    );
  }

  detachBeacon(subjectId, params, headers) {
    return this.doPost(
      `${ASSET_URL}/beacon/unmap/${subjectId}`,
      undefined,
      params,
      headers,
    );
  }

  attachUser(assetId, userId, params, headers) {
    return this.doPost(
      `${ASSET_URL}/user/map`,
      { assetId, userId },
      params,
      headers,
    );
  }

  detachAssociatedUser(assetId, userId, params, headers) {
    return this.doPost(
      `${ASSET_URL}/user/unmap`,
      { assetId, userId },
      params,
      headers,
    );
  }
}
