import React, { useEffect, useState } from "react";
import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import CardTitle from "reactstrap/lib/CardTitle";
import "./UpcomingEvents.scss";
import CalendarService from "services/CalendarService";
import { useSelector } from "react-redux";
import DateTimeService from "services/DateTimeService";
import EventCard from "./EventCard";
import moment from "moment";
import { CardBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import NoData from "../../../views/custom_components/NoData";

function UpcomingEvents() {
  const history = useHistory();
  const orgId = useSelector((state) => state.organization._id);
  const now = new Date();

  const todayMorning = DateTimeService.getMorningTime(now);
  const [data, setData] = useState([]);
  const [fromTime, setFromTime] = useState(todayMorning);

  const queryParam = {
    organizationId: orgId,
    fromTime: fromTime?.toISOString(),
  };

  useEffect(() => {
    CalendarService.get(queryParam)
      .then(({ data }) => {
        setData(data.slice(0, 5));
      })
      .catch((error) => {
        throw error;
      });
  }, []);

  return (
    <Card
      className="UpcomingEvents clickable"
      onClick={() => history.push("/admin/manage-calender")}
    >
      <CardHeader>
        <CardTitle tag="h4">Upcoming Events</CardTitle>
      </CardHeader>
      <CardBody>
        {data?.map((item) => (
          <EventCard
            name={item.name ? item.name : ""}
            fromTime={
              item.fromTime
                ? moment(item.fromTime).utc().format("Do MMMM YYYY, h:mm a")
                : ""
            }
          />
        ))}
        {data.length === 0 && (
          <NoData message={"Upcoming events will appear here"} />
        )}
      </CardBody>
    </Card>
  );
}

export default UpcomingEvents;
