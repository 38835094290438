const { ORG_URL } = require("config/api");
const { default: ApiService } = require("./ApiService");

class OrganizationService extends ApiService {
  constructor() {
    super(ORG_URL);
  }
}

export default new OrganizationService();
