const getAccuracy = (txPower, rssi) => {
  //10 ^ ((measuredPower – (RSSI))/(10 * 2))
  if (rssi === 0) {
    return -1; // if we cannot determine accuracy, return -1.
  }

  var ratio = (rssi * 1) / txPower;
  if (ratio < 1.0) {
    return Math.pow(ratio, 10);
  } else {
    return 0.89976 * Math.pow(ratio, 7.7095) + 0.111;
  }
  //return Math.pow(10, (txPower - rssi) / (10 * 2));
};

const getBeaconProximity = (accuracy) => {
  const isImmediate = accuracy >= 0 && accuracy <= 2;
  const isNear = accuracy > 2 && accuracy <= 8;
  const isFar = accuracy > 8;

  if (isNear) return "NEAR";
  if (isImmediate) return "IMMEDIATE";
  if (isFar) return "FAR";
  return;
};

export const gatewayBeaconTableConfig = [
  { Header: "Beacon macID", accessor: "mac" },
  { Header: "Ping time", accessor: "timestamp" },
  { Header: "RSSI", accessor: "rssi" },
  { Header: "TX power", accessor: "ibeaconTxPower" },
  { Header: "Major", accessor: "ibeaconMajor" },
  { Header: "Minor", accessor: "ibeaconMinor" },
  { Header: "UUID", accessor: "ibeaconUuid" },
  {
    Header: "Accuracy",
    accessor: (d) => getAccuracy(d.ibeaconTxPower, d.rssi),
  },
  {
    Header: "Proximity",
    accessor: (d) => getBeaconProximity(getAccuracy(d.ibeaconTxPower, d.rssi)),
  },
];

export default gatewayBeaconTableConfig;
