import TableHeader from "components/Tables/TableHeader/TableHeader";
import BaseDatePicker from "components/UserInputComponents/DatePickers/BaseDatePicker";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Row, Col, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import SWALService from "services/SWALService";
import VehicleService from "services/VehicleService";
import VehicleTable from "./subComponents/VehicleTable";
import * as manageVehicle from "stub/manageVehicle.json";

function ManageVehicles() {
  const now = new Date();
  const todayMorning = DateTimeService.getMorningTime(now);
  const [date, setDate] = useState(todayMorning);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vehicleParams, setVehicleParams] = useState({});
  const organizationId = useSelector((state) => state.organization._id);
  const defaultParams = { organizationId };
  const history = useHistory();

  useEffect(() => {
    setData(manageVehicle.default);
  }, [vehicleParams]);

  const fetchVehicle = async () => {
    setLoading(true);

    const params = {
      ...vehicleParams,
      ...defaultParams,
    };

    const { data } = await VehicleService.get(params).catch((err) =>
      SWALService.showError(err)
    );

    if (data) setData(data);
    setLoading(false);
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <TableHeader title="Vehicle Occupancy">
                <Col className="ml-auto" md="3">
                  <BaseDatePicker
                    label={""}
                    placeholder="Select date"
                    date={date}
                    setDate={setDate}
                  />
                </Col>
              </TableHeader>
            </CardHeader>
            <CardBody>
              <VehicleTable
                data={data}
                getData={fetchVehicle}
                loading={loading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ManageVehicles;
