import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import MasterDataService from "../../../../../services/MasterDataService";
import SWALService from "services/SWALService";
import MasterDataSelect from "components/UserInputComponents/Selects/MasterDataSelect";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import BaseTable from "components/Tables/BaseTable/BaseTable";

const masterDataService = new MasterDataService();

function MasterDataListing() {
  const history = useHistory();
  const [masterData, setMasterData] = useState([]);
  // const [masterData, setMasterData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMasterData();
  }, [masterData]);

  const getMasterData = async () => {
    setLoading(true);

    const params = {};

    const { data } = await masterDataService
      .get(params)
      .catch((err) => SWALService.showError(err));

    if (data) setMasterData(data);
    setLoading(false);
  };

  const editMasterData = (masterdata_id) => {
    history.push(`/admin/edit_master_data/${masterdata_id}`);
  };

  const addMasterData = () => {
    history.push("/admin/add_master_data");
  };

  const deleteMasterData = async (masterDataId) => {
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await masterDataService
      .delete(masterDataId)
      .catch((err) => SWALService.showError(err));

    getMasterData();

    SWALService.showSuccess("Master Data has been deleted.", "Deleted!");
  };

  const masterDataTableConfig = [
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editMasterData}
          deleteCallback={deleteMasterData}
        />
      ),
    },
  ];

  return (
    <div className="content">
      <Row>
        <Col xs={12} md={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col md={4}>
                  <CardTitle tag="h4">Master Data</CardTitle>
                </Col>
                <Col md={4}>
                  <MasterDataSelect
                    value={masterData}
                    setValue={setMasterData}
                    isMultiSelect
                  />
                </Col>
                <Col md={4}>
                  <Button
                    color="success"
                    className="right_alg_button"
                    onClick={addMasterData}
                  >
                    <i className="tim-icons icon-simple-add" /> Add Master Data
                  </Button>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              <BaseTable
                tableConfig={masterDataTableConfig}
                data={masterData}
                loading={loading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default MasterDataListing;
