import React from "react";
import { Card, CardBody, Fade } from "reactstrap";
import ReactDOM from "react-dom";
import AnimatedLogo from "./AnimatedLogo";

import "./AppLoader.scss";

function AppLoader({ loading }) {
  return ReactDOM.createPortal(
    <Fade in={loading} timeout={800} appear={false} unmountOnExit mountOnEntry>
      <Card className="AppLoader">
        <CardBody className="AppLoader__container">
          <AnimatedLogo />
          {/* <h3>Connecting...</h3> */}
        </CardBody>
      </Card>
    </Fade>,
    document.getElementById("portal")
  );
}

export default AppLoader;
