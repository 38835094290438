import React from "react";
import TableAction from "../TableAction/TableAction";

function TableActions({
  children,
  deleteCallback,
  editCallback,
  manageCallback,
  addCallback,
  miscellaneousCallback,
  icon,
  name,
  ...row
}) {
  return (
    <div className="d-flex justify-content-end align-items-center">
      {editCallback && (
        <TableAction
          color="warning"
          icon="mode_edit"
          onClick={editCallback}
          name="Edit"
          {...row}
        />
      )}
      {deleteCallback && (
        <TableAction
          color="danger"
          icon="delete_outline"
          onClick={deleteCallback}
          name="Delete"
          {...row}
        />
      )}
      {manageCallback && (
        <TableAction
          color="primary"
          icon="manage_history"
          onClick={manageCallback}
          name="Manage"
          {...row}
        />
      )}
      {addCallback && (
        <TableAction
          color="primary"
          icon="add_to_photos"
          onClick={addCallback}
          name="Add"
          {...row}
        />
      )}
      {miscellaneousCallback && (
        <TableAction
          color="primary"
          icon={icon}
          onClick={miscellaneousCallback}
          name={name || "Add"}
          {...row}
        />
      )}
      {children}
    </div>
  );
}

export default TableActions;
