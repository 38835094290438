import types from "../types";

export const setOrganization = (payload) => {
  console.log({ organization: payload });
  return {
    type: types.SET_ORGANIZATION,
    payload: payload,
  };
};

export const unsetOrganization = () => {
  return {
    type: types.UNSET_ORGANIZATION,
  };
};

const organizationActions = {
  setOrganization,
  unsetOrganization,
};

export default organizationActions;
