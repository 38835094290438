import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import { TAG_BULK_UPLOAD_URL } from "config/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import TagService from "services/TagService";
import Swal from "sweetalert2";
import CSVupload from "views/components/CSVupload";
import CSVuploadManual from "views/components/CSVuploadManual";
import TooltipAction from "views/custom_components/TooltipAction";
import CreateTags from "./CreateTags";
import SWALService from "services/SWALService";

function TagsListing() {
  const organizationId = useSelector((state) => state.organization._id);

  const history = useHistory();

  const param = { organizationId };

  const [data, setData] = useState([]);
  const [editTagId, setEditTagId] = useState("");
  const [loading, setLoading] = useState(false);
  const [createTags, setCreateTags] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [tagsData, setTagsData] = useState([]);
  const [manualModal, setManualModal] = useState(false);

  const fetchTags = () => {
    setLoading(true);
    TagService.get(param)
      .then(({ data }) => {
        if (data.length < 0) return;
        setData(data);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const actions = (id) => {
    return (
      <div className="actions-right">
        <Button
          className="btn-link btn-icon"
          color="success"
          size="sm"
          onClick={() => editTags(id)}
        >
          <i id="editUser" className="tim-icons icon-pencil" />
        </Button>
        <TooltipAction target="editUser" textContent="Edit Tag" />
        {/* use this button to remove the data row */}
        <Button
          className="btn-link btn-icon"
          color="danger"
          size="sm"
          onClick={() => deleteTags(id)}
        >
          <i id="deleteUser" className="tim-icons icon-trash-simple" />
        </Button>
        <TooltipAction target="deleteUser" textContent="Delete Tag" />
      </div>
    );
  };

  const addTags = () => {
    setCreateTags(true);
  };

  const editTags = (id) => {
    setEditTagId(id);
    setCreateTags(true);
  };

  const deleteTags = (id) => {
    Swal.fire({
      title: "Are you sure to Delete it?",
      text: "You won't be able to revert this after deleting!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          TagService.delete(id)
            .then(({ message }) => {
              Swal.fire("Deleted!", "Tag has been deleted.", "message");
              fetchTags();
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Error occured",
              });
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      filterable: false,
      Cell: ({ original }) => actions(original._id),
    },
  ];

  const fileuploadTableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
  ];

  const clearEmptyRow = () => {
    const filterData = tagsData.filter((object) => {
      return !!Object.keys(object).length;
    });
    setTagsData(filterData);
  };

  const handleBlukSubmit = (e) => {
    e.preventDefault();
    clearEmptyRow();
    tagsData.forEach((tag) => {
      tag.organization = organizationId;
    });
    const payoad = tagsData;
    console.log(payoad);
    TagService.doPost(TAG_BULK_UPLOAD_URL, payoad, true)
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Tags Added",
          text: response.msg,
        }).then(() => {
          setModalOpen(false);
          fetchTags();
        });
      })
      .catch((error) => {
        SWALService.showError(error.message);
      });
  };

  return (
    <>
      <div className="content wrapTable">
        <CreateTags
          showCreateTags={createTags}
          setShowCreateTags={setCreateTags}
          fetchTags={fetchTags}
          tagId={editTagId}
        />
        <CSVupload
          title="Upload Multiple Tags"
          modalOpen={modalOpen}
          data={tagsData}
          setData={setTagsData}
          handleSubmit={handleBlukSubmit}
          tableConfig={fileuploadTableConfig}
          setModalOpen={setModalOpen}
          setShowManual={setManualModal}
        />
        <CSVuploadManual
          setModalOpen={setManualModal}
          modalOpen={manualModal}
          gId={"1505916174"}
        />
        <Row className="mx-auto">
          <Col xs={5} md={5} className="mx-auto">
            <Card>
              <CardHeader>
                <TableHeader
                  title="Tags"
                  onClickAdd={addTags}
                  onClickFileUpload={() => {
                    setTagsData([]);
                    setModalOpen(true);
                  }}
                  tableData={data}
                />
              </CardHeader>
              <CardBody>
                <BaseTable
                  data={data}
                  tableConfig={tableConfig}
                  loading={loading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default TagsListing;
