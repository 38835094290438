import FilterTableHolder from "components/Holders/FilterTableHolder/FilterTableHolder";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import React, { useEffect, useMemo, useState } from "react";
import VisitorLogTable from "../sub-components/VisitorLogTable/VisitorLogTable";

import VisitorLogFilter from "../sub-components/VisitorLogFilter/VisitorLogFilter";
import useQuery from "customHooks/useQuery";

import "./VisitorLogBook.scss";

function VisitorLogBook() {
  const [searchParams] = useQuery();
  const [params, setParams] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableConfig, setTableConfig] = useState([]);

  useEffect(() => {
    if (!searchParams) return;
    setParams({
      purposeOfVisit: searchParams.visitorPurpose,
      fromTime: searchParams.fromTime,
      toTime: searchParams.toTime,
    });
  }, [searchParams]);

  return (
    <FilterTableHolder>
      <VisitorLogFilter setParams={setParams} />
      <TableHeader
        title="Visitor Log Book"
        onClickDownload={true}
        tableConfig={tableConfig}
        tableData={tableData}
      />
      <VisitorLogTable
        tableData={setTableData}
        tableConfig={setTableConfig}
        params={params}
      />
    </FilterTableHolder>
  );
}

export default VisitorLogBook;
