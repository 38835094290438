import React from "react";
import { Container } from "reactstrap";

function Footer() {
  return (
    <footer className="footer">
      <Container fluid={true}>
        <ul className="nav">
          <li className="nav-item">
            <a className="nav-link" href="">
              Privacy Policy
            </a>
          </li>{" "}
          <li className="nav-item">
            <a className="nav-link" href="">
              About us
            </a>
          </li>{" "}
          <li className="nav-item">
            <a className="nav-link" href="">
              Help and Support
            </a>
          </li>
        </ul>
        <div className="copyright">
          © {new Date().getFullYear() + " "}
          <a href="https://www.parchai.io/" target="_blank">
            Parchai
          </a>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
