import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import AssetSelect from "components/UserInputComponents/Selects/AssetSelect";
import Divider from "components/Divider/Divider";
import BooleanSelect from "components/UserInputComponents/Selects/BooleanSelect";
import GatewayAttachmentSelect from "components/UserInputComponents/Selects/GatewayAttachmentSelect";
import NotificationCommentTypeSelect from "components/UserInputComponents/Selects/NotificationCommentTypeSelect";
import FromTimeInput from "components/UserInputComponents/TimePickers/FromTimeInput";
import EndTimeInput from "components/UserInputComponents/TimePickers/EndTimeInput";
import NotificationEventTypeSelect from "components/UserInputComponents/Selects/NotificationEventTypeSelect";
import { useHistory } from "react-router-dom";
import setQueryParams from "utils/setQueryParams";

function InfraPresenceFilter({ getAlerts }) {
  const history = useHistory();
  const orgId = useSelector((state) => state.organization?._id);

  const now = new Date();
  const todayNight = DateTimeService.getMidnightTime(now);
  const todayMorning = DateTimeService.getMorningTime(now);
  const [startDate, setStartDate] = useState(todayMorning);
  const [endDate, setEndDate] = useState(todayNight);
  const [fromTime, setFromTime] = useState(todayMorning);
  const [toTime, setToTime] = useState(todayNight);
  const [assetId, setAssetId] = useState();
  const [comment, setComment] = useState("");
  const [gatewayAttachmentType, setGatewayAttachmentType] = useState("");
  const [status, setStatus] = useState();
  const [eventType, setEventType] = useState("");

  const handleFilters = () => {
    if (!assetId)
      return toast.error(
        "Select an Asset, Primary Group, Role,or a Beacon to proceed"
      );

    const payload = {
      organizationId: orgId,
      fromTime: DateTimeService.mergeDateAndTimeString(
        startDate.toISOString(),
        fromTime.toISOString()
      ),
      toTime: DateTimeService.mergeDateAndTimeString(
        endDate.toISOString(),
        toTime.toISOString()
      ),
      assetId,
      gatewayAttachmentType,
      comment,
      status,
      eventType,
    };

    history.push({
      pathname: "/admin/notification-logs",
      search: `${setQueryParams(payload)}`,
    });

    getAlerts(payload);
  };

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <StartDatePicker date={startDate} setDate={setStartDate} />
        <EndDatePicker date={endDate} setDate={setEndDate} />
        <FromTimeInput value={fromTime} setValue={setFromTime} />
        <EndTimeInput value={toTime} setValue={setToTime} />
        <Divider className="my-4" />
        <AssetSelect value={assetId} setValue={setAssetId} />
        <Divider className="my-4" />
        <GatewayAttachmentSelect
          value={gatewayAttachmentType}
          setValue={setGatewayAttachmentType}
        />
        <BooleanSelect
          label="Status"
          placeholder="Select status"
          value={status}
          setValue={setStatus}
        />
        <NotificationCommentTypeSelect value={comment} setValue={setComment} />
        <NotificationEventTypeSelect
          value={eventType}
          setValue={setEventType}
        />
        <br />
        <Button color="info" onClick={handleFilters}>
          Get Reports
        </Button>
      </CardBody>
    </Card>
  );
}

export default InfraPresenceFilter;
