import axios from "axios";
import { MASTER_DATA_URL } from "config/api";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormGroup, Row, Col } from "reactstrap";
import BaseSelect from "./BaseSelect";

function GroupEntitySelect({
  value,
  setValue,
  isMultiSelect,
  display,
  label,
  placeholder,
}) {
  const [_mappedGEs, _setMappedGEs] = useState([]);

  useEffect(() => {
    axios
      .get(MASTER_DATA_URL)
      .then(({ data: { data: res } }) =>
        _setMappedGEs(
          res
            .map(
              (el) =>
                el.type === "GROUPED_ENTITY" && {
                  label: el?.title,
                  value: el?._id,
                }
            )
            .filter(Boolean)
        )
      )
      .catch((err) => console.log(err));
  }, []);

  return (
    <BaseSelect
      label={label || "Groups"}
      dataSource={_mappedGEs}
      placeholder={placeholder || "Grouped Entity"}
      closeMenuOnSelect={false}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
      display={display}
    />
  );
}

export default GroupEntitySelect;
