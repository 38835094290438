import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import CalendarService from "services/CalendarService";
import DateTimeService from "services/DateTimeService";
import Swal from "sweetalert2";
import TooltipAction from "views/custom_components/TooltipAction";
import CalenderEventFilter from "./CalenderEventFilter";

function CalenderEventListing() {
  const organizationId = useSelector((state) => state.organization._id);
  const history = useHistory();

  const param = { organizationId };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCalenderEvents = (_params) => {
    setLoading(true);
    CalendarService.get(_params)
      .then(({ data }) => {
        setData(data);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCalenderEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = (id) => {
    return (
      <div className="actions-right">
        <Button
          className="btn-link btn-icon"
          color="success"
          size="sm"
          onClick={() => editCalenderEvent(id)}
        >
          <i id="editUser" className="tim-icons icon-pencil" />
        </Button>
        <TooltipAction target="editUser" textContent="Edit User" />
        {/* use this button to remove the data row */}
        <Button
          className="btn-link btn-icon"
          color="danger"
          size="sm"
          onClick={() => deleteCalenderEvent(id)}
        >
          <i id="deleteUser" className="tim-icons icon-trash-simple" />
        </Button>
        <TooltipAction target="deleteUser" textContent="Delete User" />
      </div>
    );
  };

  const addCalenderEvent = () => {
    history.push("/admin/calendar-event/add");
  };
  const editCalenderEvent = (id) => {
    history.push(`/admin/calendar-event/edit/${id}`);
  };
  const deleteCalenderEvent = (id) => {
    Swal.fire({
      title: "Are you sure to Delete it?",
      text: "You won't be able to revert this after deleting!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          CalendarService.delete(id)
            .then(({ message }) => {
              Swal.fire(
                "Deleted!",
                "Calendar Event has been deleted.",
                "message"
              );
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Error occured",
              });
            })
            .finally(() => {
              getCalenderEvents();
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
    // {
    //   Header: "From Time",
    //   accessor: ({ fromTime }) => DateTimeService.getTimeString(fromTime),
    // },
    // {
    //   Header: "To Time",
    //   accessor: ({ toTime }) => DateTimeService.getTimeString(toTime),
    // },
    {
      Header: "Start Date",
      accessor: ({ fromTime }) => DateTimeService.getDateString(fromTime),
    },
    {
      Header: "End Date",
      accessor: ({ toTime }) => DateTimeService.getDateString(toTime),
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      filterable: false,
      Cell: ({ original }) => actions(original._id),
    },
  ];

  return (
    <>
      <div className="content wrapTable">
        <Row className="ml-auto">
          <Col md={3}>
            <CalenderEventFilter getCalenderEvents={getCalenderEvents} />
          </Col>
          <Col xs={9} md={9} className="ml-auto">
            <Card>
              <CardHeader>
                <TableHeader
                  title="Calender Events"
                  onClickAdd={addCalenderEvent}
                  onClickDownload={true}
                  tableConfig={tableConfig}
                  tableData={data}
                />
              </CardHeader>
              <CardBody>
                <BaseTable
                  data={data}
                  tableConfig={tableConfig}
                  loading={loading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CalenderEventListing;
