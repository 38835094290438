const ENUMS = {
  ...require("./Attendance"),
  ...require("./AuditTrail"),
  ...require("./Beacon"),
  ...require("./Gateway"),
  ...require("./Calendar"),
  ...require("./Communication"),
  ...require("./Genders"),
  ...require("./MasterData"),
  ...require("./PolicyAndActions"),
  ...require("./FloorPlanSection"),
  ...require("./Visitor"),
  ...require("./Roles"),
};

export default ENUMS;
