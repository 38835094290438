import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import CampusSelect from "components/UserInputComponents/Selects/CampusSelect";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Form } from "reactstrap";

function CampusFilter({ setCampusParams }) {
  const [campusId, setCampusId] = useState();

  const orgId = useSelector((state) => state.organization._id);
  // const orgId = "621642aa5656450009819ffc";

  const onSubmit = (e) => {
    e.preventDefault();
    setCampusParams({
      organizationId: orgId,
      id: campusId,
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <CampusSelect value={campusId} setValue={setCampusId} />
      <BaseButton>Apply</BaseButton>
    </Form>
  );
}

export default CampusFilter;
