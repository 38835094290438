import SubjectView from "components/PopoverViews/SubjectView/SubjectView";
import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DateTimeService from "services/DateTimeService";
import ScheduleService from "services/ScheduleService";
import SubjectService from "services/SubjectService";
import SWALService from "services/SWALService";
import Avatar from "views/custom_components/Avatar/Avatar";

const subjectService = new SubjectService();

function VisitorRecordTable({ params, tableData, tableConfig }) {
  const history = useHistory();

  const [visitorRegisterations, setVisitorRegistrations] = useState([]);

  const [loading, setLoading] = useState(false);

  const visitorRegisterationTableConfig = [
    {
      Header: "#",
      Cell: ({ original }) => {
        console.log("check", original);
        return (
          <div>
            <Avatar
              height={34}
              src={original.attributes.avatar}
              alt={original.name}
              gender={original.attributes.gender}
            />
          </div>
        );
      },
    },
    {
      Header: "Name",
      accessor: (data) => data.name,
      Cell: ({ original }) => (
        <SubjectView subjectId={original._id} subjectData={original}>
          {original.name}
        </SubjectView>
      ),
    },
    {
      Header: "Contact",
      accessor: (d) => d.attributes.phoneNumbers.toString(),
    },
    // {
    //   Header: "Age",
    //   accessor: "attributes.age",
    // },
    {
      Header: "Gender",
      accessor: "attributes.gender",
    },
    {
      Header: "Registered On",
      accessor: ({ createdOn }) => DateTimeService.getDateTimeString(createdOn),
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editSubject}
          deleteCallback={deleteSubject}
        />
      ),
    },
  ];

  const getData = async () => {
    if (!params.role) return;
    setLoading(true);
    const { data } = await subjectService
      .get(params)
      .catch((err) => SWALService.showError(err));
    setLoading(false);

    if (!data) return;
    setVisitorRegistrations(data);
    tableData(data);
  };

  const editSubject = (scheduleId) =>
    history.push(`/admin/asset/edit/${scheduleId}`);

  const deleteSubject = async (scheduleId) => {
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await ScheduleService.delete(scheduleId).catch((err) =>
      SWALService.showError(err)
    );

    getData();

    SWALService.showSuccess("Visitor has been deleted.", "Deleted!");
  };

  useEffect(() => {
    getData();
    tableConfig(visitorRegisterationTableConfig);
  }, [params]);

  return (
    <BaseTable
      data={visitorRegisterations}
      tableConfig={visitorRegisterationTableConfig}
      loading={loading}
    />
  );
}

export default VisitorRecordTable;
