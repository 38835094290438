import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import GatewayMacFloorSectionSelect from "components/UserInputComponents/Selects/GatewayMacFloorSectionSelect";
import EndTimeInput from "components/UserInputComponents/TimePickers/EndTimeInput";
import FromTimeInput from "components/UserInputComponents/TimePickers/FromTimeInput";
import useQuery from "customHooks/useQuery";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import DateTimeService from "services/DateTimeService";

function GatewayStateLogsFilter({ setParams }) {
  const orgId = useSelector((state) => state.organization._id);
  const [{ macId }] = useQuery();
  const currentTimestamp = new Date();
  const todayNight = DateTimeService.getMidnightTime(currentTimestamp);
  const todayMorning = DateTimeService.getMorningTime(currentTimestamp);

  const [gatewayMac, setGatewayMac] = useState("");
  const [fromTime, setFromTime] = useState(todayMorning);
  const [toTime, setToTime] = useState(todayNight);
  const [fromDate, setFromDate] = useState(todayMorning);
  const [toDate, setToDate] = useState(todayNight);

  useEffect(() => {
    if (!macId) return;
    setGatewayMac(macId);
    setParams({
      organizationId: orgId,
      gatewayMac: macId,
      fromTime: DateTimeService.mergeDateAndTimeString(
        fromDate.toISOString(),
        fromTime.toISOString()
      ),
      toTime: DateTimeService.mergeDateAndTimeString(
        toDate.toISOString(),
        toTime.toISOString()
      ),
    });
  }, [macId]);

  const applyFilters = (e) => {
    e.preventDefault();
    setParams({
      organizationId: orgId,
      gatewayMac,
      fromTime: DateTimeService.mergeDateAndTimeString(
        fromDate.toISOString(),
        fromTime.toISOString()
      ),
      toTime: DateTimeService.mergeDateAndTimeString(
        toDate.toISOString(),
        toTime.toISOString()
      ),
    });
  };

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <StartDatePicker date={fromDate} setDate={setFromDate} />
        <EndDatePicker date={toDate} setDate={setToDate} />
        <FromTimeInput value={fromTime} setValue={setFromTime} />
        <EndTimeInput value={toTime} setValue={setToTime} />
        <GatewayMacFloorSectionSelect
          value={gatewayMac}
          setValue={setGatewayMac}
        />
        <Button color="info" onClick={applyFilters}>
          Apply
        </Button>
      </CardBody>
    </Card>
  );
}

export default GatewayStateLogsFilter;
