import types from "../types";

const initOrganizationState = {};

function organizationReducer(prevState = initOrganizationState, action) {
  switch (action.type) {
    case types.SET_ORGANIZATION:
      return { ...prevState, ...action.payload };
    case types.UNSET_ORGANIZATION:
      return initOrganizationState;
    default:
      return prevState;
  }
}

export default organizationReducer;
