import { SUBJECT_URL } from "config/api";
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import SubjectService from "services/SubjectService";
import Avatar from "views/custom_components/Avatar/Avatar";
import BrandLoader from "views/custom_components/Loader";

import "./ProfileInfo.scss";

const subjectService = new SubjectService();

const ProfilePic = ({ avatarUrl, gender }) => (
  <div className="ProfileInfo__cardAvatarContainer mx-4 my-2 ">
    <Avatar
      height={64}
      className={"border-radius-lg shadow-sm"}
      src={avatarUrl}
      alt="profile_image"
      gender={gender}
    />
  </div>
);

function ProfileInfo({ subjectId, showEdit, naked }) {
  const history = useHistory();
  const [subject, setSubject] = useState();
  const [loading, setLoading] = useState(false);

  const noMarginClass = (naked && " mb-0 ") || "";

  useEffect(() => {
    if (!subjectId) return;
    setLoading(true);
    subjectService
      .getAllSubjects(SUBJECT_URL, "GET", { id: subjectId })
      .then((data) => setSubject(data[0]))
      .catch((err) => console.log(err).finally(() => setLoading(false)));
  }, [subjectId]);

  const handleRedirect = (e) => {
    e.preventDefault();
    history.push(`/admin/editsubject/${subjectId}`);
  };

  return (
    <Row>
      <Col md="12" className="d-flex justify-content-around">
        <Card className={noMarginClass}>
          <CardBody className={noMarginClass}>
            {loading && !!subject ? (
              <Row>
                <Row className="ProfileInfo__main m-2">
                  <ProfilePic
                    avatarUrl={subject?.attributes?.avatar}
                    gender={subject?.attributes?.gender}
                  />
                  <CardBody className="my-auto">
                    <div className="h-100 text-align-center">
                      <h3 className="m-0 mb-1">{subject?.name} </h3>
                      <p className="mb-0 font-weight-bold text-sm">
                        <span>Role: </span>
                        <span className="text-capitalize">
                          {subject?.role?.toLowerCase()}{" "}
                        </span>
                      </p>
                    </div>
                  </CardBody>
                </Row>
                <CardBody className="ProfileInfo__infoBlock my-auto col-auto py-3 ml-2 ">
                  <div>Unique ID : {subject?.uniqueId}</div>
                  <div>
                    Sensor: {subject?.beaconMac || "Beacon not assigned"}
                  </div>
                </CardBody>
                <CardBody className="ProfileInfo__infoBlock my-auto col-auto py-3 ml-2 ">
                  <div>Age : {subject?.attributes?.age}</div>
                  <div>Blood Group : {subject?.attributes?.bloodGroup} </div>
                </CardBody>
                <CardBody className="ProfileInfo__infoBlock my-auto col-auto py-3 ml-2">
                  <div>
                    Contact : {subject?.attributes?.phoneNumbers?.[0] || "-"}
                  </div>
                  <div>Email : {subject?.attributes?.emailIds?.[0] || "-"}</div>
                </CardBody>
                {!!showEdit && (
                  <CardBody className="text-center my-auto">
                    <Button color="info" onClick={handleRedirect}>
                      Edit Profile
                    </Button>
                  </CardBody>
                )}
              </Row>
            ) : (
              <>
                <BrandLoader noOverlay />
                <p className="text-center">Loading subject information</p>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default ProfileInfo;
