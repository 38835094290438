import React, { useEffect, useLayoutEffect, useState } from "react";
import OverlayView from "./OverlayView";
import OccupancyTimer from "./OccupancyTimer";

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Badge,
  Input,
  Fade,
  Collapse,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import GatewayService from "services/GatewayService";

import Loader from "./Loader";
import NoData from "./NoData";
import SubjectService from "services/SubjectService";
import DateTimeService from "services/DateTimeService";
import OccupancyTimeline from "./OccupancyTimeline";
import SubjectView from "components/PopoverViews/SubjectView/SubjectView";

import { useSelector } from "react-redux";

import BaseTable from "components/Tables/BaseTable/BaseTable";
import RoleSelect from "components/UserInputComponents/Selects/RoleSelect";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import Avatar from "./Avatar/Avatar";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import OccupancyModalFilter from "./OccupancyModalFilter";
import CsvDownloader from "utils/CsvDownloader";
import PdfDownloader from "utils/PdfDownloader";
import TooltipAction from "./TooltipAction";
import getReportData from "utils/getReportData";

const subjectService = new SubjectService();

const occupancyTableConfig = [
  {
    Header: "#",
    accessor: "avatar",
    Cell: ({ original: occupancy }) => {
      return (
        <div>
          <Avatar
            height={34}
            src={occupancy?.subject?.attributes?.avatar}
            alt={occupancy?.mac}
            gender={occupancy?.subject?.attributes?.gender}
          />
        </div>
      );
    },
    filterable: false,
  },
  {
    Header: "Name",
    accessor: "subject.name",
    Cell: ({ original: occupancy }) => {
      if (!!occupancy?.subject?.name) {
        return (
          <SubjectView
            subjectId={occupancy?.subject?._id}
            subjectData={occupancy?.subject}
          >
            {occupancy?.subject?.name}
          </SubjectView>
        );
      } else {
        return <p>{occupancy?.beaconMac}</p>;
      }
    },
    // Filter: ({ onChange }) => (
    //   <BaseTextInput setValue={onChange} noLabel placeholder="Name" />
    // ),
  },
  {
    Header: "UID",
    accessor: "subject.uniqueId",
    Cell: ({ original: occupancy }) => {
      if (!!occupancy?.subject) {
        return <div>{occupancy.subject.uniqueId}</div>;
      } else {
        return <div>UNASSIGNED</div>;
      }
    },
  },
  {
    Header: "Type",
    accessor: "subject.role",
    Cell: ({ original: occupancy }) => {
      if (!!occupancy?.subject) {
        return <div>{occupancy.subject.role}</div>;
      } else {
        return <div>UNASSIGNED</div>;
      }
    },
  },
  {
    Header: "Beacon MAC",
    accessor: "beaconMac",
    // Cell: ({ original: occupancy }) => <div>{occupancy?.beaconMac}</div>,
  },
  {
    Header: "Gateway",
    accessor: "gatewayMac",
    // Filter: ({ onChange }) => (
    //   <BaseTextInput setValue={onChange} noLabel placeholder="Gateway" />
    // ),
  },
  {
    Header: "Last Detected At",
    accessor: (occupancy) =>
      DateTimeService.getTimeString(occupancy?.updatedAt),
    filterable: false,
  },
];

const OccupancyTable = ({
  occupancies,
  onClickTableRow,
  activeSubject,
  loading,
}) => {
  // error handling for the beacons without any assigned assets

  const currentTimeMs = Number(new Date());

  const checkUpdatedIsOld = ({ updatedAt }) => {
    console.log({
      updatedAt,
      cond: Number(new Date(updatedAt)) - currentTimeMs > 60_000,
    });

    return currentTimeMs - Number(new Date(updatedAt)) > 60_000 ? 0.6 : 1;
  }; // 1 minute

  if (!!occupancies?.subject) {
    return (
      <BaseTable
        data={occupancies || []}
        tableConfig={occupancyTableConfig}
        loading={loading}
        // noPagination
        onRowClick={onClickTableRow}
        activeCond={({ subject: { _id } }) => _id === activeSubject}
        conditionalRowOpacity={0.4}
        rowOpacityCallback={checkUpdatedIsOld}
        // filterable
      />
    );
  } else {
    return (
      <BaseTable
        data={occupancies || []}
        tableConfig={occupancyTableConfig}
        loading={loading}
        onRowClick={onClickTableRow}
        conditionalRowOpacity={0.4}
        rowOpacityCallback={checkUpdatedIsOld}
        // noPagination
        // filterable
      />
    );
  }
};

function OccupancyView({ context = {}, isOpen, close, activeRole }) {
  const organizationId = useSelector((state) => state.organization._id);

  const [callApi, setCallApi] = useState(false);
  const [activeSubject, setActiveSubject] = useState(null);
  const [subjects, setSubjects] = useState(null);
  // const [timeline, setTimeline] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({});
  const [displayableSubjects, setDisplayableSubjects] = useState(null);

  const cleanObj = (obj) =>
    Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

  const setData = (data) => {
    setSubjects(data);
    setDisplayableSubjects(data);
  };

  useLayoutEffect(() => {
    if (!isOpen) {
      setActiveSubject(null);
      // setTimeline(null);
      setActiveSubject(null);
      setData(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!!params.name) {
      const globalRegex = new RegExp(`(${params.name})`, "ig");
      setDisplayableSubjects(
        subjects.filter((el) => globalRegex.test(el?.subject?.name))
      );
    }
    if (!!params.uUId) {
      const globalRegex = new RegExp(`(${params.uUId})`, "ig");
      setDisplayableSubjects(
        subjects.filter((el) => globalRegex.test(el?.subject?.uniqueId))
      );
    }
    if (!!params.role) {
      setDisplayableSubjects(
        subjects.filter((el) => params.role === el?.subject?.role)
      );
    }
    if (!!params.beaconMacId) {
      setDisplayableSubjects(
        subjects.filter((el) => params.beaconMacId === el?.beaconMac)
      );
    }
    if (!!params.gateway) {
      setDisplayableSubjects(
        subjects.filter((el) => params.gateway === el?.gatewayMac)
      );
    }

    if (!Object.values(params).some(Boolean)) {
      setDisplayableSubjects(subjects);
    }
  }, [params]);

  useEffect(() => {
    console.log(!!activeRole && !!subjects);
    if (!!activeRole && !!subjects) {
      let timeOut = setTimeout(
        () =>
          setDisplayableSubjects(
            subjects.filter((el) => activeRole === el?.subject?.role)
          ),
        500
      );

      return () => clearTimeout(timeOut);
    }
  }, [subjects, activeRole]);

  const fetchOccupancy = () => {
    setLoading(true);
    GatewayService.getGatewayOccupancy(
      cleanObj({ ...context, organizationId }),
      false
    )
      .then(({ data: { data } }) => setData(data))
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        if (isFirstLoad) setIsFirstLoad(false);
      });
  };

  // used for calling api after 15 secs
  useEffect(() => {
    fetchOccupancy();
  }, [callApi]);

  useLayoutEffect(() => {
    if (!isOpen) return;
    fetchOccupancy();
  }, [isOpen]);

  const activateSubject = ({ subject }) => {
    if (!subject?._id) return;
    setActiveSubject(subject?._id);
  };

  if (!isOpen) return <></>;
  return (
    <OverlayView fadeIn={isOpen}>
      <div
        style={{
          position: "fixed",
          right: "1rem",
          top: "1rem",
        }}
      >
        <Badge onClick={close} color="danger" style={{ cursor: "pointer" }}>
          <i className="tim-icons icon-simple-remove" />
        </Badge>
      </div>

      <div style={{ height: "100vh", width: "98vw" }}>
        <Row className="p-md-4" style={{ height: "100%", width: "100%" }}>
          <Col md="8">
            <Card
              className="p-md-2"
              style={{ height: "100%", maxHeight: "93vh", overflow: "auto" }}
            >
              <CardTitle
                tag="h4"
                className="p-3 d-flex justify-content-between"
              >
                <div>Occupancy List</div>
                <div className="d-flex">
                  <div>
                    <UncontrolledDropdown>
                      <DropdownToggle data-toggle="dropdown" tag="span">
                        <IconButton
                          id="export"
                          icon="save_alt"
                          color="info"
                          outlined
                        />
                        <TooltipAction target="export" textContent="Export" />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          onClick={() =>
                            CsvDownloader({
                              fileTitle: `Occupancy Report at ${new Date().toLocaleString()}`,
                              data: getReportData(
                                occupancyTableConfig.slice(
                                  1,
                                  occupancyTableConfig.length
                                ),
                                displayableSubjects
                              ),
                            })
                          }
                        >
                          <p>Export CSV</p>
                        </DropdownItem>

                        <DropdownItem
                          onClick={() =>
                            PdfDownloader({
                              fileTitle: `Occupancy Report at ${new Date().toLocaleString()}`,
                              data: getReportData(
                                occupancyTableConfig.slice(
                                  1,
                                  occupancyTableConfig.length
                                ),
                                displayableSubjects
                              ),
                            })
                          }
                        >
                          <p>Export PDF</p>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <div className="px-2">
                    <UncontrolledDropdown>
                      <DropdownToggle data-toggle="dropdown" tag="span">
                        <IconButton
                          id="filter"
                          icon="filter_list"
                          color="info"
                          outlined
                        />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <OccupancyModalFilter setParams={setParams} />
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <div>
                    <OccupancyTimer
                      callApi={setCallApi}
                      isComplete={callApi}
                      seconds={15}
                    />
                  </div>
                </div>
              </CardTitle>

              <CardBody>
                <OccupancyTable
                  occupancies={displayableSubjects}
                  onClickTableRow={activateSubject}
                  activeSubject={activeSubject}
                  loading={loading}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <div>
              {/* <Loader loading={timelineLoading} relative /> */}

              <Fade
                in={!!subjects?.length}
                unmountOnExit
                mountOnEnter
                appear={false}
              >
                <OccupancyTimeline maxHeight="75vh" subjectId={activeSubject} />
              </Fade>
            </div>
          </Col>
        </Row>
      </div>
    </OverlayView>
  );
}

export default OccupancyView;
