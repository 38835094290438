import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { useSelector } from "react-redux";
import BeaconMacSelect from "components/UserInputComponents/Selects/BeaconMacSelect";

function GatewayBeaconFilter({ setParams }) {
  const orgId = useSelector((state) => state.organization._id);
  const [beaconMac, setBeaconMac] = useState("");

  const applyFilters = (e) => {
    e.preventDefault();
    setParams({
      beaconMac: beaconMac || "",
    });
  };

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <BeaconMacSelect value={beaconMac} setValue={setBeaconMac} />
        <Button color="info" onClick={applyFilters}>
          Apply
        </Button>
      </CardBody>
    </Card>
  );
}

export default GatewayBeaconFilter;
