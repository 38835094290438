import Divider from "components/Divider/Divider";
import FormHolder from "components/Holders/FormHolder/FormHolder";
import { FormHeaderIcon } from "components/Holders/FormHolder/FormHolder";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import Forms from "config/copies/forms";
import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import OrganizationService from "services/OrganizationService";
import AboutOrganization from "./AboutOrganization";
import BasicOrganizationInfo from "./BasicOrganizationInfo";
import ContactOrganization from "./ContactOrganization";
import { useSelector } from "react-redux";
import WorkingTimeOrganization from "./WorkingTimeOrganization";
import toast from "react-hot-toast";
import SWALService from "services/SWALService";
import BrandLoader from "views/custom_components/Loader";
import { useHistory } from "react-router-dom";
import OtherInfoOrganization from "./OtherInfoOrganization";

function OrganizationForm() {
  const { organization } = useSelector((s) => s);
  const history = useHistory();

  const [isEditable, setIsEditable] = useState(false);

  const [basicInfoState, setBasicInfoState] = useState();
  const [contactInfoState, setContactInfoState] = useState();
  const [aboutInfoState, setAboutInfoState] = useState();
  const [workingTimeInfoState, setWorkingInfoState] = useState();
  const [otherInfoState, setOtherInfoState] = useState();
  // const [finalPayload, setFinalPayload] = useState();
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setFinalPayload({
  //     ...basicInfoState,
  //     ...contactInfoState,
  //     attributes: {
  //       ...aboutInfoState,
  //       ...workingTimeInfoState,
  //     },
  //   });
  // }, [basicInfoState, contactInfoState, aboutInfoState, workingTimeInfoState]);

  const handleClick = (e) => {
    e.preventDefault();
    setIsEditable((prevState) => !prevState);
    const finalObj = {
      ...basicInfoState,
      ...contactInfoState,
      attributes: {
        ...organization.attributes,
        ...aboutInfoState,
        ...workingTimeInfoState,
        ...otherInfoState,
      },
    };
    console.log(finalObj, "rohit");
    // if (!finalObj.contactPrimary)
    //   return toast.error("Primary Contact is required");
    // if (!finalObj.emailId) return toast.error("Primary Email is required");

    try {
      setLoading(true);
      OrganizationService.put(organization._id, finalObj).then(() => {
        SWALService.showSuccess("Changes updated successfully").then((res) =>
          // history.go(0)
          console.log(res, "rohit")
        );
      });
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  const toggleEdit = () => setIsEditable((prevState) => !prevState);

  return (
    <>
      <div className="content">
        {loading ? (
          <BrandLoader />
        ) : (
          <FormHolder
            imgSrc={Forms.ORGANIZATION.illustration}
            formHeader={Forms.ORGANIZATION.title}
            formDescription={Forms.ORGANIZATION.desc}
            CardFooterComponent=""
            formActions={
              <FormHeaderIcon
                icon={isEditable ? "close" : "edit"}
                onClick={toggleEdit}
                color={isEditable ? "danger" : "warning"}
              />
            }
          >
            <Form onSubmit={handleClick}>
              <div>
                <BasicOrganizationInfo
                  setValue={setBasicInfoState}
                  editable={!isEditable}
                />
                <Divider className="my-4" />
                <ContactOrganization
                  setValue={setContactInfoState}
                  editable={!isEditable}
                />
                <Divider className="my-4" />
                <AboutOrganization
                  setValue={setAboutInfoState}
                  editable={!isEditable}
                />
                <Divider className="my-4" />
                <WorkingTimeOrganization
                  setValue={setWorkingInfoState}
                  editable={!isEditable}
                />
                <Divider className="my-4" />
                <OtherInfoOrganization
                  setValue={setOtherInfoState}
                  editable={!isEditable}
                />
                {isEditable && <BaseButton>Save Changes</BaseButton>}
              </div>
            </Form>
          </FormHolder>
        )}
      </div>
    </>
  );
}
OrganizationForm.routeName = "/admin/organization/manage";
export default OrganizationForm;
