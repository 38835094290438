import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import DaysSelect from "components/UserInputComponents/Selects/DaysSelect";
import TagSelect from "components/UserInputComponents/Selects/TagSelect";
import TeacherSelect from "components/UserInputComponents/Selects/TeacherSelect";
import EndTimeInput from "components/UserInputComponents/TimePickers/EndTimeInput";
import FromTimeInput from "components/UserInputComponents/TimePickers/FromTimeInput";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import DateTimeService from "services/DateTimeService";

function ScheduleFilter({ setScheduleParams }) {
  const orgId = useSelector((state) => state.organization._id);
  const now = new Date();

  const todayMorning = DateTimeService.getMorningTime(now);
  const todayNight = DateTimeService.getMidnightTime(now);

  const [scheduleName, setScheduleName] = useState("");
  const [startTime, setStartTime] = useState(todayMorning);
  const [endTime, setEndTime] = useState(todayNight);
  const [incharge, setIncharge] = useState("");
  const [associateIncharges, setAssociateIncharge] = useState("");
  const [appliedDays, setAppliedDays] = useState([]);
  const [groups, setGroups] = useState();

  const applyFilters = (e) => {
    e.preventDefault();
    setScheduleParams({
      fromTime: startTime.toISOString(),
      toTime: endTime.toISOString(),
      daysOfWeek: appliedDays?.join(","),
      organizationId: orgId,
      incharge: incharge,
      associateIncharges,
      name: scheduleName,
      groups,
    });
  };

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <FromTimeInput setValue={setStartTime} />
        <EndTimeInput setValue={setEndTime} />
        <DaysSelect value={appliedDays} setValue={setAppliedDays} />
        <TagSelect value={groups} setValue={setGroups} />
        <TeacherSelect
          setValue={setIncharge}
          label="Incharge"
          value={incharge}
        />
        <TeacherSelect
          setValue={setAssociateIncharge}
          label="Associate Incharge"
          value={associateIncharges}
        />
        <BaseTextInput
          label="Schedule Name"
          placeholder="Enter schedule name"
          value={scheduleName}
          setValue={setScheduleName}
        />

        <br />
        <Button color="info" onClick={applyFilters} disabled={false}>
          Apply
        </Button>
      </CardBody>
    </Card>
  );
}

export default ScheduleFilter;
