import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import BuildingSelect from "components/UserInputComponents/Selects/BuildingSelect";
import CampusSelect from "components/UserInputComponents/Selects/CampusSelect";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Form } from "reactstrap";

function BuildingFilter({ setBuildingParams }) {
  const [campus, setCampus] = useState();
  const [building, setBuilding] = useState();

  const orgId = useSelector((state) => state.organization._id);
  // const orgId = "621642aa5656450009819ffc";

  const onSubmit = (e) => {
    e.preventDefault();
    setBuildingParams({
      organizationId: orgId,
      campusId: campus,
      id: building,
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <CampusSelect value={campus} setValue={setCampus} />
      <BuildingSelect
        value={building}
        setValue={setBuilding}
        context={{ campusId: campus }}
      />
      <BaseButton>Apply</BaseButton>
    </Form>
  );
}

export default BuildingFilter;
