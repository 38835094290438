import { ATTENDANCE_URL } from "config/api";
import ApiService from "./ApiService";

class AttendanceService extends ApiService {
  constructor() {
    super(ATTENDANCE_URL);
  }
}

export default new AttendanceService();
