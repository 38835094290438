import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React from "react";

function PolicyDiagnosisTable({ data, loading, getData }) {
  const policyDiagnosisTableConfig = [
    { Header: "Policy Name", accessor: "policyName" },
    {
      Header: "Compulsory",
      Cell: (original) => {
        return <div>{original?.compulsoryLocationPresence ? "Yes" : "No"}</div>;
      },
    },
    {
      Header: () => <div style={{ textAlign: "left" }}>Active Days</div>,
      accessor: "daysActive",
    },
  ];

  return (
    <BaseTable
      data={data}
      tableConfig={policyDiagnosisTableConfig}
      loading={loading}
    />
  );
}

export default PolicyDiagnosisTable;
