import React from "react";
import "./BaseButton.scss";
import { Button } from "reactstrap";
import { FormGroup } from "reactstrap";
function BaseButton({ color, children, md, className, ...btnAttr }) {
  return (
    <FormGroup className={`BaseButton ${className}`}>
      <Button
        {...btnAttr}
        className={`btn-fill btn-lg float-right mt-2 mb-3`}
        color={color || "info"}
        type="submit"
      >
        {children}
      </Button>
    </FormGroup>
  );
}

export default BaseButton;
