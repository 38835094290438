import React, { useEffect, useState } from "react";
import BaseSelect from "./BaseSelect";
import userRoles from "config/userRoles";

function UserRoleSelect({ value, setValue, editable }) {
  const UserRoleOptions = userRoles.map((el) => ({
    value: el,
    label: el,
  }));
  return (
    <BaseSelect
      label={"Role"}
      dataSource={UserRoleOptions}
      placeholder={"Search with role"}
      closeMenuOnSelect={true}
      isMultiSelect={false}
      value={value}
      setValue={setValue}
      isDisabled={editable}
    />
  );
}

export default UserRoleSelect;
