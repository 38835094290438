import React, { useEffect, useState } from "react";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import GstinInput from "components/UserInputComponents/Inputs/TextInputs/GstinInput";
import { Collapse } from "reactstrap";
import OrganizationCategorySelect from "components/UserInputComponents/Selects/OrganizationCategorySelect";
import LocationSelector from "components/UserInputComponents/LocationSelector/LocationSelector";
import { useSelector } from "react-redux";
import OrganizationFormSectionHeader from "../OrganizationFormSectionHeader";

function BasicOrganizationInfo({ editable, setValue }) {
  const [tradeName, setTradeName] = useState();
  const [brandName, setBrandName] = useState();
  const [category, setCategory] = useState([]);
  const [address, setAddress] = useState({});
  const [gstin, setGSTIN] = useState();
  const [visible, setVisible] = useState(false);

  const { organization } = useSelector((s) => s);

  useEffect(() => {
    setTradeName(organization?.tradeName || "");
    setBrandName(organization?.brandName || "");
    setCategory(organization?.category);
    setAddress(organization?.address || {});
    setGSTIN(organization?.gstin || "");
  }, []);

  useEffect(() => {
    setValue({
      tradeName,
      brandName,
      category,
      address: `${address.address}`,
      gstin,
    });
  }, [tradeName, brandName, category, address, gstin]);

  return (
    <>
      <OrganizationFormSectionHeader
        title="Basic Info"
        value={visible}
        setValue={setVisible}
      />

      <Collapse isOpen={visible}>
        <BaseTextInput
          label="Trade Name"
          value={tradeName}
          setValue={setTradeName}
          placeholder="A name for the trade that you wanna create"
          editable={editable}
        />
        <BaseTextInput
          label="Brand Name"
          value={brandName}
          setValue={setBrandName}
          placeholder="A name for the brand that you wanna create"
          editable={editable}
        />
        <OrganizationCategorySelect
          value={category}
          setValue={setCategory}
          editable={editable}
        />
        <LocationSelector
          label="Billing Address"
          placeholder="Address for the brand that you wanna create"
          setValue={setAddress}
          location={address}
          editable={editable}
        />
        <GstinInput value={gstin} setValue={setGSTIN} editable={editable} />
      </Collapse>
    </>
  );
}

export default BasicOrganizationInfo;
