const PolicyTypes = ["SIGNAL", "POSITION", "OCCUPANCY", "TELEMETRIC"];
const AlertStatusTypes = ["PENDING", "DISPATCHED", "DONE"];
const TriggerConditionPolicyTypes = [
  "SIGNAL",
  "POSITION",
  "OCCUPANCY",
  "TELEMETRIC",
];
const TriggerParamTypes = [
  "TEMPERATURE",
  "HUMIDITY",
  "AQI",
  "BUTTON_CLICKED",
  "BATTERY",
  "POSITION",
  "OCCUPANCY",
];

const ActionTypes = [
  "EMAIL",
  "SMS",
  "PUSH_NOTIFICATION",
  "WEBSOCKET",
  "HTTP_REQUEST",
];

const EvaluationOperators = [
  "EQUALS",
  "NOT_EQUALS",
  "GREATER_THAN",
  "LESSER_THAN",
  "GREATER_THAN_EQUALS",
  "LESS_THAN_EQUAL",
];
const ProximityTypes = ["IMMEDIATE", "NEAR", "FAR", "UNKNOWN"];
const AccessTypes = ["PRIVATE", "GROUP", "PUBLIC"];
const TransmitterScopeTypes = ["ANY", "TRANSMITTER_LIST", "GROUP"];
const ReceiverScopeTypes = ["ANY", "LOCATION_LIST"];

const ActionScopes = ["DEFAULT", "LIST"];

export {
  PolicyTypes,
  AlertStatusTypes,
  TriggerConditionPolicyTypes,
  TriggerParamTypes,
  ActionTypes,
  EvaluationOperators,
  ProximityTypes,
  AccessTypes,
  TransmitterScopeTypes,
  ReceiverScopeTypes,
  ActionScopes,
};
