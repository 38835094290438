import React from 'react';
import { Row,Col,Card,CardHeader,CardTitle,CardBody,FormGroup,Label,Input } from 'reactstrap';
import ReactDatetime from 'react-datetime';

function EditHoliday() {
    const [holidayName, setHolidayName] = React.useState('');
    const [fromDate, setFromDate] = React.useState('');
    const [toDate, setToDate] = React.useState('');

    const holidayNameChange = event => {
        let holidayName = event.target.value;
        setHolidayName(holidayName);
    }

    const fromDateTimeChange = event => {
        let fromDate = event._d.toISOString();
        setFromDate(fromDate);
    }

    const toDateTimeChange = event => {
        let toDate = event._d.toISOString();
        setToDate(toDate);
    }

    return (
        <div>
            <Card>
                <CardHeader>
                    <CardTitle>Update Holiday</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={12}>
                            <Label>Holiday Name</Label>
                            <FormGroup>
                                <Input type="text" autoComplete="off" value={holidayName} onChange={holidayNameChange}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Label>From Date</Label>
                            <FormGroup>
                                <ReactDatetime
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "Select From Date"
                                    }}
                                    value={fromDate}
                                    dateFormat={true}
                                    timeFormat={true}
                                    input={true}
                                    utc={true}
                                    closeOnSelect={true}
                                    onChange={fromDateTimeChange}
                                />
                            </FormGroup>  
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Label>To Date</Label>
                            <FormGroup>
                                <ReactDatetime
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "Select To Date"
                                    }}
                                    value={toDate}
                                    dateFormat={true}
                                    timeFormat={true}
                                    input={true}
                                    utc={true}
                                    closeOnSelect={true}
                                    onChange={toDateTimeChange}
                                />
                            </FormGroup>  
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}

export default EditHoliday
