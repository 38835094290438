import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import React from "react";
import { FormGroup, FormText, Label } from "reactstrap";
import Select from "react-select";
import BaseSelect from "components/UserInputComponents/Selects/BaseSelect";
import BaseTextAreaInput from "components/UserInputComponents/Inputs/TextInputs/BestTextAreaInput";
export default class PolicyInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ACCESS_TYPES: [
        {
          value: "PUBLIC",
          label: "PUBLIC",
        },
        {
          value: "PRIVATE",
          label: "PRIVATE",
        },
      ],
      name: "",
      description: "",
      accessType: "",
    };
  }

  componentDidMount() {
    const { policy } = this.props;

    if (!policy) return;
    this.setState({
      name: policy.name,
      description: policy.description,
      accessType: policy.accessType,
    });
  }

  accessTypeChange = (event) => {
    let operatorObj = { value: event.value, label: event.value };
    this.setState({ accessType: operatorObj });
    //this.props.onTriggerOperatorChange(event.value);
  };

  render() {
    return (
      <>
        <h4 className="mb-3">Basic Info</h4>
        <br />
        <BaseTextInput
          label="Name"
          placeholder="Enter name"
          value={this.state.name}
          setValue={(val) => this.setState({ name: val })}
          bottomHintText="A name for the policy that you wanna create"
        />

        <BaseTextAreaInput
          label="Description"
          placeholder="Enter description"
          value={this.state.description}
          setValue={(val) => this.setState({ description: val })}
          bottomHintText="A small description for your policy"
        />

        <BaseSelect
          dataSource={this.state.ACCESS_TYPES}
          value={this.state.accessType}
          setValue={(val) =>
            this.setState({
              accessType: val,
            })
          }
          label="Acess Type"
          placeholder="Select the access type of the policy"
        />

        {/* <FormGroup>
          <Label>Access Type</Label> */}

        {/* <Select
            className="react-select info"
            classNamePrefix="react-select"
            name="selectedTriggerBy"
            value={this.state.accessType}
            onChange={this.accessTypeChange}
            options={this.state.ACCESS_TYPES}
          /> */}
        {/* <FormText color="muted">
            What should be the access type of this policy?
          </FormText>
        </FormGroup> */}
      </>
    );
  }
}
