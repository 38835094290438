import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React, { useEffect } from "react";
import ConfigureBeaconService from "services/ConfigureBeaconService";
import SWALService from "services/SWALService";

function ConfigureBeaconTable({ data, loading, getData, setTableConfig }) {
  const setBeaconToReconfigure = async (macId) => {
    const {
      data: { success, message },
    } = await ConfigureBeaconService.setBeaconToReconfig({ macId }).catch(
      (err) => SWALService.showError(err)
    );

    if (!success) return;

    getData();

    SWALService.showSuccess(message, "Updated!");
  };
  const beaconTableConfig = [
    {
      Header: "MAC",
      accessor: "macId",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    { Header: "Config Status", accessor: "configStatus" },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          name="Reconfigure"
          icon="manage_history"
          miscellaneousCallback={() =>
            setBeaconToReconfigure(row.original.macId)
          }
        />
      ),
    },
  ];

  useEffect(() => {
    setTableConfig(beaconTableConfig);
  }, []);

  return (
    <BaseTable data={data} tableConfig={beaconTableConfig} loading={loading} />
  );
}

export default ConfigureBeaconTable;
