import React from "react";
import { Row, Col } from "reactstrap";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import FromTimePicker from "components/UserInputComponents/TimePickers/FromTimePicker";
import ToTimePicker from "components/UserInputComponents/TimePickers/ToTimePicker";
import DaysSelect from "components/UserInputComponents/Selects/DaysSelect";

import DateTimeService from "services/DateTimeService";
import FormNarrator from "../FormNarrator/FormNarrator";

export default class PolicyTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: null,
      toDate: null,
      fromTime: null,
      toTime: null,
      days: null,
    };
  }

  componentDidMount() {
    const { policy } = this.props;

    if (!policy) return;
    const { activeTime } = policy;
    this.setState({
      fromDate: DateTimeService.initDate(activeTime.fromDate),
      toDate: DateTimeService.initDate(activeTime.toDate),
      fromTime: DateTimeService.initDate(
        DateTimeService.getDateTimeFromTimeString(activeTime.fromTime)
      ),
      toTime: DateTimeService.initDate(
        DateTimeService.getDateTimeFromTimeString(activeTime.toTime)
      ),
      days: Object.values(activeTime.days)
        .map((val, idx) => (val ? idx + 1 : null))
        .filter(Boolean),
    });
  }

  valueModifier = (val) => `<b><i>${val || "not entered"} &nbsp </i></b>`;
  arrayModifier = (val) =>
    `<b><i>${(!!val?.length && val) || "not entered"}  &nbsp</i></b>`;
  daysRender = (val) => {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    let t = null;
    if (!!val) t = val.map((el) => days[el - 1]);
    return `<b><i>${(!!t?.length && t) || "not entered"} &nbsp </i></b>`;
  };

  render() {
    this.FormNarratorTemplate = `The policy is valid from ${this.valueModifier(
      this.state.fromDate
    )} till ${this.valueModifier(
      this.state.toDate
    )}. It will be active from ${this.valueModifier(
      this.state.fromTime && new Date(this.state.fromTime).toLocaleTimeString()
    )} to ${this.valueModifier(
      this.state.toTime && new Date(this.state.toTime).toLocaleTimeString()
    )} from the days selected. The days you have selected are ${this.daysRender(
      this.state.days
    )}`;

    return (
      <>
        <h4 className="mb-3">Timings</h4>
        <br />

        <FormNarrator>{this.FormNarratorTemplate}</FormNarrator>
        <Row>
          <Col md={6}>
            <StartDatePicker
              date={this.state.fromDate}
              setDate={(date) => this.setState({ fromDate: date })}
              hintText="From which will this be activated?"
            />
          </Col>

          <Col md={6}>
            <EndDatePicker
              date={this.state.toDate}
              setDate={(date) => this.setState({ toDate: date })}
              hintText="Till when should this policy be active?"
            />
          </Col>

          <Col md={6}>
            <FromTimePicker
              date={this.state.fromTime}
              setDate={(date) => this.setState({ fromTime: date })}
              bottomHintText="Starting time of the active hours"
            />
          </Col>

          <Col md={6}>
            <ToTimePicker
              date={this.state.toTime}
              setDate={(date) => this.setState({ toTime: date })}
              bottomHintText="Ending time of the active hours"
            ></ToTimePicker>
          </Col>

          <Col md={12}>
            <DaysSelect
              value={this.state.days}
              setValue={(data) => this.setState({ days: data })}
              bottomHintText="Which days will this policy be active?"
            />
          </Col>
        </Row>
      </>
    );
  }
}
