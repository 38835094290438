import FilterTableHolder from "components/Holders/FilterTableHolder/FilterTableHolder";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import { BUILDING_URL } from "config/api";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import BuildingService from "services/BuildingService";
import SWALService from "services/SWALService";
import CsvDownloader from "views/custom_components/CsvDownloader";
import BuildingFilter from "./BuildingFilter";
import BuildingTable from "./BuildingTable";

function BuildingListing() {
  const organizationId = useSelector((state) => state.organization._id);
  const defaultParams = { organizationId };
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buildingParams, setBuildingParams] = useState({});
  const [tableConfig, setTableConfig] = useState([]);

  useEffect(() => {
    fetchBuilding();
  }, [buildingParams]);

  const fetchBuilding = async () => {
    setLoading(true);

    const params = {
      ...buildingParams,
      ...defaultParams,
    };

    const { data } = await BuildingService.get(params).catch((err) =>
      SWALService.showError(err)
    );

    if (data) setData(data);
    setLoading(false);
  };

  const addBuilding = () => history.push("/admin/building/add");

  return (
    <FilterTableHolder>
      <BuildingFilter setBuildingParams={setBuildingParams} data={data} />
      <TableHeader
        title="Building"
        onClickAdd={addBuilding}
        onClickDownload={true}
        tableConfig={tableConfig}
        tableData={data}
      />
      <BuildingTable
        data={data}
        getData={fetchBuilding}
        loading={loading}
        setTableConfig={setTableConfig}
      />
    </FilterTableHolder>
  );
}

export default BuildingListing;
