import React from "react";
import BaseTextInput from "./BaseTextInput";

function EmailInput({ label, placeholder, value, setValue, editable }) {
  return (
    <>
      <BaseTextInput
        label={label || "Email"}
        type="email"
        placeholder={placeholder || "Enter your email"}
        name={"email"}
        value={value}
        setValue={setValue}
        editable={editable}
      />
    </>
  );
}

export default EmailInput;
