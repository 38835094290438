import AssetChange from ".";

const campusRoutes = [
  {
    path: AssetChange.routeName,
    rtlName: "عالتسعير",
    component: AssetChange,
    layout: "/admin",
    name: "Data Change Requests",
    showVisible: false,
  },
];

export default campusRoutes;
