import Avatar from "views/custom_components/Avatar/Avatar";
import React from "react";

const tableConfig = [
  {
    Header: "Ticket ID",
    accessor: "ticketId",
  },
  // {
  //   Header: "Image",
  //   Cell: (row) => {
  //     if (!row.original.avatar) return <></>;
  //     return (
  //       <div>
  //         <Avatar
  //           height={34}
  //           src={row.original.avatar}
  //           alt={row.original.name}
  //           gender={row.original.gender}
  //         />
  //       </div>
  //     );
  //   },
  // },
  // {
  //   Header: "Name",
  //   accessor: "changeRequest.name",
  // },
  // {
  //   Header: "Roll",
  //   accessor: "changeRequest.roll",
  // },

  // {
  //   Header: "Phone Number",
  //   accessor: "changeRequest.phoneNumber",
  // },
  // {
  //   Header: "Primary Group",
  //   accessor: "changeRequest.primaryGroup",
  // },
  {
    Header: "Change Type",
    accessor: "type",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Comments",
    accessor: "comments",
  },
];

export default tableConfig;
