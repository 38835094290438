import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import TransportSelect from "components/UserInputComponents/Selects/TransportSelect";
import ShiftSelect from "components/UserInputComponents/Selects/ShiftSelect";
import BaseDatePicker from "components/UserInputComponents/DatePickers/BaseDatePicker";
import AssetSelect from "components/UserInputComponents/Selects/AssetSelect";
import FromTimePicker from "components/UserInputComponents/TimePickers/FromTimePicker";
import ToTimePicker from "components/UserInputComponents/TimePickers/ToTimePicker";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import DateTimeService from "services/DateTimeService";
import { useHistory } from "react-router-dom";
import setQueryParams from "utils/setQueryParams";

function TransportFilter({ getAlerts }) {
  const history = useHistory();
  const now = new Date();
  const todayNight = DateTimeService.getMidnightTime(now);
  const todayMorning = DateTimeService.getMorningTime(now);

  const orgId = useSelector((state) => state.organization?._id);
  const [startTime, setStartTime] = useState(todayMorning);
  const [endTime, setEndTime] = useState(todayNight);

  const [startTimeStamp, setStartTimeStamp] = useState(todayMorning);
  const [endTimeStamp, setEndTimeStamp] = useState(todayNight);

  const [transport, setTransport] = useState("");
  const [asset, setAsset] = useState("");
  const [shift, setShift] = useState();

  const handleFilters = () => {
    const payload = {
      organizationId: orgId,
      fromTime: DateTimeService.mergeDateAndTimeString(
        startTime.toISOString(),
        startTimeStamp.toISOString()
      ),
      toTime: DateTimeService.mergeDateAndTimeString(
        endTime.toISOString(),
        endTimeStamp.toISOString()
      ),
      gatewayAttachType: "TRANSPORT",
      areaId: transport,
      assetId: asset,
    };

    history.push({
      pathname: "/admin/transports",
      search: `${setQueryParams(payload)}`,
    });
    getAlerts(payload);
  };

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <StartDatePicker date={startTime} setDate={setStartTime} />
        <EndDatePicker date={endTime} setDate={setEndTime} />
        <FromTimePicker date={startTimeStamp} setDate={setStartTimeStamp} />
        <ToTimePicker date={endTimeStamp} setDate={setEndTimeStamp} />
        <TransportSelect value={transport} setValue={setTransport} />
        <ShiftSelect value={shift} setValue={setShift} />
        <AssetSelect value={asset} setValue={setAsset} />
        <br />
        <Button color="info" onClick={handleFilters}>
          Get Reports
        </Button>
      </CardBody>
    </Card>
  );
}

export default TransportFilter;
