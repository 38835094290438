import React from 'react';
// import BeaconEditForm from './BeaconEditForm';
import BeaconForm from "./BeaconForm";

function EditBeacon() {
  return (
    // <BeaconEditForm />
    <BeaconForm />
  );
}

export default EditBeacon;
