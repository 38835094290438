import Icon from "components/Icon/Icon";
import React from "react";
import { Row, Col, CardTitle, CardBody, Card, Fade } from "reactstrap";

import "./StatCard.scss";

function StatCard({ statTitle, statValue, icon, color, appearDelay, onClick }) {
  return (
    <Fade in={true} timeout={appearDelay || 100}>
      <Card
        className={`card-stats ${!!onClick ? ".clickable" : ""}`}
        style={onClick ? { cursor: "pointer" } : {}}
        onClick={onClick}
      >
        <CardBody>
          <Row>
            <Col xs="5">
              <div className={`info-icon text-center icon-${color || "info"}`}>
                <Icon icon={icon} />
              </div>
            </Col>
            <Col xs="7">
              <div className="numbers">
                <p className="card-category">{statTitle}</p>
                <CardTitle tag="h3">{statValue || 0}</CardTitle>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fade>
  );
}

export default StatCard;
