const getTitleContext = (filterContext) => {
  if (!Object.values(filterContext)?.length) return "";
  return (
    "\n" +
    Object.values(filterContext)
      .map(
        (el, idx) =>
          `${Object.keys(filterContext)[idx].toUpperCase()} : ${el.label}`
      )
      .join("\n")
  );
};

export default getTitleContext;
