import React, { useEffect, useState } from "react";
import ActionService from "services/ActionService";
import BaseSelect from "./BaseSelect";

function ActionSelect({
  value,
  setValue,
  isMultiSelect,
  noLabel,
  context = {},
  noPopulate,
  bottomHintText,
}) {
  const [actions, setActions] = useState([]);

  useEffect(() => {
    if (noPopulate) return;

    const params = { ...(context || {}) };
    ActionService.get(params)
      .then(({ data }) => setActions(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <BaseSelect
      label={!noLabel && "Actions"}
      dataSource={actions?.map((action) => ({
        value: action?._id,
        label: action?.name,
      }))}
      placeholder={"Select Actions"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
      bottomHintText={bottomHintText}
    />
  );
}

export default ActionSelect;
