import React from "react";
import { CSSTransition } from "react-transition-group";
import calcHeight from "../../utils/calcHeight";
import SideMenu from "../SideMenu/SideMenu";
import menuStages from "./config";

function CampusMenu({ activeMenu, campuses, setMenuHeight }) {
  return (
    <CSSTransition
      in={activeMenu === menuStages.campuses}
      timeout={500}
      classNames="Menu--primary"
      unmountOnExit
      onEnter={(el) => calcHeight(el, setMenuHeight)}
    >
      <SideMenu menuItems={campuses} menuLabel={menuStages.campuses} />
    </CSSTransition>
  );
}

export default CampusMenu;
