import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import EmailInput from "components/UserInputComponents/Inputs/TextInputs/EmailInput";
import PhoneNumberInput from "components/UserInputComponents/Inputs/NumberInputs/PhoneNumberInput";
import { useSelector } from "react-redux";
import OrganizationFormSectionHeader from "../OrganizationFormSectionHeader";

function ContactOrganization({ editable, setValue }) {
  const [primaryContact, setPrimaryContact] = useState("");
  const [secondaryContact, setSecondaryContact] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [secondaryEmail, setSecondaryEmail] = useState("");
  const [visible, setVisible] = useState(false);
  const { organization } = useSelector((s) => s);

  useEffect(() => {
    console.log("org", organization);
    setPrimaryContact(organization?.contactPrimary);
    setSecondaryContact(organization?.contactSecondary);
    setPrimaryEmail(organization?.emailId);
    setSecondaryEmail(organization?.secondaryEmailId);
  }, []);

  useEffect(() => {
    setValue({
      contactPrimary: primaryContact,
      contactSecondary: secondaryContact,
      emailId: primaryEmail,
      secondaryEmailId: secondaryEmail,
    });
  }, [primaryContact, secondaryContact, primaryEmail, secondaryEmail]);

  return (
    <>
      <OrganizationFormSectionHeader
        title="Contact"
        value={visible}
        setValue={setVisible}
      />

      <Collapse isOpen={visible}>
        <PhoneNumberInput
          label="Primary Contact"
          value={primaryContact}
          setValue={setPrimaryContact}
          placeholder="A primary contact for your company"
          editable={editable}
        />
        <PhoneNumberInput
          label="Secondary Contact"
          value={secondaryContact}
          setValue={setSecondaryContact}
          placeholder="A secondary contact for your company"
          editable={editable}
        />
        <EmailInput
          value={primaryEmail}
          setValue={setPrimaryEmail}
          label="Primary Email"
          placeholder="Primary email of your company"
          editable={editable}
        />
        <EmailInput
          value={secondaryEmail}
          setValue={setSecondaryEmail}
          label="Secondary Email"
          placeholder="Secondary email of your company"
          editable={editable}
        />
      </Collapse>
    </>
  );
}

export default ContactOrganization;
