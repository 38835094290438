import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import ReactDOM from "react-dom";
import { Card, CardBody, Fade, Row } from "reactstrap";

const texts = ["Working on it", "Please wait"];

const LoaderComponent = ({ noOverlay, loading, relative, children }) => {
  const backdropStyle = {
    top: 0,
    left: 0,
    position: relative ? "relative" : "absolute",
    height: relative ? "100%" : "100vh",
    width: relative ? "100%" : "100vw",
    backgroundColor: "rgba(0,0,0,0.1)",
    backdropFilter: "blur(5px)",
    display: "grid",
    placeItems: "center",
    zIndex: 999999,
  };

  useEffect(() => {
    if (noOverlay) return;
    const loadingChangerInterval = setInterval(
      () => setLoadingText((prevState) => !prevState),
      3000
    );

    return () => {
      if (noOverlay) return;
      clearInterval(loadingChangerInterval);
    };
  }, []);

  const [loadingText, setLoadingText] = useState(true);

  return (
    <Fade in={loading} unmountOnExit mountOnEnter>
      <div className="Loader__backdrop" style={backdropStyle}>
        <Card style={{ width: "fit-content" }}>
          <CardBody>
            <CardBody>
              <Row>
                <div
                  style={{
                    width: "fit-content",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    gap: "2rem",
                  }}
                >
                  {children}
                  <p
                    style={{ marginBottom: "-3px", whiteSpace: "nowrap" }}
                    className="pl-2 pr-2"
                  >{`${texts[loadingText ? 0 : 1]}...`}</p>
                </div>
              </Row>
            </CardBody>
          </CardBody>
        </Card>
      </div>
    </Fade>
  );
};

const BrandLoader = ({ noOverlay, monoWhite, size, loading, relative }) => {
  const AnimatedLoader = ({ size }) => (
    <div
      style={{
        width: "100%",
        display: "grid",
        placeItems: "center",
        padding: "0.25rem ",
      }}
    >
      <PuffLoader size={size || 30} color={monoWhite ? "#ffffff" : "#3358f4"} />
    </div>
  );

  if (noOverlay) return <AnimatedLoader size={size || 20} />;

  const LocalLoaderComponent = () => (
    <LoaderComponent
      loading={loading}
      relative={relative}
      noOverlay={noOverlay}
    >
      {" "}
      <AnimatedLoader />{" "}
    </LoaderComponent>
  );

  if (relative) return <LocalLoaderComponent />;

  return ReactDOM.createPortal(
    <LocalLoaderComponent />,
    document.getElementById("portal")
  );
};

export default BrandLoader;
