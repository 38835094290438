const GatewayTypes = ["BLE_SNIFFER", "WIFI_SNIFFER", "BLE_WIFI_SNIFFER"];

const GatewayPrecisionScope = {
  ENTRY: "ENTRY",
  EXIT: "EXIT",
};

const GatewayPrecisionScopes = Object.values(GatewayPrecisionScope);

const GatewaySporadicScope = {
  PORTAL: "PORTAL",
};

const GatewaySporadicScopes = Object.values(GatewaySporadicScope);

const AllGatewayScopes = [...GatewaySporadicScopes, ...GatewayPrecisionScopes];

export {
  GatewayTypes,
  GatewayPrecisionScope,
  GatewayPrecisionScopes,
  GatewaySporadicScope,
  GatewaySporadicScopes,
  AllGatewayScopes,
};
