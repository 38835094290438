import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card } from "reactstrap";
import {
  MapContainer,
  ImageOverlay,
  Rectangle,
  FeatureGroup,
  Polygon,
} from "react-leaflet";

// import Loader from "views/custom_components/Loader";

import LeafletMapsService from "services/LeafletMapsService";
import pastelColors from "config/pastelColors";
import LeafletToolTip from "views/custom_components/LeafletMaps/custom-components/LeafletToolTip";

import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import "./MapView.scss";
import { FLOOR_URL } from "config/api";

import FloorPlanService from "services/FloorPlanService";
import GatewayService from "services/GatewayService";
// import Loader from "views/custom_components/Loader";
import OccupancyLabel from "../OccupancyLabel/OccupancyLabel";
const floorPlanService = new FloorPlanService();

function MapView({ activeFloorPlan, onClickShape }) {
  const ref = useRef();
  const mapRef = useRef();
  const leafletMapsService = new LeafletMapsService();
  const [plotShapes, setPlotShapes] = useState([]);

  const [loading, setLoading] = useState(false);

  const [floorPlan, setFloorPlan] = useState();

  const [height, setHeight] = useState(0);

  // const [fgLayer, setFgLayer] = useState(null);

  const bounds = [
    [0, 0],
    [500, 500],
  ];

  // useLayoutEffect(() => {

  // },[height])

  useEffect(() => {
    if (!activeFloorPlan) return;

    setLoading(true);
    leafletMapsService
      .getMapPlots(activeFloorPlan)
      .then(({ data: { data: floorPlanSections } }) => {
        console.log("rohit", floorPlanSections);
        setHeight(Math.ceil(ref.current.clientHeight));
        // if (!!mapRef) {
        //   mapRef.current?.style?.height = ref.current.clientHeight;
        // }
        setPlotShapes(
          floorPlanSections.map((el) => el.floorPlanSectionPlot).filter(Boolean)
        );
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  }, [ref.current, activeFloorPlan]);

  useEffect(() => {
    if (!activeFloorPlan) return;

    setLoading(true);
    floorPlanService
      .getFloorPlanByID(FLOOR_URL, { id: activeFloorPlan })
      .then((data) => setFloorPlan(data[0]))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [activeFloorPlan]);

  useEffect(() => {
    if (!!plotShapes) {
      console.log("plotShapesMap", plotShapes);
    }
  }, [activeFloorPlan]);

  const shapePathOptions = () => {
    const newColor =
      pastelColors[Math.floor(Math.random() * pastelColors.length)];

    return {
      color: newColor,
      fillColor: newColor,
      fillOpacity: 0.3,
    };
  };

  const shapeData = {
    id: 0,
    label: "",
    geo: {
      type: "",
      coordinates: [],
    },
  };

  return (
    <div ref={ref} className="MapView">
      {/* {loading && <Loader />} */}
      {/* TODO: Shame Code */}
      {height == 0 ? (
        <></>
      ) : (
        <MapContainer
          className="MapContainer"
          ref={mapRef}
          style={{
            position: "absolute",
            height: height,
          }}
          center={{ lat: 50, lng: 250 }}
          zoom={2}
        >
          <FeatureGroup>
            {!!plotShapes?.length &&
              plotShapes?.map((plotShape, idx) => {
                return !!plotShape?.properties?.floorSectionId ? (
                  plotShape.geometry?.type?.toLowerCase() === "rectangle" ? (
                    <Rectangle
                      key={idx}
                      pathOptions={shapePathOptions()}
                      bounds={plotShape?.geometry?.coordinates}
                      eventHandlers={{
                        click: () =>
                          onClickShape(plotShape?.properties?.floorSectionId),
                      }}
                    >
                      <OccupancyLabel
                        key={idx}
                        floorPlanSectionId={
                          plotShape?.properties?.floorSectionId
                        }
                        permanent
                      >
                        {plotShape?.properties?.label}
                      </OccupancyLabel>
                    </Rectangle>
                  ) : (
                    <Polygon
                      key={idx}
                      pathOptions={shapePathOptions()}
                      positions={plotShape?.geometry?.coordinates}
                      eventHandlers={{
                        click: () =>
                          onClickShape(plotShape?.properties?.floorSectionId),
                      }}
                    >
                      <OccupancyLabel
                        key={idx}
                        floorPlanSectionId={
                          plotShape?.properties?.floorSectionId
                        }
                        permanent
                      >
                        {plotShape?.properties?.label}
                      </OccupancyLabel>
                    </Polygon>
                  )
                ) : (
                  <></>
                );
              })}
            <ImageOverlay
              className="object-contain my-4 relative OriginalMap"
              url={floorPlan?.imgUrl || ""}
              bounds={bounds}
            />
          </FeatureGroup>
        </MapContainer>
      )}
    </div>
  );
}

export default MapView;
