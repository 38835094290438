import axios from "axios";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import GatewayService from "services/GatewayService";
import GatewayStateLogsFilter from "./subComponents/GatewayStateLogsFilter";
import GatewayStateLogsTable from "./subComponents/GatewayStateLogsTable";
import GatewayStateLogsTableConfig from "./subComponents/GatewayStateLogsTableConfig";

function GatewayStateLogs() {
  const [params, setParams] = useState();
  const [gatewayStateLogs, setGatewayStateLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  // TODO: Change the endpoint
  const getGatewaysStateLogs = async (params) => {
    try {
      setLoading(true);
      await GatewayService.getGatewayLogsStatus(params)
        .then((res) => setGatewayStateLogs(res?.data?.data))
        .catch((err) => toast.error(err?.message));
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("rohit", params);
    if (!params) return;
    getGatewaysStateLogs(params);
  }, [params]);

  return (
    <div className="content">
      <Row>
        <Col md={3}>
          <GatewayStateLogsFilter setParams={setParams} />
        </Col>
        <Col md={9}>
          <Card>
            <CardHeader>
              <TableHeader
                title="Gateway State Diagonosis"
                onClickDownload={true}
                tableConfig={GatewayStateLogsTableConfig}
                tableData={gatewayStateLogs}
              />
            </CardHeader>
            <CardBody>
              <GatewayStateLogsTable
                data={gatewayStateLogs}
                loading={loading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default GatewayStateLogs;
