import React from "react";
import L from "leaflet";
import { Popup, Marker, Tooltip } from "react-leaflet";

function ParchaiMarker({ position, label, onClick, data }) {
  const parchaiIcon = L.icon({
    iconUrl: require("../../../../assets/img/parchai_marker.png"),
    iconSize: [24, 40],
    iconAnchor: [12, 0],
  });

  return (
    <Marker
      position={position}
      icon={parchaiIcon}
      riseOnHover={true}
      riseOffset={100}
      eventHandlers={{
        click: () => onClick?.(data),
      }}
    >
      <Tooltip direction="top">{label}</Tooltip>
    </Marker>
  );
}

export default ParchaiMarker;
