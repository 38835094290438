import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import { USER_URL } from "config/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactTable from "react-table";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import DateTimeService from "services/DateTimeService";
import UserService from "services/UserService";
import Swal from "sweetalert2";
import BrandLoader from "views/custom_components/Loader";
import TooltipAction from "views/custom_components/TooltipAction";
import UserFilters from "./UserFilters";

function UserListing() {
  const orgId = useSelector((state) => state.organization._id);
  const param = { organization: orgId };
  const history = useHistory();
  const now = new Date();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    UserService.get(param)
      .then(({ data }) => {
        if (data.length < 0) return;
        data.map((user) => {
          setData((data) => [
            ...data,
            {
              id: user._id,
              name: user.firstName + " " + user.lastName,
              email: user.email,
              contact: user.phoneNumber,
              confirmed: user.userConfirmed ? "YES" : "NO",
              active: user.enabled ? "YES" : "NO",
              createdOn: DateTimeService.getDateString(user.createdOn),
            },
          ]);
        });
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const tempGetUsers = () => {
    setLoading(true);
    UserService.get(param)
      .then(({ data }) => {
        if (data.length < 0) return;
        data.map((user) => {
          setData((data) => [
            ...data,
            {
              id: user._id,
              name: user.firstName + " " + user.lastName,
              email: user.email,
              contact: user.phoneNumber,
              confirmed: user.userConfirmed ? "YES" : "NO",
              active: user.enabled ? "YES" : "NO",
              createdOn: DateTimeService.getDateString(user.createdOn),
            },
          ]);
        });
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const actions = (id) => {
    return (
      <div className="actions-right">
        <Button
          className="btn-link btn-icon"
          color="success"
          size="sm"
          onClick={() => editUser(id)}
        >
          <i id="editUser" className="tim-icons icon-pencil" />
        </Button>
        <TooltipAction target="editUser" textContent="Edit User" />
        {/* use this button to remove the data row */}
        <Button
          className="btn-link btn-icon"
          color="danger"
          size="sm"
          onClick={() => deleteUser(id)}
        >
          <i id="deleteUser" className="tim-icons icon-trash-simple" />
        </Button>
        <TooltipAction target="deleteUser" textContent="Delete User" />
      </div>
    );
  };

  const getUsers = (queryParams = {}) => {
    if (data.length < 0) return;
    setLoading(true);
    setData([]);

    UserService.get(queryParams)
      .then(({ data }) => {
        if (data.length < 0) return;
        data.map((user) => {
          setData((data) => [
            ...data,
            {
              id: user._id,
              name: user.firstName + " " + user.lastName,
              email: user.email,
              contact: user.phoneNumber,
              confirmed: user.userConfirmed ? "YES" : "NO",
              active: user.enabled ? "YES" : "NO",
              createdOn: DateTimeService.getDateString(user.createdOn),
              // actions: actions(),
            },
          ]);
        });
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const addUser = () => {
    history.push({
      pathname: "/admin/addUser",
      state: { context: "add" },
    });
  };
  const editUser = (id) => {
    history.push(`/admin/editUser/${id}`);
  };
  const deleteUser = (id) => {
    Swal.fire({
      title: "Are you sure to Delete it?",
      text: "You won't be able to revert this after deleting!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          UserService.delete(id)
            .then(({ message }) => {
              Swal.fire("Deleted!", "User has been deleted.", "message").then(
                () => getUsers()
              );
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Error occured",
              });
            })
            .finally(() => {
              tempGetUsers();
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const tableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Contact",
      accessor: "contact",
    },
    {
      Header: "Active",
      accessor: "active",
    },
    {
      Header: "Confirmed",
      accessor: "confirmed",
    },
    {
      Header: "Created On",
      accessor: "createdOn",
    },
    {
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      filterable: false,
      Cell: ({ original }) => actions(original.id),
    },
  ];
  return (
    <>
      <div className="content wrapTable">
        <Row className="ml-auto">
          <Col md={3}>
            <UserFilters getUsers={getUsers} />
          </Col>
          <Col xs={9} md={9} className="ml-auto">
            <Card>
              <CardHeader>
                <TableHeader
                  title="Users"
                  onClickAdd={addUser}
                  onClickDownload={true}
                  tableConfig={tableConfig}
                  tableData={data}
                />
              </CardHeader>
              <CardBody>
                <BaseTable
                  data={data}
                  tableConfig={tableConfig}
                  loading={loading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserListing;
