import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  Progress,
  Collapse,
} from "reactstrap";
import OnBoardingService from "services/OnBoardingService";
import Image from "../../Image/Image";

import { setOrganization } from "data/actions/organizationAction";

import OrganizationService from "services/OrganizationService";

import OnBoarding from "Models/OnBoarding.model";

import "./OnboardingWidget.scss";

function OnboardingWidget({ isOpen }) {
  const history = useHistory();

  const organization = useSelector((s) => s.organization);

  const dispatch = useDispatch();

  const [onBoardingStatus, setOnBoardingStatus] = useState();
  const [progress, setProgress] = useState(0);
  const [clickCounter, setClickCounter] = useState(0);

  const { _id: organizationId } = useSelector((s) => s.organization);

  const getOnBoardingData = async () => {
    const { data, success } = await OnBoardingService.get({ organizationId });
    if (!success && !data) return;

    const _onBoardingStatus = new OnBoarding(data);

    setOnBoardingStatus(_onBoardingStatus);
    setProgress(_onBoardingStatus?.getProgress());
  };

  useEffect(() => {
    getOnBoardingData();
  }, [clickCounter]);

  const setOnBoarded = async () => {
    const newStatus = { isOnboarded: true };
    const newOrganizationObj = { ...organization, ...newStatus };
    await OrganizationService.put(organization._id, newOrganizationObj);
    dispatch(setOrganization(newOrganizationObj));
  };

  useEffect(() => {
    if (progress > 99) setOnBoarded();
  }, [progress]);

  const handleOnClick = (e) => {
    e.preventDefault();
    setClickCounter((prev) => prev + 1);
    const nextStep = onBoardingStatus.getNextStep();
    if (nextStep) return history.push(nextStep);
  };

  return (
    <Collapse isOpen={isOpen} appear unmountOnExit mountOnEnter>
      <Card className="OnboardingWidget" onClick={handleOnClick}>
        <CardBody>
          <Row>
            <Col
              md="4"
              className="OnboardingWidget__ImageContainer px-5 my-auto"
            >
              <Image src={`/illustrations/onboaring/STEP_${1}.svg`} />
            </Col>
            <Col md="8" className="pr-5 my-auto">
              <CardTitle tag="h2" className="mb-3">
                Your profile looks incomplete!
              </CardTitle>
              <p className="mb-4 text-justify">
                Complete the profile to have a better experience with the
                product. All the components need to work in synergy to keep
                things rolling
              </p>
              <Progress value={progress} color="danger" animated />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Collapse>
  );
}

export default OnboardingWidget;
