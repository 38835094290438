import React, { useEffect, useState } from "react";
import SubjectView from "components/PopoverViews/SubjectView/SubjectView";
import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import SubjectService from "services/SubjectService";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import { useParams, withRouter } from "react-router-dom";
import CardBody from "reactstrap/lib/CardBody";
import Avatar from "views/custom_components/Avatar/Avatar";
import toast from "react-hot-toast";
import BrandLoader from "views/custom_components/Loader";
import RouteService from "services/RouteService";

const assetService = new SubjectService();

function RouteAssetTable() {
  const [loading, setLoading] = useState(false);
  const { routeId } = useParams();
  const [assetData, setAssetData] = useState([]);
  const [tableHeader, setTableHeader] = useState("");

  useEffect(() => {
    if (!routeId) return;
    try {
      setLoading(true);
      assetService
        .getSubjects({ routeId })
        .then((data) => {
          setAssetData(data);
          console.log("subject", data);
        })
        .catch((e) => toast.error(e));
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    RouteService.getById(routeId)
      .then(({ data }) => setTableHeader(data.name))
      .catch((e) => toast.err(e));
  }, []);

  const tableConfig = [
    {
      Header: "#",
      Cell: (row) => {
        return (
          <div>
            <Avatar
              height={34}
              src={row.original.attributes.avatar}
              alt={row.original.name}
              gender={row.original.attributes.gender}
            />
          </div>
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ original }) => {
        console.log("subjectrow", original);
        return (
          <SubjectView subjectId={original._id} subjectData={original}>
            {original.name}
          </SubjectView>
        );
      },
    },
    {
      Header: "Unique ID",
      accessor: "uniqueId",
    },
    {
      Header: () => <div style={{ textAlign: "left" }}>Role</div>,
      accessor: "role",
    },
    // {
    //   Header: "Created On",
    //   accessor: (row) => {
    //     return <div>{DateTimeService.getDateTimeString(row.createdOn)}</div>;
    //   },
    // },
    // {
    //   Header: "Actions",
    //   Cell: (row) => (
    //     <TableActions
    //       {...row}
    //       //   editCallback={editAsset}
    //       deleteCallback={deleteAsset}
    //     />
    //   ),
    // },
  ];

  return (
    <>
      {loading && <BrandLoader />}
      {!loading && (
        <div className="content wrapTable">
          <Row className="ml-auto">
            {/* <Col md={3}>
            <SubjectFilter getSubjects={(params) => this.getSubjects(params)} />
          </Col> */}
            <Col xs={12} md={12} className="ml-auto">
              <Card>
                <CardHeader>
                  <TableHeader title={`Assets assigned to ${tableHeader}`} />
                </CardHeader>
                <CardBody>
                  <BaseTable
                    tableConfig={tableConfig}
                    data={assetData?.length > 0 ? assetData : []}
                    loading={loading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default RouteAssetTable;
