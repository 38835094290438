import BuildingSelect from "components/UserInputComponents/Selects/BuildingSelect";
import CampusSelect from "components/UserInputComponents/Selects/CampusSelect";
import FloorPlanSelect from "components/UserInputComponents/Selects/FloorPlanSelect";
import FloorSectionSelect from "components/UserInputComponents/Selects/FloorSectionSelect";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader } from "reactstrap";

function FloorPlanSectionFilter({ setFloorSectionParams }) {
  const [campusId, setCampusId] = useState("");
  const [buildingId, setBuildingId] = useState("");
  const [floorId, setFloorId] = useState("");
  const [floorPlanNames, setFloorPlanNames] = useState("");
  const organizationId = useSelector((state) => state.organization._id);

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <CampusSelect value={campusId} setValue={setCampusId} />
        <BuildingSelect
          value={buildingId}
          setValue={setBuildingId}
          context={{ campusId }}
        />
        <FloorPlanSelect
          value={floorId}
          setValue={setFloorId}
          context={{ campusId, buildingId }}
        />

        <FloorSectionSelect
          value={floorPlanNames}
          setValue={setFloorPlanNames}
          context={{ floorPlanId: floorId }}
        />
        <Button
          color="info"
          onClick={() =>
            setFloorSectionParams({
              organizationId: organizationId,
              campusId,
              buildingId,
              floorPlanId: floorId,
              id: floorPlanNames,
            })
          }
        >
          Apply
        </Button>
      </CardBody>
    </Card>
  );
}

export default FloorPlanSectionFilter;
