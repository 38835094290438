import { CAMPUS_URL } from "config/api";
import ApiService from "./ApiService";

class CampusService extends ApiService {
  constructor() {
    super(CAMPUS_URL);
  }
}

export default new CampusService();
