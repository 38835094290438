import React from "react";

import { Row, Col, Card, CardBody, CardHeader, CardTitle } from "reactstrap";

import "./FilterTableHolder.scss";

function FilterTableHolder({ children }) {
  return (
    <div className="content">
      <Row>
        <Col md="3">
          <Card>
            <CardHeader>
              <CardTitle tag={"h4"}>Filters</CardTitle>
            </CardHeader>
            <CardBody>{children[0]}</CardBody>
          </Card>
        </Col>
        <Col md="9">
          <Card>
            <CardHeader>{children[1]}</CardHeader>
            <CardBody>{children[2]}</CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default FilterTableHolder;
