import { BUILDING_PRESENCE_URL } from "config/api";
import ApiService from "./ApiService";

class BuildingPresenceService extends ApiService {
  constructor() {
    super(BUILDING_PRESENCE_URL);
  }
}

export default new BuildingPresenceService();
