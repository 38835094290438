import ListCard from "components/ListCard/ListCard";
import React from "react";
import { useHistory } from "react-router-dom";

import "./MostVisited.scss";

function MostVisited({ data = [] }) {
  const history = useHistory();
  const summary_data = data.slice(0, 5);
  const layoutData = summary_data?.map(({ context, count }, index) => ({
    title: context?.name,
    imgSrc: context?.attributes?.avatar,
    subtitle: context?.role,
    count: count,
  }));

  const handleRedirect = (name, role) => {
    history.push({
      pathname: `/admin/all_subjects`,
      search: `?name=${name}&&role=${role}`,
    });
  };

  return (
    <ListCard
      cardTitle="Most Visited"
      data={layoutData}
      isClick={true}
      handleClick={handleRedirect}
    />
  );
}

export default MostVisited;
