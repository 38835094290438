const Forms = {
  ORGANIZATION: {
    title: "ORGANIZATION",
    desc: "Group of campuses or buildings.",
    illustration: "/illustrations/campus.svg",
  },
  CAMPUS: {
    title: "CAMPUS",
    desc: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    illustration: "/illustrations/campus.svg",
  },
  VEHICLE: {
    title: "VEHICLE",
    desc: "A vehicle is a machine such as a car, bus, or truck which has an engine and is used to carry people from place to place. Vehicles that the institute wishes to track can be added in the system.",
    illustration: "/illustrations/vehicle.svg",
  },
  ROUTE: {
    title: "ROUTE",
    desc: "A route is a way for travel or movement, the path from point A to point B. A route can also be the method used for achieving a particular result, like going to school and working hard is your route to success.",
    illustration: "/illustrations/route.svg",
  },
  BUILDING: {
    title: "BUILDING",
    desc: "A building is a division regarded as having particular shared characteristics in the campus.",
    illustration: "/illustrations/building.svg",
  },
  FLOOR: {
    title: "FLOOR",
    desc: "A floor is a section of a building with a layout of sections or rooms.",
    illustration: "/illustrations/building.svg",
  },
  ROOM: {
    title: "FLOOR SECTION",
    desc: "A room is an area where the activies are planned to occur.",
    illustration: "/illustrations/building.svg",
  },
  GATE: {
    title: "GATE",
    desc: "A gate is any area through which an entry or an exit can happen.",
    illustration: "/illustrations/gate.svg",
  },
  GATEWAY: {
    title: "GATEWAY",
    desc: "A computer that sits between different networks or applications. The gateway converts information, data or other communications from one protocol or format to another. A router may perform some of the functions of a gateway.",
    illustration: "/illustrations/gateway.svg",
  },
  BEACON: {
    title: "BEACON",
    desc: "A light or other visible object serving as a signal, warning, or guide at an occurrence.",
    illustration: "/illustrations/beacon.svg",
  },
  ACTION: {
    title: "ACTION",
    desc: "Action is a kind of response that needs to be taken when a policy excursion or entity going averse to the policy occurs.",
    illustration: "/illustrations/beacon.svg",
  },
  //Action details needs to be changed.
  SUBJECT: {
    title: "ASSET",
    desc: "A person or thing that is being discussed or dealt with.",
    illustration: "/illustrations/subject.svg",
  },
  MASTER: {
    title: "MASTER DATA",
    desc: "Master data is the consistent and uniform set of identifiers and extended attributes that describes the core entities of the enterprise including customers, prospects, citizens, suppliers, sites, hierarchies and chart of accounts.",
    illustration: "/illustrations/master.svg",
  },
  USER: {
    title: "USER DATA",
    desc: "A user is someone who employs or puts something into service, basically the one who uses the product.",
    illustration: "/illustrations/user.svg",
  },
  SCHEDULE: {
    title: "SCHEDULE",
    desc: "A schedule is a plan that gives a list of events or tasks and the times at which each one should happen or be done.",
    illustration: "/illustrations/schedule.svg",
  },
  CALENDAR: {
    title: "CALENDAR",
    desc: "Place to know activities that are planned for a special purpose and usually involves a lot of people, for example, a meeting.",
    illustration: "/illustrations/calendar.svg",
  },
  NOTICE: {
    title: "COMMUNICATION",
    desc: "The announcement of a party's intention to inform users at a specific point in time.",
    illustration: "/illustrations/notice.svg",
  },
  POLICY: {
    title: "POLICY",
    desc: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    illustration: "/illustrations/campus.svg",
  },
  PASSWORD: {
    title: "CHANGE PASSWORD",
    desc: "Form to change the password for the admin",
    illustration: "/illustrations/changePassword.svg",
  },
  SHIFT: {
    title: "SHIFT",
    desc: "shift desc",
    illustration: "/illustrations/schedule.svg",
  },
  TRANSITITINERARY: {
    title: "TransitItinerary",
    desc: "Transitinerary desc",
    illustration: "/illustrations/schedule.svg",
  },
};

export default Forms;
