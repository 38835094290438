import settingsTabs from "./settingsTabs";

const getSettingsMenuItems = (onClickCallback) => [
  {
    id: settingsTabs.INFRASTRUCTURE,
    handleClick: () => onClickCallback(settingsTabs.INFRASTRUCTURE),
    label: "Infrastructure",
  },
  {
    id: settingsTabs.ORGANIZATION,
    handleClick: () => onClickCallback(settingsTabs.ORGANIZATION),
    label: "Organization",
  },
  {
    id: settingsTabs.DEVICES,
    handleClick: () => onClickCallback(settingsTabs.DEVICES),
    label: "IoT Devices",
  },
  {
    id: settingsTabs.SECURITY,
    handleClick: () => onClickCallback(settingsTabs.SECURITY),
    label: "Security",
  },
  {
    id: settingsTabs.DIAGNOSIS,
    handleClick: () => onClickCallback(settingsTabs.DIAGNOSIS),
    label: "Diagnosis",
  },
];

export default getSettingsMenuItems;
