import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import RoutePointService from "services/RoutePointService";
import RouteService from "services/RouteService";
import SWALService from "services/SWALService";

function RoutePointTable({
  getData,
  data,
  loading,
  setRoutePointId,
  setAssetsState,
  activeRoutePointId,
}) {
  const [_fadeState, _setFadeState] = useState(false);

  const highlightRow = (data) => {
    console.log("sfasfsd", data);
    return {
      style: {
        background: "rgb(228,255,255)",
      },
    };
  };

  const editRoute = (routePointId) => setRoutePointId(routePointId);

  const deleteRoute = async (routePointId) => {
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await RoutePointService.delete(routePointId).catch((err) =>
      SWALService.showError(err)
    );

    getData();

    SWALService.showSuccess("Route has been deleted.", "Deleted!");
  };

  const addAssets = (routePointId) => {
    setAssetsState(!_fadeState);
    _setFadeState(!_fadeState);
    setRoutePointId(routePointId);
  };

  const routeTableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Active",
      accessor: "active",
      Cell: (row) => (row.original?.active ? "Yes" : "No"),
    },
    {
      Header: "Latitude",
      accessor: "coordinates[0].lat",
      Cell: (row) => row.original?.location?.geometry?.coordinates[0].lat,
    },
    {
      Header: "Longitude",
      accessor: "coordinates[0].lng",
      Cell: (row) => row.original?.location?.geometry?.coordinates[0].lng,
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editRoute}
          deleteCallback={deleteRoute}
          miscellaneousCallback={addAssets}
          icon={"group_add"}
        />
      ),
    },
  ];

  return (
    <BaseTable
      tableConfig={routeTableConfig}
      data={data}
      loading={loading}
      emptyMessage="Add route stop to view"
      onRowClick={highlightRow}
      activeCond={({ _id }) => _id === activeRoutePointId}
    />
  );
}

export default RoutePointTable;
