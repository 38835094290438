import BaseService from "./BaseService";
import axios from "axios";
import ApiService from "./ApiService";
import { USER_URL } from "config/api";
import Swal from "sweetalert2";

export default class SignUpSignInService extends ApiService {
  constructor() {
    super(USER_URL);
  }

  signUp(userObject, params, headers) {
    return new Promise((resolve, reject) => {
      this.post(userObject, params, headers)
        .then(resolve)
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error occured!",
            text: err.message,
          });
          reject();
        });
    });
  }

  _signUp(url, userObj, headers) {
    return this.baseService
      .doPOST(url, "POST", userObj, headers)
      .then((response) => {
        if (!!response.data.success) {
          return {
            msg: "You are redirected to confirm signup....",
            data: response.data.data,
          };
        }
      })
      .catch((err) => {
        return {
          msg: "You are already in system, Please contact to system admin...",
          err: err,
        };
      });
  }

  signIn(url, userObj, headers) {
    return this.baseService
      .doPOST(url, "POST", userObj, headers)
      .then((response) => {
        if (!!response.data.success) {
          return {
            msg: response.data.message,
            data: response.data.data,
          };
        }
      })
      .catch((err) => {
        return {
          msg: err.data.message,
          err: err,
        };
      });
  }
}
