import React, { useCallback, useEffect, useState } from "react";
// reactstrap components
import {
  FormGroup,
  Form,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  ModalHeader,
} from "reactstrap";
// import { configureAmplify, SetS3Config } from "../../../../../configureAmplify";
import FloorPlanService from "../../../../../services/FloorPlanService";
import UtilsService from "../../../../../services/UtilsService";
import { FLOOR_URL, FLOOR_BULK_UPLOAD_URL } from "../../../../../config/api";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import FormHolder from "components/Holders/FormHolder/FormHolder";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import Forms from "../../../../../config/copies/forms";
import BuildingSelect from "components/UserInputComponents/Selects/BuildingSelect";
import CampusSelect from "components/UserInputComponents/Selects/CampusSelect";
import { useSelector } from "react-redux";
import LoadButton from "components/UserInputComponents/LoadButton/LoadButton.js";
import ImageUpload from "components/CustomUpload/ImageUpload";
import IconButton from "components/UserInputComponents/IconButton/IconButton";
import CSVparser from "views/components/CSVparser";
import CSVupload from "views/components/CSVupload";
import CSVuploadManual from "views/components/CSVuploadManual";
import SWALService from "services/SWALService";

const floorPlanService = new FloorPlanService();
const utilsService = new UtilsService();

function FloorPlanForm() {
  const orgId = useSelector((state) => state.organization._id);

  const { floorPlanId } = useParams();

  const history = useHistory(); //browserHistory stack

  const buttonText = floorPlanId ? "Save Changes" : "Submit";

  const tableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Width",
      accessor: "width",
    },
    {
      Header: "Length",
      accessor: "length",
    },
    {
      Header: "Offset",
      accessor: "offset",
    },
    {
      Header: () => <div style={{ textAlign: "left" }}>Image</div>,
      accessor: "imgUrl",
      Cell: ({ original }) => (
        <>
          {!!original.imgUrl && (
            <a href={original.imgUrl} target="_blank" rel="noreferrer">
              <IconButton icon={"preview"} color={"info"} naked />
            </a>
          )}
        </>
      ),
    },
  ];

  const [floorName, setFloorName] = useState("");
  const [width, setWidth] = useState("");
  const [length, setLength] = useState("");
  const [offset, setOffset] = useState("");
  const [campus, setCampus] = useState("");
  const [building, setBuilding] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [floorData, setFloorData] = useState([]);
  const [manualModal, setManualModal] = useState(false);

  useEffect(() => {
    if (!floorPlanId) return;

    floorPlanService
      .getById(floorPlanId)
      .then(({ data }) => {
        setFloorName(data.name);
        setWidth(data.width);
        setLength(data.length);
        setOffset(data.offset);
        setCampus(data.campus?._id);
        setBuilding(data.building?._id);
        parentCallback(data.imgUrl);
      })
      .catch((err) => console.log(err));
  }, [floorPlanId]);

  useEffect(() => {
    if (floorData.length > 0) {
      floorData.forEach((object) => {
        object.campus = campus;
        object.building = building;
        object.organization = orgId;
      });
    }
  }, [floorData, campus, building]);

  const createFloorPlan = (payload) => {
    floorPlanService
      .doPost(FLOOR_URL, payload, true)
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "FloorPlan Create",
          text: response.msg,
        }).then(() => {
          history.push("/admin/floor/manage");
        });
      })
      .catch((error) => {
        SWALService.showError(error.message);
      });
  };

  const updateFloorPlan = (id, payload) => {
    floorPlanService
      .updateFloorPlan(FLOOR_URL, payload, id, true)
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "FloorPlan Update",
          text: response.msg,
        }).then(() => {
          history.push("/admin/floor/manage");
        });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (floorName === "")
      return Swal.fire({
        icon: "danger",
        title: "Building Name and Campus is mandatory field",
        text: "Please add the values",
      }).then(() => setLoading(false));

    const payload = {
      name: floorName,
      campus: campus,
      building: building,
      width: width,
      length: length,
      imgUrl: imgUrl,
      offset: offset,
      organization: orgId,
    };

    if (!floorPlanId) return createFloorPlan(payload);
    updateFloorPlan(floorPlanId, payload);
  };

  const clearEmptyRow = () => {
    const filterData = floorData.filter((object) => {
      return !!Object.keys(object).length;
    });
    setFloorData(filterData);
  };

  const handleBlukSubmit = (e) => {
    e.preventDefault();
    clearEmptyRow();
    const payload = floorData;
    floorPlanService
      .doPost(FLOOR_BULK_UPLOAD_URL, payload, true)
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "FloorPlans Added",
          text: response.msg,
        }).then(() => {
          history.push("/admin/floor/manage");
        });
      })
      .catch((error) => {
        SWALService.showError(error.message);
      });
  };

  const parentCallback = (data) => {
    removePreviousImage();
    let imgUrl = utilsService.removeURLQueryStringParams(data);
    setImgUrl(imgUrl);
  };

  const removePreviousImage = () => {
    if (imgUrl !== "") {
      setImgUrl("");
    }
  };

  return (
    <div className="content">
      <Row>
        <CSVupload
          title="Upload Multiple Floor"
          modalOpen={modalOpen}
          data={floorData}
          setData={setFloorData}
          handleSubmit={handleBlukSubmit}
          setModalOpen={setModalOpen}
          tableConfig={tableConfig}
          requiredFields={[campus, building]}
          setShowManual={setManualModal}
          selectFields={
            <div>
              <CampusSelect value={campus} setValue={setCampus} />

              <BuildingSelect
                value={building}
                setValue={setBuilding}
                context={{ campusId: campus }}
                noPopulate={!campus}
              />
            </div>
          }
        />
        <CSVuploadManual
          setModalOpen={setManualModal}
          modalOpen={manualModal}
          gId={"474138019"}
        />
        <FormHolder
          imgSrc={Forms.FLOOR.illustration}
          formHeader={Forms.FLOOR.title}
          formDescription={Forms.FLOOR.desc}
          formActions={
            <IconButton
              icon={"upload_file"}
              onClick={() => setModalOpen(true)}
              color={"info"}
              outlined
            />
          }
          CardFooterComponent=""
        >
          <Form onSubmit={handleSubmit}>
            <BaseTextInput
              label="Floor Name (*)"
              placeholder="Enter floor name"
              type="text"
              value={floorName}
              setValue={setFloorName}
            />

            <CampusSelect value={campus} setValue={setCampus} />

            <BuildingSelect
              value={building}
              setValue={setBuilding}
              context={{ campusId: campus }}
              noPopulate={!campus}
            />

            <BaseTextInput
              label="Floor Width (*)"
              placeholder="Enter floor width"
              type="number"
              value={width}
              setValue={setWidth}
            />
            <BaseTextInput
              label="Floor Length (*)"
              placeholder="Enter floor length"
              type="number"
              value={length}
              setValue={setLength}
            />
            <FormGroup>
              {/* <ImageUpload
                addBtnColor="default"
                changeBtnColor="default"
                parentCallback={parentCallback}
                context="floorimages"
              /> */}
              <ImageUpload
                addBtnColor="default"
                changeBtnColor="default"
                fileData={imgUrl}
                context="floorImages"
                parentCallback={parentCallback}
                removePreviousImage={removePreviousImage}
              />
            </FormGroup>
            <BaseTextInput
              label="Offset (*)"
              placeholder="Enter offset"
              type="number"
              value={offset}
              setValue={setOffset}
            />
            <BaseButton color="info">{buttonText}</BaseButton>
          </Form>
        </FormHolder>
      </Row>
    </div>
  );
}

export default FloorPlanForm;
