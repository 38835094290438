import React, { useEffect, useState } from "react";
import MalePlaceHolder from "assets/img/male-placeholder.jpeg";
import AssetPlaceHolder from "assets/img/asset-placeholder.jpeg";
import FemalePlaceHolder from "assets/img/female-placeholder.jpg";

function Avatar({ height, width, src, alt, gender, className }) {
  const [tempAvatar, setTempAvatar] = useState();

  useEffect(() => {
    if (!!src) {
      setTempAvatar(src);
    } else {
      if (gender?.toUpperCase() === "MALE") {
        setTempAvatar(MalePlaceHolder);
      } else if (gender?.toUpperCase() === "FEMALE") {
        setTempAvatar(FemalePlaceHolder);
      } else {
        setTempAvatar(AssetPlaceHolder);
      }
    }
  }, [src]);

  return (
    <img
      height={height}
      width={width}
      src={tempAvatar}
      className={className || ""}
      style={{ objectFit: "cover" }}
      alt={alt || "..."}
    />
  );
}

export default Avatar;
