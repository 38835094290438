import Icon from "components/Icon/Icon";
import React from "react";
import { useHistory } from "react-router-dom";
import { Col } from "reactstrap";

function SettingCategoryItem({ title = "No Data", icon, target }) {
  const history = useHistory();
  const handleClick = () => {
    history.push(target);
  };
  return (
    <Col md={4}>
      <div className="item-box py-5 mb-4" onClick={handleClick}>
        <div className="infra-item">
          {/* <i className={icon} /> */}
          <Icon icon={icon} size="xxxxl" />
          <p className="infra-txt">{title}</p>
        </div>
      </div>
    </Col>
  );
}

export default SettingCategoryItem;
