import React from 'react'
import { Col, Row, Card, CardBody, CardHeader, CardTitle, FormGroup, Label, Input } from 'reactstrap'

const RenderFloorsSelect = ({selectables, onPressHandler}) => selectables.map((selectable)=>
    <Col md={3}>
      <FormGroup check className="form-check-radio">
          <Label check>
              <Input type="radio" name="floor" value={selectable._id} onChange={(e) => onPressHandler(e)}/>
              <span className="form-check-sign" />
              {selectable.name}
          </Label>
      </FormGroup>
    </Col>
)

function SelectionFloorCard({selectables, header, onPressHandler}) {
  return (
    <Row>
      <Col md="3">
        <Label md="8">{header}</Label>
      </Col>
      <Col md="9">
        <Row>
          <RenderFloorsSelect selectables={selectables} onPressHandler={onPressHandler}/>
        </Row>
      </Col>
    </Row>
  )
}

export default SelectionFloorCard;
