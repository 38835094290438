import React, { useEffect, useState } from "react";
import BaseSelect from "./BaseSelect";
import { useSelector } from "react-redux";
import SubjectService from "services/SubjectService";

const subjectService = new SubjectService();

function AssetPojoSelect({ value, setValue, isMultiSelect, label }) {
  const [assets, setAssets] = useState([]);

  const organizationId = useSelector((state) => state.organization._id);

  useEffect(() => {
    const params = { organizationId, isRoutePointAssigned: false };
    subjectService
      .get(params)
      .then(({ data }) => {
        setAssets(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <BaseSelect
      label={label === "" ? "" : "Assets"}
      dataSource={assets?.map((subjectId) => ({
        value: subjectId,
        label: `${subjectId?.name} [${subjectId?.uniqueId}]`,
      }))}
      placeholder={"Select assets"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default AssetPojoSelect;
