import React from "react";
import BaseTextInput from "./BaseTextInput";

function UniqueIdInput({ value, setValue }) {
  return (
    <>
      <BaseTextInput
        label={"Unique Id"}
        placeholder={"Enter unique id"}
        name={"uniqueid"}
        value={value}
        setValue={setValue}
      />
    </>
  );
}

export default UniqueIdInput;
