import React from "react";

import DateTimeService from "services/DateTimeService";
import Avatar from "views/custom_components/Avatar/Avatar";

const PeriodicAttendanceDetailsTableConfig = [
  {
    Header: "#",
    width: 80,
    Cell: ({ original: { asset } }) => (
      <div>
        <Avatar
          height={34}
          src={asset?.attributes?.avatar || ""}
          alt={asset?.name || ""}
          gender={asset?.attributes?.gender}
        />
      </div>
    ),
  },
  {
    Header: "Name",
    accessor: "asset.name",
  },
  {
    Header: "Date",
    width: 200,
    accessor: ({ targetDate }) => DateTimeService.getDateString(targetDate),
  },
  {
    Header: "Unique ID",
    accessor: "asset.uniqueId",
  },
  {
    Header: "Primary Group",
    accessor: "asset.primaryGroup.name",
  },
  {
    Header: "Event Name",
    accessor: ({ scheduledEvent: event }) => event?.name,
  },
  {
    Header: "Starts at",
    accessor: ({ scheduledEvent: event }) =>
      event
        ? DateTimeService.getTimeString(
            DateTimeService.getDateTimeFromTimeString(event?.fromTime)
          )
        : "-",
  },
  {
    Header: "Ends at",
    accessor: ({ scheduledEvent: event }) =>
      event
        ? DateTimeService.getTimeString(
            DateTimeService.getDateTimeFromTimeString(event?.toTime)
          )
        : "-",
  },
  {
    Header: "Present/Absent",
    accessor: ({ present }) => (present ? "PRESENT" : "ABSENT"),
  },
  {
    Header: "On Time/Late",
    accessor: ({ onTime, present }) => {
      if (!present) return "-";
      return onTime ? "ON TIME" : "LATE";
    },
  },
];

export default PeriodicAttendanceDetailsTableConfig;
