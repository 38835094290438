import { ROUTE_STOP_ASSET_URL } from "config/api";
import ApiService from "./ApiService";

class RoutePointAssetService extends ApiService {
  constructor() {
    super(ROUTE_STOP_ASSET_URL);
  }
}

export default new RoutePointAssetService();
