import React, { useEffect, useState } from "react";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import { Col, Collapse, FormGroup, Label, Row } from "reactstrap";
import ImageUpload from "components/CustomUpload/ImageUpload";
import UtilsService from "services/UtilsService";
import { useSelector } from "react-redux";
import BaseTextAreaInput from "components/UserInputComponents/Inputs/TextInputs/BestTextAreaInput";
import OrganizationFormSectionHeader from "../OrganizationFormSectionHeader";

function AboutOrganization({ editable, setValue }) {
  const [logo, setLogo] = useState("");
  const [website, setWebsite] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [mapLink, setMapLink] = useState("");
  const [about, setAbout] = useState("");
  const [visible, setVisible] = useState(false);
  const { organization } = useSelector((s) => s);

  useEffect(() => {
    setLogo(organization?.attributes?.logo || "");
    setCoverImage(organization?.attributes?.coverImage[0] || "");
    setMapLink(organization?.attributes?.mapLink);
    setAbout(organization?.attributes?.about);
    setWebsite(organization?.attributes?.website);
  }, []);

  useEffect(() => {
    setValue({
      logo,
      coverImage: [coverImage],
      website,
      about,
      mapLink,
    });
  }, [logo, coverImage, website, about, mapLink]);

  const logoCallback = (data) => {
    removePreviousLogoImage();
    const utilsService = new UtilsService();
    const image = utilsService.removeURLQueryStringParams(data);
    setLogo(image);
  };

  const removePreviousLogoImage = () => {
    if (logo !== "") {
      setLogo("");
    }
  };

  const coverCallback = (data) => {
    removePreviousCoverImage();
    const utilsService = new UtilsService();
    const image = utilsService.removeURLQueryStringParams(data);
    setCoverImage(image);
  };

  const removePreviousCoverImage = () => {
    if (coverImage !== "") {
      setCoverImage("");
    }
  };

  return (
    <div className="content">
      <OrganizationFormSectionHeader
        title="About"
        value={visible}
        setValue={setVisible}
      />

      <Collapse isOpen={visible}>
        <Row>
          <Col md={6}>
            <Label>Logo</Label>
            <FormGroup>
              <ImageUpload
                addBtnColor="default"
                changeBtnColor="default"
                context="organizationLogo"
                fileData={logo}
                parentCallback={logoCallback}
                removePreviousImage={removePreviousLogoImage}
                editable={editable}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <Label>Cover Image</Label>
            <FormGroup>
              <ImageUpload
                addBtnColor="default"
                changeBtnColor="default"
                context="organizationCoverImage"
                fileData={coverImage}
                parentCallback={coverCallback}
                removePreviousImage={removePreviousCoverImage}
                editable={editable}
              />
            </FormGroup>
          </Col>
        </Row>
        <BaseTextInput
          label="Website"
          value={website}
          setValue={setWebsite}
          placeholder="A website for your company"
          editable={editable}
        />
        <BaseTextInput
          label="Map Link"
          value={mapLink}
          setValue={setMapLink}
          placeholder="A Map Link for your company"
          editable={editable}
        />
        <BaseTextAreaInput
          label="Description"
          value={about}
          setValue={setAbout}
          placeholder="A description for your company"
          editable={editable}
        />
      </Collapse>
    </div>
  );
}

export default AboutOrganization;
