import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import ActionFilter from "./subComponents/ActionFilter";
import { useSelector } from "react-redux";
import ActionService from "services/ActionService";
import ActionTable from "./subComponents/ActionTable";
import SWALService from "services/SWALService";
import TableHeader from "components/Tables/TableHeader/TableHeader";

function ManageActions() {
  const history = useHistory();
  const organizationId = useSelector((state) => state.organization._id);
  const defaultParams = { organizationId };
  const [actionParams, setActionParams] = useState({});
  const [action, setAction] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableConfig, setTableConfig] = useState([]);

  useEffect(() => {
    getAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionParams]);

  const getAction = async () => {
    setLoading(true);

    const params = {
      ...actionParams,
      ...defaultParams,
    };

    const { data } = await ActionService.get(params).catch((err) =>
      SWALService.showError(err)
    );

    console.log({ data });
    if (data) setAction(data);
    setLoading(false);
  };

  const addAction = () => history.push(`/admin/action/add`);

  return (
    <div className="content">
      <Row>
        <Col md={3}>
          <ActionFilter setActionParams={setActionParams} data={action} />
        </Col>
        <Col md={9}>
          <Card>
            <CardHeader>
              <TableHeader
                title="Actions"
                onClickAdd={addAction}
                onClickDownload={true}
                tableConfig={tableConfig}
                tableData={action}
              />
            </CardHeader>
            <CardBody>
              <ActionTable
                data={action}
                getAction={getAction}
                loading={loading}
                setTableConfig={setTableConfig}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ManageActions;
