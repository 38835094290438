import React, { useState } from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import BrandLoader from "views/custom_components/Loader";
import NoData from "views/custom_components/NoData";
import BuildingPresenceFilter from "./BuildingPresenceFIlter";
import DateTimeService from "../../../../../services/DateTimeService";
import BaseTable from "components/Tables/BaseTable/BaseTable";
import BuildingPresenceService from "services/BuildingPresence.service";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import toast from "react-hot-toast";

function BuildingPresenceReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const getAlerts = (queryParams) => {
    setIsLoading(true);

    BuildingPresenceService.get({
      ...queryParams,
      hydrate: "asset,building,gate",
    })
      .then(({ data }) => {
        if (!data.length) return toast.error("No data to show");
        setData(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const buildingPresenceTableConfig = [
    {
      Header: "Beacon Mac",
      accessor: "beaconMac",
    },
    {
      Header: "Name",
      accessor: (original) => original?.asset?.name || "N/A",
    },
    {
      Header: "Unique ID",
      accessor: (original) => original?.asset?.uniqueId || "N/A",
    },
    {
      Header: "Gate",
      accessor: "gate.label",
    },
    {
      Header: "Building",
      accessor: "building.name",
    },
    {
      Header: "Occurance Time",
      width: 200,
      accessor: (d) => DateTimeService.getDateTimeString(d?.occuranceTime),
    },
    {
      Header: "Event",
      width: 200,
      accessor: "event",
    },
  ];

  return (
    <>
      {isLoading && <BrandLoader />}
      <div className="content">
        <Row>
          <Col md={3}>
            <BuildingPresenceFilter getAlerts={getAlerts} />
          </Col>
          <Col md={9}>
            <Card>
              <CardHeader>
                <TableHeader
                  title="Building Presence Reports"
                  onClickDownload={true}
                  tableConfig={buildingPresenceTableConfig}
                  tableData={data}
                />
              </CardHeader>
              <CardBody>
                {!!data?.length && (
                  <>
                    <BaseTable
                      tableConfig={buildingPresenceTableConfig}
                      data={data}
                      loading={isLoading}
                    />
                  </>
                )}
                {!data.length && (
                  <NoData
                    imgSrc="/illustrations/warning.svg"
                    message="Select filters and press on 'get reports'"
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default BuildingPresenceReport;
