import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import GatewayService from "services/GatewayService";
import SWALService from "services/SWALService";
import "./gateway.css";
import GatewayFilters from "./subComponent/GatewayFilters";
import GatewayTable from "./subComponent/GatewayTable";
import TableHeader from "components/Tables/TableHeader/TableHeader";

function Gateway() {
  const history = useHistory();
  const organizationId = useSelector((state) => state.organization._id);
  const defaultParams = { organizationId };
  const [gateway, setGateway] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gatewayParams, setGatewayParams] = useState({});
  const [tableConfig, setTableConfig] = useState([]);

  useEffect(() => {
    getGateway();
  }, [gatewayParams]);

  const getGateway = async () => {
    setLoading(true);

    const params = {
      ...gatewayParams,
      ...defaultParams,
    };

    const { data } = await GatewayService.get(params).catch((err) =>
      SWALService.showError(err)
    );

    if (data) setGateway(data);
    console.log("asfsfs", data);
    setLoading(false);
  };

  const addGateway = () => history.push(`/admin/gateway/add`);

  return (
    <div className="content">
      <Row>
        <Col md={3}>
          <GatewayFilters setGatewayParams={setGatewayParams} data={gateway} />
        </Col>
        <Col md={9}>
          <Card>
            <CardHeader>
              <TableHeader
                title="Gateways"
                onClickAdd={addGateway}
                onClickDownload={true}
                tableConfig={tableConfig}
                tableData={gateway}
              />
            </CardHeader>
            <CardBody>
              <GatewayTable
                getGateway={getGateway}
                gatewayData={gateway}
                loading={loading}
                setTableConfig={setTableConfig}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Gateway;
