import ImageUpload from "components/CustomUpload/ImageUpload";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import MatterService from "services/MatterService";
import SWALService from "services/SWALService";
import UtilsService from "services/UtilsService";

function EditMatter({
  showEditMatters,
  setShowEditMatters,
  fetchMatters,
  matterData,
}) {
  const [matter, setMatter] = useState("");
  const { _id: organization } = useSelector((s) => s.organization);
  const [avatar, setAvatar] = useState("");
  const [matterID, setMatterID] = useState("");

  useEffect(() => {
    setAvatar(matterData?.attributes?.avatar || "");
    console.log("matterData", matterData);
    setMatter(matterData.name);
    setMatterID(matterData?._id);
  }, [matterData]);

  const handleEdit = () => {
    MatterService.put(matterID, {
      name: matter,
      attributes: {
        avatar,
      },
      organization,
    }).then(({ data }) => {
      setShowEditMatters(false);
      SWALService.showSuccess("Matter updated successfully.", "Updated");
      fetchMatters();
    });
  };

  const parentCallback = (data) => {
    removePreviousImage();
    const utilsService = new UtilsService();
    const icon = utilsService.removeURLQueryStringParams(data);
    setAvatar(icon);
  };

  const removePreviousImage = () => {
    if (avatar !== "") {
      setAvatar("");
    }
  };

  return (
    <div className="d-flex justify-content-center py-4">
      <Modal isOpen={showEditMatters}>
        <ModalBody>
          <BaseTextInput
            label="Matter"
            placeholder="Enter the matter"
            value={matter}
            setValue={setMatter}
          />
          <Label>Icon</Label>
          <FormGroup>
            <ImageUpload
              addBtnColor="default"
              changeBtnColor="default"
              fileData={avatar}
              context="matterAvatarPic"
              parentCallback={parentCallback}
              removePreviousImage={removePreviousImage}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleEdit}>
            Update
          </Button>
          <Button onClick={() => setShowEditMatters(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default EditMatter;
