import FilterTableHolder from "components/Holders/FilterTableHolder/FilterTableHolder";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import { FLOOR_URL } from "config/api";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import FloorPlanService from "services/FloorPlanService";
import SWALService from "services/SWALService";
import CsvDownloader from "views/custom_components/CsvDownloader";
import FloorPlanFilter from "./FloorPlanFilter";
import FloorPlanTable from "./FloorPlanTable";

const floorPlanService = new FloorPlanService();
function FloorPlanListing() {
  const organizationId = useSelector((state) => state.organization._id);
  const defaultParams = {};
  const history = useHistory();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [floorParams, setFloorParams] = useState();
  const [tableConfig, setTableConfig] = useState([]);

  useEffect(() => {
    fetchFloorPlan();
  }, [floorParams]);

  const fetchFloorPlan = () => {
    setLoading(true);

    const params = {
      ...floorParams,
      ...defaultParams,
    };

    floorPlanService
      .get(params)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        SWALService.showError(err);
        console.log(err);
      });
  };

  const addFloor = () => {
    history.push("/admin/floor/add");
  };

  return (
    <FilterTableHolder>
      <FloorPlanFilter setFloorParams={setFloorParams} data={data} />
      <TableHeader
        title="Floor"
        onClickAdd={addFloor}
        onClickDownload={true}
        tableConfig={tableConfig}
        tableData={data}
      />
      <FloorPlanTable
        data={data}
        getData={fetchFloorPlan}
        loading={loading}
        setTableConfig={setTableConfig}
      />
    </FilterTableHolder>
  );
}

export default FloorPlanListing;
