import gatewayScopes from "config/gatewayScopes";
import React from "react";
import BaseSelect from "./BaseSelect";

function GatewayScopeSelect({ value, setValue }) {
  const ScopeOptions = gatewayScopes.map((el) => ({
    value: el,
    label: el,
  }));
  return (
    <BaseSelect
      label={"Gateway Scope"}
      dataSource={ScopeOptions}
      placeholder={"Select gateway scope"}
      closeMenuOnSelect={false}
      isMultiSelect={true}
      value={value}
      setValue={setValue}
    />
  );
}

export default GatewayScopeSelect;
