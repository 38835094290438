import React from "react";
import BaseSelect from "./BaseSelect";

function DaysSelect({ setValue, label, value, bottomHintText }) {
  const daysObj = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ].map((day, index) => ({
    value: index + 1,
    label: day,
  }));

  return (
    <BaseSelect
      label={"Applied Days" || label}
      dataSource={daysObj}
      placeholder={"Select Days"}
      closeMenuOnSelect={false}
      isMultiSelect={true}
      value={value}
      setValue={setValue}
      bottomHintText={bottomHintText}
    />
  );
}

export default DaysSelect;
