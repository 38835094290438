import React from "react";
import { Collapse } from "reactstrap";
import FloorSectionSelect from "components/UserInputComponents/Selects/FloorSectionSelect";
import BaseSelect from "components/UserInputComponents/Selects/BaseSelect";

const LOCATIONS = [
  { value: "ANY", label: "ANY" },
  { value: "LOCATION_LIST", label: "LOCATION_LIST" },
];

class PolicyLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      triggerCondition_config_locationRange: "",
      triggerCondition_config_floorPlanSections: [],
      groups: [],
    };
  }

  componentDidMount() {
    const { policy } = this.props;
    if (!policy) return;

    this.setState({
      triggerCondition_config_locationRange:
        policy.triggerCondition_config_locationRange,
      triggerCondition_config_floorPlanSections:
        policy.triggerCondition_config_floorPlanSections,
    });
  }

  render() {
    return (
      <>
        <h4>Location bounds</h4>
        <br />
        <BaseSelect
          label="Locations"
          value={this.state.triggerCondition_config_locationRange}
          setValue={(val) =>
            this.setState({
              triggerCondition_config_locationRange: val,
            })
          }
          dataSource={LOCATIONS}
          bottomHintText="Select between 'ANY' location or set a list of locations"
        />

        <Collapse
          isOpen={
            this.state.triggerCondition_config_locationRange === "LOCATION_LIST"
          }
        >
          <FloorSectionSelect
            isMultiSelect
            value={this.state.triggerCondition_config_floorPlanSections}
            setValue={(val) =>
              this.setState({
                triggerCondition_config_floorPlanSections: val,
              })
            }
            bottomHintText="Add floor sections here"
          />
        </Collapse>
      </>
    );
  }
}

export default PolicyLocation;
