import React from "react";
import AuditTrailListing from "./sub-components/AuditTrailListing";

function AuditTrail() {
  return (
    <>
      <AuditTrailListing />
    </>
  );
}

export default AuditTrail;
