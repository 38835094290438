import React from "react";
import BaseSelect from "./BaseSelect";
import attendanceSummaryTypes from "config/attendanceSummaryTypes";

function AttendanceSummarySelect({
  value,
  setValue,
  isMultiSelect,
  noLabel,
  renderPortal,
  ...props
}) {
  const attendanceSummaryTypeOptions = attendanceSummaryTypes.map((el) => ({
    value: el,
    label: el,
  }));

  return (
    <BaseSelect
      label={!noLabel && "Attendance Type"}
      dataSource={attendanceSummaryTypeOptions}
      placeholder={"Select attendance type"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
      renderPortal={renderPortal}
      {...props}
    />
  );
}

export default AttendanceSummarySelect;
