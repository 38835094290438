import React from "react";
import "./ComingSoon.scss";

function ComingSoon({ children }) {
  return (
    <div className="ComingSoon">
      <p className="ComingSoon__text">Coming Soon</p>
      {children}
    </div>
  );
}

export default ComingSoon;
