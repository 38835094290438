import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Row, Col, Button } from "reactstrap";
import SWALService from "services/SWALService";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import GatewayBeaconTable from "./subComponents/GatewayPingsTable";
import GatewayBeaconFilter from "./subComponents/GatewayPingsFilter";
import axios from "axios";
import { BASE_URL } from "config/api";
import GatewayService from "services/GatewayService";
import gatewayBeaconTableConfig from "./subComponents/gatewayBeaconTableConfig";
import useQuery from "customHooks/useQuery";
const KalmanFilter = require("kalmanjs");
const { mean } = require("simple-statistics");

const groupBy = (fn, list) =>
  list.reduce(
    (prev, next) => ({
      ...prev,
      [fn(next)]: [...(prev[fn(next)] || []), next],
    }),
    {}
  );

// const utilFac = require("../../../services/UtilsService");
// const UtilityService = new utilFac();

const kf = new KalmanFilter();

const getKalmanMean = (arr, fn) => mean(arr.map((v) => kf.filter(fn(v))));

const extractPings = (gateways) => gateways[0]?.lastPackets;

function ManageGatewayBeacon() {
  const [gatewayPings, setGatewayPings] = useState([]);
  const [rawGatewayPings, setRawGatewayPings] = useState([]);
  const [exportPings, setExportPings] = useState([]);

  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState();
  const [isCollecting, setIsCollecting] = useState(false);
  const [collections, setCollections] = useState(0);
  const [kalman, setKalman] = useState(false);

  useEffect(() => {
    if (!params) return;
    getGatewayBeacon();
    const timeout = setInterval(() => getGatewayBeacon(), 1000);

    console.log({ exportPings });

    return () => clearInterval(timeout);
  }, [params]);

  useEffect(() => {
    if (!kalman) return setGatewayPings(rawGatewayPings);

    const groupedFilteredPings = groupBy(
      (v) => `${v.mac}-${v.ibeaconUuid}`,
      gatewayPings
    );

    const noiseRemovedPings = Object.keys(groupedFilteredPings)
      .map((mac_uuid) => {
        const pingArray = groupedFilteredPings[mac_uuid];
        const [mac, uuid] = mac_uuid.split("-");
        console.log({ lol: groupedFilteredPings[mac_uuid] });

        const rssi = Math.round(getKalmanMean(pingArray, (v) => v.rssi));

        return {
          ...pingArray[0],
          ibeaconUuid: uuid,
          mac,
          rssi,
        };
      })
      .filter(Boolean);

    setGatewayPings(noiseRemovedPings);
  }, [rawGatewayPings, kalman]);

  const getGatewayBeacon = async () => {
    try {
      setLoading(true);
      const { data } = await GatewayService.get(params);
      setRawGatewayPings(extractPings(data));
    } catch (err) {
      SWALService.showError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (() => {
      if (!isCollecting) return;
      setCollections((prevCollects) => prevCollects + 1);
      setExportPings((prevPings) => [...prevPings, ...gatewayPings]);
    })();
  }, [gatewayPings, isCollecting]);

  const toggleCollectionState = () => {
    if (isCollecting === false) setExportPings([]);
    setIsCollecting((prevState) => !prevState);
  };

  return (
    <div className="content">
      <Row>
        <Col md={3}>
          <GatewayBeaconFilter setParams={setParams} />
        </Col>
        <Col md={9}>
          <Card>
            <CardHeader>
              <TableHeader
                title={`Live Pings 🔴 (${
                  Array.from(new Set(gatewayPings?.map((p) => p.mac)))
                    ?.length ?? 0
                })`}
                onClickDownload={true}
                tableConfig={gatewayBeaconTableConfig}
                tableData={exportPings}
              >
                <p>{collections}</p>
                <Button
                  color={isCollecting ? "danger" : "success"}
                  onClick={toggleCollectionState}
                >
                  Collect Pings
                </Button>
              </TableHeader>
            </CardHeader>
            <CardBody>
              <GatewayBeaconTable
                getData={getGatewayBeacon}
                data={gatewayPings}
                loading={false}
                kalmanStatus={setKalman}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ManageGatewayBeacon;
