import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import AssetSelect from "components/UserInputComponents/Selects/AssetSelect";
import DaysSelect from "components/UserInputComponents/Selects/DaysSelect";
import RouteSelect from "components/UserInputComponents/Selects/RouteSelect";
import EndTimeInput from "components/UserInputComponents/TimePickers/EndTimeInput";
import FromTimeInput from "components/UserInputComponents/TimePickers/FromTimeInput";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "reactstrap";
import Validate from "utils/validators";

function TransitItineraryFilter({ setTransitItineraryParams }) {
  const [transitItinerarytName, setTransitItineraryName] = useState("");
  const [route, setRoute] = useState("");
  const [driver, setDriver] = useState("");

  const orgId = useSelector((state) => state.organization._id);

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("onSubmit");
    setTransitItineraryParams({
      name: transitItinerarytName,
      route: route,
      driver: driver,
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <BaseTextInput
        label="TransitItinerary Name"
        placeholder="Enter TransitItinerary name"
        value={transitItinerarytName}
        setValue={setTransitItineraryName}
        validator={Validate.Name}
      />
      <AssetSelect value={driver} setValue={setDriver} label="Driver" />
      <RouteSelect value={route} setValue={setRoute} />
      <BaseButton>Apply</BaseButton>
    </Form>
  );
}

export default TransitItineraryFilter;
