import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import RegisteredOnDatePicker from "components/UserInputComponents/DatePickers/RegisteredOnDatePicker";
import PhoneNumberInput from "components/UserInputComponents/Inputs/NumberInputs/PhoneNumberInput";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import GenderSelect from "components/UserInputComponents/Selects/GenderSelect";
import useQuery from "customHooks/useQuery";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "reactstrap";

function VisitorRecordFilter({ setParams }) {
  const organizationId = useSelector((s) => s.organization._id);
  const [phoneNumber, setPhoneNumber] = useState();
  const [gender, setGender] = useState("");
  const [registeredOn, setRegisteredOn] = useState("");
  const [searchParams] = useQuery();

  useEffect(() => {
    if (!searchParams) return;
    setParams({
      organizationId,
      phoneNumber: searchParams?.contact,
      role: "VISITOR",
    });
    setPhoneNumber(searchParams?.contact);
  }, [searchParams]);

  const onSubmit = (e) => {
    e.preventDefault();
    setParams({
      organizationId,
      role: "VISITOR",
      gender: gender,
      phoneNumber: phoneNumber,
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <PhoneNumberInput value={phoneNumber} setValue={setPhoneNumber} />
      <GenderSelect value={gender} setValue={setGender} />
      <RegisteredOnDatePicker date={registeredOn} setDate={setRegisteredOn} />
      <BaseButton> Apply</BaseButton>
    </Form>
  );
}

export default VisitorRecordFilter;
