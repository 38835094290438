const { ONBOARDING_URL } = require("config/api");
const { default: ApiService } = require("./ApiService");

class OnBoardingService extends ApiService {
  constructor() {
    super(ONBOARDING_URL);
  }
}

export default new OnBoardingService();
