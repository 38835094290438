import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import DateTimeService from "services/DateTimeService";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import RoleSelect from "components/UserInputComponents/Selects/RoleSelect";
import GroupEntitySelect from "components/UserInputComponents/Selects/GroupEntitySelect";
import { useSelector } from "react-redux";
import useQuery from "customHooks/useQuery";
import AlertTypes from "components/UserInputComponents/Selects/AlertTypes";
import toast from "react-hot-toast";
import setQueryParams from "utils/setQueryParams";
import { useHistory } from "react-router-dom";

function SOSFilter({ getAlerts }) {
  const history = useHistory();
  const orgId = useSelector((state) => state.organization?._id);

  const [{ alertType }] = useQuery();

  const now = new Date();
  const todayNight = DateTimeService.getMidnightTime(now);
  const todayMorning = DateTimeService.getMorningTime(now);
  const [startDate, setStartDate] = useState(todayMorning);
  const [endDate, setEndDate] = useState(todayNight);
  // const [fromTime, setFromTime] = useState(todayMorning)
  // const [toTime, setToTime] = useState(todayNight);
  const [roles, setRoles] = useState([]);
  const [mappedGE, setMappedGE] = useState();

  const [eventTypes, setEventTypes] = useState([]);

  useEffect(() => {
    if (!alertType) return;
    setEventTypes([alertType]);

    getAlerts({
      organizationId: orgId,
      fromTime: startDate.toISOString(),
      toTime: endDate.toISOString(),
      eventTypes: alertType || eventTypes,
    });
  }, [alertType]);

  const handleFilters = (e) => {
    e.preventDefault();
    if (eventTypes.length <= 0) return toast.error("Event type not selected");

    const payload = {
      organizationId: orgId,
      fromTime: startDate.toISOString(),
      toTime: endDate.toISOString(),
      eventTypes: eventTypes?.join(","),
    };

    history.push({
      pathname: "/admin/alerts",
      search: `${setQueryParams(payload)}`,
    });

    getAlerts(payload);
  };

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <StartDatePicker date={startDate} setDate={setStartDate} />
        <EndDatePicker date={endDate} setDate={setEndDate} />
        {/* <FromTimeInput setValue={setFromTime} />
        <EndTimeInput setValue={setToTime} /> */}

        <AlertTypes value={eventTypes} setValue={setEventTypes} isMultiSelect />
        <RoleSelect value={roles} setValue={setRoles} isMultiSelect />
        {roles?.some((role) => role === "STUDENT") && (
          <GroupEntitySelect
            value={mappedGE}
            setValue={setMappedGE}
            isMultiSelect
          />
        )}
        <br />
        <Button color="info" onClick={handleFilters}>
          Get Reports
        </Button>
      </CardBody>
    </Card>
  );
}

export default SOSFilter;
