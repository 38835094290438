import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import DateTimeService from "services/DateTimeService";
import PolicyService from "services/PolicyService";
import SWALService from "services/SWALService";
import _ from "lodash";

function PolicyTable({ getData, data, loading, setTableConfig }) {
  const history = useHistory();

  const today = new Date();

  const editPolicy = (policyId) =>
    history.push(`/admin/policy/edit/${policyId}`);

  const deletePolicy = async (policyId) => {
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await PolicyService.delete(policyId).catch((err) =>
      SWALService.showError(err)
    );

    getData();

    SWALService.showSuccess("Policy has been deleted.", "Deleted!");
  };

  const policyTableConfig = [
    { Header: "Name", accessor: "name" },
    { Header: "Description", accessor: "description" },
    {
      Header: "From Time",
      accessor: ({ activeTime: { fromTime } }) =>
        fromTime
          ? DateTimeService.getTimeString(
              today
                .toISOString()
                .slice(0, today.toISOString().indexOf("T") + 1) +
                fromTime +
                "Z"
            )
          : "",
    },
    {
      Header: "To Time",
      accessor: ({ activeTime: { toTime } }) =>
        toTime
          ? DateTimeService.getTimeString(
              today
                .toISOString()
                .slice(0, today.toISOString().indexOf("T") + 1) +
                toTime +
                "Z"
            )
          : "",
    },
    {
      Header: "Applied Days",
      accessor: (d) => {
        const daysOfWeek = Object.keys(
          _.pickBy(d?.activeTime?.days, (value, key) => value)
        );
        return DateTimeService.getDaysFromArray(daysOfWeek);
      },
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editPolicy}
          deleteCallback={deletePolicy}
        />
      ),
    },
  ];

  useEffect(() => {
    setTableConfig(policyTableConfig);
  }, []);

  return (
    <BaseTable tableConfig={policyTableConfig} data={data} loading={loading} />
  );
}

export default PolicyTable;
