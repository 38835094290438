import Icon from "components/Icon/Icon";
import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import "./RedirectWidgetHolder.scss";

function RedirectWidgetHolder({ icon, text, redirectTo }) {
  const history = useHistory();
  const handleRedirect = () => {
    history.push(`${redirectTo}`);
  };
  return (
    <div onClick={handleRedirect} className="RedirectWidgetHolder">
      <Icon size="xxl" icon={icon} />
      <div>{text}</div>
    </div>
  );
}

export default RedirectWidgetHolder;
