import axios from 'axios';
import BaseService from "./BaseService";

export default class ReportsService {
    constructor(){
        this.baseService = new BaseService();
    }

    getDailAttendanceReport(url, params, headers){
        return this.baseService.doGET(url, "GET", params, headers).then((response) => {
            if(!!response.data.success){
               return response.data.data;
            }
        });
    }

    getLateArrivalReport(url, params, headers){
        return this.baseService.doGET(url, "GET", params, headers).then((response) => {
            if(!!response.data.success){
               return response.data.data;
            }
        });
    }

    getCurrentOccupancyReport(url, params, headers){
        return this.baseService.doGET(url, "GET", params, headers).then((response) => {
            if(!!response.data.success){
               return response.data.data;
            }
        });
    }

    getPolicyViolationsReport(url, params, headers){
        return this.baseService.doGET(url, "GET", params, headers).then((response) => {
            if(!!response.data.success){
               return response.data.data;
            }
        });
    }

    getSOSReport(url, params, headers){
        return this.baseService.doGET(url, "GET", params, headers).then((response) => {
            if(!!response.data.success){
               return response.data.data;
            }
        });
    }
}