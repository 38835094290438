import { Auth } from "aws-amplify";
import classNames from "classnames";
import Icon from "components/Icon/Icon";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import { login } from "data/actions/authActions";
import { setOrganization } from "data/actions/organizationAction";
import { setUser } from "data/actions/userActions";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import BrandLoader from "views/custom_components/Loader";
import toast from "react-hot-toast";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Form,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardFooter,
  Input,
  Button,
} from "reactstrap";
import SWALService from "services/SWALService";

import UserService from "services/UserService";

const toastId = "LOGGING_IN";

function SignIn() {
  const [mobileNumer, setMobileNumber] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.toggle("login-page");

    return () => {
      document.body.classList.toggle("login-page");
    };
  }, []);

  const onSigninSubmit = async (e) => {
    if (!mobileNumer || !password) {
      return toast.error("Username or Password cannot be empty");
    } else {
      toast.loading("Signing in", { id: toastId });
      await signInCallback(e);
    }
  };

  const signInCallback = async (e) => {
    e.preventDefault();
    setLoading(true);
    const congnitoUser = await Auth.signIn({
      username: `+91${mobileNumer}`,
      password,
    }).catch((err) => toast.error(err.message, { id: toastId }));

    if (!congnitoUser?.attributes) return setLoading(false);

    const session_key =
      congnitoUser?.signInUserSession?.accessToken?.payload?.event_id;
    localStorage.setItem("session_key", session_key);

    const { data } = await UserService.get(
      {
        userSub: congnitoUser?.attributes?.sub,
      },
      {},
      true
    ).catch((err) => toast.error(err, { id: toastId }));

    const user = data[0];
    if (!user) return setLoading(false);
    const organization = user?.organization;
    if (!organization) setLoading(false);

    localStorage.setItem("Organization_ID", organization._id);
    dispatch(setOrganization(organization));
    dispatch(setUser(user));
    dispatch(login(congnitoUser));

    toast.success("Successfully logged in", { id: toastId });

    setLoading(false);
  };

  return (
    <div className="content">
      <Container>
        <Col className="ml-auto mr-auto" lg="4" md="6">
          <Form className="form" onSubmit={onSigninSubmit}>
            <img
              style={{
                position: "absolute",
                top: "-4rem",
                left: "50%",
                transform: "translateX(-55%)",
                width: "12rem",
                height: "3rem",
                zIndex: "10",
                objectFit: "contain",
              }}
              alt="..."
              src={require("assets/img/parchai.png")}
            />
            <Card className="card-login card-white">
              <CardHeader>
                <img alt="..." src={require("assets/img/card-info.png")} />
                <CardTitle tag="h1">Sign in</CardTitle>
              </CardHeader>
              <CardBody>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <Icon icon="phone_android" size="sm"></Icon>&#32;&#32;
                      <span className="pl-2"> +91 </span>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Mobile Number"
                    type="text"
                    name="username"
                    onChange={(e) => setMobileNumber(e.target.value)}
                    // value={mobileNumer}
                  />
                </InputGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <Icon clickable icon="lock" size="sm"></Icon>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    // value={password}
                  />
                </InputGroup>
              </CardBody>
              <CardFooter>
                <Button
                  block
                  className="mb-3"
                  color="info"
                  href="#"
                  size="lg"
                  onClick={onSigninSubmit}
                  style={{ display: "grid", placeItems: "center" }}
                >
                  {!loading ? "Sign In" : "Loading..."}
                </Button>
                <div className="pull-left">
                  <h6>
                    <Link className="link footer-link" to="/auth/register">
                      Create Account
                    </Link>
                  </h6>
                </div>
                <div className="pull-right">
                  <h6>
                    <Link
                      className="link footer-link"
                      to="/auth/forgotPassword"
                    >
                      Forgot Password
                    </Link>
                  </h6>
                </div>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Container>
    </div>
  );
}

export default SignIn;
