import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import RouteSelect from "components/UserInputComponents/Selects/RouteSelect";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "reactstrap";

function RouteFilter({ setRouteParams }) {
  const [routeId, setRouteId] = useState();

  const orgId = useSelector((state) => state.organization._id);
  // const orgId = "621642aa5656450009819ffc";

  const onSubmit = (e) => {
    e.preventDefault();
    setRouteParams({
      organizationId: orgId,
      id: routeId,
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <RouteSelect value={routeId} setValue={setRouteId} />
      <BaseButton>Apply</BaseButton>
    </Form>
  );
}

export default RouteFilter;
