import React, { useEffect, useState } from "react";
// core components
import BuildingService from "../../../../../services/BuildingService";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
// import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput.js";
import FormHolder from "components/Holders/FormHolder/FormHolder.js";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton.js";
import Forms from "../../../../../config/copies/forms";
import LocationSelector from "components/UserInputComponents/LocationSelector/LocationSelector.js";
import CampusSelect from "components/UserInputComponents/Selects/CampusSelect.js";
import { Form } from "reactstrap";
import SWALService from "services/SWALService";
import BrandLoader from "views/custom_components/Loader";
import BaseTextAreaInput from "components/UserInputComponents/Inputs/TextInputs/BestTextAreaInput";
import toast from "react-hot-toast";

function BuildingAdd() {
  const orgId = useSelector((state) => state.organization._id);

  const [loading, setLoading] = useState(false);

  const { buildingId } = useParams();

  const history = useHistory(); //browserHistory stack

  const buttonText = buildingId ? "Save Changes" : "Submit";

  const [campus, setCampus] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [buildingDesc, setBuildingDesc] = useState("");
  const [location, setLocation] = useState({});

  useEffect(() => {
    if (!buildingId) return;

    BuildingService.getById(buildingId).then(({ data }) => {
      if (!data) return;
      setCampus(data.campus?._id);
      setBuildingName(data.name);
      setBuildingDesc(data.desc);
      setLocation(data.location);
    });
  }, [buildingId]);

  const updateBuilding = async (buildingId, payload) => {
    const { message, success } = await BuildingService.put(
      buildingId,
      payload
    ).catch((err) => toast.error(err.message));

    setLoading(false);

    if (!success) return;

    history.goBack();
    SWALService.showSuccess(message);
  };

  const createBuilding = async (payload) => {
    const { message, success } = await BuildingService.post(payload).catch(
      (err) => toast.error(err.message)
    );

    setLoading(false);

    if (!success) return;

    history.goBack();
    SWALService.showSuccess(message);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      name: buildingName,
      desc: buildingDesc,
      campus: campus,
      location: location,
      organization: orgId,
    };

    if (buildingName === "" && campus === "")
      return Swal.fire({
        icon: "danger",
        title: "Building Name and Campus is mandatory field",
        text: "Please add the values",
      });

    setLoading(true);

    if (!buildingId) return createBuilding(payload);
    updateBuilding(buildingId, payload);
  };

  return (
    <div className="content">
      <BrandLoader loading={loading} />
      <FormHolder
        imgSrc={Forms.BUILDING.illustration}
        formHeader={Forms.BUILDING.title}
        formDescription={Forms.BUILDING.desc}
        CardFooterComponent=""
      >
        <Form onSubmit={handleSubmit}>
          <BaseTextInput
            label="Building Name (*)"
            placeholder="Enter building name"
            type="text"
            value={buildingName}
            setValue={setBuildingName}
          />

          <BaseTextAreaInput
            label="Building Desc"
            placeholder="Enter building description"
            value={buildingDesc}
            setValue={setBuildingDesc}
          />

          <CampusSelect value={campus} setValue={setCampus} />

          <LocationSelector
            label="Address"
            setValue={setLocation}
            location={location}
          />
          <BaseButton color="info">{buttonText}</BaseButton>
        </Form>
      </FormHolder>
    </div>
  );
}

export default BuildingAdd;
