import axios from "axios";
import LocalStorage from "./storage";
import { BASE_AUTH_KEY } from "../config/config";
//import { PRESIGNED_API_URL } from '../config/api';
import Swal from "sweetalert2";
import toast from "react-hot-toast";
class BaseService {
  constructor() {
    this.defaultGetParams = "";
    this.headers = {};
    this.invalidateCacheParam = "";
  }

  getAuthorizationKey() {
    const items = { ...localStorage };
    for (let item in items) {
      if (!!item.includes(".idToken")) {
        const localStorage = new LocalStorage(item, "");
        const authKey = localStorage.getLocalStorage();
        return authKey;
      }
    }
  }

  getBaseAuthKey() {
    return BASE_AUTH_KEY;
  }

  generateArray(obj) {
    return Object.keys(obj).map((key) => {
      return { key: key, value: obj[key] };
    });
  }

  generateQueryParamString = (queryParamObj) => {
    const queryObj = Object.assign(
      {},
      this.invalidateCacheParam,
      this.defaultGetParams,
      queryParamObj,
    );
    const queryParamArr = this.generateArray(queryObj);
    let arrayParam = "";
    Object.entries(queryParamObj).forEach((item) => {
      if (Array.isArray(item[1])) {
        arrayParam = item[0] + "=" + item[1].join("&" + item[0] + "=");
        arrayParam += "&";
        return false;
      }
      arrayParam = arrayParam + item[0] + "=" + item[1] + "&";
    });
    arrayParam = arrayParam.slice(0, -1);
    return arrayParam;
  };

  setHeaders() {
    let headers = new Headers();
    if (!!headers && headers.size !== undefined) {
      headers.forEach((value, name) => {
        headers.append(name, value);
      });
    }
    if (!headers.get("Authorization")) {
      let authKey = this.getAuthorizationKey();
      if (authKey === null || authKey === undefined) {
        authKey = this.getBaseAuthKey();
      }
      headers.append("Authorization", authKey);
      headers.append("Content-Type", "application/json");
    }
    return headers;
  }

  doGET = (url, method, queryParams, headers) => {
    try {
      let header;
      if (method !== "GET") {
        return false;
      } else {
        let queryParam = this.generateQueryParamString(queryParams);
        if (!!headers) {
          header = this.setHeaders();
          this.headers["Authorization"] = header.get("Authorization");
          this.headers["Content-Type"] = header.get("Content-Type");
        }
        url += "?" + queryParam;
        const options = {
          method: method,
          url: url,
          data: "",
          headers: this.headers,
        };
        return axios(options)
          .then((response) => response)
          .catch((err) => {
            toast.error(err.message || "Something went wrong");
            return false;
          });
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  doPOST = (url, method, data, headers) => {
    try {
      let header;
      if (method !== "POST") {
        return false;
      } else {
        if (!!headers) {
          header = this.setHeaders();
          this.headers["Authorization"] = header.get("Authorization");
          this.headers["Content-Type"] = header.get("Content-Type");
        }
        const options = {
          method: method,
          url: url,
          data: JSON.stringify(data),
          headers: this.headers,
        };
        return axios(options)
          .then((response) => {
            console.log(response);
            return response;
          })
          .catch((err) => {
            return err;
          });
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  doPUT = (url, method, data, urlParam, headers) => {
    try {
      let header;
      if (method !== "PUT") {
        return false;
      } else {
        if (!!headers) {
          header = this.setHeaders();
          this.headers["Authorization"] = header.get("Authorization");
          this.headers["Content-Type"] = header.get("Content-Type");
        }
        console.log(this.headers);
        const options = {
          method: method,
          url: `${url}${urlParam ? `/${urlParam}` : ""}`,
          data: JSON.stringify(data),
          headers: this.headers,
        };
        console.log(data);
        console.log(options);
        return axios(options)
          .then((response) => {
            console.log(response);
            return response;
          })
          .catch((err) => console.log(err));
      }
    } catch (error) {
      console.log(error);
    }
  };

  doDELETE = (url, method, urlParam, headers) => {
    try {
      let header;
      if (method !== "DELETE") {
        return false;
      } else {
        if (!!headers) {
          header = this.setHeaders();
          this.headers["Authorization"] = header.get("Authorization");
          this.headers["Content-Type"] = header.get("Content-Type");
        }
        const options = {
          method: method,
          url: `${url}/${urlParam}`,
          data: "",
          headers: this.headers,
        };
        return axios(options)
          .then((response) => {
            console.log(response);
            return response;
          })
          .catch((err) => console.log(err));
      }
    } catch (error) {
      console.log(error);
    }
  };

  doPATCH = (constructedUrl, data, headers) => {
    try {
      let header;
      if (!!headers) {
        header = this.setHeaders();
        this.headers["Authorization"] = header.get("Authorization");
        this.headers["Content-Type"] = header.get("Content-Type");
      }
      const options = {
        method: "PATCH",
        url: constructedUrl,
        data,
        headers: this.headers,
      };

      return axios(options)
        .then((response) => {
          return response;
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log({ error });
    }
  };

  getPresignedUploadParams() {
    try {
      const options = {
        method: "GET",
        url: "https://rnhro2l89j.execute-api.ap-southeast-1.amazonaws.com/default/partha-demo-func-1",
        data: "",
        headers: this.headers,
      };
      return axios(options);
    } catch (error) {
      console.log(error);
    }
  }
}

export default BaseService;
