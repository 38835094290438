import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import RouteService from "services/RouteService";
import SWALService from "services/SWALService";

function RouteTable({ getData, data, loading, setTableConfig }) {
  const history = useHistory();

  const editRoute = (routeId) => history.push(`/admin/route/edit/${routeId}`);

  const showAssets = (routeId) => history.push(`/admin/route/asset/${routeId}`);

  const deleteRoute = async (routeId) => {
    console.log(routeId);
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await RouteService.delete(routeId).catch((err) =>
      SWALService.showError(err)
    );

    getData();

    SWALService.showSuccess("Route has been deleted.", "Deleted!");
  };

  const addRoutePoint = (routeId) =>
    history.push(`/admin/route/stop/${routeId}`);

  const routeTableConfig = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "desc",
    },
    {
      Header: "Encoded Polyline",
      accessor: "encodedPolyline",
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editRoute}
          deleteCallback={deleteRoute}
          addCallback={addRoutePoint}
          miscellaneousCallback={showAssets}
          icon={"group_add"}
          name="Show"
        />
      ),
    },
  ];

  useEffect(() => {
    setTableConfig(routeTableConfig);
  }, []);

  return (
    <BaseTable tableConfig={routeTableConfig} data={data} loading={loading} />
  );
}

export default RouteTable;
