import React from "react";
import BaseNumberInput from "./BaseNumberInput";

function AgeInput({ value, setValue, validator }) {
  return (
    <BaseNumberInput
      label={"Age"}
      type="number"
      placeholder={"Enter Age"}
      name={"age"}
      value={value}
      setValue={setValue}
      validator={validator}
    />
  );
}

export default AgeInput;
