import { CONFIGURE_BEACON_URL } from "config/api";
import ApiService from "./ApiService";
import BaseService from "./BaseService";

class ConfigureBeaonService extends ApiService {
  constructor() {
    super(CONFIGURE_BEACON_URL);
    this.baseService = new BaseService();
  }

  getBeaconConfig(params) {
    return this.baseService.doGET(
      `${CONFIGURE_BEACON_URL}/get-beacon-config`,
      "GET",
      params
    );
  }

  setBeaconToReconfig(body) {
    return this.baseService.doPUT(
      `${CONFIGURE_BEACON_URL}/set-reconfigure-beacon-config`,
      "PUT",
      body
    );
  }

  configureBeacon(gatewayMac) {
    return this.baseService.doPOST(
      `${CONFIGURE_BEACON_URL}/${gatewayMac}`,
      "POST",
      {}
    );
  }
}

export default new ConfigureBeaonService();
