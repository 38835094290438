const { HOOK_URL } = require("config/api");
const { default: ApiService } = require("./ApiService");

class AuditLogService {
  async postSignIn(sessionId, userId) {
    const payload = {
      user: userId,
      action: "USER_LOGGED_IN",
      sessionId,
    };
    localStorage.setItem("session_key", sessionId);
    localStorage.setItem("user_id", userId);
    const res = await new ApiService(`${HOOK_URL}/auth/postAuth`).post(payload);
  }

  async postSignOut(userId) {
    const sessionId = localStorage.getItem("session_key");
    const payload = {
      user: userId,
      action: "USER_LOGGED_OUT",
      sessionId,
    };
    const res = await new ApiService(`${HOOK_URL}/auth/postAuth`).post(payload);
    localStorage.clear();
  }
}

export default new AuditLogService();
