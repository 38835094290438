import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import EndDatePicker from "components/UserInputComponents/DatePickers/EndDatePicker";
import StartDatePicker from "components/UserInputComponents/DatePickers/StartDatePicker";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import CalenderEventSelect from "components/UserInputComponents/Selects/CalenderEventSelect";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Form } from "reactstrap";
import DateTimeService from "services/DateTimeService";

function CalenderEventFilter({ getCalenderEvents }) {
  const orgId = useSelector((state) => state.organization._id);
  const now = new Date();

  const todayMorning = DateTimeService.getMorningTime(now);
  const todayNight = DateTimeService.getMidnightTime(now);

  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [calenderEventName, setCalenderEventName] = useState("");

  const [type, setType] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (fromTime !== "" && toTime !== "") {
      getCalenderEvents({
        organizationId: orgId,
        fromTime: fromTime?.toISOString(),
        toTime: toTime?.toISOString(),
        type: type,
        name: calenderEventName,
      });
    }
    // this is done when the user only wants to filter with type of events
    else {
      getCalenderEvents({
        organizationId: orgId,
        type: type,
        name: calenderEventName,
      });
    }
  };

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <StartDatePicker date={fromTime} setDate={setFromTime} />
          <EndDatePicker date={toTime} setDate={setToTime} />
          <CalenderEventSelect value={type} setValue={setType} />
          <BaseTextInput
            label="Event Name"
            placeholder="Enter event name"
            value={calenderEventName}
            setValue={setCalenderEventName}
          />
          <BaseButton>Apply</BaseButton>
        </Form>
      </CardBody>
    </Card>
  );
}

export default CalenderEventFilter;
