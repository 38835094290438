import React, { useState } from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import BrandLoader from "views/custom_components/Loader";
import NoData from "views/custom_components/NoData";
import NotificationFilter from "./NotificationsFilter";
import DateTimeService from "../../../../../services/DateTimeService";
import BaseTable from "components/Tables/BaseTable/BaseTable";
import NotificationService from "services/Notification.service";
import TableHeader from "components/Tables/TableHeader/TableHeader";
import toast from "react-hot-toast";
import SubjectView from "components/PopoverViews/SubjectView/SubjectView";

function NotificationReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const getAlerts = (queryParams) => {
    setIsLoading(true);

    NotificationService.get({ ...queryParams, hydrate: "assetId" })
      .then(({ data }) => {
        if (!data.length) return toast.error("No data to show");
        setData(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const sosReportTableConfig = [
    {
      Header: "Place Type",
      accessor: "triggerContext.gatewayAttachmentType",
    },
    {
      Header: "Event Type",
      accessor: "triggerContext.eventType",
    },
    {
      Header: "Name",
      accessor: (original) => original?.assetId?.name || "N/A",
      Cell: ({ original: { assetId } }) => (
        <SubjectView subjectId={assetId._id} subjectData={assetId}>
          {assetId.name}
        </SubjectView>
      ),
    },
    {
      Header: "Unique Id",
      accessor: (original) => original?.assetId?.uniqueId || "N/A",
    },
    {
      Header: "Role",
      accessor: (original) => original?.assetId?.role || "N/A",
    },
    {
      Header: "Status",
      accessor: (original) => original?.meta?.status || "N/A",
    },
    {
      Header: "Comment",
      accessor: (original) => original?.meta?.comment || "N/A",
    },
    {
      Header: "Dispatch Time",
      width: 200,
      accessor: (d) => DateTimeService.getDateTimeString(d?.createdAt),
    },
    {
      Header: "Event Time",
      width: 200,
      accessor: (d) => DateTimeService.getDateTimeString(d?.eventTime),
    },
  ];

  return (
    <>
      {isLoading && <BrandLoader />}
      <div className="content">
        <Row>
          <Col md={3}>
            <NotificationFilter getAlerts={getAlerts} />
          </Col>
          <Col md={9}>
            <Card>
              <CardHeader>
                <TableHeader
                  title="Notification Log Reports"
                  onClickDownload={true}
                  tableConfig={sosReportTableConfig}
                  tableData={data}
                />
              </CardHeader>
              <CardBody>
                {!!data?.length && (
                  <>
                    <BaseTable
                      tableConfig={sosReportTableConfig}
                      data={data}
                      loading={isLoading}
                    />
                  </>
                )}
                {!data.length && (
                  <NoData
                    imgSrc="/illustrations/warning.svg"
                    message="Select filters and press on 'get reports'"
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default NotificationReport;
