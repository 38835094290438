function getPropByString(obj, propString) {
  // return _.get(obj, propString, "-");
  if (!propString) return obj;

  let prop,
    props = propString.split(".");

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    let candidate = obj[prop];
    if (candidate !== null && candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
}

function getReportData(tableConfig = [], tableData = []) {
  const _headers = [tableConfig?.map((el) => el.Header)];
  const headers = [_headers[0]?.filter((el) => el !== "#" && el !== "Actions")];
  const accessors = tableConfig
    ?.map(({ accessor }) => accessor)
    ?.filter((el) => el !== undefined);

  const accessRow = (data, idx, level = 0) => {
    return accessors?.map((el) => {
      if (typeof el === "string") return getPropByString(data, el);
      if (typeof el === "function") return el(data);
      return "";
    });
  };
  const data = tableData?.map((el, idx) => accessRow(el, idx));
  return { headers, data };
}

export default getReportData;
