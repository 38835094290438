import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ActionService from "services/ActionService";
import DateTimeService from "services/DateTimeService";
import SWALService from "services/SWALService";

function ActionTable({ getAction, data, loading, setTableConfig }) {
  const history = useHistory();
  const editAction = (actionId) =>
    history.push(`/admin/action/edit/${actionId}`);

  const deleteAction = async (actionId) => {
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    await ActionService.delete(actionId).catch((err) =>
      SWALService.showError(err)
    );

    getAction();

    SWALService.showSuccess("Action has been deleted.", "Deleted!");
  };
  const actionTableConfig = [
    { Header: "Name", accessor: "name" },
    { Header: "Type", accessor: "type" },
    {
      Header: "Created On",
      accessor: (original) =>
        DateTimeService.getDateTimeString(original.createdOn),
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editAction}
          deleteCallback={deleteAction}
        />
      ),
    },
  ];

  useEffect(() => {
    setTableConfig(actionTableConfig);
  }, []);

  return (
    <BaseTable tableConfig={actionTableConfig} data={data} loading={loading} />
  );
}

export default ActionTable;
