import React, { useEffect, useState } from "react";
// reactstrap components
import { Form } from "reactstrap";
// core components
import GateService from "../../../../../services/GateService";
import { useHistory, useParams } from "react-router-dom";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput.js";
import FormHolder from "components/Holders/FormHolder/FormHolder.js";
import BaseButton from "components/UserInputComponents/BaseButton/BaseButton.js";
import Forms from "../../../../../config/copies/forms";
import SWALService from "services/SWALService";
import { useSelector } from "react-redux";
import Validate from "utils/validators";
import GatewaySelect from "components/UserInputComponents/Selects/GatewaySelect";

function GateForm() {
  const organizationId = useSelector((state) => state.organization._id);

  const { gateId } = useParams();
  const history = useHistory(); //browserHistory stack

  const buttonText = !!gateId ? "Save Changes" : "Submit";

  const [label, setLabel] = useState("");
  const [entryGateways, setEntryGateways] = useState([]);
  const [exitGateways, setExitGateways] = useState([]);

  useEffect(() => {
    if (!gateId) return;

    (async () => {
      const { data } = await GateService.getById(gateId).catch((err) =>
        SWALService.showError(err)
      );
      if (!data) return;

      setLabel(data?.label || "");
      setEntryGateways(data?.entryGateways || []);
      setExitGateways(data?.exitGateways || []);
    })();
  }, [gateId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const gateObj = {
      label,
      entryGateways,
      exitGateways,
      organization: organizationId,
    };

    if (!gateId)
      return GateService.post(gateObj).then(({ message }) => {
        history.goBack();
        SWALService.showSuccess(message);
      });

    GateService.put(gateId, gateObj).then(({ message }) => {
      history.goBack();
      SWALService.showSuccess(message);
    });
  };

  return (
    <div className="content">
      <FormHolder
        imgSrc={Forms.GATE.illustration}
        formHeader={Forms.GATE.title}
        formDescription={Forms.GATE.desc}
      >
        <Form onSubmit={handleSubmit}>
          <BaseTextInput
            label="Gate Name"
            placeholder="Enter gate name"
            value={label}
            setValue={setLabel}
            validator={Validate.Name}
          />

          <GatewaySelect
            value={entryGateways}
            setValue={setEntryGateways}
            label="Entry Gateways"
            placeholder={"Enter gateways towards the entrance"}
          />

          <GatewaySelect
            value={exitGateways}
            setValue={setExitGateways}
            label="Exit Gateways"
            placeholder={"Enter gateways towards the exit"}
          />

          <BaseButton color="info">{buttonText}</BaseButton>
        </Form>
      </FormHolder>
    </div>
  );
}

export default GateForm;
