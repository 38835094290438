import React from 'react';
import BeaconForm from './BeaconForm';

function AddBeacon() {
  return (
    <BeaconForm />
  )
}

export default AddBeacon;
