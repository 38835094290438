import React from "react";
import {
  Input,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import {
  FLOORSECTION_URL,
  SUBJECT_URL,
  ATTENDANCE_URL,
} from "../../../../../config/api";
import FloorPlanSectionService from "../../../../../services/FloorPlanSectionService";
import SubjectService from "../../../../../services/SubjectService";
import ReportsService from "../../../../../services/ReportsService";
import LeftSearchPanel from "../FilterPanel/LeftSearchPanel";
import qs from "qs";
import axios from "axios";
import PolicyViolationTableValues from "./PolicyViolationTableValues";

class PolicyViolationReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      floorPlanSections: [],
      results: [],
      subjectData: [],
      Subject_Id: "",
      floorPlanSection_Id: "",
      fromTime: "00:00",
      toTime: "23:59",
      onlyDate: "",
      fromTimeStamp: "",
      toTimeStamp: "",
    };
    this.subjectService = new SubjectService();
    this.reportsService = new ReportsService();
  }

  componentDidMount() {
    this.fetchFloorPlanSection();
    this.setState({ onlyDate: new Date().toISOString() });
  }

  fetchFloorPlanSection = () => {
    try {
      let param = {};
      if (this.props.organization !== undefined) {
        param = {
          organizationId: this.props.organization[0]._id,
        };
      }
      FloorPlanSectionService.get(param)
        .then(({ data }) => {
          let final_data = [];
          data.forEach((ele) => {
            let obj = { value: ele._id, label: ele.floorPlanSectionName };
            final_data.push(obj);
          });
          this.setState({ floorPlanSections: final_data });
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  fetchSubjects = (floorsection_id) => {
    try {
      let param = {
        floorPlanSectionId: floorsection_id,
      };
      this.subjectService
        .getAllSubjectsByRoomID(SUBJECT_URL, param, true)
        .then((response) => {
          let data = response;
          let final_data = [];
          data.map((ele) => {
            let obj = { value: ele._id, label: ele.name };
            final_data.push(obj);
          });
          this.setState({ subjectData: final_data });
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  onChangeEvent = (event) => {
    let floorSection_Id = event.value;
    this.setState({ floorPlanSection_Id: floorSection_Id });
    this.fetchSubjects(floorSection_Id);
  };

  onChangeSubjectEvent = (event) => {
    let subject_id = event.value;
    this.setState({ Subject_Id: subject_id });
  };

  handleDateEvent = (event, picker) => {
    console.log(picker.startDate);
    //this.setState({onlyDate : new Date(picker.startDate._i).toISOString()});
    console.log(new Date(picker.startDate._d).toISOString());
  };

  handleDateCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  onFromTimeChange = (options) => {
    const { hour, minute, meridiem } = options;
    let date_str = this.state.onlyDate.split("T")[0];
    console.log(date_str);
    let final_string = date_str + "T" + hour + ":" + minute + ":" + "00";
    let frm = options.hour + ":" + options.minute;
    this.setState({ fromTime: frm });
    this.setState({ fromTimeStamp: new Date(final_string).toISOString() });
  };

  onFromFocusChange = (options) => {
    const { hour, minute, meridiem } = options;
  };

  onToTimeChange = (options) => {
    const { hour, minute, meridiem } = options;
    let date_str = this.state.onlyDate.split("T")[0];
    let final_string = date_str + "T" + hour + ":" + minute + ":" + "00";
    let to = options.hour + ":" + options.minute;
    this.setState({ toTime: to });
    this.setState({ toTimeStamp: new Date(final_string).toISOString() });
  };

  onToFocusChange = (options) => {
    const { hour, minute, meridiem } = options;
  };

  getAttendanceReport = () => {
    console.log(this.state.fromTimeStamp, this.state.toTimeStamp);
    try {
      if (this.state.Subject_Id === "") {
        axios
          .get(`${ATTENDANCE_URL}`, {
            params: {
              floorPlanSection: this.state.floorPlanSection_Id,
              fromTime: this.state.fromTimeStamp,
              toTime: this.state.toTimeStamp,
            },
            paramsSerializer: (params) => {
              return qs.stringify(params, { encode: false });
            },
          })
          .then((response) => {
            console.log(response.data.data);
            let data = [];
            data = response.data.data;
            this.setState(this.state);
            this.setState({ results: data });
          });
      } else {
        axios
          .get(`${ATTENDANCE_URL}`, {
            params: {
              subject: this.state.Subject_Id,
              fromTime: this.state.fromTimeStamp,
              toTime: this.state.toTimeStamp,
            },
            paramsSerializer: (params) => {
              return qs.stringify(params, { encode: false });
            },
          })
          .then((response) => {
            console.log(response.data.data);
            let data = [];
            data = response.data.data;
            this.setState(this.state);
            this.setState({ results: data });
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onChangeSearch = () => {
    console.log("searching");
  };

  render() {
    return (
      <div className="content">
        <Row>
          <Col md={3}>
            <LeftSearchPanel onSubmit={this.getAttendanceReport} />
          </Col>
          <Col md={9}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Violation</CardTitle>
                <p></p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <Input
                      placeholder="Search"
                      type="text"
                      onChange={(e) => this.onChangeSearch(e)}
                    />
                  </Col>
                </Row>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th></th>
                      <th className="text-center">Name</th>
                      <th className="text-center">Unique ID</th>
                      <th className="text-center">Place</th>
                      <th className="text-center">Rule</th>
                      <th className="text-center">Gateway ID</th>
                      <th className="text-center">Incident Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PolicyViolationTableValues
                      imgUrls={[
                        "james.jpg",
                        "mike.jpg",
                        "james.jpg",
                        "mike.jpg",
                      ]}
                      nameList={[
                        "James Hoods",
                        "Mike Tyson",
                        "Philip Baszler",
                        "Mike Kerninghen",
                      ]}
                      uniqueIDList={[
                        "S-3452353",
                        "S-3652353",
                        "S-3422353",
                        "S-3552353",
                      ]}
                      placeList={[
                        "Near Server Room",
                        "Near History Museum",
                        "Near History Museum",
                        "Near History Museum",
                      ]}
                      ruleList={[
                        "Unauthorized movement near server room more than 10 minutes",
                        "Unauthorized movement near history museum more than 10 minutes",
                        "Unauthorized movement near history museum more than 10 minutes",
                        "Unauthorized movement near history museum more than 10 minutes",
                      ]}
                      gatewayIDList={[
                        "AZ345353535",
                        "AZ345353535",
                        "AZ345353535",
                        "AZ345353535",
                      ]}
                      incidentTimeList={[
                        "15/09/2021 11:15 AM",
                        "15/09/2021 11:15 AM",
                        "15/09/2021 11:15 AM",
                        "15/09/2021 11:15 AM",
                      ]}
                    />
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    organization: state.getOrganizationReducer.organization,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(PolicyViolationReport);
