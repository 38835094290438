import React from "react";
import classNames from "classnames";
// react component for creating dynamic tables
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import { withRouter } from "react-router-dom";

const dataTable = [
  [1, "Server Room Ruleset", "Server Room"],
  [2, "Server Room Ruleset", "Server Room"],
  [3, "Server Room Ruleset", "Server Room"],
  [4, "Server Room Ruleset", "Server Room"],
  [5, "Server Room Ruleset", "Server Room"],
  [6, "Server Room Ruleset", "Server Room"],
  [7, "Server Room Ruleset", "Server Room"],
  [8, "Server Room Ruleset", "Server Room"],
  [9, "Server Room Ruleset", "Server Room"],
  [10, "Server Room Ruleset", "Server Room"],
];

class RestrictionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: dataTable.map((prop, key) => {
        return {
          id: key,
          name: prop[1],
          room_section: prop[2],
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                onClick={() => this.editRestriction(this.state.data, key)}
                color="warning"
                size="sm"
                className={classNames("btn-icon btn-link like", {
                  "btn-neutral": key < 0,
                })}
              >
                <i className="tim-icons icon-pencil" />
              </Button>{" "}
              {/* use this button to remove the data row */}
              <Button
                onClick={() => {
                  var data = this.state.data;
                  data.find((o, i) => {
                    if (o.id === key) {
                      // here you should add some custom code so you can delete the data
                      // from this component and from your server as well
                      data.splice(i, 1);
                      console.log(data);
                      return true;
                    }
                    return false;
                  });
                  this.setState({ data: data });
                }}
                color="danger"
                size="sm"
                className={classNames("btn-icon btn-link like", {
                  "btn-neutral": key < 0,
                })}
              >
                <i className="tim-icons icon-trash-simple" />
              </Button>{" "}
            </div>
          ),
        };
      }),
    };
  }

  editRestriction = (datas, key) => {
    let obj = datas.find((o) => o.id === key);
    this.props.history.push(`/admin/restrictions/${key}`, { usecase: "edit" });
  };

  addRestriction = () => {
    this.props.history.push(`/admin/restrictions`, { usecase: "add" });
  };

  render() {
    return (
      <>
        <div className="content wrapTable">
          <Row className="mt-5 ml-auto">
            <Col xs={12} md={12} className="ml-auto">
              <Card>
                <CardHeader>
                  <div className="tools float-right">
                    <Row className="butt_row">
                      <Col md="12">
                        <Button
                          color="success"
                          className="right_alg_button"
                          onClick={() => this.addRestriction()}
                        >
                          <i className="tim-icons icon-check-2" /> Add
                          Restriction
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <CardTitle tag="h4">Ruleset List</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: "Name",
                        accessor: "name",
                      },
                      {
                        Header: "Room Sections",
                        accessor: "room_section",
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className="-highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(RestrictionList);
