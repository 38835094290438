import React from "react";
import ReactDOM from "react-dom";

import { configureAmplify } from "./configureAmplify";
//import ProtectedRoutes from "./protectedRoutes";
// import { Provider } from "react-redux";
//import AmplifyBridge from "data_layer_saga/AmplifyBridge";

// import { PersistGate } from "redux-persist/integration/react";
import "assets/css/nucleo-icons.css";
import "react-datetime/css/react-datetime.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.1.0";
import "assets/scss/black-dashboard-pro-react/custom/mixins/_sidebar-hover.scss";
import "assets/demo/demo.css";
import "./index.css";
import { StoreProvider } from "data/store";
import App from "App";

configureAmplify();

document.body.classList.add("white-content");

ReactDOM.render(
  <StoreProvider>
    <App />
  </StoreProvider>,
  document.getElementById("root")
);
