import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import DaysSelect from "components/UserInputComponents/Selects/DaysSelect";
import EndTimeInput from "components/UserInputComponents/TimePickers/EndTimeInput";
import FromTimeInput from "components/UserInputComponents/TimePickers/FromTimeInput";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "reactstrap";
import Validate from "utils/validators";

function ShiftFilter({ setShiftParams }) {
  const [ShiftName, setShiftName] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [days, setDays] = useState([]);

  const orgId = useSelector((state) => state.organization._id);

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("onSubmit");
    setShiftParams({
      startTime,
      endTime,
      daysOfWeek: days,
      name: ShiftName,
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <BaseTextInput
        label="Shift Name"
        placeholder="Enter Shift name"
        value={ShiftName}
        setValue={setShiftName}
        validator={Validate.Name}
      />
      <FromTimeInput date={startTime} setValue={setStartTime} />
      <EndTimeInput date={endTime} setValue={setEndTime} />
      <DaysSelect value={days} setValue={setDays} />
      <BaseButton>Apply</BaseButton>
    </Form>
  );
}

export default ShiftFilter;
