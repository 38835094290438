import BaseButton from "components/UserInputComponents/BaseButton/BaseButton";
import VehicleRegistrationSelect from "components/UserInputComponents/Selects/VehicleRegistrationSelect";
import VehicleSelect from "components/UserInputComponents/Selects/VehicleSelect";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "reactstrap";

function VehicleFilter({ setVehicleParams }) {
  const [vehicleId, setVehicleId] = useState();
  const [vehicleReg, setVehicleReg] = useState();

  const orgId = useSelector((state) => state.organization._id);
  // const orgId = "621642aa5656450009819ffc";

  const onSubmit = (e) => {
    e.preventDefault();
    setVehicleParams({
      organizationId: orgId,
      id: vehicleId,
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <VehicleSelect value={vehicleId} setValue={setVehicleId} />
      <VehicleRegistrationSelect value={vehicleReg} setValue={setVehicleReg} />
      <BaseButton>Apply</BaseButton>
    </Form>
  );
}

export default VehicleFilter;
