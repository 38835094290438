const dangerChart = {
  opaque: "rgb(244, 67, 54)",
  translucent: "rgba(244, 67, 54, 0.5)",
  transparent: "rgba(244, 67, 54, 0)",
};

const infoChart = {
  opaque: "rgb(29, 140, 248)",
  translucent: "rgba(29, 140, 248, 0.5)",
  transparent: "rgba(29, 140, 248, 0)",
};

const successChart = {
  opaque: "rgb(88, 184, 147)",
  translucent: "rgba(88, 184, 147, 0.5)",
  transparent: "rgba(88, 184, 147, 0)",
};

const warningChart = {
  opaque: "rgb(255, 152, 0)",
  translucent: "rgba(255, 152, 0, 0.5)",
  transparent: "rgba(255, 152, 0, 0)",
};

const ChartColors = {
  info: infoChart,
  danger: dangerChart,
  success: successChart,
  warning: warningChart,
};

export default ChartColors;
