import GatewayInput from "components/UserInputComponents/Inputs/TextInputs/GatewayInput";
import GatewayModelSelect from "components/UserInputComponents/Selects/GatewayModelSelect";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader } from "reactstrap";

function GatewayFilters({ setGatewayParams }) {
  const orgId = useSelector((state) => state.organization._id);
  const [gateway, setGateway] = useState("");
  const [macId, setMacId] = useState("");
  const [gatewayModels, setGatewayModels] = useState([]);

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <GatewayInput className="mb-4" setValue={setGateway} value={gateway} />
        <GatewayInput
          className="mb-4"
          setValue={setMacId}
          value={macId}
          label={"Mac ID"}
          placeholder={"Enter mac id"}
        />
        <GatewayModelSelect value={gatewayModels} setValue={setGatewayModels} />
        <Button
          color="info"
          onClick={() =>
            setGatewayParams({
              organizationId: orgId,
              name: gateway.replace(" ", "_") || "",
              macId: macId || "",
              model: gatewayModels?.join(",") || "",
            })
          }
        >
          Apply
        </Button>
      </CardBody>
    </Card>
  );
}

export default GatewayFilters;
