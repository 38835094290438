import visitorPurpose from "config/visitorPurpose";
import React from "react";
import BaseSelect from "./BaseSelect";

function VisitPurposeSelect({ value, setValue, isMultiSelect }) {
  const purposeOptions = visitorPurpose.map((el) => ({
    label: el,
    value: el,
  }));
  return (
    <BaseSelect
      label={"Purpose of visit"}
      dataSource={purposeOptions}
      placeholder={"Select purpose"}
      closeMenuOnSelect={!isMultiSelect}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default VisitPurposeSelect;
