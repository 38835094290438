import React from "react";

import { Pie } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Col,
  Row,
} from "reactstrap";
import chartConfig from "config/chart";
import pastelColors from "config/pastelColors";
import { useHistory } from "react-router-dom";
import DateTimeService from "services/DateTimeService";
import NoData from "views/custom_components/NoData";

function VisitorPurpose({ data = [], dateContext, context }) {
  const history = useHistory();
  const labels = data?.map(({ purpose }) => purpose);
  const colors = pastelColors.slice(0, labels?.length || 0);
  const chartData = data?.map(({ count }) => count);

  // labels, chartData, colors, chartScope

  const _chartData = chartConfig.createData(
    labels,
    chartData,
    colors,
    "Visitor Purposes"
  );

  const toTime = DateTimeService.getMidnightTime(new Date()).toISOString();

  const fromTime = DateTimeService.getMorningTime(
    DateTimeService.getDateTimeNDaysAgo(toTime, dateContext)
  ).toISOString();

  const handlePurpose = (purpose) => {
    history.push({
      pathname: `/admin/visitors/log-book`,
      search: `?visitorPurpose=${purpose}&fromTime=${fromTime}&toTime=${toTime}`,
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Most Visited Staff</CardTitle>
      </CardHeader>
      <CardBody className="p-3 d-flex align-items-center">
        {!data?.length && (
          <NoData
            show={!data?.length}
            message={`No visitors for ${context}`}
            imgSrc="/illustrations/empty_street.svg"
          />
        )}
        <Row className="flex-grow-1">
          <Col md="6">
            <div className="chart-area">
              <Pie data={_chartData} options={chartConfig.options} />
            </div>
          </Col>

          <Col md="6">
            <Table responsive className="cursor">
              <tbody>
                {labels.map((label, idx) => (
                  <tr onClick={() => handlePurpose(label)} key={idx}>
                    <td>
                      <div
                        className="PresenceInsider__legends"
                        style={{
                          backgroundColor: colors[idx],
                        }}
                      ></div>
                    </td>
                    <td className="text-capitalize">{label.toLowerCase()}</td>
                    <td className="text-right PresenceInsider__percentages">
                      {chartData[idx]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default VisitorPurpose;
