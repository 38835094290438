import BaseTable from "components/Tables/BaseTable/BaseTable";
import TableActions from "components/Tables/subComponents/TableActions/TableActions";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import BeaconService from "services/BeaconService";
import SWALService from "services/SWALService";

function BeaconTable({ data, loading, getData, setTableConfig }) {
  const history = useHistory();

  const editBeacon = (beaconId) =>
    history.push(`/admin/beacon/edit/${beaconId}`);

  const deleteBeacon = async (beaconId) => {
    const { isConfirmed } = await SWALService.showConfirmDelete();

    if (!isConfirmed) return;

    const { success } = await BeaconService.delete(beaconId).catch((err) =>
      SWALService.showError(err)
    );

    if (!success) return;

    getData();

    SWALService.showSuccess("Beacon has been deleted.", "Deleted!");
  };
  const beaconTableConfig = [
    {
      Header: "Beacon Name",
      accessor: "beaconName",
    },
    {
      Header: "MAC",
      accessor: "macId",
    },
    {
      Header: "Model",
      accessor: "model",
    },
    { Header: "Major", accessor: "iBeaconMajor" },
    { Header: "Minor", accessor: "iBeaconMinor" },
    {
      Header: "ACTIVE",
      accessor: ({ activatedOn }) => (!!activatedOn ? "YES" : "NO"),
    },
    {
      Header: "Actions",
      Cell: (row) => (
        <TableActions
          {...row}
          editCallback={editBeacon}
          deleteCallback={deleteBeacon}
        />
      ),
    },
  ];

  useEffect(() => {
    setTableConfig(beaconTableConfig);
  }, []);

  return (
    <BaseTable data={data} tableConfig={beaconTableConfig} loading={loading} />
  );
}

export default BeaconTable;
