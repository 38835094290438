import StatView from "components/Widgets/StatView/StatView";
import React, { useEffect, useState } from "react";
import Card from "reactstrap/lib/Card";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Table from "reactstrap/lib/Table";
import DateTimeService from "services/DateTimeService";
import SubjectService from "services/SubjectService";
import NoData from "views/custom_components/NoData";

import "./Profile.scss";
import ProfileAnalytics from "./ProfileAnalytics/ProfileAnalytics";
import ProfileAnalyticsMonthly from "./ProfileAnalyticsMonthly/ProfileAnalyticsMonthly";
import ProfileInfo from "./ProfileInfo/ProfileInfo";

const subjectService = new SubjectService();

function Profile({ subjectId, match }) {
  const { id } = match.params;

  const [selectedDate, setSelectedDate] = useState();

  const isShowProfile = !!id && id !== ":id";

  return (
    <div className="Profile content">
      {!isShowProfile && (
        <NoData
          message="Empty parameters"
          imgSrc={"/illustrations/profile_view.svg"}
        />
      )}
      {isShowProfile && (
        <Row>
          <Col md="12">
            <ProfileInfo subjectId={id} showEdit />
          </Col>
          <Col md="12">
            <ProfileAnalytics
              setSelectedDate={setSelectedDate}
              subjectId={id}
            />
          </Col>
          <Col md="12">
            <ProfileAnalyticsMonthly
              selectedDate={selectedDate}
              subjectId={id}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default Profile;
