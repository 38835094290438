import React from "react";
import { Row, Col } from "reactstrap";
import BuildingListing from "./subComponent/BuildingListing";
class Building extends React.Component {
  render() {
    return <BuildingListing />;
  }
}

export default Building;
