import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MatterService from "services/MatterService";
import BaseSelect from "./BaseSelect";

function MatterSelect({
  value,
  setValue,
  isMultiSelect,
  noLabel,
  context = {},
  noPopulate,
}) {
  const [matters, setMatters] = useState([]);

  useEffect(() => {
    if (noPopulate) return;

    MatterService.get()
      .then(({ data }) => setMatters(data))
      .catch((err) => console.log(err));
  }, [noPopulate]);

  return (
    <BaseSelect
      label={!noLabel && "Subject"}
      dataSource={matters?.map((matter) => ({
        value: matter?._id,
        label: matter?.name,
      }))}
      placeholder={"Select Subject"}
      closeMenuOnSelect={false}
      isMultiSelect={isMultiSelect}
      value={value}
      setValue={setValue}
    />
  );
}

export default MatterSelect;
