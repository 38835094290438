import React, { useEffect, useState } from "react";
import "./OccupancyTimeline.scss";
import "./AssetTimeline.scss";
import SubjectService from "services/SubjectService";
import Timeline from "./Timeline/Timeline";
import DateTimeService from "services/DateTimeService";
import toast from "react-hot-toast";

const subjectService = new SubjectService();

function ProfileViewTimeline({ date, subjectId, maxHeight }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTimeline = () => {
    if (!date) {
      setLoading(true);
      subjectService
        .getSubjectTimeline(subjectId)
        .then(({ data: { data } }) => {
          setData(data);
        })
        .catch((err) => toast.error("Something went wrong"))
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      const fromTime = new Date(DateTimeService.getMorningTime(date));
      const toTime = new Date(DateTimeService.getMidnightTime(date));
      subjectService
        .getSubjectTimeline(subjectId, fromTime, toTime)
        .then(({ data: { data } }) => {
          setData(data);
        })
        .catch((err) => toast.error(err))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (!subjectId) return;
    fetchTimeline();
  }, [subjectId]);

  return (
    <Timeline
      data={data}
      loading={loading}
      noDataMsg={"No timeline data to show"}
      header={"Movement Timeline"}
      maxHeight={maxHeight}
    />
  );
}

export default ProfileViewTimeline;
