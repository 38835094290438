import React from "react";
import { Toaster } from "react-hot-toast";
import ReactDOM from "react-dom";

function ToastHolder() {
  return ReactDOM.createPortal(
    <Toaster position="top-center" reverseOrder={true} />,
    document.getElementById("portal")
  );
}

export default ToastHolder;
