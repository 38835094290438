import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { useSelector } from "react-redux";
import GatewaySelect from "components/UserInputComponents/Selects/GatewaySelect";
import BaseTextInput from "components/UserInputComponents/Inputs/TextInputs/BaseTextInput";
import useQuery from "customHooks/useQuery";

function GatewayPingsFilter({ setParams }) {
  const orgId = useSelector((state) => state.organization._id);
  const [{ macId }] = useQuery();
  const [gatewayMac, setGatewayMac] = useState("");

  useEffect(() => {
    if (!macId) return;
    setGatewayMac(macId);
    setParams({
      organizationId: orgId,
      macId: macId,
    });
  }, []);

  const applyFilters = (e) => {
    e.preventDefault();
    setParams({
      organizationId: orgId,
      macId: gatewayMac,
    });
  };

  return (
    <Card>
      <CardHeader tag="h4">Filters</CardHeader>
      <br />
      <CardBody>
        <GatewaySelect
          value={gatewayMac}
          setValue={setGatewayMac}
          pickKey="macId"
        />
        <BaseTextInput value={gatewayMac} setValue={setGatewayMac} />
        <Button color="info" onClick={applyFilters}>
          Apply
        </Button>
      </CardBody>
    </Card>
  );
}

export default GatewayPingsFilter;
